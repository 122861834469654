import { CALL_API } from '../../../middleware/api'
import * as AddUpdateFacilityApiActionConstants from "./AddUpdateFacilityApiActionConstants"
import {getAddUpdateFacilityUriPath} from '../EndpointResolver'

export function addUpdateFacility(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateFacilityUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateFacilityApiActionConstants.ADD_UPDATE_FACILITY_REQUEST,
                AddUpdateFacilityApiActionConstants.ADD_UPDATE_FACILITY_SUCCESS,
                AddUpdateFacilityApiActionConstants.ADD_UPDATE_FACILITY_FAILED,
            ]
        },
    }
}

export function addUpdateFacilitySelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateFacilityApiActionConstants.ADD_UPDATE_FACILITY_SELECTED,
             val: val
         })
     }
}