import { CALL_API } from '../../../middleware/api'
import * as ValidateIcd10cmCodeApiActionConstants from "./ValidateIcd10cmCodeApiActionConstants"
import { validateIcd10cmCodeUriPath } from '../EndpointResolver'

export default function validateIcd10cmCode(code) {
    return {
        [CALL_API]: {
            endpoint: validateIcd10cmCodeUriPath(code),
            config: { method: 'get' },
            types: [
                ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_REQUEST,
                ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_SUCCESS,
                ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_FAILED,
            ],
        },
    }
}