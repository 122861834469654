import { CALL_API } from '../../../middleware/api'
import * as AddUpdateClientApiActionConstants from "./AddUpdateClientApiActionConstants"
import {getAddUpdateClientUriPath} from '../EndpointResolver'

export function addUpdateClient(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateClientUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_REQUEST,
                AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_SUCCESS,
                AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_FAILED,
            ]
        },
    }
}

export function addUpdateClientSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_SELECTED,
             val: val
         })
     }
}