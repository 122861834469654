import * as GetPayersApiActionConstants from '../../redux/actions/api/payer/GetPayersApiActionConstants'

const initialState = {
    data: []
};

export default function getPayersReducer(state = initialState, action) {
    switch (action.type) {
        case GetPayersApiActionConstants.GET_PAYERS_REQUEST:
            return {
                ...state,
            };
        case GetPayersApiActionConstants.GET_PAYERS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
            break;
        }
        case GetPayersApiActionConstants.GET_PAYERS_FAILED:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
