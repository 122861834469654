import * as GetPayerDtoApiActionConstants from '../../redux/actions/api/payer/GetPayerDtoApiActionConstants'

const initialState = {
    data: {}
};

export default function getPayerDtoReducer(state = initialState, action) {
    switch (action.type) {
        case GetPayerDtoApiActionConstants.GET_PAYER_DTO_REQUEST:
            return {
                ...state,
            };
        case GetPayerDtoApiActionConstants.GET_PAYER_DTO_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
            break;
        }
        case GetPayerDtoApiActionConstants.GET_PAYER_DTO_FAILED:
            return {
                ...state,
                data: {}
            };
        default:
            return state
    }
}
