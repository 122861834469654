import * as GetUserApiActionConstants from '../../redux/actions/api/authentication/GetUserApiActionConstants'

const initialState = {
    data: ''
};

export default function getUserReducer(state = initialState, action) {
    switch (action.type) {
        case GetUserApiActionConstants.GET_USER_REQUEST:
            return {
                ...state,
            };
        case GetUserApiActionConstants.GET_USER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetUserApiActionConstants.GET_USER_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
