import { CALL_API } from '../../../middleware/api'
import * as ValidateIcdCptApiActionConstants from "./ValidateIcdCptApiActionConstants"
import { validateIcdCptUriPath } from '../EndpointResolver'

export function validateIcdCpt(clientId, authId) {
    return {
        [CALL_API]: {
            endpoint: validateIcdCptUriPath(clientId, authId),
            config: { method: 'get' },
            types: [
                ValidateIcdCptApiActionConstants.VALIDATE_ICD_CPT_REQUEST,
                ValidateIcdCptApiActionConstants.VALIDATE_ICD_CPT_SUCCESS,
                ValidateIcdCptApiActionConstants.VALIDATE_ICD_CPT_FAILED,
            ],
        },
    }
}

export function validateIcdCptSelected(val) {
     return function (dispatch) {
         dispatch({
             type: ValidateIcdCptApiActionConstants.VALIDATE_ICD_CPT_SELECTED,
             val: val
         })
     }
}