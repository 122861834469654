import * as AddUpdateAuthorizationProviderApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationProviderApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationProviderReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
