import { CALL_API } from '../../../middleware/api'
import * as GetClientEmployeesApiActionConstants from "./GetClientEmployeesApiActionConstants"
import {getClientEmployeesUriPath} from '../EndpointResolver'

export function getClientEmployees(parentId) {
    return {
        [CALL_API]: {
            endpoint: getClientEmployeesUriPath(parentId),
            config: { method: 'get' },
            types: [
                GetClientEmployeesApiActionConstants.GET_CLIENT_EMPLOYEES_REQUEST,
                GetClientEmployeesApiActionConstants.GET_CLIENT_EMPLOYEES_SUCCESS,
                GetClientEmployeesApiActionConstants.GET_CLIENT_EMPLOYEES_FAILED,
            ],
        },
    }
}

export function clientEmployeesClear() {
     return function (dispatch) {
         dispatch({
             type: GetClientEmployeesApiActionConstants.GET_CLIENT_EMPLOYEES_CLEAR
         })
     }
}
