import React, { useState, Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import moment from "moment";

class EncounterInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id:'',
            accountNumber: '',
            visitDate: '',
            visitTime: '',
            orderNumber: ''
        };
    }

    async componentDidMount() {
        const {selectedEncounter} = this.props;

        if(selectedEncounter){
            this.setState(selectedEncounter);
        }

    }

    render() {
        return (
            <div>
                <div className="mb-3">
                    Encounter
                </div>
                <Form>
                    <Row className="mb-1">
                        <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="accountNumber">
                                <Form.Label
                                    className="table-form-label mb-0">Account Number</Form.Label>
                                <Form.Control
                                    className="grid-form-control"
                                    placeholder="Account Number"
                                    value={this.state.accountNumber} onChange={e => this.setState({accountNumber:e.target.value})}
                                    />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-6">
                            <Form.Group as={Col}
                                        controlId="orderNumber">
                                <Form.Label
                                    className="table-form-label mb-0">Order Number</Form.Label>
                                <Form.Control
                                    className="grid-form-control"
                                    placeholder="Referring Physician"
                                    value={this.state.orderNumber} onChange={e => this.setState({orderNumber:e.target.value})}
                                    />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-6">
                            <Form.Group as={Col}
                                controlId="visitDate">
                                <Form.Label
                                    className="table-form-label mb-0">Visit Date</Form.Label>
                                <Form.Control
                                        className="grid-form-control"
                                        placeholder="Referring Physician"
                                        value={this.state.visitDate} onChange={e => this.setState({visitDate:e.target.value})}
                                        />
                            </Form.Group>
                        </div>
                        <div className="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="authStatus">
                                <Form.Label
                                    className="table-form-label mb-0">Visit Time</Form.Label>
                                <Form.Control
                                        className="grid-form-control"
                                        placeholder="Referring Physician"
                                        value={this.state.visitTime} onChange={e => this.setState({visitTime:e.target.value})}
                                        />
                            </Form.Group>
                        </div>

                    </Row>

                    <Row>
                         <div>
                             <Button className="form-save-btn btn-primary mt-1" type="button" onClick={() => {this.saveEncounter();}}>
                                Next
                             </Button>
                         </div>
                   </Row>
                </Form>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const mapStateToProps = state => {
    return {
        payers: state.getPayersAll.data,
        selectedEncounter: state.addUpdateEncounter.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EncounterInfo)