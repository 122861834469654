import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationProviderApiActionConstants from "./GetAuthorizationProviderApiActionConstants"
import { getAuthorizationProviderUriPath } from '../EndpointResolver'

export function getAuthorizationProvider(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationProviderUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationProviderApiActionConstants.GET_AUTHORIZATION_PROVIDER_REQUEST,
                GetAuthorizationProviderApiActionConstants.GET_AUTHORIZATION_PROVIDER_SUCCESS,
                GetAuthorizationProviderApiActionConstants.GET_AUTHORIZATION_PROVIDER_FAILED,
            ],
        },
    }
}

export function getAuthorizationProviderSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationProviderApiActionConstants.GET_AUTHORIZATION_PROVIDER_SELECTED,
             val: val
         })
     }
}