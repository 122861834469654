import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationProcedureApiActionConstants from "./GetAuthorizationProcedureApiActionConstants"
import { getAuthorizationProcedureUriPath } from '../EndpointResolver'

export function getAuthorizationProcedure(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationProcedureUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationProcedureApiActionConstants.GET_AUTHORIZATION_PROCEDURE_REQUEST,
                GetAuthorizationProcedureApiActionConstants.GET_AUTHORIZATION_PROCEDURE_SUCCESS,
                GetAuthorizationProcedureApiActionConstants.GET_AUTHORIZATION_PROCEDURE_FAILED,
            ],
        },
    }
}

export function getAuthorizationProcedureSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationProcedureApiActionConstants.GET_AUTHORIZATION_PROCEDURE_SELECTED,
             val: val
         })
     }
}