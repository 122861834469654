import * as GetCorrespondenceApiActionConstants from "../actions/api/correspondence/GetCorrespondenceApiActionConstants";

const initialState = {
  data: [],
};

export default function getCorrespondenceReducer(state = initialState, action) {
  switch (action.type) {
    case GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_REQUEST:
      return {
        ...state,
      };
    case GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_SUCCESS: {
      return {
        ...state,
        data: action.data,
      };
    }
    case GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_FAILED: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
}
