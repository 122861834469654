import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationDuplicatesApiActionConstants from "./GetAuthorizationDuplicatesApiActionConstants"
import { getAuthorizationDuplicatesUriPath } from '../EndpointResolver'

export function getAuthorizationDuplicates(clientId, type, id, orderNumber) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationDuplicatesUriPath(clientId, type, id, orderNumber),
            config: { method: 'get' },
            types: [
                GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_REQUEST,
                GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_SUCCESS,
                GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_FAILED,
            ],
        },
    }
}