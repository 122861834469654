import * as AddUpdatePhoneEmailApiActionConstants from '../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdatePhoneEmailReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_REQUEST:
            return {
                ...state,
            };
        case AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
