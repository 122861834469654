import * as GetTransactionTypeListApiActionConstants from '../../redux/actions/api/transaction-type/GetTransactionTypeListApiActionConstants'

const initialState = {
    data: [],
    selected: null
};

export default function getTransactionTypeListReducer(state = initialState, action) {
    switch (action.type) {
        case GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_REQUEST:
            return {
                ...state,
            };
        case GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_SUCCESS: {
            return {
                ...state,
                data: action.data,
                selected: action.data
            };
        }
        case GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_FAILED:
            return {
                ...state,
                data: []
            };
        case GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_SELECTED:
            return {
                ...state,
                selected: action.val
            };
        case GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_CLEAR:
            return {
                ...state,
                data: [],
                selected: null
            };
        default:
            return state
    }
}
