import * as GetPatientAddressApiActionConstants from '../../redux/actions/api/address/GetPatientAddressApiActionConstants'

const initialState = {
    data: []
};

export default function getPatientAddressReducer(state = initialState, action) {
    switch (action.type) {
        case GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_REQUEST:
            return {
                ...state,
            };
        case GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
