import { CALL_API } from '../../../middleware/api'
import * as GetEmployeeByOrgIdApiActionConstants from "./GetEmployeeByOrgIdApiActionConstants"
import { getEmployeeByOrgIdUriPath } from '../EndpointResolver'

export default function getEmployeeByOrgId(orgId) {
    return {
        [CALL_API]: {
            endpoint: getEmployeeByOrgIdUriPath(orgId),
            config: { method: 'get' },
            types: [
                GetEmployeeByOrgIdApiActionConstants.GET_EMPLOYEE_BY_ORG_ID_REQUEST,
                GetEmployeeByOrgIdApiActionConstants.GET_EMPLOYEE_BY_ORG_ID_SUCCESS,
                GetEmployeeByOrgIdApiActionConstants.GET_EMPLOYEE_BY_ORG_ID_FAILED,
            ],
        },
    }
}