import { CALL_API } from '../../../middleware/api'
import * as DocumentationDownloadFileApiActionConstants from "./DocumentationDownloadFileApiActionConstants"
import { getDocumentationDownloadFileUriPath } from '../EndpointResolver'

export function getDocumentationDownloadFile(filename, clientName) {
    return {
        [CALL_API]: {
            endpoint: getDocumentationDownloadFileUriPath(filename, clientName),
            config: { method: 'get', responseType: 'blob' },
            types: [
                DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_REQUEST,
                DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_SUCCESS,
                DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_FAILED,
            ]
        },
    }
}

export function documentationDownloadFileClear() {
    return {
        type: DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_CLEAR
    }
}