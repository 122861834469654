import { CALL_API } from '../../../middleware/api'
import * as GetAllOrganizationApiActionConstants from "./GetAllOrganizationApiActionConstants"
import { getAllOrganizationUriPath } from '../EndpointResolver'

export default function getAllOrganization() {
    return {
        [CALL_API]: {
            endpoint: getAllOrganizationUriPath(),
            config: { method: 'get' },
            types: [
                GetAllOrganizationApiActionConstants.GET_ALL_ORGANIZATION_REQUEST,
                GetAllOrganizationApiActionConstants.GET_ALL_ORGANIZATION_SUCCESS,
                GetAllOrganizationApiActionConstants.GET_ALL_ORGANIZATION_FAILED,
            ],
        },
}
}