import * as GetFaxApiActionConstants from '../../redux/actions/api/fax/GetFaxApiActionConstants'

const initialState = {
    data: null
};

export default function getFaxReducer(state = initialState, action) {
    switch (action.type) {
        case GetFaxApiActionConstants.GET_FAX_REQUEST:
            return {
                ...state,
            };
        case GetFaxApiActionConstants.GET_FAX_SUCCESS: {
            const file = new Blob(
                [action.data],
                {type: "application/pdf"});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, "cignaFax.pdf");
            }
            else {
                //window.location = fileURL;
            }
            return {
                ...state,
                data: action.data
            };
        }
        case GetFaxApiActionConstants.GET_FAX_FAILED:
            return {
                ...state,
                data: null
            };
        default:
            return state
    }
}