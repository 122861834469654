import { CALL_API } from '../../../middleware/api'
import * as GetUserApiActionConstants from "./GetUserApiActionConstants"
import { getUserUriPath } from '../EndpointResolver'

export default function getUser(userId) {
    return {
        [CALL_API]: {
            endpoint: getUserUriPath(userId),
            config: { method: 'get' },
            types: [
                GetUserApiActionConstants.GET_USER_REQUEST,
                GetUserApiActionConstants.GET_USER_SUCCESS,
                GetUserApiActionConstants.GET_USER_FAILED,
            ],
        },
    }
}