import React, { useState, Component } from "react";
import "./Profile.css"
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import {Table, Tab, Tabs } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

class Profile extends Component {

    constructor(props) {
        super(props);
            this.state = {
             key: 1
            };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(key) {
        console.log('selected' + key);
        this.setState({ key: key });
    };

    render() {

    const onClickHandler = (e) => {

        const elementsShow = document.querySelectorAll('tr.show');
        const hiddenElement = e.currentTarget.nextSibling;

        /*Reset all show details*/
        elementsShow.forEach((element) => {
          if(hiddenElement != element){
            element.classList.remove('show');
          }
        });

        /*Set selected row*/
        const selected = e.currentTarget;
        const elementsSelected = document.querySelectorAll('tr.selected-table-row');

        elementsSelected.forEach((element) => {
          if(selected != element){
            element.classList.remove('selected-table-row');
          }
        });

        selected.classList.add("selected-table-row");

        /*Reset all up arrow*/
        const elementsArrow = document.querySelectorAll('.fa-chevron-up');
        elementsArrow.forEach((element) => {
            element.classList.remove('fa-chevron-up');
            element.classList.add('fa-chevron-down');
        });

        /*set up and show details on selected row*/
        const arrowSelected = document.querySelectorAll('.selected-table-row .fa');

        if(hiddenElement.className.indexOf("collapse show") > -1){
            hiddenElement.classList.remove("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-up');
                element.classList.add('fa-chevron-down');
            });

        }else{
            hiddenElement.classList.add("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-down');
                element.classList.add('fa-chevron-up');
            });

        }

    };

    return (
        <div>
           <Tabs
           activeKey={this.state.key}
           onSelect={this.handleSelect}
           id="controlled-tab-example"
           >
               <Tab eventKey={1} title="PROFILE">
                    <div class="form-title-label mt-4">
                        <label class="border-bottom"class="border-bottom">Basic</label>
                    </div>
                    <Row className="mb-1">
                       <div class="col-lg-3 col-6">
                           <Form.Group as={Col} controlId="">
                             <Form.Label className="table-form-label mb-0">First Name</Form.Label>
                             <Form.Control className="grid-form-control" placeholder="Jane" />
                           </Form.Group>
                       </div>
                       <div class="col-lg-3 col-6">
                           <Form.Group as={Col} controlId="">
                             <Form.Label className="table-form-label mb-0">Middle Name</Form.Label>
                             <Form.Control className="grid-form-control" placeholder="Pablo" />
                           </Form.Group>
                       </div>
                       <div class="col-lg-3 col-6">
                           <Form.Group as={Col} controlId="">
                             <Form.Label className="table-form-label mb-0">Last Name</Form.Label>
                             <Form.Control className="grid-form-control" placeholder="Sainz" />
                           </Form.Group>
                       </div>
                    </Row>
                    <Row className="mb-1">
                       <div class="col-lg-3 col-6">
                           <Form.Group as={Col} controlId="">
                             <Form.Label className="table-form-label mb-0">SSN</Form.Label>
                             <Form.Control className="grid-form-control" placeholder="1234" />
                           </Form.Group>
                       </div>
                       <div class="col-lg-3 col-6">
                           <Form.Group as={Col} controlId="">
                             <Form.Label className="table-form-label mb-0">Date of Birth</Form.Label>
                             <Form.Control className="grid-form-control" placeholder="10-18-1994" />
                           </Form.Group>
                       </div>
                    </Row>
                    <div class="form-title-label mt-4">
                        <label class="border-bottom"class="border-bottom">Contacts</label>
                    </div>
                    <Table bordered className="table-main">
                       <thead>
                           <tr>
                               <th className="td-first"></th>
                               <th>Phone Number</th>
                               <th>Cell</th>
                               <th className="td-last">Email</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr onClick={onClickHandler}>
                               <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                               <td>4569663974</td>
                               <td>1234567</td>
                               <td td-last>msainz@sample.com</td>
                           </tr>
                           <tr className="collapse">
                               <td className="td-first td-last"colSpan="4">
                                   <Form cla>
                                        <Row className="mb-1">
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="patientFullName">
                                                 <Form.Label className="table-form-label mb-0">Phone Number</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="4569663974" />
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="ssn">
                                                 <Form.Label className="table-form-label mb-0">Cell</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="1234567"/>
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="formGridPassword">
                                                 <Form.Label className="table-form-label mb-0">Email</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="msainz@sample.com" />
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Device</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="PHONE"/>
                                               </Form.Group>
                                           </div>
                                        </Row>
                                   </Form>
                               </td>
                           </tr>
                      </tbody>
                    </Table>
                    <div class="form-title-label mt-4">
                        <label class="border-bottom"class="border-bottom">Addresses</label>
                    </div>
                    <Table bordered className="table-main">
                       <thead>
                           <tr>
                               <th className="td-first"></th>
                               <th>Address</th>
                               <th>City</th>
                               <th>State</th>
                               <th className="td-last">Type</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr onClick={onClickHandler}>
                               <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                               <td>309 MAIN STREET, DUKE CENTER, PA</td>
                               <td>DUKE CENTER</td>
                               <td>PENNSYLVANIA</td>
                               <td className="td-last">Home</td>
                           </tr>
                           <tr className="collapse">
                               <td className="td-first td-last"colSpan="5">
                                   <Form cla>
                                        <Row className="mb-1">
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Address</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="309 MAIN STREET, DUKE CENTER, PA"/>
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">City</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="DUKE CENTER"/>
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Country</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="AZ"/>
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">State</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="PENNSYLVANIA"/>
                                               </Form.Group>
                                           </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Type</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="HOME"/>
                                               </Form.Group>
                                            </div>
                                            <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Source</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="HOSPITAL" />
                                               </Form.Group>
                                            </div>
                                            <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Person</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="ABBY  WILLIAMS"/>
                                               </Form.Group>
                                           </div>
                                           <div class="col-lg-3 col-6">
                                               <Form.Group as={Col} controlId="">
                                                 <Form.Label className="table-form-label mb-0">Zip</Form.Label>
                                                 <Form.Control className="grid-form-control" placeholder="16729"/>
                                               </Form.Group>
                                            </div>
                                        </Row>
                                   </Form>
                               </td>
                           </tr>
                      </tbody>
                    </Table>
               </Tab>
               <Tab eventKey={2} title="ACCOUNT INFO">
                   <Table bordered className="table-main">
                       <thead>
                           <tr>
                               <th className="td-first"></th>
                               <th>First Name</th>
                               <th>Middle Name</th>
                               <th>Last Name</th>
                               <th>DOB</th>
                               <th className="td-last">MRN</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr onClick={onClickHandler}>
                               <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                               <td>Jane</td>
                               <td>Pablo</td>
                               <td>Sainz</td>
                               <td>10-18-1994</td>
                               <td className="td-last">1234</td>
                           </tr>
                           <tr className="collapse">
                              <td className="td-first td-last"colSpan="6">
                                  <Form cla>
                                       <Row className="mb-1">
                                          <div class="col-lg-3 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">First Name</Form.Label>
                                                <Form.Control className="grid-form-control" placeholder="Jane"/>
                                              </Form.Group>
                                          </div>
                                          <div class="col-lg-3 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">Middle Name</Form.Label>
                                                <Form.Control className="grid-form-control" placeholder="Pablo"/>
                                              </Form.Group>
                                          </div>
                                          <div class="col-lg-3 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">Last Nae</Form.Label>
                                                <Form.Control className="grid-form-control" placeholder="Sainz"/>
                                              </Form.Group>
                                          </div>
                                          <div class="col-lg-3 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">Date of Birth</Form.Label>
                                                <Form.Control className="grid-form-control" placeholder="10-18-1994"/>
                                              </Form.Group>
                                          </div>
                                       </Row>
                                       <Row className="mb-1">
                                           <div class="col-lg-3 col-6">
                                              <Form.Group as={Col} controlId="">
                                                <Form.Label className="table-form-label mb-0">MRN</Form.Label>
                                                <Form.Control className="grid-form-control" placeholder="1234"/>
                                              </Form.Group>
                                           </div>
                                       </Row>
                                  </Form>
                              </td>
                          </tr>
                       </tbody>
                   </Table>
               </Tab>
           </Tabs>
        </div>
    );

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile)