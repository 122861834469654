import { CALL_API } from '../../../middleware/api'
import * as AddUpdateInsuranceApiActionConstants from "./AddUpdateInsuranceApiActionConstants"
import {getAddUpdateInsuranceUriPath} from '../EndpointResolver'

export function addUpdateInsurance(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateInsuranceUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_REQUEST,
                AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_SUCCESS,
                AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_FAILED,
            ]
        },
    }
}