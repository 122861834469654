import * as AddUpdateCorrespondenceApiActionConstants from "./../actions/api/correspondence/AddUpdateCorrespondenceApiActionConstants";

const initialState = {
  data: "",
};

export default function addUpdateCorrespondenceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_REQUEST:
      return {
        ...state,
      };
    case AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_FAILED:
      return {
        ...state,
        data: "",
      };
    default:
      return state;
  }
}
