import { CALL_API } from '../../../middleware/api'
import * as AddUpdateGuarantorApiActionConstants from "./AddUpdateGuarantorApiActionConstants"
import {getAddUpdatePatientUriPath, getPatientGuarantorUriPath} from '../EndpointResolver'

export function addUpdateGuarantor(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdatePatientUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_REQUEST,
                AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_SUCCESS,
                AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_FAILED,
            ]
        },
    }
}


export function getPatientGuarantor(parentId) {
    return {
            [CALL_API]: {
                endpoint: getPatientGuarantorUriPath(parentId),
                config: { method: 'get' },
                types: [
                    AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_REQUEST,
                    AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_SUCCESS,
                    AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_FAILED,
                ],
            },
        }
}

export function addUpdateGuarantorSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateGuarantorApiActionConstants.ADD_UPDATE_GUARANTOR_SELECTED,
             val: val
         })
     }
}