import * as MedicalNecessityApiActionConstants from '../actions/api/encounter/MedicalNecessityApiActionConstants';
const initialState = {
    data:{}
};

export default function MedicalNecessity(state = initialState, action) {
    switch (action.type) {
        case MedicalNecessityApiActionConstants.MEDICAL_NECESSITY_REQUEST:
            return {
                ...state,
            };
        case MedicalNecessityApiActionConstants.MEDICAL_NECESSITY_SUCCESS:
            const filetype = localStorage.getItem("tickler_sent_attachment_filetype");
            const file = new Blob(
                [action.data],
                {type: filetype});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {

                window.navigator.msSaveOrOpenBlob(file, localStorage.getItem("tickler_sent_attachment_filename"));

            }
            else {
                //window.location = fileURL;
            }

            return {
                ...state,
                data:action.data
            };
        case MedicalNecessityApiActionConstants.MEDICAL_NECESSITY_FAILED:


            return {
                ...state,
                data: {}
            };
        case MedicalNecessityApiActionConstants.MEDICAL_NECESSITY_CLEAR:
            return {
                ...state,
                data: {}
            };
        default:
            return state
    }
}
