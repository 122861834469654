import { CALL_API } from '../../../middleware/api'
import * as GetOrganizationApiActionConstants from "./GetOrganizationApiActionConstants"
import { getOrganizationUriPath } from '../EndpointResolver'

export default function getOrganization(orgId) {
    return {
        [CALL_API]: {
            endpoint: getOrganizationUriPath(orgId),
            config: { method: 'get' },
            types: [
                GetOrganizationApiActionConstants.GET_ORGANIZATION_REQUEST,
                GetOrganizationApiActionConstants.GET_ORGANIZATION_SUCCESS,
                GetOrganizationApiActionConstants.GET_ORGANIZATION_FAILED,
            ],
        },
    }
}