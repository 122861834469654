import { CALL_API } from '../../../middleware/api'
import * as GetFaxApiActionConstants from "./GetFaxApiActionConstants"
import { getCignaFaxUriPath } from '../EndpointResolver'

export default function getCignaFax(clientId, authId) {
    return {
        [CALL_API]: {
            endpoint: getCignaFaxUriPath(clientId, authId),
            config: { method: 'get', responseType: 'blob' },
            types: [
                GetFaxApiActionConstants.GET_FAX_REQUEST,
                GetFaxApiActionConstants.GET_FAX_SUCCESS,
                GetFaxApiActionConstants.GET_FAX_FAILED,
            ],
        },
    }
}