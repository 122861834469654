import * as GetNotesApiActionConstants from "./../actions/api/client/GetNotesApiActionConstants";

const initialState = {
  data: [],
};

export default function GetNotesReducer(state = initialState, action) {
  switch (action.type) {
    case GetNotesApiActionConstants.GET_NOTE_REQUEST:
      return {
        ...state,
      };
    case GetNotesApiActionConstants.GET_NOTE_SUCCESS: {
      return {
        ...state,
        data: action.data,
      };
    }
    case GetNotesApiActionConstants.GET_NOTE_FAILED: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
}
