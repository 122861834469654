import { CALL_API } from '../../../middleware/api'
import * as GetClientsByOrgIdApiActionConstants from "./GetClientsByOrgIdApiActionConstants"
import { getClientsByOrgIdUriPath } from '../EndpointResolver'

export default function getClientsByOrgId(orgId) {
    return {
        [CALL_API]: {
            endpoint: getClientsByOrgIdUriPath(orgId),
            config: { method: 'get' },
            types: [
                GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_REQUEST,
                GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_SUCCESS,
                GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_FAILED,
            ],
        },
    }
}