import * as AddUpdateAuthorization278ApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorization278ApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorization278Reducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
