import { CALL_API } from '../../../middleware/api'
import * as GetPhoneEmailCommonApiActionConstants from "./GetPhoneEmailCommonApiActionConstants"
import {getPhoneEmailCommonUriPath} from '../EndpointResolver'

export function getPhoneEmailCommon(parentId, parentType, guaId) {
    return {
        [CALL_API]: {
            endpoint: getPhoneEmailCommonUriPath(parentId, parentType, guaId),
            config: { method: 'get' },
            types: [
                GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_REQUEST,
                GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_SUCCESS,
                GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_FAILED,
            ],
        },
    }
}


export function phoneEmailCommonClear() {
    return function (dispatch) {
        dispatch({
            type: GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_CLEAR
        })
    }
}