import { CALL_API } from '../../../middleware/api'
import * as GetCptDescriptionApiActionConstants from "./GetCptDescriptionApiActionConstants"
import { getCptDescriptionUriPath } from '../EndpointResolver'

export function getCptDescription(clientId, cptCode) {
    return {
        [CALL_API]: {
            endpoint: getCptDescriptionUriPath(clientId, cptCode),
            config: { method: 'get' },
            types: [
                GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_REQUEST,
                GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_SUCCESS,
                GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_FAILED,
            ],
        },
    }
}