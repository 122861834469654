import { CALL_API } from '../../../middleware/api'
import * as AddUpdateDocumentationApiActionConstants from "./AddUpdateDocumentationApiActionConstants"
import {getAddUpdateDocumentationUriPath} from '../EndpointResolver'

export function addUpdateDocumentation(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateDocumentationUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateDocumentationApiActionConstants.ADD_UPDATE_DOCUMENTATION_REQUEST,
                AddUpdateDocumentationApiActionConstants.ADD_UPDATE_DOCUMENTATION_SUCCESS,
                AddUpdateDocumentationApiActionConstants.ADD_UPDATE_DOCUMENTATION_FAILED,
            ]
        },
    }
}