import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAddressApiActionConstants from "./AddUpdateAddressApiActionConstants"
import {getAddUpdateAddressUriPath} from '../EndpointResolver'

export function addUpdateAddress(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAddressUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAddressApiActionConstants.ADD_UPDATE_ADDRESS_REQUEST,
                AddUpdateAddressApiActionConstants.ADD_UPDATE_ADDRESS_SUCCESS,
                AddUpdateAddressApiActionConstants.ADD_UPDATE_ADDRESS_FAILED,
            ]
        },
    }
}