import * as AddUpdateAuthorizationDocumentationByStatusApiActionConstants from '../../redux/actions/api/authorizationDocumentation/AddUpdateAuthorizationDocumentationByStatusApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationDocumentationByStatusReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_CLEAR:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
