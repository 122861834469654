import React, {Component} from "react";
import {addUpdatePhoneEmail} from "../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActions";
import {connect} from "react-redux";
import {WithRouter} from "../common/WithRouter";
import {bindActionCreators} from "redux"
import {Form, Row, Table, Col, Button} from "react-bootstrap";
import {getPhoneEmailCommon} from "../../redux/actions/api/phoneEmail/GetPhoneEmailCommonApiActions";


class PhoneEmail extends Component {

    state = {
        type: null,
        subtype: null,
        numberEmail: null,
        status: null,
        isPrimary: null,
        sms: null,
        isNew:'Y',
        btnClick:'N',
    };

    phoneEmailDefault = {
        id: null,
        type: 'WORK',
        subtype: 'LANDLINE',
        numberEmail: '',
        isPrimary: true,
        sms: true,
        status: 'ACTIVE',
        btnClick: 'Y'
    };

    savePhoneEmail = async () => {
        if (!this.state.numberEmail) return;

        const {isNew} = this.state;
        const data = this.props.data;
        let phoneEmail = {...this.state};
        console.log("phoneEmial" , phoneEmail);

        phoneEmail.isPrimary = phoneEmail.isPrimary?"Y":"N";
        phoneEmail.sms = phoneEmail.sms?"Y":"N";
        phoneEmail.parentId = data.parentId;
        phoneEmail.parentType = data.parentType;
        phoneEmail.guarantorId = data.guarantorId?data.guarantorId:null;
        await this.props.addUpdatePhoneEmail(phoneEmail);
        await this.props.getPhoneEmailCommon(data.parentId, data.parentType, data.guarantorId?data.guarantorId:"");
        console.log('I am here 4');
        if(isNew == 'Y'){
            this.setState(this.phoneEmailDefault);
        }

    };

    onClickHandler(record, e) {

        console.log('I am here 2');
        var {btnClick} = this.state;

        console.log('test ' + btnClick);



        if(btnClick == 'N'){
            console.log('I am here 3');

            //
        }
        console.log(record);
        this.setState(record);

        const elementsShow = document.querySelectorAll('tr.show');
        const hiddenElement = e.currentTarget.nextSibling;

        /*Reset all show details*/
        elementsShow.forEach((element) => {
            if(hiddenElement != element){
                element.classList.remove('show');
            }
        });

        /*Set selected row*/
        const selected = e.currentTarget;
        const elementsSelected = document.querySelectorAll('tr.selected-table-row');

        elementsSelected.forEach((element) => {
            if(selected != element){
                element.classList.remove('selected-table-row');
            }
        });

        selected.classList.add("selected-table-row");

        /*Reset all up arrow*/
        const elementsArrow = document.querySelectorAll('.fa-chevron-up');
        elementsArrow.forEach((element) => {
            element.classList.remove('fa-chevron-up');
            element.classList.add('fa-chevron-down');
        });

        /*set up and show details on selected row*/
        const arrowSelected = document.querySelectorAll('.selected-table-row .fa');

        if(hiddenElement.className.indexOf("collapse show") > -1){
            hiddenElement.classList.remove("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-up');
                element.classList.add('fa-chevron-down');
            });

        }else{
            hiddenElement.classList.add("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-down');
                element.classList.add('fa-chevron-up');
            });

        }
        //}

        //this.setState({btnClick:'N'});

    };

    async componentDidUpdate(prevProps, prevState) {
        if(prevProps.data != this.props.data) {
            let data = this.props.data;
            if((data.parentId && data.parentType) || data.guarantorId) {
                await this.props.getPhoneEmailCommon(data.parentId, data.parentType, data.guarantorId?data.guarantorId:"")
            }
        }
    }

    async componentDidMount() {
        console.log(this.props.data);
        const data = this.props.data;
        if((data.parentId && data.parentType) || data.guarantorId) {
            await this.props.getPhoneEmailCommon(data.parentId, data.parentType, data.guarantorId?data.guarantorId:"")
        }
    };

    render() {

        let phoneEmailList = [];
        if(this.props.phoneEmailCommon) {
            phoneEmailList = this.props.phoneEmailCommon;
        }

        return(
            <Table bordered className="mt-2 table-main">
                <thead>
                <tr>
                    <th className="td-first"></th>
                    <th>Type</th>
                    <th>Number/ Email</th>
                    <th>Sub Type</th>
                    <th className="td-last">Status</th>
                </tr>
                </thead>
                <tbody>
                {phoneEmailList.map((record, key) => {
                    return (
                        <>
                            <tr key={key} onClick={(event)=> this.onClickHandler({...record, isNew:'N', btnClick:'N'}, event)}>
                                <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                                <td>{record.type}</td>
                                <td>{record.numberEmail}</td>
                                <td>{record.subtype}</td>
                                <td className="td-last">{record.status}</td>
                            </tr>
                            <tr key={key + 1000} className="sub-table collapse">
                                <td className="td-first td-last"colSpan="6">
                                    <Form>
                                        <Row className="mb-1">
                                            <Col>
                                                <Form.Group as={Col} controlId="type">
                                                    <Form.Label className="table-form-label mb-0">Type</Form.Label>
                                                    <Form.Select className="grid-form-control" value={this.state.type} onChange={e => {
                                                        if(e.target.value == "EMAIL") {
                                                            this.setState({subtype: "EMAIL"})
                                                        }
                                                        this.setState({type:e.target.value})
                                                    }}>
                                                        <option value="HOME">HOME</option>
                                                        <option value="WORK">WORK</option>
                                                        <option value="EMAIL">EMAIL</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Col} controlId="subType">
                                                    <Form.Label className="table-form-label mb-0">Sub Type</Form.Label>
                                                    <Form.Select className="grid-form-control" value={this.state.subtype} onChange={e => this.setState({subtype:e.target.value})}>
                                                        <option value="MOBILE">MOBILE</option>
                                                        <option value="LANDLINE">LANDLINE</option>
                                                        <option value="EMAIL">EMAIL</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Col} controlId="numberEmail">
                                                    <Form.Label className="table-form-label mb-0">Number/ Email</Form.Label>
                                                    <Form.Control className="grid-form-control" maxLength={40} value={this.state.numberEmail} onChange={e => this.setState({numberEmail:e.target.value})}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Col} controlId="status">
                                                    <Form.Label className="table-form-label mb-0">Status</Form.Label>
                                                    <Form.Select className="grid-form-control" value={this.state.status} onChange={e => this.setState({status:e.target.value})}>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="BAD">BAD</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Col} controlId="isPrimary">
                                                    <Form.Check
                                                        className="grid-form-control mb-0"
                                                        type="checkbox"
                                                        label="Primary"
                                                        checked={this.state.isPrimary}
                                                        onChange={(e) => this.setState({isPrimary: e.target.checked})}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="sms">
                                                    <Form.Check
                                                        className="grid-form-control mb-0"
                                                        type="checkbox"
                                                        label="SMS"
                                                        checked={this.state.sms}
                                                        onChange={(e) => this.setState({sms: e.target.checked})}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div div className="d-flex justify-content-center">
                                                <Button className="form-save-btn btn-primary mt-1" type="button" onClick={() => {this.savePhoneEmail();}}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </td>
                            </tr>
                        </>
                    )
                })}
                <tr onClick={(event)=> this.onClickHandler({...this.phoneEmailDefault, isNew:'Y', btnClick:'N'}, event)}>
                    <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="td-last">
                    </td>
                </tr>
                <tr className="sub-table collapse">
                    <td className="td-first td-last"colSpan="6">
                        <Form>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group as={Col} controlId="type">
                                        <Form.Label className="table-form-label mb-0">Type</Form.Label>
                                        <Form.Select className="grid-form-control" value={this.state.type} onChange={e => {
                                            if(e.target.value == "EMAIL") {
                                                this.setState({subtype: "EMAIL", sms: false})
                                            }
                                            this.setState({type:e.target.value})
                                        }}>
                                            <option value="HOME">HOME</option>
                                            <option value="WORK">WORK</option>
                                            <option value="EMAIL">EMAIL</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Col} controlId="subtype">
                                        <Form.Label className="table-form-label mb-0">Sub Type</Form.Label>
                                        <Form.Select className="grid-form-control" value={this.state.subtype} onChange={e => this.setState({subtype:e.target.value})}>
                                            <option value="MOBILE">MOBILE</option>
                                            <option value="LANDLINE">LANDLINE</option>
                                            <option value="EMAIL">EMAIL</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Col} controlId="numberEmail">
                                        <Form.Label className="table-form-label mb-0">Number/ Email</Form.Label>
                                        <Form.Control className="grid-form-control" maxLength={40} value={this.state.numberEmail} onChange={e => this.setState({numberEmail:e.target.value})}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Col} controlId="status">
                                        <Form.Label className="table-form-label mb-0">Status</Form.Label>
                                        <Form.Select className="grid-form-control" value={this.state.status} onChange={e => this.setState({status:e.target.value})}>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="BAD">BAD</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group as={Col} controlId="isPrimary">
                                        <Form.Check
                                            className="grid-form-control mb-0"
                                            type="checkbox"
                                            label="Primary"
                                            checked={this.state.isPrimary}
                                            onChange={(e) => this.setState({isPrimary: e.target.checked})}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="sms">
                                        <Form.Check
                                            className="grid-form-control mb-0"
                                            type="checkbox"
                                            label="SMS"
                                            checked={this.state.sms}
                                            onChange={(e) => this.setState({sms: e.target.checked})}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-center">
                                    <Button className="form-save-btn btn-primary mt-1" type="button" onClick={() => {this.savePhoneEmail();}}>
                                        Save
                                    </Button>
                                </div>
                            </Row>
                        </Form>
                    </td>
                </tr>
                </tbody>
            </Table>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addUpdatePhoneEmail: bindActionCreators(addUpdatePhoneEmail, dispatch),
        getPhoneEmailCommon: bindActionCreators(getPhoneEmailCommon, dispatch)
    }
};

const mapStateToProps = state => {
    return {
        phoneEmailCommon: state.getPhoneEmailCommon.data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(PhoneEmail))