import { CALL_API } from "../../../middleware/api";
import * as CorrespondenceDownloadFileApiActionConstants from "./CorrespondenceDownloadFileApiActionConstants";
import { getCorrespondenceDownloadFileUriPath } from "../EndpointResolver";

export function getCorrespondenceDownloadFile(filename, clientName) {
  return {
    [CALL_API]: {
      endpoint: getCorrespondenceDownloadFileUriPath(filename, clientName),
      config: { method: "get", responseType: "blob" },
      types: [
        CorrespondenceDownloadFileApiActionConstants.CORRESPONDENCE_DOWNLOAD_FILE_REQUEST,
        CorrespondenceDownloadFileApiActionConstants.CORRESPONDENCE_DOWNLOAD_FILE_SUCCESS,
        CorrespondenceDownloadFileApiActionConstants.CORRESPONDENCE_DOWNLOAD_FILE_FAILED,
      ],
    },
  };
}

export function correspondenceDownloadFileClear() {
  return {
    type: CorrespondenceDownloadFileApiActionConstants.CORRESPONDENCE_DOWNLOAD_FILE_CLEAR,
  };
}
