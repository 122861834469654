import * as GetGuarantorAddressApiActionConstants from '../../redux/actions/api/address/GetGuarantorAddressApiActionConstants'

const initialState = {
    data: ''
};

export default function getGuarantorAddressReducer(state = initialState, action) {
    switch (action.type) {
        case GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_REQUEST:
            return {
                ...state,
            };
        case GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_FAILED:
            return {
                ...state,
                data: ''
            };
        case GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
