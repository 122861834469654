import * as AddUpdateClientApiActionConstants from '../../redux/actions/api/client/AddUpdateClientApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateClientReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_REQUEST:
            return {
                ...state,
            };
        case AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateClientApiActionConstants.ADD_UPDATE_CLIENT_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
