import * as GetMedicalReqApiActionConstants from '../../redux/actions/api/encounter/GetMedicalReqApiActionConstants'

const initialState = {
    data: []
};

export default function getMedicalReqReducer(state = initialState, action) {
    switch (action.type) {
        case GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_REQUEST:
            return {
                ...state,
            };
        case GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_FAILED:
            return {
                ...state,
                data: []
            };
        case GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
