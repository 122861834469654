import { CALL_API } from '../../../middleware/api'
import * as GetPatientInsuranceApiActionConstants from "./GetPatientInsuranceApiActionConstants"
import {getPatientInsuranceUriPath} from '../EndpointResolver'

export function getPatientInsurance(patientId) {
    return {
        [CALL_API]: {
            endpoint: getPatientInsuranceUriPath(patientId),
            config: { method: 'get' },
            types: [
                GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_REQUEST,
                GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_SUCCESS,
                GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_FAILED,
            ],
        },
    }
}


export function patientInsuranceClear() {
     return function (dispatch) {
         dispatch({
             type: GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_CLEAR
         })
     }
}