import { CALL_API } from '../../../middleware/api'
import * as AddUpdatePhoneEmailApiActionConstants from "./AddUpdatePhoneEmailApiActionConstants"
import {getAddUpdatePhoneEmailUriPath} from '../EndpointResolver'

export function addUpdatePhoneEmail(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdatePhoneEmailUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_REQUEST,
                AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_SUCCESS,
                AddUpdatePhoneEmailApiActionConstants.ADD_UPDATE_PHONE_EMAIL_FAILED,
            ]
        },
    }
}