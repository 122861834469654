import * as GetAuthorizationApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationApiActionConstants.GET_AUTHORIZATION_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationApiActionConstants.GET_AUTHORIZATION_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationApiActionConstants.GET_AUTHORIZATION_FAILED:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
