import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Table, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { formatDateToCustomISO } from "../../common/UtilsDate";

const PatientsTable = (guarantorPatients) => {
  const row = (guarantor, key) => {
    let patientRows = [];
    const patientRow = (patient) => {
      const patientDOB = moment(
        formatDateToCustomISO(new Date(patient.dob))
      ).format("MM/DD/YYYY");
      return (
        <div className="row">
          <div className="col-sm-3">
            Account Number: {patient.accountNumber}
          </div>
          <div className="col-sm-3">MRN: {patient.mrn}</div>
          <div className="col-sm-3">
            Patient Name: {patient.lastName + ", " + patient.firstName}
          </div>
          <div className="col-sm-3">Date of Birth: {patientDOB}</div>
        </div>
      );
    };
    for (let patient of guarantor.patients) {
      patientRows.push(patientRow(patient));
    }
    return (
      <div className="guarantor-patients-view">
        <div className="row px-5" key={key}>
          <div className="col-sm-12">
            Guarantor Name: {guarantor.lastName + ", " + guarantor.firstName}
          </div>
          <div className="col-sm-12">Patients:</div>
          <div className="col-sm-12">{patientRows}</div>
        </div>
      </div>
    );
  };

  const getRows = (guarantorPatients) => {
    let rows = [];
    let key = 0;
    for (let guarantor of guarantorPatients) {
      rows.push(row(guarantor, key));
      key++;
    }
    return rows;
  };

  const onClickHandler = (e) => {
    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
  };

  let rows = getRows(guarantorPatients);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = "add-patients";
    navigate(path);
  };

  return (
    <div className="container-fluid">
      <div className="main-view">
        <div>
          <Button
            className="add-patients-btn btn-primary mb-1 float-end"
            variant="primary"
            type="button"
            onClick={routeChange}
          >
            Add Patient
          </Button>
        </div>
        <Table bordered className="table-main">
          <thead>
            <tr>
              <th>Guarantor Name</th>
              <th>Patient Name</th>
              <th>MRN</th>
              <th>Date of Birth</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={onClickHandler}>
              <td className="td-left-border">Test</td>
              <td>Jane Sainz</td>
              <td>Test</td>
              <td className="td-last">10-18-1994</td>
            </tr>
          </tbody>
        </Table>
        {rows}
      </div>
    </div>
  );
};

const SearchResult = (props) => {
  return <div>{PatientsTable(props.guarantorPatientsSearchResults)}</div>;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
