import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationDetailApiActionConstants from "./AddUpdateAuthorizationDetailApiActionConstants"
import {getAddUpdateAuthorizationUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationDetail(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_REQUEST,
                AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_SUCCESS,
                AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_FAILED,
            ]
        },
    }
}

export function addUpdateAuthorizationDetailSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_SELECTED,
             val: val
         })
     }
}