import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationDocumentationByStatusApiActionConstants from "./AddUpdateAuthorizationDocumentationByStatusApiActionConstants"
import {getAddUpdateAuthorizationDocumentationByStatusUriPath} from '../EndpointResolver'

export function getAddUpdateAuthorizationDocumentationByStatus(clientId, patientId, authorizationId, documentationId, status) {
    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationDocumentationByStatusUriPath(clientId, patientId, authorizationId, documentationId, status),
            config: { method: 'get' },
            types: [
                AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_REQUEST,
                AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_SUCCESS,
                AddUpdateAuthorizationDocumentationByStatusApiActionConstants.ADD_UPDATE_AUTHORIZATION_DOCUMENTATION_BY_STATUS_FAILED,
            ],
        },
    }
}

