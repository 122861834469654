import * as GetAuthorizationDuplicatesApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationDuplicatesApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationDuplicatesReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationDuplicatesApiActionConstants.GET_AUTHORIZATION_DUPLICATES_FAILED:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
