import React, { Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import {Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {WithRouter} from "../../common/WithRouter";
import {
    authDtoClear,
    createAuthDto,
    setAuthDto
} from "../../../redux/actions/api/authorization/CreateAuthDTOApiActions";
import getFacilities from "../../../redux/actions/api/facility/GetFacilitiesApiActions";
import {getProvider} from "../../../redux/actions/api/provider/GetProviderApiActions";
import USStates from "../../common/USStates";

class ServiceProvider extends Component {

    state = {
        role: "FACILITY",
        providerFacility: [],
        name: "",
        firstName: "",
        lastName: "",
        npi: "",
        address1: "",
        city: "",
        state: "",
        zip: ""
    };

    submit = async () =>{
        let dto = {...this.props.authDto};
        dto.authorization.preAuthStatus = "NEW";
        dto.authorization.requestedDate = new Date();

        await this.props.setAuthDto(dto);
        console.log(this.props.authDto);

        await this.props.createAuthDto(this.props.authDto);
        await this.props.authDtoClear();
        this.props.navigate("/dashboard")
    };

    prevBtn = () =>{
        let path = '/authorizations/service-information';
        this.props.navigate(path);
    };

    selectRole = (e) => {
        this.setState({ role: e.target.value});
        if(e.target.value === "FACILITY") {
            this.setState({providerFacility: this.props.facilities})
        } else {
            this.setState({providerFacility: this.props.providers})
        }
    };

    selectProviderFacility = (e) => {
        const { role } = this.state;

        if(role === "FACILITY") {
            let facility = this.props.facilities[e.target.value];
            this.setState({
                name: facility.name,
                firstName: "",
                lastName: "",
                npi: facility.npi,
                address1: "",
                city: "",
                state: "",
                zip: ""
            })
        } else {
            let provider = this.props.providers[e.target.value];
            this.setState({
                name: provider.name,
                firstName: provider.firstName,
                lastName: provider.lastName,
                npi: provider.npi,
                address1: "",
                city: "",
                state: "",
                zip: ""
            })
        }
    };

    addServiceProvider = (e) => {
        let prov = {...this.state};
        delete prov["providerFacility"];
        let dto = {...this.props.authDto};

        dto.serviceProviderList.push(prov);
        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    async componentDidMount() {
        await this.props.getFacilities(this.props.authDto.authorization.clientId);
        await this.props.getProvider(this.props.authDto.authorization.clientId);
        this.setState({providerFacility: this.props.facilities})
    };

    render() {
        var { role, providerFacility, name, firstName, lastName, npi, address1, city, state, zip } = this.state;
        if(!providerFacility){
            providerFacility = [];
        }
        let providers = this.props.authDto.serviceProviderList;

        return (
            <div className="w-100">
                <div className="row mt-2">
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green"
                             className="bi bi-1-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z"/>
                        </svg>
                        <div>
                            <label className="auth-step-active">Start an Authorization</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green"
                             className="bi bi-2-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Add Service Information</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                             className="bi bi-3-circle" viewBox="0 0 16 16">
                            <path
                                d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z"/>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Rendering Provider/Facility</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                             className="bi bi-4-circle" viewBox="0 0 16 16">
                            <path
                                d="M7.519 5.057c.22-.352.439-.703.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z"/>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Review and Submit/Facility</label>
                        </div>
                    </div>
                </div>
                <div className="patient-info">
                    <div className="col-lg-12 d-flex">
                        <div className="info-active">

                        </div>
                        <div className="info-tittle">
                            <h2>{this.props.authDto.authorization.patientName}</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 d-flex">
                            <div className="info-active">

                            </div>
                            <div>
                                <div className="info-tittle">
                                    <label>Member ID</label>
                                </div>
                                <div className="info-desc">
                                    <label>{this.props.authDto.authorization.memberId}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Date of Birth</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.patientDob}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Gender</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.patientGender}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 d-flex">
                            <div className="info-active">

                            </div>
                            <div>
                                <div className="info-tittle">
                                    <label>Request Type</label>
                                </div>
                                <div className="info-desc">
                                    <label>{this.props.authDto.authorization.requestType}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Client</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.clientName}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Payer</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.payerName}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 emp-label-select">
                    <label>Service Provider</label>
                </div>
                <div className="row">
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Rendering Provider Role</Form.Label>
                            <Form.Select
                                className="grid-form-control"
                                value={role}
                                onChange={this.selectRole.bind(this)}
                            >
                                <option value="FACILITY">Facility</option>
                                <option value="PROVIDER">Provider</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mt-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Select a Provider</Form.Label>
                            <Form.Select className="grid-form-control" onChange={this.selectProviderFacility.bind(this)}>
                                <option selected disabled value=""> -- Select Provider/Facility -- </option>
                                {
                                    providerFacility.map((data, key) => {
                                        return (<option key={key} value={key}>{data.name}</option>)
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    {role == "FACILITY" ? <div className="col-lg-4">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Name</Form.Label>
                            <Form.Control className="grid-form-control" value={name}/>
                        </Form.Group>
                    </div> : <><div className="col-lg-4">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">First Name</Form.Label>
                            <Form.Control className="grid-form-control" value={firstName}/>
                        </Form.Group>
                    </div>
                        <div className="col-lg-4">
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Last Name</Form.Label>
                                <Form.Control className="grid-form-control" value={lastName}/>
                            </Form.Group>
                        </div></> }
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">NPI</Form.Label>
                            <Form.Control className="grid-form-control" value={npi}/>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-4">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Address Line 1</Form.Label>
                            <Form.Control className="grid-form-control" value={address1}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">City</Form.Label>
                            <Form.Control className="grid-form-control" value={city}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">State</Form.Label>
                            <Form.Select className="grid-form-control">
                                <option selected disabled value=""> -- Select State -- </option>
                                {
                                    USStates.map((USState, key) => {
                                        return <option key={key} value={USState.abbreviation}>{USState.name}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-lg-1">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Zip</Form.Label>
                            <Form.Control className="grid-form-control" value={zip}/>
                        </Form.Group>
                    </div>
                </div>
                <div className="col-lg-2">
                    <Button className="prev-btn btn-secondary mt-4 m-0"
                            variant="primary" type="button"
                            onClick={this.addServiceProvider.bind(this)}
                    >
                        Add Service Provider
                    </Button>
                </div>
                <Table bordered className="table-main mt-4">
                    <thead>
                    <tr>
                        <th>Role</th>
                        <th>Name</th>
                        <th>NPI</th>
                        <th className="td-last"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {providers.map((prov, key) => {
                        return (
                            <tr key={key}>
                                <td className="td-right-border">{prov.role}</td>
                                <td>{prov.name}</td>
                                <td>{prov.npi}</td>
                                <td className="td-last text-center">
                                    <div onClick={(e) => {
                                        console.log("I am here");
                                        this.props.authDto.serviceProviderList.splice(key, 1);
                                        this.setState({});
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                                         className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                                    </svg>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
                <div className="row mt-2">
                    <div className="col-lg-2 req-provider-btn-cont">
                        <Button className="prev-btn btn-secondary mt-4 m-0"
                                variant="primary" type="button"
                                onClick={this.prevBtn.bind(this)}
                        >
                            Previous
                        </Button>
                    </div>
                    <div className="col-lg-3 req-provider-btn-cont">
                        <Button className="service-provider-btn btn-success mt-4 m-0"
                                variant="primary" type="button"
                                onClick={this.submit.bind(this)}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAuthDto: bindActionCreators(setAuthDto, dispatch),
        createAuthDto: bindActionCreators(createAuthDto, dispatch),
        authDtoClear: bindActionCreators(authDtoClear, dispatch),
        getFacilities: bindActionCreators(getFacilities, dispatch),
        getProvider: bindActionCreators(getProvider, dispatch),
    }
}

const mapStateToProps = state => {
    return {
        authDto: state.createAuthDto.authDto,
        facilities: state.getFacilities.data,
        providers: state.getProvider.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(ServiceProvider))