import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationProcedureApiActionConstants from "./AddUpdateAuthorizationProcedureApiActionConstants"
import {getAddUpdateAuthorizationProcedureUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationProcedure(requestParams = {}, ...restParams) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationProcedureUriPath(restParams[0], restParams[1]),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_REQUEST,
                AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_SUCCESS,
                AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_FAILED,
            ],
            ...restParams
        },
    }
}