import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationRequestingProviderApiActionConstants from "./AddUpdateAuthorizationRequestingProviderApiActionConstants"
import {getAddUpdateAuthorizationRequestingProviderUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationRequestingProvider(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationRequestingProviderUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_REQUEST,
                AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_SUCCESS,
                AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_FAILED,
            ]
        },
    }


}

export function addUpdateAuthorizationRequestingProviderSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_SELECTED,
             val: val
         })
     }
}