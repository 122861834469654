import { CALL_API } from "../../../middleware/api";
import { getCorrespondenceSearchUriPath } from "../EndpointResolver";
import * as CorrespondenceSearchApiActionConstants from "./CorrespondenceSearchApiActionConstants";

export function correspondenceSearch(
  title,
  clientId,
  patientId,
  authorizationId,
  payerId
) {
  return {
    [CALL_API]: {
      endpoint: getCorrespondenceSearchUriPath(
        title,
        clientId,
        patientId,
        authorizationId,
        payerId
      ),
      config: { method: "get" },
      types: [
        CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_REQUEST,
        CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_SUCCESS,
        CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_FAILED,
      ],
    },
  };
}

export function correspondenceSearchClear() {
  return function (dispatch) {
    dispatch({
      type: CorrespondenceSearchApiActionConstants.CORRESPONDENCE_SEARCH_CLEAR,
    });
  };
}
