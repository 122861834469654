import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationHistoryApiActionConstants from "./GetAuthorizationHistoryApiActionConstants"
import { getAuthorizationHistoryUriPath } from '../EndpointResolver'

export function getAuthorizationHistory(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationHistoryUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationHistoryApiActionConstants.GET_AUTHORIZATION_HISTORY_REQUEST,
                GetAuthorizationHistoryApiActionConstants.GET_AUTHORIZATION_HISTORY_SUCCESS,
                GetAuthorizationHistoryApiActionConstants.GET_AUTHORIZATION_HISTORY_FAILED,
            ],
        },
    }
}

export function getAuthorizationHistorySelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationHistoryApiActionConstants.GET_AUTHORIZATION_HISTORY_SELECTED,
             val: val
         })
     }
}