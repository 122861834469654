import { CALL_API } from '../../../middleware/api'
import * as GetPatientEncounterApiActionConstants from "./GetPatientEncounterApiActionConstants"
import {getPatientEncounterUriPath} from '../EndpointResolver'

export function getPatientEncounter(patientId) {
    return {
        [CALL_API]: {
            endpoint: getPatientEncounterUriPath(patientId),
            config: { method: 'get' },
            types: [
                GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_REQUEST,
                GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_SUCCESS,
                GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_FAILED,
            ],
        },
    }
}


export function patientEncounterClear() {
     return function (dispatch) {
         dispatch({
             type: GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_CLEAR
         })
     }
}