import React, { useState, Component } from "react";
import {connect} from "react-redux";
import {WithRouter} from "../common/WithRouter";
class RefreshEncounter extends Component {

    state = {
          redirect: false
        };


        componentDidMount()  {
            this.setState({ redirect: true });
            this.redirect();
        }

        redirect = () => {
           const {redirect} = this.state;
           if(redirect == true){
                this.props.navigate('/encounter');
           }
        }

        render() {

            return (
                <div>
                    {this.redirect()}
                </div>
            );

        }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(RefreshEncounter))