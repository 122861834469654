import React, { Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import {WithRouter} from "../../common/WithRouter";
import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import {getProvider, providerClear} from "../../../redux/actions/api/provider/GetProviderApiActions";
import {setAuthDto} from "../../../redux/actions/api/authorization/CreateAuthDTOApiActions";

class RequestProvider extends Component {
    state = {
        selectedProvider: {}
    };

    requestProviderNext = () => {
        let path = '/authorizations/procedure-code';
        this.props.navigate(path);
    };

    patientInfoPrev = () => {
        let path = '/authorizations/patient-information';
        this.props.navigate(path);
    };

    async componentDidMount() {
        const {user, getClients, providerClear} = this.props;
        await getClients(user.orgId);
        providerClear();
    };

    selectClient = (e) => {
        let clientId = e.target.value;
        this.props.getProvider(clientId);
    };

    selectProvider = (e) => {
        let selected = this.props.providers[e.target.value];
        this.setState({selectedProvider:selected});

        let dto = {...this.props.authDto};
        dto.authorization.rpName = selected.lastName + ", " + selected.firstName;
        dto.authorization.rpFirstName = selected.firstName;
        dto.authorization.rpMiddleName = selected.middleName;
        dto.authorization.rpLastName = selected.lastName;
        dto.authorization.rpNpi = selected.npi;
        dto.authorization.rpSpecialtyTaxonomy = selected.specialtyTaxonomy;
        dto.authorization.rpAddress1 = selected.address1;
        dto.authorization.rpAddress2 = selected.address2;
        dto.authorization.rpCity = selected.city;
        dto.authorization.rpState = selected.state;
        dto.authorization.rpZip = selected.zip;
        dto.authorization.rpContactName = selected.contactName;
        dto.authorization.rpContactPhone = selected.contactPhone;
        dto.authorization.rpContactFax = selected.contactFax;

        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    selectProviderType = (e) => {
        let dto = {...this.props.authDto};

        dto.authorization.rpType = e.target.value;
        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    render() {
        var {clients, providers} = this.props;
        const {selectedProvider} = this.state;
        if(!clients){
            clients = [];
        }

        if(!providers){
            providers = [];
        }

        return (
            <div className="w-100">
                <div className="row mt-2">
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green" className="bi bi-1-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z"/>
                        </svg>
                        <div>
                            <label className="auth-step-active">Start an Authorization</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-2-circle" viewBox="0 0 16 16">
                            <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Add Service Information</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-3-circle" viewBox="0 0 16 16">
                            <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z"/>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Rendering Provider/Facility</label>
                        </div>
                    </div>
                    <div className="col-lg-3 mt-2 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-4-circle" viewBox="0 0 16 16">
                            <path d="M7.519 5.057c.22-.352.439-.703.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z"/>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z"/>
                        </svg>
                        <div>
                            <label className="auth-step">Review and Submit/Facility</label>
                        </div>
                    </div>
                </div>
                <div className="patient-info">
                    <div className="col-lg-12 d-flex">
                        <div className="info-active">

                        </div>
                        <div className="info-tittle">
                            <h2>{this.props.authDto.authorization.patientName}</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 d-flex">
                            <div className="info-active">

                            </div>
                            <div>
                                <div className="info-tittle">
                                    <label>Member ID</label>
                                </div>
                                <div className="info-desc">
                                    <label>{this.props.authDto.authorization.memberId}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Date of Birth</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.patientDob}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Gender</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.patientGender}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 d-flex">
                            <div className="info-active">

                            </div>
                            <div>
                                <div className="info-tittle">
                                    <label>Request Type</label>
                                </div>
                                <div className="info-desc">
                                    <label>{this.props.authDto.authorization.requestType}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Client</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.clientName}</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="info-tittle">
                                <label>Payer</label>
                            </div>
                            <div className="info-desc">
                                <label>{this.props.authDto.authorization.payerName}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mt-1 emp-label-select mt-4">
                    <label>Requesting Provider</label>
                </div>
                <div className="row">
                    <div className="col-lg-2 mt-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Client</Form.Label>
                            <Form.Select className="grid-form-control-select" placeholder="Select Client"
                                         onChange={(e) => {
                                             this.selectClient(e);
                                         }}>
                                <option selected disabled value=""> -- Select Client -- </option>
                                {clients.map((client, key) => {
                                    return <option value={client.id} key={key}>{client.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mt-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Select a Provider</Form.Label>
                            <Form.Select className="grid-form-control"
                                         onChange={(e) => {
                                             this.selectProvider(e);
                                         }}>
                                <option selected disabled value=""> -- Select Provider -- </option>
                                {providers.map((provider, key) => {
                                    return <option value={key} key={key}>{provider.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2 mt-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Provider Type</Form.Label>
                            <Form.Select className="grid-form-control" onChange={this.selectProviderType.bind(this)} value={this.props.authDto.authorization.rpType}>
                                <option selected value=""> -- Select Provider Type-- </option>
                                <option value="REQUESTING_PROVIDER">Requesting Provider</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">First Name</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.firstName}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Last Name</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.lastName}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">NPI</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.npi}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Speciality/ Taxonomy</Form.Label>
                            <Form.Select className="grid-form-control" value={selectedProvider.specialtyTaxonomy}>
                                <option value="1">Speciality/ Taxonomy</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-4">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Address Line 1</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.address1}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">City</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.city}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">State</Form.Label>
                            <Form.Select className="grid-form-control" value={selectedProvider.state}>
                                <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-lg-1">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Zip</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.zip}/>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Contact Name</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.contactName}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Contact Phone</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.contactPhone}/>
                        </Form.Group>
                    </div>
                    <div className="col-lg-2">
                        <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">Contact Fax</Form.Label>
                            <Form.Control className="grid-form-control" value={selectedProvider.contactFax}/>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-2 req-provider-btn-cont">
                        <Button className="prev-btn btn-secondary mt-4 m-0"
                                variant="primary" type="button"
                                onClick={this.patientInfoPrev}
                        >
                            Previous
                        </Button>
                    </div>
                    <div className="col-lg-3 req-provider-btn-cont">
                        <Button className="req-provider-btn btn-primary mt-4 m-0"
                                variant="primary" type="button"
                                onClick={this.requestProviderNext}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getClients: bindActionCreators(getClientsByOrgId, dispatch),
        getProvider: bindActionCreators(getProvider, dispatch),
        providerClear: bindActionCreators(providerClear, dispatch),
        setAuthDto: bindActionCreators(setAuthDto, dispatch),
    }
}

const mapStateToProps = state => {
    return {
        clients: state.getClientsByOrgId.data,
        user: state.currentUser.data,
        providers: state.getProvider.data,
        authDto: state.createAuthDto.authDto,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(RequestProvider))