import React, { Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import {WithRouter} from "../../common/WithRouter";
import getAllOrganization from "../../../redux/actions/api/organization/GetAllOrganizationApiActions";
import getPayersFindAll from "../../../redux/actions/api/payer/GetPayersApiActions";
import {setAuthDto} from "../../../redux/actions/api/authorization/CreateAuthDTOApiActions";
import createAuthDto from "../../../redux/reducers/CreateAuthDtoReducer";
import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";

class SelectPayer extends Component {


    selectPayerNext = () =>{
        let path = '/authorizations/patient-information';
        this.props.navigate(path);
    };

    selectPayer = (e) => {
        let dto = {...this.props.authDto};
        let payer = this.props.payers[e.target.value];

        dto.authorization.payerId = payer.id;
        dto.authorization.payerName = payer.name;
        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    selectClient = (e) => {
        let dto = {...this.props.authDto};
        let client = this.props.clients[e.target.value];

        dto.authorization.clientId = client.id;
        dto.authorization.clientName = client.name;
        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    selectRequestType = (e) => {
        let dto = {...this.props.authDto};

        dto.authorization.requestType = e.target.value;
        this.props.setAuthDto(dto);
        console.log(this.props.authDto);
    };

    async componentDidMount() {
        const {user, getClients} = this.props;
        await getClients(user.orgId);

        await this.props.getAllOrganization();
        await this.props.getPayersFindAll();

        let dto = {...this.props.authDto};
        dto.authorization.orgId = user.orgId;
    }

    render() {
        let { clients } = this.props;
        if(!clients){
            clients = [];
        }

        return (
            <div className="w-100">
                <div className="mt-1 emp-label-select">
                    <label>Authorizations</label>
                </div>
                <div className="col-lg-3 col-6 mt-2">
                    <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">Client</Form.Label>
                        <Form.Select className="grid-form-control" onChange={this.selectClient.bind(this)}>
                            <option selected value=""> -- Select Client -- </option>
                            {clients.map((client, key) => {
                                return <option value={key} key={key}>{client.name}</option>
                            })}

                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="col-lg-3 col-6 mt-2">
                    <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">Payer</Form.Label>
                        <Form.Select className="grid-form-control" onChange={this.selectPayer.bind(this)}>
                            <option selected value=""> -- Select Payer -- </option>
                            {this.props.payers.map((payer, key) => {
                                return <option key={key} value={key}>{payer.name}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="col-lg-3 col-6 mt-2">
                    <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">Request Type</Form.Label>
                        <Form.Select className="grid-form-control" onChange={this.selectRequestType.bind(this)}>
                            <option selected value=""> -- Select Request Type -- </option>
                            <option value="IN-PATIENT AUTHORIZATION">IN-PATIENT AUTHORIZATION</option>
                            <option value="OUT-PATIENT AUTHORIZATION">OUT-PATIENT AUTHORIZATION</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="col-lg-3 col-6 mt-4">
                    <Button className="add-patients-btn btn-primary mb-1 m-0"
                            variant="primary" type="button"
                            onClick={this.selectPayerNext.bind(this)}
                    >
                        Next
                    </Button>
                </div>
            </div>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
        getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
        setAuthDto: bindActionCreators(setAuthDto, dispatch),
        getClients: bindActionCreators(getClientsByOrgId, dispatch),
    }
};

const mapStateToProps = state => {
    return {
        organizationList: state.getAllOrganization.data,
        payers: state.getPayersAll.data,
        authDto: state.createAuthDto.authDto,
        clients: state.getClientsByOrgId.data,
        user: state.currentUser.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(SelectPayer))