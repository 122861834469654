import { CALL_API } from '../../../middleware/api'
import * as GetTransactionTypeListApiActionConstants from "./GetTransactionTypeListApiActionConstants"
import {getTransactionTypeListUriPath} from '../EndpointResolver'

export function getTransactionTypeList(payerId) {
    return {
        [CALL_API]: {
            endpoint: getTransactionTypeListUriPath(payerId),
            config: { method: 'get' },
            types: [
                GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_REQUEST,
                GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_SUCCESS,
                GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_FAILED,
            ],
        },
    }
}

export function selectTransactionTypeFromList(val) {
    return function (dispatch) {
        dispatch({
            type: GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_SELECTED,
            val: val
        })
    }
}

export function transactionTypeListClear() {
    return function (dispatch) {
        dispatch({
            type: GetTransactionTypeListApiActionConstants.GET_TRANSACTION_TYPE_LIST_CLEAR
        })
    }
}