import { CALL_API } from '../../../middleware/api'
import * as AddPayerApiActionConstants from "./AddPayerApiActionConstants"
import {getAddUpdatePayerUriPath} from '../EndpointResolver'

export function addUpdatePayer(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdatePayerUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddPayerApiActionConstants.ADD_UPDATE_PAYER_REQUEST,
                AddPayerApiActionConstants.ADD_UPDATE_PAYER_SUCCESS,
                AddPayerApiActionConstants.ADD_UPDATE_PAYER_FAILED,
            ]
        },
    }
}

export function selectPayer(val) {
    return function (dispatch) {
        dispatch({
            type: AddPayerApiActionConstants.ADD_UPDATE_PAYER_SELECTED,
            val: val
        })
    }
}

export function selectedPayerClear() {
    return function (dispatch) {
        dispatch({
            type: AddPayerApiActionConstants.ADD_UPDATE_PAYER_SELECTED_CLEAR
        })
    }
}