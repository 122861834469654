import React, { Component } from "react";
import {Modal, Button, Table} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import './medical-documentation-requirements-modal.css';

class MedicalDocumentationRequirementsModal extends Component {
    state = { result: {isOpen: false}};

    closeModal = () => {
        let result = { isOpen: false };
        this.props.result(result);
    };

    componentDidMount() {
        console.log(this.props.data)
    }

    render() {
        return(
            <Modal show={this.props.data.isOpen} size="xl">
                <Modal.Body className="auth-modal-body" style={{
                     maxHeight: 'calc(100vh - 200px)',
                     overflowY: 'auto'
                    }}
                   >
                    <label className="auth-modal-title">MEDICAL DOCUMENTATION REQUIREMENTS</label>
                    <Table bordered hover className="table-portal mt-1">
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}>Service</th>
                                <th style={{ width: '25%' }}>Codes</th>
                                <th style={{ width: '50%' }}>Medical Records Used for Reviews</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Behavioral Health Services</td>
                                <td>All codes</td>
                                <td>Provider should call the number on the member’s identification (ID) card when referring for any mental health or substance abuse/ substance use services</td>
                            </tr>
                            {this.props.medicalReq.map((record, key) => {
                              return (
                              <>
                                <tr>
                                    <td>{record.service}</td>
                                    <td>{record.codes}</td>
                                    <td dangerouslySetInnerHTML={{ __html: record.description }}></td>
                                </tr>
                               </>
                               )
                               })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button"
                            onClick={this.closeModal.bind(this)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = state => {
    return {
        medicalReq: state.getMedicalReq.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalDocumentationRequirementsModal)
