import * as AddUpdateEncounterApiActionConstants from '../../redux/actions/api/encounter/AddUpdateEncounterApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateEncounterReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_REQUEST:
            return {
                ...state,
            };
        case AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
