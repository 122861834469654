import axios from 'axios'
import { logout } from '../actions/api/authentication/AuthenticationLogOutActions'

export let BASE_URL;

if (process.env.REACT_APP_BASE_API_URL && process.env.REACT_APP_BASE_API_URL.length) {

    BASE_URL = process.env.REACT_APP_BASE_API_URL

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
    {
        BASE_URL = process.env.REACT_APP_BASE_API_URL_MSIE
    }

    //console.log("ua" + ua + " " + "BASE_URL" + BASE_URL);

} else {
    console.error('Environment variable BASE_API_URL is not defined. Please add it to the .env file and restart the app.')
}
/*TO DO Hard coded for now will fix it later*/
//BASE_URL = 'http://localhost:9101'; //https://backend.hospitalbillpay.com/CNetDWServices';


/*End TO DO*/
const callApi = (endpoint, config) => {
    //console.log(endpoint);
    const token = localStorage.getItem('id_token') || null

    if (token) {
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
        axios.defaults.headers.common['Authorization'] = `${token}`
    }

    return axios.request(endpoint, config).then(response => {
        return response
    })
}

export const CALL_API = 'Call_API'

export default store => next => action => {
    const callAPI = action[CALL_API]

    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    const { endpoint, types, config, ...params } = callAPI
    const [requestType, successType, errorType] = types

    next({
        config: config,
        type: requestType,
        ...params
    })

    return callApi(endpoint, config)
        .then(status)
        .then(response => {
            if (response.status < 200 || response.status > 300) {

                next({
                    errorMessage: 'There was an error.',
                    type: errorType,
                    ...params
                })
            } else {

                next({
                    data: response.data,
                    headers: response.headers,
                    config: config,
                    type: successType,
                    ...params
                })

            }
        }).catch(error => {
                if (error.response) {
                    status(error.response, store)
                } else {
                    console.log("")
                }

                next({
                    type: errorType,
                    config: config,
                    ...params,
                    error,
                })
            },
        )

    function status(response, store) {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response)
        } else {
            const message = response.data.message
            const re = new RegExp('JWT expired', 'gi')
            if(message && message.match(re)){
                store.dispatch(logout())
            }
            // return Promise.reject(new Error(message))
            return Promise.resolve(response)
        }
    }
}

