import * as GetServiceTypesApiActionConstants from '../../redux/actions/api/serviceType/GetServiceTypesApiActionConstants'

const initialState = {
    data: ''
};

export default function getServiceTypesReducer(state = initialState, action) {
    switch (action.type) {
        case GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_REQUEST:
            return {
                ...state,
            };
        case GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_FAILED:
            return {
                ...state,
                data: ''
            };
        case GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
