import * as GetCptDescriptionApiActionConstants from '../../redux/actions/api/authorization/GetCptDescriptionApiActionConstants'

const initialState = {
    data: []
};

export default function getCptDescriptionReducer(state = initialState, action) {
    switch (action.type) {
        case GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_REQUEST:
            return {
                ...state,
            };
        case GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetCptDescriptionApiActionConstants.GET_CPT_DESCRIPTION_FAILED:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
