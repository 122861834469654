import * as AddUpdateAuthorizationRequestingProviderApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationRequestingProviderApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationRequestingProviderReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorizationRequestingProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUESTING_PROVIDER_SELECTED:
                    return {
                        ...state,
                        data: action.val
                    };
        default:
            return state
    }
}
