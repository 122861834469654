import { CALL_API } from '../../../middleware/api'
import * as GetTransactionTypeApiActionConstants from "./GetTransactionTypeApiActionConstants"
import {getTransactionTypeUriPath} from '../EndpointResolver'

export function getTransactionType(payerId, trxType) {
    return {
        [CALL_API]: {
            endpoint: getTransactionTypeUriPath(payerId, trxType),
            config: { method: 'get' },
            types: [
                GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_REQUEST,
                GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_SUCCESS,
                GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_FAILED,
            ],
        },
    }
}

export function selectTransactionType(val) {
    return function (dispatch) {
        dispatch({
            type: GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_SELECTED,
            val: val
        })
    }
}

export function transactionTypeClear() {
    return function (dispatch) {
        dispatch({
            type: GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_CLEAR
        })
    }
}