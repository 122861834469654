import React, { useState } from "react";
import "./Login.css"
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import authenticate from '../../redux/actions/api/authentication/AuthenticationApiActions';
import {WithRouter} from "../common/WithRouter";

const Login = (props) => {
    let [userNameValue, setUserName] = useState('');
    let [passwordValue, setPassword] = useState('');
    let [authenticationFailed, setAuthenticationFailed] = useState(false);
    var onSubmit = async () => {
        console.log('username:' , userNameValue);
        console.log('password:' , passwordValue);

        try {
            var creds = {};
            creds.userId = userNameValue;
            creds.userPasswordEncrypted = passwordValue;
            await props.authenticate(creds);

            console.log(!!sessionStorage.getItem("id_token"));
            if(!sessionStorage.getItem("id_token")){
                setAuthenticationFailed(true);
            } else {
                setAuthenticationFailed(false)
            }

        }
        catch(err) {
            console.log("");
        }
    };

    const failedLogin = (failed) => {
        if(failed){
            return (
                <div className="failed-login-div">
                    <p className="failed-login-font">Authentication Failed</p>
                </div>)
        }
        return (
            <div> </div>
        )

    }


    return (
        <div className="d-flex align-items-center justify-content-center viewport-bg">
            <div className="d-flex">
                <div className="bg-white login-form">
                    <h5 className="m-5">
                        <img alt="#" src={require("../../images/AuthoFiLogo.jpg")}/>
                    </h5>
                    <h4 className="px-5 login-title">Login</h4>
                    <p className="px-5 text-muted login-subtitle">Enter your username and password.</p>
                    <Form className="p-5 mb-5">
                        <Form.Group className="mb-3" controlId="formBasicUsername">
                            <Form.Label className="mb-0 text-muted form-label">Username</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                    </svg>
                                </InputGroup.Text>
                                <Form.Control name="username" type="text" placeholder="Enter username" onChange={e => setUserName(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="mb-0 text-muted form-label">Password</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                                    </svg>
                                </InputGroup.Text>
                                <Form.Control name="password" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                        <Button className="btn btn-primary w-100 mt-1" variant="primary" type="button" onClick={(e) => {onSubmit(e, "");}}>
                            Submit
                        </Button>
                        <div>
                            {failedLogin(authenticationFailed)}
                        </div>
                    </Form>
                </div>
                <div className="right-panel d-none d-sm-none d-md-block d-lg-block d-xl-block p-4">
                    <h3 className="right-panel-label">
                        AuthoFi automates
                        <br/>the patient pre-authorization
                        <br/>process
                        <br/>for healthcare providers
                        <br/>to improve
                        <br/>communication,
                        <br/>accuracy,
                        <br/>and
                        <br/>timeliness process
                        <br/>in an end-to-end
                        <br/>solution.
                    </h3>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        authenticate: bindActionCreators(authenticate, dispatch)
    }
}

const mapStateToProps = ({authentication}) => {
    return {
        errorMessage: authentication.errorMessage,
        isAuthenticated: authentication.isAuthenticated,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Login));