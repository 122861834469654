import * as AddUpdateInsuranceApiActionConstants from '../../redux/actions/api/insurance/AddUpdateInsuranceApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateInsuranceReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_REQUEST:
            return {
                ...state,
            };
        case AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateInsuranceApiActionConstants.ADD_UPDATE_INSURANCE_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
