import * as CreateAuthDTOApiActionConstants from '../../redux/actions/api/authorization/CreateAuthDTOApiActionConstants'

const initialState = {
    data: null,
    authDto: {authorization: {}, serviceProviderList: [], procedureCodeList: []}
};

export default function CreateAuthDTOReducer(state = initialState, action) {
    switch (action.type) {
        case CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_REQUEST:
            return {
                ...state,
            };
        case CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_FAILED:
            return {
                ...state,
                data: null
            };
        case CreateAuthDTOApiActionConstants.AUTH_DTO_SET_VALUE:
            return {
                ...state,
                authDto: action.val
            };
        case CreateAuthDTOApiActionConstants.AUTH_DTO_VALUE_CLEAR:
            return {
                ...state,
                authDto: {authorization: {}, serviceProviderList: [], procedureCodeList: []}
            };
        default:
            return state
    }
}
