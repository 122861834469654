import * as GetTransactionTypeApiActionConstants from '../../redux/actions/api/transaction-type/GetTransactionTypeApiActionConstants'

const initialState = {
    data: null,
    selected: null
};

export default function getTransactionTypeReducer(state = initialState, action) {
    switch (action.type) {
        case GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_REQUEST:
            return {
                ...state,
            };
        case GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_SUCCESS: {
            return {
                ...state,
                data: action.data,
                selected: action.data
            };
            break;
        }
        case GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_FAILED:
            return {
                ...state,
                data: null
            };
        case GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_SELECTED:
            return {
                ...state,
                selected: action.val
            };
        case GetTransactionTypeApiActionConstants.GET_TRANSACTION_TYPE_CLEAR:
            return {
                ...state,
                data: null,
                selected: null
            };
        default:
            return state
    }
}
