import { CALL_API } from "../../../middleware/api";
import { getAddUpdateCorrespondenceUriPath } from "../EndpointResolver";
import * as AddUpdateCorrespondenceApiActionConstants from "./AddUpdateCorrespondenceApiActionConstants";

export function addUpdateCorrespondence(requestParams = {}) {
  return {
    [CALL_API]: {
      endpoint: getAddUpdateCorrespondenceUriPath(),
      config: {
        data: requestParams,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestParams,
      },
      types: [
        AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_REQUEST,
        AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_SUCCESS,
        AddUpdateCorrespondenceApiActionConstants.ADD_UPDATE_CORRESPONDENCE_FAILED,
      ],
    },
  };
}
