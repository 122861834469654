import * as GetClientsByOrgIdApiActionConstants from "./../actions/api/client/GetClientsByOrgIdApiActionConstants";

const initialState = {
  data: [],
};

export default function getClientsByOrgIdReducer(state = initialState, action) {
  switch (action.type) {
    case GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_REQUEST:
      return {
        ...state,
      };
    case GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case GetClientsByOrgIdApiActionConstants.GET_CLIENT_BY_ORG_ID_FAILED:
      return {
        ...state,
        data: [],
      };
    default: {
      return state;
    }
  }
}
