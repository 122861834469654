import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationDetailApiActionConstants from "./GetAuthorizationDetailApiActionConstants"
import { getAuthorizationDetailUriPath } from '../EndpointResolver'

export function getAuthorizationDetail(parentId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationDetailUriPath(parentId),
            config: { method: 'get' },
            types: [
                GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_REQUEST,
                GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_SUCCESS,
                GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_FAILED,
            ],
        },
    }
}

export function getAuthorizationDetailSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_SELECTED,
             val: val
         })
     }
}