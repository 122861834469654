import React, { useState, Component } from "react";
import "../../App.css";
import "./Clients.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Table, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftPanel from "../common/leftPanel/LeftPanel";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import Search from "../common/search/Search";
import {
  addUpdateClient,
  addUpdateClientSelected,
} from "../../redux/actions/api/client/AddUpdateClientApiActions";
import { addUpdatePhoneEmail } from "../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActions";
import { addUpdateAddress } from "../../redux/actions/api/address/AddUpdateAddressApiActions";
import {
  getPatientPhoneEmail,
  patientPhoneEmailClear,
} from "../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";
import {
  getPatientAddress,
  patientAddressClear,
} from "../../redux/actions/api/address/GetPatientAddressApiActions";
import { addUpdateProvider } from "../../redux/actions/api/provider/AddUpdateProviderApiActions";
import { addUpdateFacility } from "../../redux/actions/api/facility/AddUpdateFacilityApiActions";
import {
  getProvider,
  providerClear,
} from "../../redux/actions/api/provider/GetProviderApiActions";
import { addUpdateEmployee } from "../../redux/actions/api/employee/AddUpdateEmployeeApiActions";
import {
  getClientEmployees,
  clientEmployeesClear,
} from "../../redux/actions/api/employee/GetClientEmployeesApiActions";
import { getClients } from "../../redux/actions/api/client/GetClientsApiActions";
import getFacilities from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import facilitiesClear from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import getOrganization from "../../redux/actions/api/organization/GetOrganizationApiActions";
import getAllOrganization from "../../redux/actions/api/organization/GetAllOrganizationApiActions";
import getClientsByOrgId from "../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Routes, Route } from "react-router-dom";
import USStates from "../common/USStates";

import { WithRouter } from "../common/WithRouter";
import getUser from "../../redux/actions/api/authentication/GetUserApiActions";
import Notes from "./Notes";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      id: "",

      name: "",
      type: "",
      executiveSponsorName: "",
      executiveSponsorPhone: "",
      accountsPayablePhoneNumber: "",
      contactInvoicing: "",
      contactInvoicingEmail: "",
      taxId: "",
      npi: "",
      specialtyTaxonomy: "",
      runEligibility: "",
      runEligibilityIsChecked: ["N"],
      fullService: "",
      fullServiceIsChecked: ["N"],
      outpatientRate: "",
      outpatientRateIsChecked: [],
      inpatientRate: "",
      inpatientRateIsChecked: [],
      contactCaseManager: "",
      contactCaseRepresentative: "",
      contactCaseRepresentativePhone: "",
      status: "ACTIVE",

      nameErr: "",
      executiveSponsorNameErr: "",
      executiveSponsorPhoneErr: "",
      accountsPayablePhoneNumberErr: "",
      contactInvoicingErr: "",
      contactInvoicingEmailErr: "",
      taxIdErr: "",
      npiErr: "",
      outpatientRateErr: "",
      inpatientRateErr: "",
      contactCaseManagerErr: "",
      contactCaseRepresentativeErr: "",
      contactCaseRepresentativePhoneErr: "",

      subtype: "MOBILE",
      numberEmail: "",
      numberEmailErr: "",
      isPrimary: "Y",
      sms: "Y",
      statusPhoneEmail: "ACTIVE",

      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
      //isPrimary:'Y',
      statusAddress: "ACTIVE",
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",

      physicalAddress: "",
      physicalAddress1: "",
      physicalAddress2: "",
      physicalCity: "",
      physicalState: "",
      physicalZip: "",
      mailingAddress: "",
      mailingAddress1: "",
      mailingAddress2: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",

      isNew: "Y",

      //Facility
      outpatientRateFacility: "",
      outpatientRateIsCheckedFacility: [],
      inpatientRateFacility: "",
      inpatientRateIsCheckedFacility: [],
      statusFacility: "ACTIVE",
      nameFacility: "",
      executiveSponsorNameFacility: "",
      executiveSponsorPhoneFacility: "",
      accountsPayablePhoneNumberFacility: "",
      contactInvoicingFacility: "",
      contactInvoicingEmailFacility: "",
      taxIdFacility: "",
      npiFacility: "",
      specialtyTaxonomyFacility: "",
      runEligibilityFacility: "",
      runEligibilityFacilityIsChecked: ["N"],
      fullServiceFacility: "",
      fullServiceFacilityIsChecked: ["N"],
      contactCaseManagerFacility: "",
      contactCaseRepresentativeFacility: "",
      contactCaseRepresentativePhoneFacility: "",
      mainPhoneNumber: "",

      nameFacilityErr: "",
      specialtyTaxonomyFacilityErr: "",
      npiFacilityErr: "",
      taxIdFacilityErr: "",
      mainPhoneNumberErr: "",
      executiveSponsorNameFacilityErr: "",
      executiveSponsorPhoneFacilityErr: "",
      accountsPayablePhoneNumberFacilityErr: "",
      contactInvoicingFacilityErr: "",
      contactInvoicingEmailFacilityErr: "",
      outpatientRateFacilityErr: "",
      inpatientRateFacilityErr: "",
      contactCaseManagerFacilityErr: "",
      contactCaseRepresentativeFacilityErr: "",
      contactCaseRepresentativePhoneFacilityErr: "",

      //Provider
      firstName: "",
      lastName: "",
      statusProvider: "ACTIVE",
      nameProvider: "",
      taxIdProvider: "",
      npiProvider: "",
      specialtyTaxonomyProvider: "",
      fax: "",
      caseManager: "",
      caseManagerEmail: "",
      concurrentReviewNurse: "",
      concurrentReviewNurseEmail: "",
      contactName: "",
      contactPhone: "",
      contactFax: "",
      nameProviderErr: "",
      firstNameErr: "",
      lastNameErr: "",
      specialtyTaxonomyProviderErr: "",
      npiProviderErr: "",
      taxIdProviderErr: "",
      caseManagerErr: "",
      caseManagerEmailErr: "",
      concurrentReviewNurseErr: "",
      concurrentReviewNurseEmailErr: "",
      contactNameErr: "",
      contactPhoneErr: "",
      contactFaxErr: "",
      emailErr: "",
      faxErr: "",
      phoneNumberErr: "",

      parentId: "",

      middleName: "",
      //lastName:'',
      userRole: "USER",
      userId: "",
      userPassword: "",
      userPasswordEncrypted: "",
      statusEmployee: "ACTIVE",

      middleNameErr: "",
      userIdErr: "",
      userPasswordErr: "",

      saveToTable: "",

      editAddressModal: false,
      editAddressModalName: "",

      disabledSaveClient: false,
      disabledSavePhoneEmail: false,
      disabledSaveAddress: false,
      disabledSaveFacility: false,
      disabledSaveProvider: false,
      disabledSaveEmployee: false,

      hideClientInfo: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInpatientRate = this.handleInpatientRate.bind(this);
    this.handleRunEligibility = this.handleRunEligibility.bind(this);
    this.handleFullService = this.handleFullService.bind(this);
    this.handleRunEligibilityFacility =
      this.handleRunEligibilityFacility.bind(this);
    this.handleFullServiceFacility = this.handleFullServiceFacility.bind(this);
    this.handleOutpatientRate = this.handleOutpatientRate.bind(this);
    this.handleInpatientRateFacility =
      this.handleInpatientRateFacility.bind(this);
    this.handleOutpatientRateFacility =
      this.handleOutpatientRateFacility.bind(this);
    this.showEditAddressModal = this.showEditAddressModal.bind(this);
  }

  clientDefault = {
    id: "",
    name: "",
    type: "",
    executiveSponsorName: "",
    executiveSponsorPhone: "",
    accountsPayablePhoneNumber: "",
    contactInvoicing: "",
    contactInvoicingEmail: "",
    taxId: "",
    npi: "",
    runEligibility: "",
    runEligibilityIsChecked: ["N"],
    fullService: "",
    fullServiceIsChecked: ["N"],
    outpatientRate: "",
    outpatientRateIsChecked: [],
    inpatientRate: "",
    inpatientRateIsChecked: [],
    contactCaseManager: "",
    contactCaseRepresentative: "",
    contactCaseRepresentativePhone: "",
    status: "ACTIVE",

    nameErr: "",
    executiveSponsorNameErr: "",
    executiveSponsorPhoneErr: "",
    accountsPayablePhoneNumberErr: "",
    contactInvoicingErr: "",
    contactInvoicingEmailErr: "",
    taxIdErr: "",
    npiErr: "",
    outpatientRateErr: "",
    inpatientRateErr: "",
    contactCaseManagerErr: "",
    contactCaseRepresentativeErr: "",
    contactCaseRepresentativePhoneErr: "",
  };

  phoneEmailDefault = {
    id: "",
    type: "HOME",
    subtype: "MOBILE",
    numberEmail: "",
    numberEmailErr: "",
    isPrimary: "Y",
    sms: "Y",
    statusPhoneEmail: "ACTIVE",
  };

  addressDefault = {
    id: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    isPrimary: "Y",
    statusAddress: "ACTIVE",
    address1Err: "",
    cityErr: "",
    stateErr: "",
    zipErr: "",
  };

  facilityDefault = {
    id: "",
    outpatientRateFacility: "",
    outpatientRateIsCheckedFacility: [],
    inpatientRateFacility: "",
    inpatientRateIsCheckedFacility: [],
    contactCaseManagerFacility: "",
    contactCaseRepresentativeFacility: "",
    contactCaseRepresentativePhoneFacility: "",
    statusFacility: "ACTIVE",
    nameFacility: "",
    executiveSponsorNameFacility: "",
    executiveSponsorPhoneFacility: "",
    accountsPayablePhoneNumberFacility: "",
    contactInvoicingFacility: "",
    contactInvoicingEmailFacility: "",
    taxIdFacility: "",
    npiFacility: "",
    specialtyTaxonomyFacility: "",
    runEligibilityFacility: "",
    runEligibilityFacilityIsChecked: ["N"],
    fullServiceFacility: "",
    fullServiceFacilityIsChecked: ["N"],
    physicalAddress: "",
    physicalAddress1: "",
    physicalAddress2: "",
    physicalCity: "",
    physicalState: "",
    physicalZip: "",
    mailingAddress: "",
    mailingAddress1: "",
    mailingAddress2: "",
    mailingCity: "",
    mailingState: "",
    mailingZip: "",
    mainPhoneNumber: "",

    nameFacilityErr: "",
    specialtyTaxonomyFacilityErr: "",
    npiFacilityErr: "",
    taxIdFacilityErr: "",
    mainPhoneNumberErr: "",
    executiveSponsorNameFacilityErr: "",
    executiveSponsorPhoneFacilityErr: "",
    accountsPayablePhoneNumberFacilityErr: "",
    contactInvoicingFacilityErr: "",
    contactInvoicingEmailFacilityErr: "",
    outpatientRateFacilityErr: "",
    inpatientRateFacilityErr: "",
    contactCaseManagerFacilityErr: "",
    contactCaseRepresentativeFacilityErr: "",
    contactCaseRepresentativePhoneFacilityErr: "",
    type: "Institutional",
  };

  providerDefault = {
    id: "",
    firstName: "",
    lastName: "",
    statusProvider: "ACTIVE",
    nameProvider: "",
    taxIdProvider: "",
    npiProvider: "",
    specialtyTaxonomyProvider: "",
    fax: "",
    caseManager: "",
    caseManagerEmail: "",
    concurrentReviewNurse: "",
    concurrentReviewNurseEmail: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    physicalAddress: "",
    physicalAddress1: "",
    physicalAddress2: "",
    physicalCity: "",
    physicalState: "",
    physicalZip: "",
    mailingAddress: "",
    mailingAddress1: "",
    mailingAddress2: "",
    mailingCity: "",
    mailingState: "",
    mailingZip: "",
    phoneNumber: "",
    nameProviderErr: "",
    firstNameErr: "",
    lastNameErr: "",
    specialtyTaxonomyProviderErr: "",
    npiProviderErr: "",
    taxIdProviderErr: "",
    caseManagerErr: "",
    caseManagerEmailErr: "",
    concurrentReviewNurseErr: "",
    concurrentReviewNurseEmailErr: "",
    contactNameErr: "",
    contactPhoneErr: "",
    contactFaxErr: "",
    emailErr: "",
    faxErr: "",
    phoneNumberErr: "",
    type: "Professional",
  };

  employeeDefault = {
    id: "",
    type: "",
    firstName: "",
    middleName: "",
    lastName: "",
    userRole: "USER",
    userId: "",
    userPassword: "",
    userPasswordEncrypted: "",
    statusEmployee: "ACTIVE",
    middleNameErr: "",
    userIdErr: "",
    userPasswordErr: "",
  };

  submitEditAddressModal = () => {
    var address = "";
    const { editAddressModalName } = this.state;
    if (editAddressModalName == "PHYSICAL") {
      address =
        this.state.physicalAddress1 +
        ", " +
        this.state.physicalCity +
        ", " +
        this.state.physicalState +
        ", " +
        this.state.physicalZip;
      this.setState({ physicalAddress: address });
    } else {
      address =
        this.state.mailingAddress1 +
        ", " +
        this.state.mailingCity +
        ", " +
        this.state.mailingState +
        ", " +
        this.state.mailingZip;
      this.setState({ mailingAddress: address });
    }

    this.setState({ editAddressModal: false });
  };

  showEditAddressModal = (modalName) => {
    console.log(modalName);
    this.setState({ editAddressModal: true, editAddressModalName: modalName });
  };

  handleSelect(key) {
    console.log("selected" + key);
    this.setState({ key: key });
  }

  handleOrgId(e) {
    const state = this.state;

    var orgId = e.target.value;
    this.setState({ orgId: orgId });
    if (orgId != undefined) {
      this.props.getClientsByOrgId(orgId);
    }
    this.clearClientAndList();
    console.log("this.state.orgId", orgId);
  }

  clearClientAndList = async () => {
    this.props.addUpdateClientSelected({});
    this.props.patientPhoneEmailClear();
    this.props.patientAddressClear();
    this.props.facilitiesClear();
    this.props.providerClear();
    this.props.clientEmployeesClear();
    this.setState(this.clientDefault);
  };

  setFullName = () => {
    var name = "";
    name = this.state.lastName + ", " + this.state.firstName;
    this.setState({ nameProvider: name });
  };

  saveClient = async () => {
    this.setState({ disabledSaveClient: true });
    var error = false;

    if (!this.state.name) {
      this.setState({ nameErr: "Please enter Name." });
      error = true;
    } else {
      this.setState({ nameErr: "" });
    }
    if (!this.state.executiveSponsorName) {
      this.setState({
        executiveSponsorNameErr: "Please enter Executive Sponsor Name.",
      });
      error = true;
    } else {
      this.setState({ executiveSponsorNameErr: "" });
    }
    if (!this.state.executiveSponsorPhone) {
      this.setState({
        executiveSponsorPhoneErr: "Please enter Executive Sponsor Phone.",
      });
      error = true;
    } else {
      this.setState({ executiveSponsorPhoneErr: "" });
    }
    if (!this.state.accountsPayablePhoneNumber) {
      this.setState({
        accountsPayablePhoneNumberErr: "Please enter Accounts Payable Phone.",
      });
      error = true;
    } else {
      this.setState({ accountsPayablePhoneNumberErr: "" });
    }
    if (!this.state.contactInvoicing) {
      this.setState({ contactInvoicingErr: "Please enter Contact Invoicing." });
      error = true;
    } else {
      this.setState({ contactInvoicingErr: "" });
    }
    if (!this.state.contactInvoicingEmail) {
      this.setState({
        contactInvoicingEmailErr: "Please enter Contact Invoicing Email.",
      });
      error = true;
    } else {
      this.setState({ contactInvoicingEmailErr: "" });
    }
    if (!this.state.taxId) {
      this.setState({ taxIdErr: "Please enter Tax Id." });
      error = true;
    } else {
      this.setState({ taxIdErr: "" });
    }
    if (!this.state.npi) {
      this.setState({ npiErr: "Please enter NPI." });
      error = true;
    } else {
      this.setState({ npiErr: "" });
    }
    if (!this.state.outpatientRate) {
      this.setState({ outpatientRateErr: "Please enter Out Patient Rate." });
      error = true;
    } else {
      this.setState({ outpatientRateErr: "" });
    }
    if (!this.state.inpatientRate) {
      this.setState({ inpatientRateErr: "Please enter In Patient Rate." });
      error = true;
    } else {
      this.setState({ inpatientRateErr: "" });
    }
    if (!this.state.contactCaseManager) {
      this.setState({
        contactCaseManagerErr: "Please enter Contact Case Manager.",
      });
      error = true;
    } else {
      this.setState({ contactCaseManagerErr: "" });
    }
    if (!this.state.contactCaseRepresentative) {
      this.setState({
        contactCaseRepresentativeErr: "Please enter Contact Case Rep.",
      });
      error = true;
    } else {
      this.setState({ contactCaseRepresentativeErr: "" });
    }
    if (!this.state.contactCaseRepresentativePhone) {
      this.setState({
        contactCaseRepresentativePhoneErr:
          "Please enter Contact Case Rep. Phone.",
      });
      error = true;
    } else {
      this.setState({ contactCaseRepresentativePhoneErr: "" });
    }

    if (error == false) {
      const { addedClient, menuSearch } = this.props;
      var orgId = menuSearch.clientOrgId;
      var client = { ...this.state, orgId: orgId, id: addedClient.id };
      console.log("client", client);
      await this.props.addUpdateClient(client);
      //this.props.getClients();
      this.props.getClientsByOrgId(orgId);

      this.clearClientErrors();
    }
    this.setState({ disabledSaveClient: false });
  };

  clearPhoneEmailErrors = () => {
    this.setState({
      numberEmailErr: "",
    });
  };

  savePhoneEmail = async () => {
    this.setState({ disabledSavePhoneEmail: true });
    var error = false;

    if (!this.state.numberEmail) {
      this.setState({ numberEmailErr: "Number/ Email is required." });
      error = true;
    } else {
      this.setState({ numberEmailErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedClient } = this.props;

      var phoneEmail = { ...this.state, parentId: addedClient.id };
      phoneEmail.status = state.statusPhoneEmail;
      await this.props.addUpdatePhoneEmail(phoneEmail);
      await this.props.getPatientPhoneEmail(addedClient.id);

      if (isNew == "Y") {
        this.setState(this.phoneEmailDefault);
      }
      this.clearPhoneEmailErrors();
    }

    this.setState({ disabledSavePhoneEmail: false });
  };

  clearAddressErrors = () => {
    this.setState({
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",
    });
  };

  saveAddress = async () => {
    this.setState({ disabledSaveAddress: true });
    var error = false;

    if (!this.state.address1) {
      this.setState({ address1Err: "Address1 is required." });
      error = true;
    } else {
      this.setState({ address1Err: "" });
    }

    if (!this.state.city) {
      this.setState({ cityErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityErr: "" });
    }

    if (!this.state.state) {
      this.setState({ stateErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!this.state.zip) {
      this.setState({ zipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedClient } = this.props;

      var address = { ...this.state, parentId: addedClient.id };
      address.status = state.statusAddress;
      await this.props.addUpdateAddress(address);
      await this.props.getPatientAddress(addedClient.id);
      if (isNew == "Y") {
        this.setState(this.addressDefault);
      }
      this.clearAddressErrors();
    }
    this.setState({ disabledSaveAddress: false });
  };

  clearProviderErrors = () => {
    this.setState({
      nameProviderErr: "",
      firstNameErr: "",
      lastNameErr: "",
      specialtyTaxonomyProviderErr: "",
      npiProviderErr: "",
      taxIdProviderErr: "",
      caseManagerErr: "",
      caseManagerEmailErr: "",
      concurrentReviewNurseErr: "",
      concurrentReviewNurseEmailErr: "",
      contactNameErr: "",
      contactPhoneErr: "",
      contactFaxErr: "",
      emailErr: "",
      faxErr: "",
      phoneNumberErr: "",
    });
  };

  saveProvider = async () => {
    this.setState({ disabledSaveProvider: true });

    var error = false;
    if (!this.state.nameProvider) {
      this.setState({ nameProviderErr: "Name is required." });
      error = true;
    } else {
      this.setState({ nameProviderErr: "" });
    }
    if (!this.state.firstName) {
      this.setState({ firstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ firstNameErr: "" });
    }
    if (!this.state.lastName) {
      this.setState({ lastNameErr: "LastName is required." });
      error = true;
    } else {
      this.setState({ lastNameErr: "" });
    }
    if (!this.state.specialtyTaxonomyProvider) {
      this.setState({
        specialtyTaxonomyProviderErr: "Specialty/ Taxonomy is required.",
      });
      error = true;
    } else {
      this.setState({ specialtyTaxonomyProviderErr: "" });
    }
    if (!this.state.npiProvider) {
      this.setState({ npiProviderErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ npiProviderErr: "" });
    }
    if (!this.state.taxIdProvider) {
      this.setState({ taxIdProviderErr: "Tax ID is required." });
      error = true;
    } else {
      this.setState({ taxIdProviderErr: "" });
    }
    if (!this.state.caseManager) {
      this.setState({ caseManagerErr: "Case Manager is required." });
      error = true;
    } else {
      this.setState({ caseManagerErr: "" });
    }
    if (!this.state.caseManagerEmail) {
      this.setState({ caseManagerEmailErr: "Case Manager Email is required." });
      error = true;
    } else {
      this.setState({ caseManagerEmailErr: "" });
    }
    if (!this.state.concurrentReviewNurse) {
      this.setState({
        concurrentReviewNurseErr: "Concurrent Review Nurse is required.",
      });
      error = true;
    } else {
      this.setState({ concurrentReviewNurseErr: "" });
    }
    if (!this.state.concurrentReviewNurseEmail) {
      this.setState({
        concurrentReviewNurseEmailErr:
          "Concurrent Review Nurse Email is required.",
      });
      error = true;
    } else {
      this.setState({ concurrentReviewNurseEmailErr: "" });
    }
    if (!this.state.contactName) {
      this.setState({ contactNameErr: "Contact Name is required." });
      error = true;
    } else {
      this.setState({ contactNameErr: "" });
    }
    if (!this.state.contactPhone) {
      this.setState({ contactPhoneErr: "Contact Phone is required." });
      error = true;
    } else {
      this.setState({ contactPhoneErr: "" });
    }
    if (!this.state.contactFax) {
      this.setState({ contactFaxErr: "Contact Fax is required." });
      error = true;
    } else {
      this.setState({ contactFaxErr: "" });
    }
    if (!this.state.email) {
      this.setState({ emailErr: "Email is required." });
      error = true;
    } else {
      this.setState({ emailErr: "" });
    }
    if (!this.state.fax) {
      this.setState({ faxErr: "Fax is required." });
      error = true;
    } else {
      this.setState({ faxErr: "" });
    }
    if (!this.state.phoneNumber) {
      this.setState({ phoneNumberErr: "Phone Number is required." });
      error = true;
    } else {
      this.setState({ phoneNumberErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedClient } = this.props;

      var provider = { ...this.state, clientId: addedClient.id };
      provider.status = state.statusProvider;
      provider.name = state.nameProvider;
      provider.taxId = state.taxIdProvider;
      provider.npi = state.npiProvider;
      provider.specialtyTaxonomy = state.specialtyTaxonomyProvider;
      provider.type = state.type;
      console.log("provider 1", provider);
      await this.props.addUpdateProvider(provider);
      await this.props.getProvider(addedClient.id);
      if (isNew == "Y") {
        this.setState(this.providerDefault);
      }
      this.clearProviderErrors();
    }
    this.setState({ disabledSaveProvider: false });
  };

  clearFacilityErrors = () => {
    this.setState({
      nameFacilityErr: "",
      specialtyTaxonomyFacilityErr: "",
      npiFacilityErr: "",
      taxIdFacilityErr: "",
      mainPhoneNumberErr: "",
      executiveSponsorNameFacilityErr: "",
      executiveSponsorPhoneFacilityErr: "",
      accountsPayablePhoneNumberFacilityErr: "",
      contactInvoicingFacilityErr: "",
      contactInvoicingEmailFacilityErr: "",
      outpatientRateFacilityErr: "",
      inpatientRateFacilityErr: "",
      contactCaseManagerFacilityErr: "",
      contactCaseRepresentativeFacilityErr: "",
      contactCaseRepresentativePhoneFacilityErr: "",
    });
  };

  saveFacility = async () => {
    this.setState({ disabledSaveFacility: true });
    var error = false;

    if (!this.state.nameFacility) {
      this.setState({ nameFacilityErr: "Name is required." });
      error = true;
    } else {
      this.setState({ nameFacilityErr: "" });
    }
    if (!this.state.specialtyTaxonomyFacility) {
      this.setState({
        specialtyTaxonomyFacilityErr: "Specialty Taxonomy is required.",
      });
      error = true;
    } else {
      this.setState({ specialtyTaxonomyFacilityErr: "" });
    }
    if (!this.state.npiFacility) {
      this.setState({ npiFacilityErr: "NPI is required." });
      error = true;
    } else {
      this.setState({ npiFacilityErr: "" });
    }
    if (!this.state.taxIdFacility) {
      this.setState({ taxIdFacilityErr: "Tax Id is required." });
      error = true;
    } else {
      this.setState({ taxIdFacilityErr: "" });
    }
    if (!this.state.mainPhoneNumber) {
      this.setState({ mainPhoneNumberErr: "Main Phone Number is required." });
      error = true;
    } else {
      this.setState({ mainPhoneNumberErr: "" });
    }
    if (!this.state.executiveSponsorNameFacility) {
      this.setState({
        executiveSponsorNameFacilityErr: "Exe. Sponsor Name is required.",
      });
      error = true;
    } else {
      this.setState({ executiveSponsorNameFacilityErr: "" });
    }
    if (!this.state.executiveSponsorPhoneFacility) {
      this.setState({
        executiveSponsorPhoneFacilityErr: "Exe. Sponsor Phone is required.",
      });
      error = true;
    } else {
      this.setState({ accountsPayablePhoneNumberFacilityErr: "" });
    }
    if (!this.state.accountsPayablePhoneNumberFacility) {
      this.setState({
        accountsPayablePhoneNumberFacilityErr:
          "Accnt. Payable Phone is required.",
      });
      error = true;
    } else {
      this.setState({ accountsPayablePhoneNumberFacilityErr: "" });
    }
    if (!this.state.contactInvoicingFacility) {
      this.setState({
        contactInvoicingFacilityErr: "Contact Invoicing is required.",
      });
      error = true;
    } else {
      this.setState({ contactInvoicingFacilityErr: "" });
    }
    if (!this.state.contactInvoicingEmailFacility) {
      this.setState({
        contactInvoicingEmailFacilityErr: "Cont. Invoicing Email is required.",
      });
      error = true;
    } else {
      this.setState({ contactInvoicingEmailFacilityErr: "" });
    }
    if (!this.state.outpatientRateFacility) {
      this.setState({
        outpatientRateFacilityErr: "Outpatient Rate is required.",
      });
      error = true;
    } else {
      this.setState({ outpatientRateFacilityErr: "" });
    }
    if (!this.state.inpatientRateFacility) {
      this.setState({
        inpatientRateFacilityErr: "Inpatient Rate is required.",
      });
      error = true;
    } else {
      this.setState({ inpatientRateFacilityErr: "" });
    }
    if (!this.state.contactCaseManagerFacility) {
      this.setState({
        contactCaseManagerFacilityErr: "Cont. Case Manager is required.",
      });
      error = true;
    } else {
      this.setState({ contactCaseManagerFacilityErr: "" });
    }
    if (!this.state.contactCaseRepresentativeFacility) {
      this.setState({
        contactCaseRepresentativeFacilityErr:
          "Cont. Case Representative is required.",
      });
      error = true;
    } else {
      this.setState({ contactCaseRepresentativeFacilityErr: "" });
    }
    if (!this.state.contactCaseRepresentativePhoneFacility) {
      this.setState({
        contactCaseRepresentativePhoneFacilityErr:
          "Cont. Case Representative Phone is required.",
      });
      error = true;
    } else {
      this.setState({ contactCaseRepresentativePhoneFacilityErr: "" });
    }
    console.log("I am here..." + error);
    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedClient } = this.props;

      var facility = { ...this.state, clientId: addedClient.id };
      facility.inpatientRate = state.inpatientRateFacility;
      facility.outpatientRate = state.outpatientRateFacility;
      facility.status = state.statusFacility;
      facility.name = state.nameFacility;
      facility.executiveSponsorName = state.executiveSponsorNameFacility;
      facility.executiveSponsorPhone = state.executiveSponsorPhoneFacility;
      facility.accountsPayablePhoneNumber =
        state.accountsPayablePhoneNumberFacility;
      facility.contactInvoicing = state.contactInvoicingFacility;
      facility.contactInvoicingEmail = state.contactInvoicingEmailFacility;
      facility.taxId = state.taxIdFacility;
      facility.npi = state.npiFacility;
      facility.specialtyTaxonomy = state.specialtyTaxonomyFacility;
      facility.runEligibility = state.runEligibilityFacility;
      facility.fullService = state.fullServiceFacility;
      facility.contactCaseManager = state.contactCaseManagerFacility;
      facility.contactCaseRepresentative =
        state.contactCaseRepresentativeFacility;
      facility.contactCaseRepresentativePhone =
        state.contactCaseRepresentativePhoneFacility;
      facility.type = state.type;

      await this.props.addUpdateFacility(facility);
      await this.props.getFacilities(addedClient.id);
      if (isNew == "Y") {
        this.setState(this.FacilityDefault);
      }
      this.clearFacilityErrors();
    }
    this.setState({ disabledSaveFacility: false });
  };

  clearEmployeeErrors = () => {
    this.setState({
      nameProviderErr: "",
      firstNameErr: "",
      lastNameErr: "",
    });
  };

  saveEmployee = async () => {
    this.setState({ disabledSaveEmployee: true });

    var error = false;

    if (!this.state.middleName) {
      this.setState({ middleNameErr: "Middle Name is required." });
      error = true;
    } else {
      this.setState({ middleNameErr: "" });
    }
    if (!this.state.firstName) {
      this.setState({ firstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ firstNameErr: "" });
    }
    if (!this.state.lastName) {
      this.setState({ lastNameErr: "Last Name is required." });
      error = true;
    } else {
      this.setState({ lastNameErr: "" });
    }
    if (!this.state.userId) {
      this.setState({ userIdErr: "User ID is required." });
      error = true;
    } else {
      this.setState({ userIdErr: "" });
    }
    if (!this.state.userPassword) {
      this.setState({ userPasswordErr: "User Password is required." });
      error = true;
    } else {
      this.setState({ userPasswordErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const state = this.state;
      const { addedClient } = this.props;

      var employee = { ...this.state, parentId: addedClient.id };
      var re =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
      employee.status = state.statusEmployee;
      if (employee.userPassword.match(re)) {
        await this.props.addUpdateEmployee(employee);
        await this.props.getClientEmployees(addedClient.id);
        if (isNew == "Y") {
          this.setState(this.employeeDefault);
        }
      } else {
        alert(
          "Password did not satisfy the rules:\n" +
            "1. Password must be between 8 to 30 characters.\n" +
            "2. Password must contain at least one uppercase or capital letter.\n" +
            "3. Password must contain at least one lowercase letter.\n" +
            "4. Password must contain at least one number.\n" +
            "5. Password must contain at least one special character ($, #, @, !, &, *, (,))."
        );
      }
      this.clearEmployeeErrors();
    }
    this.setState({ disabledSaveEmployee: false });
  };

  onClickRow = (record) => {
    this.setInOutPatientRate(record);
    this.setRunEligibility(record);
    this.setFullService(record);
    this.setRunEligibilityFacility(record);
    this.setFullServiceFacility(record);

    this.props.addUpdateClientSelected(record);
    const { addedClient } = this.props;

    console.log("addedClient", addedClient);

    this.props.getPatientPhoneEmail(record.id);
    this.props.getPatientAddress(record.id);
    this.props.getFacilities(record.id);
    this.props.getClientEmployees(record.id);
    this.props.getProvider(record.id);

    this.setState(record);

    this.clearClientErrors();
    this.clearPhoneEmailErrors();
    this.clearAddressErrors();
    this.clearFacilityErrors();
    this.clearProviderErrors();
    this.clearEmployeeErrors();
  };

  ninetyDaysChangePassword = async () => {
    let userInfo = this.props.user;
    if (!userInfo.passwordChanged) {
      userInfo.passwordChanged = new Date();
      await this.props.addUpdateEmployee(userInfo);
      await this.props.getUser(userInfo.userId);
    } else {
      let passwordChanged = new Date(userInfo.passwordChanged);
      let ninetyDaysFromNow = new Date();
      ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() - 90);
      if (passwordChanged < ninetyDaysFromNow) {
        alert("Your password has expired. Please create a new password.");
        this.props.navigate("/change-password");
      }
    }
  };

  async componentDidMount() {
    if (!this.props.user) {
      let userId = sessionStorage.getItem("userId");
      await this.props.getUser(userId);
    }

    const { menuSearch } = this.props;

    await this.ninetyDaysChangePassword();
    this.props.getClientsByOrgId(menuSearch.clientOrgId);
    await this.props.getAllOrganization();
    this.clearClientAndList();

    this.setState({ hideClientInfo: true });
  }

  setInOutPatientRate = (record) => {
    var inpatientRate = 0;
    var inpatientRateIsChecked = [];
    if (record.inpatientRate && record.inpatientRate != null) {
      console.log("record.inpatientRate", record.inpatientRate);
      if (record.inpatientRate == 0) {
        inpatientRate = 0;
      } else {
        inpatientRateIsChecked = [1];
        inpatientRate = 1;
      }
    }

    this.setState({
      inpatientRate: inpatientRate,
      inpatientRateIsChecked: inpatientRateIsChecked,
    });

    var outpatientRate = 0;
    var outpatientRateIsChecked = [];
    if (record.outpatientRate && record.outpatientRate != null) {
      console.log("record.outpatientRate", record.outpatientRate);
      if (record.outpatientRate == 0) {
        outpatientRate = 0;
      } else {
        outpatientRateIsChecked = [1];
        outpatientRate = 1;
      }
    }

    this.setState({
      outpatientRate: outpatientRate,
      outpatientRateIsChecked: outpatientRateIsChecked,
    });
  };

  setRunEligibility = (record) => {
    var runEligibility = "N";
    var runEligibilityIsChecked = [];
    if (record.runEligibility && record.runEligibility != null) {
      console.log("record.runEligibility", record.runEligibility);
      if (record.runEligibility == "N") {
        runEligibility = "N";
      } else {
        runEligibilityIsChecked = ["Y"];
        runEligibility = "Y";
      }
    }
    this.setState({
      runEligibility: runEligibility,
      runEligibilityIsChecked: runEligibilityIsChecked,
    });
  };

  handleRunEligibility(event) {
    var runEligibility = "N";
    var runEligibilityIsChecked = [];
    if (event.target.checked) {
      runEligibility = "Y";
      runEligibilityIsChecked = ["Y"];
    }
    this.setState({
      runEligibility: runEligibility,
      runEligibilityIsChecked: runEligibilityIsChecked,
    });
  }

  setRunEligibilityFacility = (record) => {
    var runEligibilityFacility = "N";
    var runEligibilityFacilityIsChecked = [];
    if (
      record.runEligibilityFacility &&
      record.runEligibilityFacility != null
    ) {
      if (record.runEligibilityFacility == "N") {
        runEligibilityFacility = "N";
      } else {
        runEligibilityFacilityIsChecked = ["Y"];
        runEligibilityFacility = "Y";
      }
    }
    this.setState({
      runEligibilityFacility: runEligibilityFacility,
      runEligibilityFacilityIsChecked: runEligibilityFacilityIsChecked,
    });
  };

  handleRunEligibilityFacility(event) {
    var runEligibilityFacility = "N";
    var runEligibilityFacilityIsChecked = [];
    if (event.target.checked) {
      runEligibilityFacility = "Y";
      runEligibilityFacilityIsChecked = ["Y"];
    }
    this.setState({
      runEligibilityFacility: runEligibilityFacility,
      runEligibilityFacilityIsChecked: runEligibilityFacilityIsChecked,
    });
  }

  setFullService = (record) => {
    var fullService = "N";
    var fullServiceIsChecked = [];
    if (record.fullService && record.fullService != null) {
      if (record.fullService == "N") {
        fullService = "N";
      } else {
        fullServiceIsChecked = ["Y"];
        fullService = "Y";
      }
    }
    this.setState({
      fullService: fullService,
      fullServiceIsChecked: fullServiceIsChecked,
    });
  };

  handleFullService(event) {
    var fullService = "N";
    var fullServiceIsChecked = [];
    if (event.target.checked) {
      fullService = "Y";
      fullServiceIsChecked = ["Y"];
    }
    this.setState({
      fullService: fullService,
      fullServiceIsChecked: fullServiceIsChecked,
    });
  }

  setFullServiceFacility = (record) => {
    var fullServiceFacility = "N";
    var fullServiceFacilityIsChecked = [];
    if (record.fullServiceFacility && record.fullServiceFacility != null) {
      if (record.fullServiceFacility == "N") {
        fullServiceFacility = "N";
      } else {
        fullServiceFacilityIsChecked = ["Y"];
        fullServiceFacility = "Y";
      }
    }
    this.setState({
      fullServiceFacility: fullServiceFacility,
      fullServiceFacilityIsChecked: fullServiceFacilityIsChecked,
    });
  };

  handleFullServiceFacility(event) {
    var fullServiceFacility = "N";
    var fullServiceFacilityIsChecked = [];
    if (event.target.checked) {
      fullServiceFacility = "Y";
      fullServiceFacilityIsChecked = ["Y"];
    }
    this.setState({
      fullServiceFacility: fullServiceFacility,
      fullServiceFacilityIsChecked: fullServiceFacilityIsChecked,
    });
  }

  handleOutpatientRate(event) {
    var outpatientRate = 0;
    var outpatientRateIsChecked = [];
    if (event.target.checked) {
      outpatientRate = 1;
      outpatientRateIsChecked = [1];
    }
    this.setState({
      outpatientRate: outpatientRate,
      outpatientRateIsChecked: outpatientRateIsChecked,
    });

    console.log("I am here 2", outpatientRate);
    console.log("I am here 3", outpatientRateIsChecked);
  }

  handleInpatientRate(event) {
    var inpatientRate = 0;
    var inpatientRateIsChecked = [];
    if (event.target.checked) {
      inpatientRate = 1;
      inpatientRateIsChecked = [1];
    }
    this.setState({
      inpatientRate: inpatientRate,
      inpatientRateIsChecked: inpatientRateIsChecked,
    });

    console.log("I am here 2", inpatientRate);
  }

  setInOutPatientRateFacility = (record) => {
    var inpatientRateFacility = 0;
    var inpatientRateIsCheckedFacility = [];
    if (record.inpatientRateFacility && record.inpatientRateFacility != null) {
      console.log("record.inpatientRate", record.inpatientRate);
      if (record.inpatientRateFacility == 0) {
        inpatientRateFacility = 0;
      } else {
        inpatientRateIsCheckedFacility = [1];
        inpatientRateFacility = 1;
      }
    }

    this.setState({
      inpatientRateFacility: inpatientRateFacility,
      inpatientRateIsCheckedFacility: inpatientRateIsCheckedFacility,
    });

    var outpatientRateFacility = 0;
    var outpatientRateIsCheckedFacility = [];
    if (
      record.outpatientRateFacility &&
      record.outpatientRateFacility != null
    ) {
      console.log("record.outpatientRate", record.outpatientRateFacility);
      if (record.outpatientRateFacility == 0) {
        outpatientRateFacility = 0;
      } else {
        outpatientRateIsCheckedFacility = [1];
        outpatientRateFacility = 1;
      }
    }

    this.setState({
      outpatientRateFacility: outpatientRateFacility,
      outpatientRateIsCheckedFacility: outpatientRateIsCheckedFacility,
    });
  };

  handleOutpatientRateFacility(event) {
    var outpatientRateFacility = 0;
    var outpatientRateIsCheckedFacility = [];
    if (event.target.checked) {
      outpatientRateFacility = 1;
      outpatientRateIsCheckedFacility = [1];
    }
    this.setState({
      outpatientRateFacility: outpatientRateFacility,
      outpatientRateIsCheckedFacility: outpatientRateIsCheckedFacility,
    });

    console.log("I am here 2", outpatientRateFacility);
    console.log("I am here 3", outpatientRateIsCheckedFacility);
  }

  handleInpatientRateFacility(event) {
    var inpatientRateFacility = 0;
    var inpatientRateIsCheckedFacility = [];
    if (event.target.checked) {
      inpatientRateFacility = 1;
      inpatientRateIsCheckedFacility = [1];
    }
    this.setState({
      inpatientRateFacility: inpatientRateFacility,
      inpatientRateIsCheckedFacility: inpatientRateIsCheckedFacility,
    });

    console.log("I am here 2", inpatientRateFacility);
  }

  clearClientErrors = () => {
    this.setState({
      nameErr: "",
      executiveSponsorNameErr: "",
      executiveSponsorPhoneErr: "",
      accountsPayablePhoneNumberErr: "",
      contactInvoicingErr: "",
      contactInvoicingEmailErr: "",
      taxIdErr: "",
      npiErr: "",
      outpatientRateErr: "",
      inpatientRateErr: "",
      contactCaseManagerErr: "",
      contactCaseRepresentativeErr: "",
      contactCaseRepresentativePhoneErr: "",
    });
  };

  onClickHandler(record, e) {
    this.clearClientErrors();
    this.clearPhoneEmailErrors();
    this.clearAddressErrors();
    this.clearFacilityErrors();
    this.clearProviderErrors();
    this.clearEmployeeErrors();

    const state = this.state;

    if (record.saveToTable) {
      if (record.saveToTable == "Facility") {
        if (record.isNew == "N") {
          record.statusFacility = record.status;
          record.nameFacility = record.name;
          record.executiveSponsorNameFacility = record.executiveSponsorName;
          record.accountsPayablePhoneNumberFacility =
            record.accountsPayablePhoneNumber;
          record.contactInvoicingFacility = record.contactInvoicing;
          record.contactInvoicingEmailFacility = record.contactInvoicingEmail;
          record.taxIdFacility = record.taxId;
          record.npiFacility = record.npi;
          record.specialtyTaxonomyFacility = record.specialtyTaxonomy;
          record.runEligibilityFacility = record.runEligibility;
          record.fullServiceFacility = record.fullService;
          record.contactCaseManagerFacility = record.contactCaseManager;
          record.contactCaseRepresentativeFacility =
            record.contactCaseRepresentative;
          record.contactCaseRepresentativePhoneFacility =
            record.contactCaseRepresentativePhone;
          record.inpatientRateFacility = record.inpatientRate;
          record.outpatientRateFacility = record.outpatientRate;
          record.type = record.type;
        }

        console.log("inpatientRate", state.inpatientRate);
        console.log(" inpatientRateFacility", state.inpatientRateFacility);
        record.inpatientRate = state.inpatientRate;
        record.outpatientRate = state.outpatientRate;
        record.status = state.status;
        record.name = state.name;
        record.executiveSponsorName = state.executiveSponsorName;
        record.executiveSponsorPhone = state.executiveSponsorPhone;
        record.accountsPayablePhoneNumber = state.accountsPayablePhoneNumber;
        record.contactInvoicing = state.contactInvoicing;
        record.contactInvoicingEmail = state.contactInvoicingEmail;
        record.taxId = state.taxId;
        record.npi = state.npi;
        record.runEligibility = state.runEligibility;
        record.fullService = state.fullService;
        record.contactCaseManager = state.contactCaseManager;
        record.contactCaseRepresentative = state.contactCaseRepresentative;
        record.contactCaseRepresentativePhone =
          state.contactCaseRepresentativePhone;

        this.setInOutPatientRateFacility(record);
        this.setRunEligibilityFacility(record);
        this.setFullServiceFacility(record);
      } else if (record.saveToTable == "PhoneEmail") {
        if (record.isNew == "N") {
          record.statusPhoneEmail = record.status;
        }
        record.status = state.status;
      } else if (record.saveToTable == "Address") {
        if (record.isNew == "N") {
          record.statusAddress = record.status;
        }
        record.status = state.status;
      } else if (record.saveToTable == "Provider") {
        if (record.isNew == "N") {
          record.statusProvider = record.status;
          record.nameProvider = record.name;
          record.taxIdProvider = record.taxId;
          record.npiProvider = record.npi;
          record.specialtyTaxonomyProvider = record.specialtyTaxonomy;
          record.type = record.type;
        }

        record.status = state.status;
        record.name = state.name;
        record.taxId = state.taxId;
        record.npi = state.npi;
      } else if (record.saveToTable == "Employee") {
        if (record.isNew == "N") {
          record.statusEmployee = record.status;
        }
        record.status = state.status;
      }
    }
    console.log(record);
    this.setState(record);
    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
  }

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  render() {
    var {
      addedClient,
      phoneEmailList,
      addressList,
      providerList,
      employeesList,
      clientList,
      facilityList,
      organizationList,
      menuSearch,
    } = this.props;
    const { editAddressModal, hideClientInfo } = this.state;
    const orgId = menuSearch.clientOrgId;

    if (!phoneEmailList) {
      phoneEmailList = [];
    }

    phoneEmailList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!addressList) {
      addressList = [];
    }

    addressList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!providerList) {
      providerList = [];
    }

    providerList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!employeesList) {
      employeesList = [];
    }

    employeesList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!clientList) {
      clientList = [];
    }

    if (!facilityList) {
      facilityList = [];
    }

    facilityList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!organizationList) {
      organizationList = [];
    }

    var hideList = { display: "none" };
    if (addedClient) {
      if (addedClient.id) {
        hideList = { display: "block" };
      }
    }

    var hideBasic = { display: "none" };
    if (orgId) {
      hideBasic = { display: "block" };
    }

    var hideClient = { display: "none" };
    var hideClientList = { display: "none" };
    if (hideClientInfo == true) {
      hideClient = { display: "none" };
      hideClientList = { display: "block" };
    } else {
      hideClient = { display: "block" };
      hideClientList = { display: "none" };
    }

    const tooltipNote = () => (
      <Tooltip id="button-tooltip">
        <div>
          <label>
            1. Password must be between 8 to 30 characters. <br />
            2. Password must contain at least one uppercase or capital letter{" "}
            <br />
            3. Password must contain at least one lowercase letter <br />
            4. Password must contain at least one number <br />
            5. Password must contain at least one special character ($, #, @, !,
            &, *, (,)).")
          </label>
        </div>
      </Tooltip>
    );

    console.log("organizationList", organizationList);
    console.log(
      "this.state.outpatientRateIsCheckedFacilit",
      this.state.outpatientRateIsCheckedFacility
    );
    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="auth-logo-cont">
            <svg
              width="118"
              height="24"
              viewBox="0 0 118 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.28 0C0.413213 0 0 8.54562 0 8.54562V23.7477C0 23.8803 0.108893 23.988 0.243042 23.988H2.55173C2.68588 23.988 2.79478 23.8803 2.79478 23.7477V13.6394C2.79478 13.5068 2.90367 13.3991 3.03782 13.3991H17.522C17.6562 13.3991 17.7651 13.5068 17.7651 13.6394V23.7477C17.7651 23.8803 17.8739 23.988 18.0081 23.988H20.3168C20.4509 23.988 20.5598 23.8803 20.5598 23.7477V8.54562C20.5598 8.54562 20.1466 0 10.28 0ZM17.7653 10.8523C17.7653 10.9849 17.6564 11.0926 17.5222 11.0926H3.03782C2.90367 11.0926 2.79478 10.9849 2.79478 10.8523V9.0262C2.79478 5.49636 5.68892 2.63499 9.2592 2.63499H11.3006C14.8709 2.63499 17.7651 5.49636 17.7651 9.0262V10.8523H17.7653Z"
                fill="#2DC0D0"
              />
              <path
                d="M37.6368 5.4519V15.287C37.6368 18.7372 34.8078 21.5341 31.3181 21.5341C27.8283 21.5341 24.9994 18.7372 24.9994 15.287V5.4519H22.3423V15.0796C22.3423 19.9806 26.3608 23.9536 31.3179 23.9536C36.275 23.9536 40.2934 19.9806 40.2934 15.0796V5.4519H37.6363H37.6368Z"
                fill="#2DC0D0"
              />
              <path
                d="M49.3089 21.4402C46.6133 21.4402 44.4282 19.2798 44.4282 16.6148V8.38945C44.4282 8.27217 44.5242 8.1772 44.6429 8.1772H50.4349C50.5535 8.1772 50.6496 8.08223 50.6496 7.96495V5.95463C50.6496 5.83735 50.5535 5.74238 50.4349 5.74238H44.6429C44.5242 5.74238 44.4282 5.64741 44.4282 5.53013V0.212249C44.4282 0.0949695 44.3321 0 44.2135 0H42.245C42.1263 0 42.0303 0.0949695 42.0303 0.212249V17.6719C42.0303 20.9915 44.7522 23.6826 48.1098 23.6826H52.379C52.4976 23.6826 52.5937 23.5876 52.5937 23.4704V21.6522C52.5937 21.535 52.4976 21.44 52.379 21.44H49.3089V21.4402Z"
                fill="#2DC0D0"
              />
              <path
                d="M63.3938 5.48613C60.9294 5.48613 58.6971 6.46816 57.0751 8.05767V0H54.418V23.988H57.0751V14.1529C57.0751 10.7027 59.904 7.9058 63.3938 7.9058C66.8835 7.9058 69.7124 10.7027 69.7124 14.1529V23.988H72.3695V14.3602C72.3695 9.45929 68.3511 5.48633 63.394 5.48633L63.3938 5.48613Z"
                fill="#2DC0D0"
              />
              <path
                d="M83.7622 4.90674C78.4294 4.90674 74.106 9.18098 74.106 14.4534C74.106 19.7259 78.4292 24.0001 83.7622 24.0001C89.0953 24.0001 93.4183 19.7259 93.4183 14.4534C93.4183 9.18098 89.0951 4.90674 83.7622 4.90674ZM83.7622 21.5503C79.7978 21.5503 76.584 18.373 76.584 14.4534C76.584 10.5339 79.7978 7.3565 83.7622 7.3565C87.7267 7.3565 90.9404 10.5339 90.9404 14.4534C90.9404 18.373 87.7267 21.5503 83.7622 21.5503Z"
                fill="#2DC0D0"
              />
              <path
                d="M112.778 3.39188V0.212249C112.778 0.0949695 112.682 0 112.563 0H95.2317V23.7757C95.2317 23.893 95.3277 23.988 95.4464 23.988H98.6624C98.781 23.988 98.8771 23.893 98.8771 23.7757V12.8506C98.8771 12.7333 98.9732 12.6383 99.0918 12.6383H109.89C110.009 12.6383 110.105 12.5433 110.105 12.4261V9.24643C110.105 9.12915 110.009 9.03418 109.89 9.03418H99.0918C98.9732 9.03418 98.8771 8.93921 98.8771 8.82193V3.81638C98.8771 3.6991 98.9732 3.60413 99.0918 3.60413H112.563C112.682 3.60413 112.778 3.50916 112.778 3.39188Z"
                fill="#22449C"
              />
              <path
                d="M117.805 5.99707H114.783C114.675 5.99707 114.588 6.08348 114.588 6.19008V9.88059C114.588 9.98718 114.675 10.0736 114.783 10.0736H117.805C117.913 10.0736 118 9.98718 118 9.88059V6.19008C118 6.08348 117.913 5.99707 117.805 5.99707Z"
                fill="#22449C"
              />
              <path
                d="M117.805 11.6594H114.783C114.675 11.6594 114.588 11.7458 114.588 11.8524V23.7852C114.588 23.8918 114.675 23.9782 114.783 23.9782H117.805C117.913 23.9782 118 23.8918 118 23.7852V11.8524C118 11.7458 117.913 11.6594 117.805 11.6594Z"
                fill="#22449C"
              />
            </svg>
          </div>
          <div className="d-flex justify-content-end">
            <Search />
            <RightPanel />
          </div>
        </div>
        <Modal show={editAddressModal} size="lg" centered>
          <Modal.Body className="p-5">
            <div class="form-title-label">
              <label className="border-bottom">Edit Address:</label>
            </div>
            <Form className="mt-4">
              <Row>
                <div class="col-lg-12 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Address 1
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalAddress1
                          : this.state.mailingAddress1
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalAddress1: e.target.value }
                            : { mailingAddress1: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.address1Err}
                  </span>
                </div>
              </Row>
              <Row>
                <div class="col-lg-12 col-6">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Address 2
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalAddress2
                          : this.state.mailingAddress2
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalAddress2: e.target.value }
                            : { mailingAddress2: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div class="col-lg-5 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      City
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalCity
                          : this.state.mailingCity
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalCity: e.target.value }
                            : { mailingCity: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.cityErr}
                  </span>
                </div>
                <div class="col-lg-5 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      State
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalState
                          : this.state.mailingState
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalState: e.target.value }
                            : { mailingState: e.target.value }
                        )
                      }
                    >
                      <option selected disabled value="">
                        {" "}
                        -- Select State --{" "}
                      </option>
                      {USStates.map((USState, key) => {
                        return (
                          <option key={key} value={USState.abbreviation}>
                            {USState.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.stateErr}
                  </span>
                </div>
                <div class="col-lg-2 col-6 mt-1">
                  <Form.Group as={Col} controlId="">
                    <Form.Label className="table-form-label mb-0">
                      Zip
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      value={
                        this.state.editAddressModalName == "PHYSICAL"
                          ? this.state.physicalZip
                          : this.state.mailingZip
                      }
                      onChange={(e) =>
                        this.setState(
                          this.state.editAddressModalName == "PHYSICAL"
                            ? { physicalZip: e.target.value }
                            : { mailingZip: e.target.value }
                        )
                      }
                    />
                  </Form.Group>
                  <span className="table-form-label-err mb-0">
                    {this.state.zipErr}
                  </span>
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-6 p-0">
                  <Button
                    className="btn btn-success grid-btn submit-modal"
                    type="button"
                    onClick={this.submitEditAddressModal.bind(this)}
                  >
                    SUBMIT
                  </Button>
                </div>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content">
            <div class="row">
              <div className="col-md-8">
                <label className="patient-title" l>
                  Clients
                </label>
              </div>
              <div className="col-md-4">
                <Button
                  className="add-patients-btn btn-primary mb-1 float-end m-0"
                  variant="primary"
                  type="button"
                  onClick={() => {
                    this.clearClientAndList();
                    this.setState({ hideClientInfo: false });
                  }}
                >
                  <img
                    src={require("../../images/add-icon.png")}
                    className="add-icon"
                  />
                  Add New Client
                </Button>
                <Button
                  style={hideClient}
                  className="btn back-btn mb-1 float-end mr-1"
                  type="button"
                  onClick={() => {
                    this.clearClientAndList();
                    this.setState({ hideClientInfo: true });
                  }}
                >
                  <img
                    src={require("../../images/back-icon.png")}
                    className="add-icon"
                  />
                  Back
                </Button>
              </div>
            </div>
            <div style={hideClient} class="main-panel">
              <div style={hideBasic}>
                <Row className="col-lg-12">
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.nameErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Executive Sponsor Name
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.executiveSponsorName}
                        onChange={(e) =>
                          this.setState({
                            executiveSponsorName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.executiveSponsorNameErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Executive Sponsor Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.executiveSponsorPhone}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({ executiveSponsorPhone: phone });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.executiveSponsorPhoneErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Accounts Payable Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.accountsPayablePhoneNumber}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({ accountsPayablePhoneNumber: phone });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.accountsPayablePhoneNumberErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Invoicing
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactInvoicing}
                        onChange={(e) =>
                          this.setState({ contactInvoicing: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactInvoicingErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Invoicing Email
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactInvoicingEmail}
                        onChange={(e) =>
                          this.setState({
                            contactInvoicingEmail: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactInvoicingEmailErr}
                    </span>
                  </div>
                </Row>
                <Row className="col-lg-12">
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Tax Id
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.taxId}
                        onChange={(e) =>
                          this.setState({ taxId: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.taxIdErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Npi
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.npi}
                        onChange={(e) => this.setState({ npi: e.target.value })}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.npiErr}
                    </span>
                  </div>
                  <div class="col-lg-2 mt-1 text-center">
                    <Form.Label className="table-form-label mb-0">
                      Out Patient Rate
                    </Form.Label>
                    <Form.Group
                      className="client-rate-form"
                      as={Col}
                      controlId=""
                    >
                      <Form.Control
                        className="client-rate-control"
                        value={this.state.outpatientRate}
                        onChange={(e) =>
                          this.setState({ outpatientRate: e.target.value })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div class="col-lg-2 mt-1 text-center">
                    <Form.Label className="table-form-label mb-0">
                      In Patient Rate
                    </Form.Label>
                    <Form.Group
                      className="client-rate-form"
                      as={Col}
                      controlId=""
                    >
                      <Form.Control
                        className="client-rate-control"
                        value={this.state.inpatientRate}
                        onChange={(e) =>
                          this.setState({ inpatientRate: e.target.value })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div class="col-lg-4 col-6 mt-4 d-flex">
                    <Form.Check
                      className="grid-form-control client-checkbox mt-1"
                      label="Run Eligibility"
                      onChange={this.handleRunEligibility}
                      checked={this.state.runEligibilityIsChecked.includes("Y")}
                    />
                    <Form.Check
                      className="grid-form-control client-checkbox mt-1"
                      label="Full Service"
                      onChange={this.handleFullService}
                      checked={this.state.fullServiceIsChecked.includes("Y")}
                    />
                  </div>
                </Row>
                <Row className="col-lg-12">
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Manager
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactCaseManager}
                        onChange={(e) =>
                          this.setState({ contactCaseManager: e.target.value })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseManagerErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Representative
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={40}
                        value={this.state.contactCaseRepresentative}
                        onChange={(e) =>
                          this.setState({
                            contactCaseRepresentative: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseRepresentativeErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Contact Case Phone
                      </Form.Label>
                      <Form.Control
                        className="grid-form-control"
                        maxLength={15}
                        value={this.state.contactCaseRepresentativePhone}
                        onChange={(e) => {
                          let phone = this.addDashes(e.target.value);
                          this.setState({
                            contactCaseRepresentativePhone: phone,
                          });
                        }}
                      />
                    </Form.Group>
                    <span className="table-form-label-err mb-0">
                      {this.state.contactCaseRepresentativePhoneErr}
                    </span>
                  </div>
                  <div class="col-lg-2 col-6 mt-1">
                    <Form.Group as={Col} controlId="">
                      <Form.Label className="table-form-label mb-0">
                        Status
                      </Form.Label>
                      <Form.Select
                        className="grid-form-control"
                        value={this.state.status}
                        onChange={(e) =>
                          this.setState({ status: e.target.value })
                        }
                      >
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="NOT ACTIVE">NOT ACTIVE</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div class="col-lg-4">
                    <Button
                      className="form-save-btn mt-4 mb-2 float-end"
                      variant="primary"
                      type="button"
                      disabled={this.state.disabledSaveClient}
                      onClick={() => {
                        this.saveClient();
                      }}
                    >
                      <img
                        src={require("../../images/save-btn-icon.png")}
                        className="add-icon"
                      />
                      Save
                    </Button>
                  </div>
                </Row>
              </div>
            </div>
            <div style={hideList}>
              <div className="line-sep mt-2"></div>
              <div>
                <div className="row">
                  <div className="col-6">
                    <div class="form-title-label mt-2">
                      <label className="border-bottom">Phone and Email</label>
                    </div>
                    <Table bordered hover className="table-portal">
                      <thead>
                        <tr>
                          <th className="td-first"></th>
                          <th>Type</th>
                          <th>Number/ Email</th>
                          <th className="td-last">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {phoneEmailList.map((record, key) => {
                          return (
                            <>
                              <tr
                                onClick={(event) =>
                                  this.onClickHandler(
                                    {
                                      ...record,
                                      isNew: "N",
                                      saveToTable: "PhoneEmail",
                                    },
                                    event
                                  )
                                }
                              >
                                <td className="td-first">
                                  <i className="fa fa-chevron-down"></i>
                                </td>
                                <td>{record.type}</td>
                                <td>{record.numberEmail}</td>
                                <td className="td-last">{record.status}</td>
                              </tr>
                              <tr className="sub-table collapse">
                                <td className="td-first td-last" colSpan="4">
                                  <Form>
                                    <Row className="mb-1">
                                      <div class="col-lg-3 col-6">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Type
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.type}
                                            onChange={(e) =>
                                              this.setState({
                                                type: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="HOME">HOME</option>
                                            <option value="WORK">WORK</option>
                                            <option value="MOBILE">
                                              MOBILE
                                            </option>
                                            <option value="LANDLINE">
                                              LANDLINE
                                            </option>
                                            <option value="EMAIL">EMAIL</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                      <div class="col-lg-6 col-6">
                                        <Form.Group
                                          as={Col}
                                          controlId="formGridPassword"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Number/ Email
                                          </Form.Label>
                                          <Form.Control
                                            className="grid-form-control"
                                            maxLength={40}
                                            value={this.state.numberEmail}
                                            onChange={(e) =>
                                              this.setState({
                                                numberEmail: e.target.value,
                                              })
                                            }
                                          />
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.numberEmailErr}
                                        </span>
                                      </div>
                                      <div class="col-lg-3 col-6">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Status
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.statusPhoneEmail}
                                            onChange={(e) =>
                                              this.setState({
                                                statusPhoneEmail:
                                                  e.target.value,
                                              })
                                            }
                                          >
                                            <option value="ACTIVE">
                                              ACTIVE
                                            </option>
                                            <option value="INACTIVE">
                                              INACTIVE
                                            </option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-2">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Primary
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.isPrimary}
                                            onChange={(e) =>
                                              this.setState({
                                                isPrimary: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-2">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            SMS
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.sms}
                                            onChange={(e) =>
                                              this.setState({
                                                sms: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                    </Row>
                                    <Row>
                                      <div>
                                        <Button
                                          className="form-save-btn btn-primary mt-1 float-end"
                                          type="button"
                                          disabled={
                                            this.state.disabledSavePhoneEmail
                                          }
                                          onClick={() => {
                                            this.savePhoneEmail();
                                          }}
                                        >
                                          <img
                                            src={require("../../images/save-btn-icon.png")}
                                            className="add-icon"
                                          />
                                          Save
                                        </Button>
                                      </div>
                                    </Row>
                                  </Form>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              {
                                ...this.phoneEmailDefault,
                                isNew: "Y",
                                saveToTable: "PhoneEmail",
                              },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td></td>
                          <td></td>
                          <td className="td-last"></td>
                        </tr>
                        <tr className="sub-table collapse">
                          <td className="td-first td-last" colSpan="4">
                            <Form>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Type
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.type}
                                      onChange={(e) =>
                                        this.setState({ type: e.target.value })
                                      }
                                    >
                                      <option value="HOME">HOME</option>
                                      <option value="WORK">WORK</option>
                                      <option value="MOBILE">MOBILE</option>
                                      <option value="LANDLINE">LANDLINE</option>
                                      <option value="EMAIL">EMAIL</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-6 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="formGridPassword"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Number/ Email
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.numberEmail}
                                      onChange={(e) =>
                                        this.setState({
                                          numberEmail: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Status
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.statusPhoneEmail}
                                      onChange={(e) =>
                                        this.setState({
                                          statusPhoneEmail: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="ACTIVE">ACTIVE</option>
                                      <option value="INACTIVE">INACTIVE</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-2">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Primary
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.isPrimary}
                                      onChange={(e) =>
                                        this.setState({
                                          isPrimary: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-2">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      SMS
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.sms}
                                      onChange={(e) =>
                                        this.setState({ sms: e.target.value })
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-1 float-end"
                                    type="button"
                                    disabled={this.state.disabledSavePhoneEmail}
                                    onClick={() => {
                                      this.savePhoneEmail();
                                    }}
                                  >
                                    <img
                                      src={require("../../images/save-btn-icon.png")}
                                      className="add-icon"
                                    />
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="col-6">
                    <div class="form-title-label mt-2">
                      <label className="border-bottom">Address</label>
                    </div>
                    <Table bordered hover className="table-portal">
                      <thead>
                        <tr>
                          <th className="td-first"></th>
                          <th>Type</th>
                          <th>Address</th>
                          <th>City</th>
                          <th className="td-last">State</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addressList.map((record, key) => {
                          return (
                            <>
                              <tr
                                onClick={(event) =>
                                  this.onClickHandler(
                                    {
                                      ...record,
                                      isNew: "N",
                                      saveToTable: "Address",
                                    },
                                    event
                                  )
                                }
                              >
                                <td className="td-first">
                                  <i className="fa fa-chevron-down"></i>
                                </td>
                                <td>{record.type}</td>
                                <td>{record.address1}</td>
                                <td>{record.city}</td>
                                <td className="td-last">{record.state}</td>
                              </tr>
                              <tr className="sub-table collapse">
                                <td className="td-first td-last" colSpan="6">
                                  <Form>
                                    <Row className="mb-1">
                                      <div class="col-lg-3 col-6">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            TYPE
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.type}
                                            onChange={(e) =>
                                              this.setState({
                                                type: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="HOME">HOME</option>
                                            <option value="WORK">WORK</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                    </Row>
                                    <Row className="mb-1">
                                      <div class="col-lg-6 col-6">
                                        <Form.Group as={Col} controlId="">
                                          <Form.Label className="table-form-label mb-0">
                                            Address 1
                                          </Form.Label>
                                          <Form.Control
                                            className="grid-form-control"
                                            maxLength={40}
                                            value={this.state.address1}
                                            onChange={(e) =>
                                              this.setState({
                                                address1: e.target.value,
                                              })
                                            }
                                          />
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.address1Err}
                                        </span>
                                      </div>
                                      <div class="col-lg-6 col-6">
                                        <Form.Group as={Col} controlId="">
                                          <Form.Label className="table-form-label mb-0">
                                            Address 2
                                          </Form.Label>
                                          <Form.Control
                                            className="grid-form-control"
                                            maxLength={40}
                                            value={this.state.address2}
                                            onChange={(e) =>
                                              this.setState({
                                                address2: e.target.value,
                                              })
                                            }
                                          />
                                        </Form.Group>
                                      </div>
                                    </Row>
                                    <Row className="mb-1">
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            City
                                          </Form.Label>
                                          <Form.Control
                                            className="grid-form-control"
                                            maxLength={40}
                                            value={this.state.city}
                                            onChange={(e) =>
                                              this.setState({
                                                city: e.target.value,
                                              })
                                            }
                                          />
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.cityErr}
                                        </span>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            State
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.state}
                                            onChange={(e) =>
                                              this.setState({
                                                state: e.target.value,
                                              })
                                            }
                                          >
                                            <option selected disabled value="">
                                              {" "}
                                              -- Select State --{" "}
                                            </option>
                                            {USStates.map((USState, key) => {
                                              return (
                                                <option
                                                  key={key}
                                                  value={USState.abbreviation}
                                                >
                                                  {USState.name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.stateErr}
                                        </span>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group as={Col} controlId="">
                                          <Form.Label className="table-form-label mb-0">
                                            Country
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.country}
                                            onChange={(e) =>
                                              this.setState({
                                                country: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="US">US</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group as={Col} controlId="">
                                          <Form.Label className="table-form-label mb-0">
                                            Zip
                                          </Form.Label>
                                          <Form.Control
                                            className="grid-form-control"
                                            maxLength={10}
                                            value={this.state.zip}
                                            onChange={(e) =>
                                              this.setState({
                                                zip: e.target.value,
                                              })
                                            }
                                          />
                                        </Form.Group>
                                        <span className="table-form-label-err mb-0">
                                          {this.state.zipErr}
                                        </span>
                                      </div>
                                    </Row>
                                    <Row className="mb-1">
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Status
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.statusAddress}
                                            onChange={(e) =>
                                              this.setState({
                                                statusAddress: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="ACTIVE">
                                              ACTIVE
                                            </option>
                                            <option value="INACTIVE">
                                              INACTIVE
                                            </option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                      <div class="col-lg-3 col-6 mt-1">
                                        <Form.Group
                                          as={Col}
                                          controlId="patientFullName"
                                        >
                                          <Form.Label className="table-form-label mb-0">
                                            Primary
                                          </Form.Label>
                                          <Form.Select
                                            className="grid-form-control"
                                            value={this.state.isPrimary}
                                            onChange={(e) =>
                                              this.setState({
                                                isPrimary: e.target.value,
                                              })
                                            }
                                          >
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                          </Form.Select>
                                        </Form.Group>
                                      </div>
                                    </Row>
                                    <Row>
                                      <div>
                                        <Button
                                          className="form-save-btn btn-primary mt-1 float-end"
                                          type="button"
                                          disabled={
                                            this.state.disabledSaveAddress
                                          }
                                          onClick={() => {
                                            this.saveAddress();
                                          }}
                                        >
                                          <img
                                            src={require("../../images/save-btn-icon.png")}
                                            className="add-icon"
                                          />
                                          Save
                                        </Button>
                                      </div>
                                    </Row>
                                  </Form>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              {
                                ...this.addressDefault,
                                isNew: "Y",
                                saveToTable: "Address",
                              },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="td-last"></td>
                        </tr>
                        <tr className="sub-table collapse">
                          <td className="td-first td-last" colSpan="6">
                            <Form>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      TYPE
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.type}
                                      onChange={(e) =>
                                        this.setState({ type: e.target.value })
                                      }
                                    >
                                      <option value="HOME">HOME</option>
                                      <option value="WORK">WORK</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div class="col-lg-6 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Address 1
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.address1}
                                      onChange={(e) =>
                                        this.setState({
                                          address1: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.address1Err}
                                  </span>
                                </div>
                                <div class="col-lg-6 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Address 2
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.address2}
                                      onChange={(e) =>
                                        this.setState({
                                          address2: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      City
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.city}
                                      onChange={(e) =>
                                        this.setState({ city: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.cityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      State
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.state}
                                      onChange={(e) =>
                                        this.setState({ state: e.target.value })
                                      }
                                    >
                                      <option selected disabled value="">
                                        {" "}
                                        -- Select State --{" "}
                                      </option>
                                      {USStates.map((USState, key) => {
                                        return (
                                          <option
                                            key={key}
                                            value={USState.abbreviation}
                                          >
                                            {USState.name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.stateErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Country
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.country}
                                      onChange={(e) =>
                                        this.setState({
                                          country: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="US">US</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Zip
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={10}
                                      value={this.state.zip}
                                      onChange={(e) =>
                                        this.setState({ zip: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.zipErr}
                                  </span>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Status
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.statusAddress}
                                      onChange={(e) =>
                                        this.setState({
                                          statusAddress: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="ACTIVE">ACTIVE</option>
                                      <option value="INACTIVE">INACTIVE</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Label className="table-form-label mb-0">
                                      Primary
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.isPrimary}
                                      onChange={(e) =>
                                        this.setState({
                                          isPrimary: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="Y">Yes</option>
                                      <option value="N">No</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-1 float-end"
                                    type="button"
                                    disabled={this.state.disabledSaveAddress}
                                    onClick={() => {
                                      this.saveAddress();
                                    }}
                                  >
                                    <img
                                      src={require("../../images/save-btn-icon.png")}
                                      className="add-icon"
                                    />
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div class="form-title-label mt-1">
                <label class="border-bottom" class="border-bottom">
                  Facility
                </label>
              </div>
              <Table bordered hover className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first"></th>
                    <th>Name</th>
                    <th>Physical Address</th>
                    <th>Phone Number</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {facilityList.map((record, key) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              {
                                ...record,
                                isNew: "N",
                                saveToTable: "Facility",
                              },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td>{record.name}</td>
                          <td>{record.physicalAddress}</td>
                          <td>{record.mainPhoneNumber}</td>
                          <td>{record.status}</td>
                        </tr>
                        <tr className="main-table collapse">
                          <td className="td-first td-last" colSpan="5">
                            <Form>
                              <Row>
                                <div class="col-lg-3 col-6 d-flex">
                                  <label className="encounter-form-label">
                                    Name
                                  </label>
                                  <Form.Group as={Col} controlId="">
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.nameFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          nameFacility: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.nameFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6"></div>
                                <div class="col-lg-3 col-6 d-flex">
                                  <label className="encounter-form-label">
                                    Status
                                  </label>
                                  <Form.Group
                                    as={Col}
                                    controlId="patientFullName"
                                  >
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.statusFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          statusFacility: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="ACTIVE">ACTIVE</option>
                                      <option value="NOT ACTIVE">
                                        NOT ACTIVE
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Speciality/Taxonomy
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.specialtyTaxonomyFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          specialtyTaxonomyFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.specialtyTaxonomyFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Npi
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.npiFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          npiFacility: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.npiFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Tax Id
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.taxIdFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          taxIdFacility: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.taxIdFacilityErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Physical Address
                                    </Form.Label>
                                    <InputGroup className="d-flex flex-row">
                                      <Form.Control
                                        className="grid-form-control"
                                        readOnly
                                        value={this.state.physicalAddress}
                                      />
                                      <InputGroup.Text className="input-group-edit">
                                        <a
                                          onClick={() => {
                                            this.showEditAddressModal(
                                              "PHYSICAL"
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </a>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Mailing Address
                                    </Form.Label>
                                    <InputGroup className="d-flex flex-row">
                                      <Form.Control
                                        className="grid-form-control"
                                        readOnly
                                        value={this.state.mailingAddress}
                                      />
                                      <InputGroup.Text className="input-group-edit">
                                        <a
                                          onClick={() => {
                                            this.showEditAddressModal(
                                              "MAILING"
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </a>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Main Phone Number
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={this.state.mainPhoneNumber}
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({
                                          mainPhoneNumber: phone,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.mainPhoneNumberErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Executive Sponsor Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.executiveSponsorNameFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          executiveSponsorNameFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.executiveSponsorNameFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Executive Sponsor Phone
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={
                                        this.state.executiveSponsorPhoneFacility
                                      }
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({
                                          executiveSponsorPhoneFacility: phone,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {
                                      this.state
                                        .executiveSponsorPhoneFacilityErr
                                    }
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Accounts Payable Phone
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={
                                        this.state
                                          .accountsPayablePhoneNumberFacility
                                      }
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({
                                          accountsPayablePhoneNumberFacility:
                                            phone,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {
                                      this.state
                                        .accountsPayablePhoneNumberFacilityErr
                                    }
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Invoicing
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.contactInvoicingFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          contactInvoicingFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.contactInvoicingFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Invoicing Email
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.contactInvoicingEmailFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          contactInvoicingEmailFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {
                                      this.state
                                        .contactInvoicingEmailFacilityErr
                                    }
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 mt-1 text-center">
                                  <Form.Label className="table-form-label mb-0">
                                    Out Patient Rate
                                  </Form.Label>
                                  <Form.Group
                                    className="client-rate-form"
                                    as={Col}
                                    controlId=""
                                  >
                                    <Form.Control
                                      className="client-rate-control"
                                      value={this.state.outpatientRateFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          outpatientRateFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 mt-1 text-center">
                                  <Form.Label className="table-form-label mb-0">
                                    In Patient Rate
                                  </Form.Label>
                                  <Form.Group
                                    className="client-rate-form"
                                    as={Col}
                                    controlId=""
                                  >
                                    <Form.Control
                                      className="client-rate-control"
                                      value={this.state.inpatientRateFacility}
                                      onChange={(e) =>
                                        this.setState({
                                          inpatientRateFacility: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1 d-flex">
                                  <Form.Check
                                    className="grid-form-control client-checkbox mt-3"
                                    label="Run Eligibility"
                                    onChange={this.handleRunEligibilityFacility}
                                    checked={this.state.runEligibilityFacilityIsChecked.includes(
                                      "Y"
                                    )}
                                  />
                                  <Form.Check
                                    className="grid-form-control client-checkbox mt-3"
                                    label="Full Service"
                                    onChange={this.handleFullServiceFacility}
                                    checked={this.state.fullServiceFacilityIsChecked.includes(
                                      "Y"
                                    )}
                                  />
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Case Manager
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.contactCaseManagerFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          contactCaseManagerFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.contactCaseManagerFacilityErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Case Representative
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state
                                          .contactCaseRepresentativeFacility
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          contactCaseRepresentativeFacility:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {
                                      this.state
                                        .contactCaseRepresentativeFacilityErr
                                    }
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Case Representative Phone
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={
                                        this.state
                                          .contactCaseRepresentativePhoneFacility
                                      }
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({
                                          contactCaseRepresentativePhoneFacility:
                                            phone,
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {
                                      this.state
                                        .contactCaseRepresentativePhoneFacilityErr
                                    }
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Type
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.type}
                                      onChange={(e) =>
                                        this.setState({ type: e.target.value })
                                      }
                                    >
                                      <option value="Institutional">
                                        Institutional
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0"></span>
                                </div>
                              </Row>
                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-1 float-end"
                                    type="button"
                                    disabled={this.state.disabledSaveFacility}
                                    onClick={() => {
                                      this.saveFacility();
                                    }}
                                  >
                                    <img
                                      src={require("../../images/save-btn-icon.png")}
                                      className="add-icon"
                                    />
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr
                    onClick={(event) =>
                      this.onClickHandler(
                        {
                          ...this.facilityDefault,
                          isNew: "Y",
                          saveToTable: "Facility",
                        },
                        event
                      )
                    }
                  >
                    <td className="td-first">
                      <i className="fa fa-chevron-down"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="main-table collapse">
                    <td className="td-first td-last" colSpan="5">
                      <Form>
                        <Row>
                          <div class="col-lg-3 col-6 d-flex">
                            <label className="encounter-form-label">Name</label>
                            <Form.Group as={Col} controlId="">
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.nameFacility}
                                onChange={(e) =>
                                  this.setState({
                                    nameFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.nameFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6"></div>
                          <div class="col-lg-3 col-6 d-flex">
                            <label className="encounter-form-label">
                              Status
                            </label>
                            <Form.Group as={Col} controlId="patientFullName">
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.statusFacility}
                                onChange={(e) =>
                                  this.setState({
                                    statusFacility: e.target.value,
                                  })
                                }
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="NOT ACTIVE">NOT ACTIVE</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Speciality/Taxonomy
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.specialtyTaxonomyFacility}
                                onChange={(e) =>
                                  this.setState({
                                    specialtyTaxonomyFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.specialtyTaxonomyFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Npi
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.npiFacility}
                                onChange={(e) =>
                                  this.setState({ npiFacility: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.npiFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Tax Id
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.taxIdFacility}
                                onChange={(e) =>
                                  this.setState({
                                    taxIdFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.taxIdFacilityErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Physical Address
                              </Form.Label>
                              <InputGroup className="d-flex flex-row">
                                <Form.Control
                                  className="grid-form-control"
                                  readOnly
                                  value={this.state.physicalAddress}
                                />
                                <InputGroup.Text className="input-group-edit">
                                  <a
                                    onClick={() => {
                                      this.showEditAddressModal("PHYSICAL");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </a>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Mailing Address
                              </Form.Label>
                              <InputGroup className="d-flex flex-row">
                                <Form.Control
                                  className="grid-form-control"
                                  readOnly
                                  value={this.state.mailingAddress}
                                />
                                <InputGroup.Text className="input-group-edit">
                                  <a
                                    onClick={() => {
                                      this.showEditAddressModal("MAILING");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </a>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Main Phone Number
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={this.state.mainPhoneNumber}
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({ mainPhoneNumber: phone });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.mainPhoneNumberErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Executive Sponsor Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.executiveSponsorNameFacility}
                                onChange={(e) =>
                                  this.setState({
                                    executiveSponsorNameFacility:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.executiveSponsorNameFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Executive Sponsor Phone
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={this.state.executiveSponsorPhoneFacility}
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({
                                    executiveSponsorPhoneFacility: phone,
                                  });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.executiveSponsorPhoneFacilityErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Accounts Payable Phone
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={
                                  this.state.accountsPayablePhoneNumberFacility
                                }
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({
                                    accountsPayablePhoneNumberFacility: phone,
                                  });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.accountsPayablePhoneNumberFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Invoicing
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.contactInvoicingFacility}
                                onChange={(e) =>
                                  this.setState({
                                    contactInvoicingFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactInvoicingFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Invoicing Email
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.contactInvoicingEmailFacility}
                                onChange={(e) =>
                                  this.setState({
                                    contactInvoicingEmailFacility:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactInvoicingEmailFacilityErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 mt-1 text-center">
                            <Form.Label className="table-form-label mb-0">
                              Out Patient Rate
                            </Form.Label>
                            <Form.Group
                              className="client-rate-form"
                              as={Col}
                              controlId=""
                            >
                              <Form.Control
                                className="client-rate-control"
                                value={this.state.outpatientRateFacility}
                                onChange={(e) =>
                                  this.setState({
                                    outpatientRateFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 mt-1 text-center">
                            <Form.Label className="table-form-label mb-0">
                              In Patient Rate
                            </Form.Label>
                            <Form.Group
                              className="client-rate-form"
                              as={Col}
                              controlId=""
                            >
                              <Form.Control
                                className="client-rate-control"
                                value={this.state.inpatientRateFacility}
                                onChange={(e) =>
                                  this.setState({
                                    inpatientRateFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6 mt-1 d-flex">
                            <Form.Check
                              className="grid-form-control client-checkbox mt-3"
                              label="Run Eligibility"
                              onChange={this.handleRunEligibilityFacility}
                              checked={this.state.runEligibilityFacilityIsChecked.includes(
                                "Y"
                              )}
                            />
                            <Form.Check
                              className="grid-form-control client-checkbox mt-3"
                              label="Full Service"
                              onChange={this.handleFullServiceFacility}
                              checked={this.state.fullServiceFacilityIsChecked.includes(
                                "Y"
                              )}
                            />
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Case Manager
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.contactCaseManagerFacility}
                                onChange={(e) =>
                                  this.setState({
                                    contactCaseManagerFacility: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactCaseManagerFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Case Representative
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={
                                  this.state.contactCaseRepresentativeFacility
                                }
                                onChange={(e) =>
                                  this.setState({
                                    contactCaseRepresentativeFacility:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactCaseRepresentativeFacilityErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Case Representative Phone
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={
                                  this.state
                                    .contactCaseRepresentativePhoneFacility
                                }
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({
                                    contactCaseRepresentativePhoneFacility:
                                      phone,
                                  });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {
                                this.state
                                  .contactCaseRepresentativePhoneFacilityErr
                              }
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Type
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.type}
                                onChange={(e) =>
                                  this.setState({ type: e.target.value })
                                }
                              >
                                <option value="Institutional">
                                  Institutional
                                </option>
                              </Form.Select>
                            </Form.Group>
                            <span className="table-form-label-err mb-0"></span>
                          </div>
                        </Row>
                        <Row>
                          <div>
                            <Button
                              className="form-save-btn btn-primary mt-1 float-end"
                              type="button"
                              disabled={this.state.disabledSaveFacility}
                              onClick={() => {
                                this.saveFacility();
                              }}
                            >
                              <img
                                src={require("../../images/save-btn-icon.png")}
                                className="add-icon"
                              />
                              Save
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div class="form-title-label mt-1">
                <label class="border-bottom" class="border-bottom">
                  Provider/s
                </label>
              </div>
              <Table bordered hover className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first"></th>
                    <th>Name</th>
                    <th>Physical Address</th>
                    <th>Phone Number</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {providerList.map((record, key) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              {
                                ...record,
                                isNew: "N",
                                saveToTable: "Provider",
                              },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td>{record.name}</td>
                          <td>{record.physicalAddress}</td>
                          <td>{record.phoneNumber}</td>
                          <td>{record.status}</td>
                        </tr>
                        <tr className="main-table collapse">
                          <td className="td-first td-last" colSpan="5">
                            <Form>
                              <Row>
                                <div class="col-lg-3 col-6 d-flex">
                                  <label className="encounter-form-label">
                                    Name
                                  </label>
                                  <Form.Group as={Col} controlId="">
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      readOnly
                                      value={this.state.nameProvider}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.nameProviderErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6"></div>
                                <div class="col-lg-3 col-6 d-flex">
                                  <label className="encounter-form-label">
                                    Status
                                  </label>
                                  <Form.Group as={Col} controlId="">
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.statusProvider}
                                      onChange={(e) =>
                                        this.setState({
                                          statusProvider: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="ACTIVE">ACTIVE</option>
                                      <option value="NOT ACTIVE">
                                        NOT ACTIVE
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.firstName}
                                      onChange={(e) => {
                                        this.setState({
                                          firstName: e.target.value,
                                        });
                                      }}
                                      onBlur={(e) => {
                                        this.setFullName();
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.firstNameErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.lastName}
                                      onChange={(e) => {
                                        this.setState({
                                          lastName: e.target.value,
                                        });
                                      }}
                                      onBlur={(e) => {
                                        this.setFullName();
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.lastNameErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Speciality/Taxonomy
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.specialtyTaxonomyProvider
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          specialtyTaxonomyProvider:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.specialtyTaxonomyProviderErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Npi
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.npiProvider}
                                      onChange={(e) =>
                                        this.setState({
                                          npiProvider: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.npiProviderErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Tax Id
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.taxIdProvider}
                                      onChange={(e) =>
                                        this.setState({
                                          taxIdProvider: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.taxIdProviderErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Physical Address
                                    </Form.Label>
                                    <InputGroup className="d-flex flex-row">
                                      <Form.Control
                                        className="grid-form-control"
                                        readOnly
                                        value={this.state.physicalAddress}
                                      />
                                      <InputGroup.Text className="input-group-edit">
                                        <a
                                          onClick={() => {
                                            this.showEditAddressModal(
                                              "PHYSICAL"
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </a>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Phone Number
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={this.state.phoneNumber}
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({ phoneNumber: phone });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.phoneNumberErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Fax
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={this.state.fax}
                                      onChange={(e) => {
                                        let fax = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({ fax: fax });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.faxErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Email
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.email}
                                      onChange={(e) =>
                                        this.setState({ email: e.target.value })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.emailErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Mailing Address
                                    </Form.Label>
                                    <InputGroup className="d-flex flex-row">
                                      <Form.Control
                                        className="grid-form-control"
                                        readOnly
                                        value={this.state.mailingAddress}
                                      />
                                      <InputGroup.Text className="input-group-edit">
                                        <a
                                          onClick={() => {
                                            this.showEditAddressModal(
                                              "MAILING"
                                            );
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </a>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Case Manager
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.caseManager}
                                      onChange={(e) =>
                                        this.setState({
                                          caseManager: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.caseManagerErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Case Manager Email
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.caseManagerEmail}
                                      onChange={(e) =>
                                        this.setState({
                                          caseManagerEmail: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.caseManagerEmailErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Concurrent Review Nurse
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.concurrentReviewNurse}
                                      onChange={(e) =>
                                        this.setState({
                                          concurrentReviewNurse: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.concurrentReviewNurseErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Concurrent Review Nurse Email
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={
                                        this.state.concurrentReviewNurseEmail
                                      }
                                      onChange={(e) =>
                                        this.setState({
                                          concurrentReviewNurseEmail:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.concurrentReviewNurseEmailErr}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.contactName}
                                      onChange={(e) =>
                                        this.setState({
                                          contactName: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.contactNameErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Phone
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={this.state.contactPhone}
                                      onChange={(e) => {
                                        let phone = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({ contactPhone: phone });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.contactPhoneErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Contact Fax
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={15}
                                      value={this.state.contactFax}
                                      onChange={(e) => {
                                        let fax = this.addDashes(
                                          e.target.value
                                        );
                                        this.setState({ contactFax: fax });
                                      }}
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.contactFaxErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-1">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Type
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.type}
                                      onChange={(e) =>
                                        this.setState({ type: e.target.value })
                                      }
                                    >
                                      <option value="Professional">
                                        Professional
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0"></span>
                                </div>
                              </Row>
                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-2 float-end"
                                    type="button"
                                    disabled={this.state.disabledSaveProvider}
                                    onClick={() => {
                                      this.saveProvider();
                                    }}
                                  >
                                    <img
                                      src={require("../../images/save-btn-icon.png")}
                                      className="add-icon"
                                    />
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr
                    onClick={(event) =>
                      this.onClickHandler(
                        {
                          ...this.providerDefault,
                          isNew: "Y",
                          saveToTable: "Provider",
                        },
                        event
                      )
                    }
                  >
                    <td className="td-first">
                      <i className="fa fa-chevron-down"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="main-table collapse">
                    <td className="td-first td-last" colSpan="5">
                      <Form>
                        <Row>
                          <div class="col-lg-3 col-6 d-flex">
                            <label className="encounter-form-label">Name</label>
                            <Form.Group as={Col} controlId="">
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                readOnly
                                value={this.state.nameProvider}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.nameProviderErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6"></div>
                          <div class="col-lg-3 col-6 d-flex">
                            <label className="encounter-form-label">
                              Status
                            </label>
                            <Form.Group as={Col} controlId="">
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.statusProvider}
                                onChange={(e) =>
                                  this.setState({
                                    statusProvider: e.target.value,
                                  })
                                }
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="NOT ACTIVE">NOT ACTIVE</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                First Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.firstName}
                                onChange={(e) => {
                                  this.setState({ firstName: e.target.value });
                                  this.setFullName();
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.firstNameErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.lastName}
                                onChange={(e) => {
                                  this.setState({ lastName: e.target.value });
                                  this.setFullName();
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.lastNameErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Speciality/Taxonomy
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.specialtyTaxonomyProvider}
                                onChange={(e) =>
                                  this.setState({
                                    specialtyTaxonomyProvider: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.specialtyTaxonomyProviderErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Npi
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.npiProvider}
                                onChange={(e) =>
                                  this.setState({ npiProvider: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.npiProviderErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Tax Id
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.taxIdProvider}
                                onChange={(e) =>
                                  this.setState({
                                    taxIdProvider: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.taxIdProviderErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Physical Address
                              </Form.Label>
                              <InputGroup className="d-flex flex-row">
                                <Form.Control
                                  className="grid-form-control"
                                  readOnly
                                  value={this.state.physicalAddress}
                                />
                                <InputGroup.Text className="input-group-edit">
                                  <a
                                    onClick={() => {
                                      this.showEditAddressModal("PHYSICAL");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </a>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Phone Number
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={this.state.phoneNumber}
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({ phoneNumber: phone });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.phoneNumberErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Fax
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={15}
                                value={this.state.fax}
                                onChange={(e) => {
                                  let fax = this.addDashes(e.target.value);
                                  this.setState({ fax: fax });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.faxErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Email
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.emailErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Mailing Address
                              </Form.Label>
                              <InputGroup className="d-flex flex-row">
                                <Form.Control
                                  className="grid-form-control"
                                  readOnly
                                  value={this.state.mailingAddress}
                                />
                                <InputGroup.Text className="input-group-edit">
                                  <a
                                    onClick={() => {
                                      this.showEditAddressModal("MAILING");
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil-square"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </a>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Case Manager
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.caseManager}
                                onChange={(e) =>
                                  this.setState({ caseManager: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.caseManagerErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Case Manager Email
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.caseManagerEmail}
                                onChange={(e) =>
                                  this.setState({
                                    caseManagerEmail: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.caseManagerEmailErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Concurrent Review Nurse
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.concurrentReviewNurse}
                                onChange={(e) =>
                                  this.setState({
                                    concurrentReviewNurse: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.concurrentReviewNurseErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Concurrent Review Nurse Email
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.concurrentReviewNurseEmail}
                                onChange={(e) =>
                                  this.setState({
                                    concurrentReviewNurseEmail: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.concurrentReviewNurseEmailErr}
                            </span>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.contactName}
                                onChange={(e) =>
                                  this.setState({ contactName: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactNameErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Phone
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={12}
                                value={this.state.contactPhone}
                                onChange={(e) => {
                                  let phone = this.addDashes(e.target.value);
                                  this.setState({ contactPhone: phone });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactPhoneErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Contact Fax
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={12}
                                value={this.state.contactFax}
                                onChange={(e) => {
                                  let fax = this.addDashes(e.target.value);
                                  this.setState({ contactFax: fax });
                                }}
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.contactFaxErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-1">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Type
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.type}
                                onChange={(e) =>
                                  this.setState({ type: e.target.value })
                                }
                              >
                                <option value="Professional">
                                  ProfessionalZ
                                </option>
                              </Form.Select>
                            </Form.Group>
                            <span className="table-form-label-err mb-0"></span>
                          </div>
                        </Row>
                        <Row>
                          <div>
                            <Button
                              className="form-save-btn btn-primary mt-2 float-end"
                              type="button"
                              disabled={this.state.disabledSaveProvider}
                              onClick={() => {
                                this.saveProvider();
                              }}
                            >
                              <img
                                src={require("../../images/save-btn-icon.png")}
                                className="add-icon"
                              />
                              Save
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div class="form-title-label">
                <label class="border-bottom" class="border-bottom">
                  Employee
                </label>
              </div>
              <Table bordered hover className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first"></th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>User Id</th>
                    <th>User Role</th>
                    <th className="td-last">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeesList.map((record, key) => {
                    return (
                      <>
                        <tr
                          onClick={(event) =>
                            this.onClickHandler(
                              {
                                ...record,
                                isNew: "N",
                                saveToTable: "Employee",
                              },
                              event
                            )
                          }
                        >
                          <td className="td-first">
                            <i className="fa fa-chevron-down"></i>
                          </td>
                          <td>{record.firstName}</td>
                          <td>{record.middleName}</td>
                          <td>{record.lastName}</td>
                          <td>{record.userId}</td>
                          <td>{record.userRole}</td>
                          <td>{record.status}</td>
                        </tr>
                        <tr className="main-table collapse">
                          <td className="td-first td-last" colSpan="7">
                            <Form>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.firstName}
                                      onChange={(e) =>
                                        this.setState({
                                          firstName: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.firstNameErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Middle Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.middleName}
                                      onChange={(e) =>
                                        this.setState({
                                          middleName: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.middleNameErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.lastName}
                                      onChange={(e) =>
                                        this.setState({
                                          lastName: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.lastNameErr}
                                  </span>
                                </div>
                              </Row>
                              <Row className="mb-1">
                                <div class="col-lg-3 col-6 mt-2">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      User Role
                                    </Form.Label>
                                    <Form.Select
                                      className="grid-form-control"
                                      value={this.state.userRole}
                                      onChange={(e) =>
                                        this.setState({
                                          userRole: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="USER">USER</option>
                                      <option value="ADMIN">ADMIN</option>
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                                <div class="col-lg-3 col-6 mt-2">
                                  <Form.Group as={Col} controlId="">
                                    <Form.Label className="table-form-label mb-0">
                                      User ID
                                    </Form.Label>
                                    <Form.Control
                                      className="grid-form-control"
                                      maxLength={40}
                                      value={this.state.userId}
                                      onChange={(e) =>
                                        this.setState({
                                          userId: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.userIdErr}
                                  </span>
                                </div>
                                <div class="col-lg-3 col-6 mt-2">
                                  <Form.Group as={Col} controlId="">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={tooltipNote()}
                                    >
                                      <div>
                                        <Form.Label className="table-form-label mb-0">
                                          User Password
                                        </Form.Label>
                                        <Form.Control
                                          type="password"
                                          className="grid-form-control"
                                          value={this.state.userPassword}
                                          onChange={(e) =>
                                            this.setState({
                                              userPassword: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </Form.Group>
                                  <span className="table-form-label-err mb-0">
                                    {this.state.userPasswordErr}
                                  </span>
                                </div>
                              </Row>
                              <div class="col-lg-3 col-6 mt-1">
                                <Form.Group
                                  as={Col}
                                  controlId="patientFullName"
                                >
                                  <Form.Label className="table-form-label mb-0">
                                    Status
                                  </Form.Label>
                                  <Form.Select
                                    className="grid-form-control"
                                    value={this.state.statusEmployee}
                                    onChange={(e) =>
                                      this.setState({
                                        statusEmployee: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="ACTIVE">ACTIVE</option>
                                    <option value="NOT ACTIVE">
                                      NOT ACTIVE
                                    </option>
                                  </Form.Select>
                                </Form.Group>
                              </div>
                              <Row>
                                <div>
                                  <Button
                                    className="form-save-btn btn-primary mt-1 float-end"
                                    type="button"
                                    disabled={this.state.disabledSaveEmployee}
                                    onClick={() => {
                                      this.saveEmployee();
                                    }}
                                  >
                                    <img
                                      src={require("../../images/save-btn-icon.png")}
                                      className="add-icon"
                                    />
                                    Save
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  <tr
                    onClick={(event) =>
                      this.onClickHandler(
                        {
                          ...this.employeeDefault,
                          isNew: "Y",
                          saveToTable: "Employee",
                        },
                        event
                      )
                    }
                  >
                    <td className="td-first">
                      <i className="fa fa-chevron-down"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr className="main-table collapse">
                    <td className="td-first td-last" colSpan="7">
                      <Form>
                        <Row className="mb-1">
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                First Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.firstNameErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Middle Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.middleName}
                                onChange={(e) =>
                                  this.setState({ middleName: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.middleNameErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({ lastName: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.lastNameErr}
                            </span>
                          </div>
                        </Row>
                        <Row className="mb-1">
                          <div class="col-lg-3 col-6 mt-2">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                User Role
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.userRole}
                                onChange={(e) =>
                                  this.setState({ userRole: e.target.value })
                                }
                              >
                                <option value="USER">USER</option>
                                <option value="ADMIN">ADMIN</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div class="col-lg-3 col-6 mt-2">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                User ID
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                maxLength={40}
                                value={this.state.userId}
                                onChange={(e) =>
                                  this.setState({ userId: e.target.value })
                                }
                              />
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.userIdErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-2">
                            <Form.Group as={Col} controlId="">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={tooltipNote()}
                              >
                                <div>
                                  <Form.Label className="table-form-label mb-0">
                                    User Password
                                  </Form.Label>
                                  <Form.Control
                                    type="password"
                                    className="grid-form-control"
                                    value={this.state.userPassword}
                                    onChange={(e) =>
                                      this.setState({
                                        userPassword: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </OverlayTrigger>
                            </Form.Group>
                            <span className="table-form-label-err mb-0">
                              {this.state.userPasswordErr}
                            </span>
                          </div>
                          <div class="col-lg-3 col-6 mt-2">
                            <Form.Group as={Col} controlId="patientFullName">
                              <Form.Label className="table-form-label mb-0">
                                Status
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control"
                                value={this.state.statusEmployee}
                                onChange={(e) =>
                                  this.setState({
                                    statusEmployee: e.target.value,
                                  })
                                }
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="NOT ACTIVE">NOT ACTIVE</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div>
                            <Button
                              className="form-save-btn btn-primary mt-1 float-end"
                              type="button"
                              disabled={this.state.disabledSaveEmployee}
                              onClick={() => {
                                this.saveEmployee();
                              }}
                            >
                              <img
                                src={require("../../images/save-btn-icon.png")}
                                className="add-icon"
                              />
                              Save
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Notes />
            </div>
            <div style={hideClientList}>
              <label className="form-main-title mt-2">Client List</label>
            </div>
            <div style={hideClientList} className="mb-2">
              <Table bordered hover className="table-portal">
                <thead>
                  <tr>
                    <th className="td-first-none-collapse">Name</th>
                    <th>Executive Sponsor Name</th>
                    <th>Executive Sponsor Phone</th>
                    <th className="td-last">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {clientList.map((record, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          className={key % 2 == 1 ? "even-row" : ""}
                          onClick={() => {
                            this.onClickRow(record);
                            this.setState({ hideClientInfo: false });
                          }}
                        >
                          <td className="td-right-border">{record.name}</td>
                          <td>{record.executiveSponsorName}</td>
                          <td>{record.executiveSponsorPhone}</td>
                          <td className="td-last">{record.status}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateClient: bindActionCreators(addUpdateClient, dispatch),
    addUpdateClientSelected: bindActionCreators(
      addUpdateClientSelected,
      dispatch
    ),
    addUpdatePhoneEmail: bindActionCreators(addUpdatePhoneEmail, dispatch),
    addUpdateAddress: bindActionCreators(addUpdateAddress, dispatch),
    addUpdateProvider: bindActionCreators(addUpdateProvider, dispatch),
    addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch),
    addUpdateFacility: bindActionCreators(addUpdateFacility, dispatch),
    getPatientPhoneEmail: bindActionCreators(getPatientPhoneEmail, dispatch),
    getPatientAddress: bindActionCreators(getPatientAddress, dispatch),
    getProvider: bindActionCreators(getProvider, dispatch),
    getClientEmployees: bindActionCreators(getClientEmployees, dispatch),
    getClients: bindActionCreators(getClients, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    getOrganization: bindActionCreators(getOrganization, dispatch),
    getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
    getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    patientAddressClear: bindActionCreators(patientAddressClear, dispatch),
    patientPhoneEmailClear: bindActionCreators(
      patientPhoneEmailClear,
      dispatch
    ),
    facilitiesClear: bindActionCreators(facilitiesClear, dispatch),
    providerClear: bindActionCreators(providerClear, dispatch),
    clientEmployeesClear: bindActionCreators(providerClear, dispatch),
    getUser: bindActionCreators(getUser, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    addedClient: state.addUpdateClient.data,
    addedPhoneEmail: state.addUpdatePhoneEmail.data,
    phoneEmailList: state.getPatientPhoneEmail.data,
    addressList: state.getPatientAddress.data,
    providerList: state.getProvider.data,
    employeesList: state.getClientEmployees.data,
    clientList: state.getClientsByOrgId.data,
    facilityList: state.getFacilities.data,
    organizationList: state.getAllOrganization.data,
    menuSearch: state.menu.dataSearch,
    user: state.currentUser.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(Clients));
