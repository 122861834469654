import { CALL_API } from '../../../middleware/api'
import * as GetPatientAddressApiActionConstants from "./GetPatientAddressApiActionConstants"
import {getPatientAddressUriPath} from '../EndpointResolver'

export function getPatientAddress(parentId) {
    return {
        [CALL_API]: {
            endpoint: getPatientAddressUriPath(parentId),
            config: { method: 'get' },
            types: [
                GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_REQUEST,
                GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_SUCCESS,
                GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_FAILED,
            ],
        },
    }
}


export function patientAddressClear() {
     return function (dispatch) {
         dispatch({
             type: GetPatientAddressApiActionConstants.GET_PATIENT_ADDRESS_CLEAR
         })
     }
}