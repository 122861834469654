import { CALL_API } from '../../../middleware/api'
import * as GetPatientPhoneEmailApiActionConstants from "./GetPatientPhoneEmailApiActionConstants"
import {getPatientPhoneEmailUriPath} from '../EndpointResolver'

export function getPatientPhoneEmail(parentId) {
    return {
        [CALL_API]: {
            endpoint: getPatientPhoneEmailUriPath(parentId),
            config: { method: 'get' },
            types: [
                GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_REQUEST,
                GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_SUCCESS,
                GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_FAILED,
            ],
        },
    }
}


export function patientPhoneEmailClear() {
     return function (dispatch) {
         dispatch({
             type: GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_CLEAR
         })
     }
}