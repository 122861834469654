import React, { useState, Component } from "react";
import "../../../App.css";
import "./AddPatients.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { Table, Tab, Tabs, Modal } from "react-bootstrap";
import "font-awesome/css/font-awesome.min.css";
import {
  addUpdateGuarantor,
  getPatientGuarantor,
} from "../../../redux/actions/api/guarantor/AddUpdateGuarantorApiActions";
import { addUpdatePatient } from "../../../redux/actions/api/patient/AddUpdatePatientApiActions";
import { addUpdatePhoneEmail } from "../../../redux/actions/api/phoneEmail/AddUpdatePhoneEmailApiActions";
import { addUpdateInsurance } from "../../../redux/actions/api/insurance/AddUpdateInsuranceApiActions";
import {
  addUpdateEncounter,
  addUpdateEncounterSelected,
} from "../../../redux/actions/api/encounter/AddUpdateEncounterApiActions";
import { addUpdateAuthorizationSelected } from "../../../redux/actions/api/authorization/AddUpdateAuthorizationApiActions";
import { getPatientPhoneEmail } from "../../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";
import { getPatientAddress } from "../../../redux/actions/api/address/GetPatientAddressApiActions";
import { getPatientInsurance } from "../../../redux/actions/api/insurance/GetPatientInsuranceApiActions";
import { getPatientEncounter } from "../../../redux/actions/api/encounter/GetPatientEncounterApiActions";
import { addUpdateAddress } from "../../../redux/actions/api/address/AddUpdateAddressApiActions";

import { addUpdatePatientSelected } from "../../../redux/actions/api/patient/AddUpdatePatientApiActions";
import { addUpdateGuarantorSelected } from "../../../redux/actions/api/guarantor/AddUpdateGuarantorApiActions";

import { patientInsuranceClear } from "../../../redux/actions/api/insurance/GetPatientInsuranceApiActions";
import { patientAddressClear } from "../../../redux/actions/api/address/GetPatientAddressApiActions";
import { patientEncounterClear } from "../../../redux/actions/api/encounter/GetPatientEncounterApiActions";
import { patientPhoneEmailClear } from "../../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";

import { guarantorAddressClear } from "../../../redux/actions/api/address/GetGuarantorAddressApiActions";
import { guarantorPhoneEmailClear } from "../../../redux/actions/api/phoneEmail/GetGuarantorPhoneEmailApiActions";
import { guarantorPatientsClear } from "../../../redux/actions/api/patient/GetGuarantorPatientsApiActions";

import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getFacilities from "../../../redux/actions/api/facility/GetFacilitiesApiActions";

import {
  guarantorPatientsSearch,
  guarantorPatientsSearchClear,
} from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";
import {
  guarantorPatientSearchDuplicate,
  guarantorPatientSearchDuplicateClear,
} from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientSearchDuplicateApiActions";

import { addUpdateDocumentation } from "../../../redux/actions/api/documentation/AddUpdateDocumentationApiActions";
import { getPatientDocumentation } from "../../../redux/actions/api/documentation/GetPatientDocumentationApiActions";
import { getDocumentationUploadFileUriPath } from "../../../redux/actions/api/EndpointResolver";

import { addUpdateAuthorizationRequestingProviderSelected } from "../../../redux/actions/api/authorization/AddUpdateAuthorizationRequestingProviderApiActions";

import getPayersFindAll from "../../../redux/actions/api/payer/GetPayersApiActions";

import {
  getDocumentationDownloadFile,
  documentationDownloadFileClear,
} from "../../../redux/actions/api/documentation/DocumentationDownloadFileApiActions";

import axios from "axios";
import { WithRouter } from "../../common/WithRouter";
import moment from "moment";
import USStates from "../../common/USStates";
import AddPayerModal from "./AddPayerModal";
import MaskedFormControl from "react-bootstrap-maskedinput";
import DatePicker from "react-datepicker";
import { formatDateToCustomISO } from "../../common/UtilsDate";
import MaskedTextInput from "react-text-mask";
import { updatePatientsSearchResult } from "../../../redux/actions/api/guarantor-patients-search/PatientsSearchApiActions";

class AddPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      id: "",

      searchGuarantorsModal: false,
      searchGuaPatDupModal: false,
      searchMrn: null,
      searchLastName: null,
      searchFirstName: null,
      searchDob: null,

      parentId: "",

      orgId: "",
      clientId: "",
      clientIdErr: "",
      facilityId: "",
      facilityIdErr: "",
      lastName: "",
      lastNameErr: "",
      firstName: "",
      firstNameErr: "",
      middleName: "",
      middleNameErr: "",
      mrn: "",
      dob: "",
      dobErr: "",
      ssn: "",
      ssnErr: "",
      gender: "",

      type: "",
      typeErr: "",
      subtype: "MOBILE",
      numberEmail: "",
      numberEmailErr: "",
      isPrimary: "Y",
      sms: "Y",
      status: "ACTIVE",

      address1: "",
      address1Err: "",
      address2: "",
      city: "",
      cityErr: "",
      state: "",
      stateErr: "",
      zip: "",
      zipErr: "",
      country: "US",

      patientId: "",

      relation: "",
      relationErr: "",
      provider: "",
      phoneNumber: "",
      phoneNumberErr: "",
      groupName: "",
      groupNameErr: "",
      groupId: "",
      groupIdErr: "",
      policyNumber: "",
      policyNumberErr: "",
      plan: "",
      planErr: "",
      insBegin: "",
      insBeginErr: "",
      insEnd: "",
      insEndErr: "",
      payerId: "",
      payerIdErr: "",

      accountNumber: "",
      referringPhysician: "",
      patientType: "",
      icdCodes: "",
      requestedUnit: "",
      payerName: "",
      attendingPhysician: "",
      requestedDate: "",
      invoiceStatus: "",
      procedureCodes: "",
      approvedUnit: "",
      scheduleDate: "",
      completedUnit: "",

      isNew: "Y",
      btnClick: "N",
      guarantorSameAsPatient: false,

      documentName: "",
      documentType: "",
      dateUploaded: "",
      username: "",
      submitted: "",
      filename: "",
      selectedFile: null,

      disabledSavePatient: false,
      addPayerModal: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSameAsGuarantor = this.handleSameAsGuarantor.bind(this);
  }

  showAddPayerModal = async (show) => {
    this.setState({ addPayerModal: show });
    await this.props.getPayersFindAll();
  };

  async componentDidMount() {
    console.log("I am here 1");

    const { user, getClients, getFacilities } = this.props;
    this.setState({ orgId: user.orgId });
    await getClients(user.orgId);

    const { addedGuarantor, addedPatient } = this.props;
    console.log("I am here 2");
    console.log("addedGuarantor", addedGuarantor);
    if (addedGuarantor) {
      if (addedGuarantor.id && addedPatient.id) {
        console.log("addedPatient", addedPatient);
        var patient = { ...addedPatient, parentId: addedGuarantor.id };
        this.props.addUpdatePatient(patient);
        console.log("I am here 4");
      }
    }
    if (addedPatient.id) {
      console.log("I am here 5");
      this.props.getPatientPhoneEmail(addedPatient.id);
      this.props.getPatientAddress(addedPatient.id);
      this.props.getPatientInsurance(addedPatient.id);
      this.props.getPatientEncounter(addedPatient.id);
      this.props.getPatientDocumentation(addedPatient.id);
      await getFacilities(addedPatient.clientId);
      await this.props.getPayersFindAll();
      this.setState({ ...addedPatient });

      console.log("I am here 5");
    } else {
      await getFacilities(0);
    }
    this.props.guarantorPatientsSearchClear();
    this.props.guarantorPatientSearchDuplicateClear();
    console.log("this.props.facilities", this.props.facilities);
  }

  patientDefault = {
    clientId: "",
    clientIdErr: "",
    facilityId: "",
    facilityIdErr: "",
    lastName: "",
    lastNameErr: "",
    firstName: "",
    firstNameErr: "",
    middleName: "",
    middleNameErr: "",
    mrn: "",
    dob: "",
    dobErr: "",
    ssn: "",
    ssnErr: "",
    parentId: "",
  };

  phoneEmailDefault = {
    id: "",
    type: "HOME",
    subtype: "MOBILE",
    numberEmail: "",
    numberEmailErr: "",
    isPrimary: "Y",
    sms: "Y",
    status: "ACTIVE",
    btnClick: "Y",
  };

  addressDefault = {
    id: "",
    type: "HOME",
    address1: "",
    address1Err: "",
    address2: "",
    city: "",
    cityErr: "",
    state: "",
    stateErr: "",
    zip: "",
    zipErr: "",
    country: "US",
    isPrimary: "Y",
  };

  insuranceDefault = {
    id: "",
    patientId: "",
    type: "PRIMARY",
    typeErr: "",
    relation: "",
    provider: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    groupName: "",
    groupId: "",
    policyNumber: "",
    plan: "",
    insBegin: "",
    insEnd: "",
    payerId: "",
    relationErr: "",
    phoneNumberErr: "",
    groupNameErr: "",
    groupIdErr: "",
    policyNumberErr: "",
    planErr: "",
    insBeginErr: "",
    insEndErr: "",
    payerIdErr: "",
    address1Err: "",
    cityErr: "",
    stateErr: "",
    zipErr: "",
  };

  encounterDefault = {
    id: "",
    accountNumber: "",
    referringPhysician: "",
    patientType: "",
    status: "ACTIVE",
    icdCodes: "",
    requestedUnit: "",
    payerName: "",
    attendingPhysician: "",
    requestedDate: "",
    invoiceStatus: "",
    procedureCodes: "",
    approvedUnit: "",
    scheduleDate: "",
    completedUnit: "",
  };

  documentationDefault = {
    id: "",
    documentName: "",
    documentType: "",
    dateUploaded: "",
    username: "",
    submitted: "",
    filename: "",
    status: "",
  };

  closeSearchGuaPatDupDialog = () => {
    this.props.guarantorPatientSearchDuplicateClear();
    this.setState({ searchGuaPatDupModal: false });
  };

  showSearchGuaPatDupDialog = () => {
    console.log("test");
    this.setState({ searchGuaPatDupModal: true });
  };

  searchGuaPatDup = async () => {
    var { orgId, clientId, facilityId, mrn, ssn, lastName, firstName, dob } =
      this.state;
    const { addedPatient } = this.props;

    var id = 0;

    if (addedPatient.id) {
      id = addedPatient.id;
    }

    if (!orgId) {
      orgId = 0;
    }

    if (!clientId) {
      clientId = 0;
    }

    if (!facilityId) {
      facilityId = 0;
    }

    dob = formatDateToCustomISO(moment(dob).toDate());
    dob = moment(dob).format("MM/DD/YYYY");

    await this.props.guarantorPatientSearchDuplicate(
      id,
      orgId,
      clientId,
      facilityId,
      "P",
      mrn,
      ssn,
      lastName,
      firstName,
      dob
    );
  };

  closeSearchGuarantorDialog = () => {
    console.log("test");
    this.setState({ searchGuarantorsModal: false });
  };

  showSearchGuarantorDialog = () => {
    console.log("test");
    this.setState({ searchGuarantorsModal: true });
  };

  searchGuarantors = () => {
    const { searchMrn, searchLastName, searchFirstName, searchDob } =
      this.state;
    let dob = undefined;
    if (!searchMrn && !searchLastName && !searchFirstName && !searchDob) return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      if (searchDob) {
        dob = moment(searchDob).format("MM/DD/YYYY");
      } else {
        dob = undefined;
      }
      this.props.guarantorPatientsSearch(
        searchMrn,
        lastName,
        firstName,
        dob,
        "G"
      );
    } catch (err) {
      console.log(err);
    }
  };

  selectClient = (e) => {
    let clientId = e.target.value;
    console.log(clientId);
    this.setState({ clientId: clientId, facilityId: "" });
    //this.setState({selectedClient: selectedClient, clientName: selectedClient.name});
    this.props.getFacilities(clientId);
  };

  selectPayer = (e) => {
    let payerId = e.target.value;
    console.log("I am here!!!", payerId);
    const { payers } = this.props;
    var payer = payers.filter((a) => a.id == payerId);

    if (payer.length != 0) {
      console.log("payer", payer);

      this.setState({
        address1: payer[0].address1 ? payer[0].address1 : "",
        address2: payer[0].address2 ? payer[0].address2 : "",
        city: payer[0].city ? payer[0].city : "",
        zip: payer[0].zip ? payer[0].zip : "",
        state: payer[0].state ? payer[0].state : "",
        phoneNumber: payer[0].phoneNumber
          ? this.addDashes(payer[0].phoneNumber)
          : "",
      });
    }

    this.setState({ payerId: payerId });
  };

  handleSelect(key) {
    console.log("selected" + key);
    this.setState({ key: key });
  }

  handleSameAsGuarantor(event) {
    this.setState({ guarantorSameAsPatient: event.target.checked });
  }

  savePatientSameAsGuarantor = async () => {
    const { addedGuarantor, addedPatient } = this.props;
    if (addedGuarantor.id && addedGuarantor.id > 0) {
      var patient = {
        ...this.state,
        id: addedPatient.id,
        parentId: addedGuarantor.id,
        type: "P",
      };
      await this.props.addUpdatePatient(patient);
    } else {
      this.savePatientSameAsGuarantor();
    }
  };

  clearPatientErrors = () => {
    this.setState({
      clientIdErr: "",
      facilityIdErr: "",
      lastNameErr: "",
      firstNameErr: "",
      middleNameErr: "",
      dobErr: "",
      ssnErr: "",
    });
  };

  savePatient = async () => {
    this.setState({ disabledSavePatient: true });
    var error = false;

    if (!this.state.clientId) {
      this.setState({ clientIdErr: "Client is required." });
      error = true;
    } else {
      this.setState({ clientIdErr: "" });
    }
    console.log("this.state.facilityId" + this.state.facilityId);
    if (!this.state.facilityId) {
      this.setState({ facilityIdErr: "Facility is required." });
      error = true;
    } else {
      this.setState({ facilityIdErr: "" });
    }

    if (!this.state.lastName) {
      this.setState({ lastNameErr: "Last Name is required." });
      error = true;
    } else {
      this.setState({ lastNameErr: "" });
    }

    if (!this.state.firstName) {
      this.setState({ firstNameErr: "First Name is required." });
      error = true;
    } else {
      this.setState({ firstNameErr: "" });
    }

    if (!this.state.dob) {
      this.setState({ dobErr: "Date of Birth is required." });
      error = true;
    } else {
      this.setState({ dobErr: "" });
    }

    if (!this.state.ssn) {
      this.setState({ ssnErr: "SSN is required." });
      error = true;
    } else {
      this.setState({ ssnErr: "" });
    }

    if (error == false) {
      console.log(this.state.guarantorSameAsPatient);
      var { guarantorSameAsPatient, dob } = this.state;
      const {
        addedGuarantor,
        addedPatient,
        guarantorPatientSearchDuplicateResults,
      } = this.props;

      var hasDuplicate = true;

      if (guarantorPatientSearchDuplicateResults == "CLEARED") {
        await this.searchGuaPatDup();
        this.savePatient();
      } else {
        console.log(
          "guarantorPatientSearchDuplicateResults",
          guarantorPatientSearchDuplicateResults
        );
        if (!guarantorPatientSearchDuplicateResults) {
          hasDuplicate = false;
        } else {
          if (guarantorPatientSearchDuplicateResults.length != 0) {
            this.setState({ searchGuaPatDupModal: true });
          } else {
            hasDuplicate = false;
          }
        }
      }

      console.log("hasDuplicate=" + hasDuplicate);
      if (hasDuplicate == false) {
        this.clearPatientErrors();

        this.props.guarantorPatientSearchDuplicateClear();

        if (guarantorSameAsPatient == true) {
          var guarantor = { ...this.state, id: addedGuarantor.id, type: "G" };
          await this.props.addUpdateGuarantor(guarantor);

          this.savePatientSameAsGuarantor();
        } else {
          var patient = {
            ...this.state,
            id: addedPatient.id,
            parentId: addedGuarantor.id,
            type: "P",
          };
          this.props.updatePatientsSearchResult(patient);
          await this.props.addUpdatePatient(patient);
          if (!addedGuarantor.id) {
            this.props.navigate("/accounts/add-guarantor");
          }
        }
      }
    }

    this.setState({ disabledSavePatient: false });
  };

  clearPhoneEmailErrors = () => {
    this.setState({
      numberEmailErr: "",
    });
  };

  savePhoneEmail = async () => {
    var error = false;

    if (!this.state.numberEmail) {
      this.setState({ numberEmailErr: "Number/ Email is required." });
      error = true;
    } else {
      this.setState({ numberEmailErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const { addedGuarantor, addedPatient } = this.props;

      var phoneEmail = {
        ...this.state,
        guarantorId: addedGuarantor.id,
        parentId: addedPatient.id,
      };
      console.log("phoneEmial", phoneEmail);
      await this.props.addUpdatePhoneEmail(phoneEmail);
      await this.props.getPatientPhoneEmail(addedPatient.id);
      console.log("I am here 4");
      if (isNew == "Y") {
        this.setState(this.phoneEmailDefault);
      }

      this.clearPhoneEmailErrors();
    }
  };

  badStatusPhoneEmail = async (record) => {
    console.log("I am here 1", record);
    await this.setState(record);
    this.savePhoneEmail();
    //this.setState({btnClick:'Y'});
  };

  clearAddressErrors = () => {
    this.setState({
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",
    });
  };

  saveAddress = async () => {
    var error = false;

    if (!this.state.address1) {
      this.setState({ address1Err: "Address1 is required." });
      error = true;
    } else {
      this.setState({ address1Err: "" });
    }

    if (!this.state.city) {
      this.setState({ cityErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityErr: "" });
    }

    if (!this.state.state) {
      this.setState({ stateErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!this.state.zip) {
      this.setState({ zipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipErr: "" });
    }

    if (error == false) {
      const { isNew } = this.state;
      const { addedGuarantor, addedPatient } = this.props;

      var address = {
        ...this.state,
        guarantorId: addedGuarantor.id,
        parentId: addedPatient.id,
      };

      await this.props.addUpdateAddress(address);
      await this.props.getPatientAddress(addedPatient.id);
      if (isNew == "Y") {
        this.setState(this.addressDefault);
      }

      this.clearAddressErrors();
    }
  };

  clearInsuranceErrors = () => {
    this.setState({
      typeErr: "",
      relationErr: "",
      phoneNumberErr: "",
      groupNameErr: "",
      groupIdErr: "",
      policyNumberErr: "",
      planErr: "",
      insBeginErr: "",
      insEndErr: "",
      payerIdErr: "",
      address1Err: "",
      cityErr: "",
      stateErr: "",
      zipErr: "",
    });
  };

  saveInsurance = async () => {
    var { isNew, id, type } = this.state;
    const { addedPatient, insuranceList } = this.props;
    var error = false;
    if (!id) {
      id = 0;
    }

    console.log(
      "id:" + id + "addedPatient.id:" + addedPatient.id + "type:" + type
    );

    var insuranceTypeCheck = insuranceList.filter(
      (a) => a.patientId == addedPatient.id && a.id != id && a.type == type
    );

    var errorDesc = "";
    if (insuranceTypeCheck.length != 0) {
      errorDesc = type + " already exist!";
      this.setState({ typeErr: errorDesc });
      error = true;
      console.log("test test" + type);
    } else {
      this.setState({ typeErr: "" });
    }

    if (!this.state.address1) {
      this.setState({ address1Err: "Address1 is required." });
      error = true;
    } else {
      this.setState({ address1Err: "" });
    }

    if (!this.state.city) {
      this.setState({ cityErr: "City is required." });
      error = true;
    } else {
      this.setState({ cityErr: "" });
    }

    if (!this.state.state) {
      this.setState({ stateErr: "State is required." });
      error = true;
    } else {
      this.setState({ stateErr: "" });
    }

    if (!this.state.zip) {
      this.setState({ zipErr: "Zip is required." });
      error = true;
    } else {
      this.setState({ zipErr: "" });
    }

    if (!this.state.relation) {
      this.setState({ relationErr: "Relation is required." });
      error = true;
    } else {
      this.setState({ relationErr: "" });
    }

    if (!this.state.policyNumber) {
      this.setState({ policyNumberErr: "Policy Number is required." });
      error = true;
    } else {
      this.setState({ policyNumberErr: "" });
    }

    if (!this.state.insBegin) {
      this.setState({ insBeginErr: "Begin is required." });
      error = true;
    } else {
      this.setState({ insBeginErr: "" });
    }

    if (!this.state.payerId) {
      this.setState({ payerIdErr: "Payer is required." });
      error = true;
    } else {
      this.setState({ payerIdErr: "" });
    }

    if (error == false) {
      var insurance = { ...this.state, patientId: addedPatient.id };
      this.clearInsuranceErrors();
      await this.props.addUpdateInsurance(insurance);
      await this.props.getPatientInsurance(addedPatient.id);
      if (isNew == "Y") {
        this.setState(this.insuranceDefault);
      }
    }
  };

  saveEncounter = async () => {
    const { isNew } = this.state;
    const { addedPatient } = this.props;

    var encounter = { ...this.state, patientId: addedPatient.id };

    await this.props.addUpdateEncounter(encounter);
    await this.props.getPatientEncounter(addedPatient.id);
    if (isNew == "Y") {
      this.setState(this.encounterDefault);
    }
  };

  saveDocumentation = async () => {
    var { isNew, filename, documentType, documentName, selectedFile } =
      this.state;
    const { addedPatient, authentication } = this.props;

    console.log(selectedFile);

    if (selectedFile != null && selectedFile != "") {
      var name = selectedFile.name;
      var fileType = name.substring(name.length - 3);
      filename =
        addedPatient.clientId +
        "_" +
        addedPatient.id +
        "_" +
        documentType +
        "_" +
        documentName +
        "." +
        fileType;
      filename = filename.replace(" ", "_");
      var response = await this.uploadFile(
        selectedFile,
        filename,
        addedPatient.clientId
      );
      if (response.success) {
      } else {
        return;
      }
    }

    var c = document.getElementsByClassName("myfile");
    for (var b = 0; b < c.length; b++) {
      c[b].value = null;
    }

    var dateUploaded = "";
    dateUploaded = new Date();
    console.log(dateUploaded);

    var documentation = {
      ...this.state,
      patientId: addedPatient.id,
      clientId: addedPatient.clientId,
      orgId: addedPatient.orgId,
      filename: filename,
      username: authentication.username,
      dateUploaded: dateUploaded,
    };

    await this.props.addUpdateDocumentation(documentation);
    await this.props.getPatientDocumentation(addedPatient.id);
    if (isNew == "Y") {
      this.setState(this.documentationDefault);
    }
  };

  uploadFile = async (file, fileName, clientName) => {
    //var axios = require('axios');
    var FormData = require("form-data");
    //var fs = require('fs');
    var data = new FormData();
    data.append("file", file);

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    var config = {
      method: "post",
      url:
        getDocumentationUploadFileUriPath() +
        "?name=" +
        fileName +
        "&clientName=" +
        clientName,
      headers: {
        Authorization: localStorage.getItem("id_token") || null,
        "Content-Type": "multipart/form-data",
        //...data.getHeaders()
      },
      data: data,
    };
    var retData = {};
    retData.success = false;
    await axios(config)
      .then(function (response) {
        retData.success = true;
        retData.response = response.data;
      })
      .catch(function (error) {
        retData.success = false;
        retData.error = error;
      });
    return retData;
  };

  viewAttachment = async (record) => {
    var fileName = record.filename;
    var filetype = fileName.substring(fileName.length - 3);
    var type = "image/tif";
    if (filetype.toUpperCase() == "PNG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/png");
      type = "image/png";
    } else if (filetype.toUpperCase() == "PDF") {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/pdf"
      );
      type = "application/pdf";
    } else if (filetype.toUpperCase() == "TIF") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/tif");
      type = "image/tif";
    } else if (filetype.toUpperCase() == "JPG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/jpg");
      type = "image/jpg";
    } else {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/octet-stream"
      );
      type = "application/octet-stream";

      // download etc files...
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //window.navigator.msOpenBlob(file, "Statement.pdf");
        this.props.getDocumentationDownloadFile(fileName, record.clientId);
        console.log("I am here 1");
      } else {
        await this.props.getDocumentationDownloadFile(
          fileName,
          record.clientId
        );

        const file = new Blob([this.props.documentaitonDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = fileName;
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(fileURL);
            a.removeEventListener("click", clickHandler);
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        console.log("I am here 2");
        return;
      }
    }
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //window.navigator.msOpenBlob(file, "Statement.pdf");
      this.props.getDocumentationDownloadFile(fileName, "");
      console.log("I am here 3");
    } else {
      //localStorage.setItem("tickler_sent_attachment_filename", data.attachmentFilename);
      //localStorage.setItem("tickler_sent_parent_id", data.parentId);
      //window.open('../attachment');
      await this.props.getDocumentationDownloadFile(fileName, record.clientId);
      console.log("I am here 5 c", record.clientId);
      if (this.props.documentationDownloadFile instanceof Blob) {
        const file = new Blob([this.props.documentationDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);

        this.props.documentationDownloadFileClear();
        console.log("I am here 5");
        window.open(fileURL, "_blank");
      }
    }
  };

  clearPatientList = () => {
    this.props.addUpdatePatientSelected({});
    this.props.addUpdateGuarantorSelected({});

    this.props.guarantorAddressClear();
    this.props.guarantorPhoneEmailClear();
    this.props.patientInsuranceClear();
    this.props.patientAddressClear();
    this.props.patientEncounterClear();
    this.props.patientPhoneEmailClear();
    this.props.guarantorPatientsClear();
    this.props.documentationDownloadFileClear();
    this.setState(this.patientDefault);
  };

  addNewPatientClick = () => {
    this.props.guarantorPatientSearchDuplicateClear();
    this.clearPatientList();
  };

  onClickGuarantorInfo = (record) => {
    this.props.navigate("/accounts/add-guarantor");
  };

  getPayerName = (payerId) => {
    var name = "";
    const { payers } = this.props;
    var payer = payers.filter((a) => a.id == payerId);
    if (payer.length != 0) {
      name = payer[0].name;
    }

    return name;
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  onClickEncounter(record) {
    if (!record) {
      this.props.addUpdateEncounterSelected({});
      this.props.addUpdateAuthorizationSelected({});
      this.props.addUpdateAuthorizationRequestingProviderSelected({});
    } else {
      this.props.addUpdateEncounterSelected(record.encounter);
      if (record.authorization) {
        this.props.addUpdateAuthorizationSelected(record.authorization);
      } else {
        this.props.addUpdateAuthorizationSelected({});
      }

      if (record.authRequestingProvider) {
        this.props.addUpdateAuthorizationRequestingProviderSelected(
          record.authRequestingProvider[0]
        );
      } else {
        this.props.addUpdateAuthorizationRequestingProviderSelected({});
      }
    }

    this.props.navigate("/encounter");
  }

  onClickHandlerSubTable(record, e) {
    console.log("I am here 2");
    var { btnClick } = this.state;

    console.log("test " + btnClick);

    if (btnClick == "N") {
      console.log("I am here 3");

      //
    }
    console.log(record);
    //this.setState(record);

    const elementsShow = document.querySelectorAll("tr.sub-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  onClickHandler(record, e) {
    this.clearPhoneEmailErrors();
    this.clearAddressErrors();
    this.clearInsuranceErrors();

    console.log("I am here 2 onClickHandler", record);
    var { btnClick } = this.state;

    console.log("test " + btnClick);

    if (btnClick == "N") {
      console.log("I am here 3");

      //
    }
    console.log(record);
    this.setState(record);

    const elementsShow = document.querySelectorAll("tr.main-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  addDashes = (phone) => {
    let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !x[2] ? x[1] : +x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    return phone;
  };

  render() {
    var {
      addedGuarantor,
      addedPatient,
      phoneEmailList,
      addressList,
      insuranceList,
      encounterList,
      documentationList,
      clients,
      facilities,
      guarantorPatientsSearchResults,
      guarantorPatientSearchDuplicateResults,
    } = this.props;
    var {
      searchGuarantorsModal,
      searchGuaPatDupModal,
      addPayerModal,
      guarantorSameAsPatient,
    } = this.state;

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    if (!phoneEmailList) {
      phoneEmailList = [];
    }

    phoneEmailList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!addressList) {
      addressList = [];
    }

    addressList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!insuranceList) {
      insuranceList = [];
    }

    insuranceList.sort((a, b) => {
      return a.id - b.id;
    });

    if (!encounterList) {
      encounterList = [];
    }

    if (!documentationList) {
      documentationList = [];
    }

    if (!addedGuarantor) {
      addedGuarantor = {};
    }

    if (!clients) {
      clients = [];
    }

    if (!facilities) {
      facilities = [];
    }

    if (!guarantorPatientsSearchResults) {
      guarantorPatientsSearchResults = [];
    }

    if (
      guarantorPatientSearchDuplicateResults == "CLEARED" ||
      !guarantorPatientSearchDuplicateResults
    ) {
      guarantorPatientSearchDuplicateResults = [];
    }

    var { menu } = this.props;

    if (!menu) {
      this.props.selectedMenu("Patients");
      menu = "Patients";
    }

    var addButton;

    var addPatientToGuarantor;

    var cancelNavigate = "/encounter";

    if (menu == "Patients") {
      cancelNavigate = "/patients";
    } else if (menu == "Guarantors") {
      cancelNavigate = "/accounts/add-guarantor";
      if (addedPatient.id) {
        console.log("test", addedPatient);
        addPatientToGuarantor = (
          <div>
            <Button
              className="btn btn-success grid-btn grid-btn mt-1"
              variant="primary"
              type="button"
              onClick={() => {
                if (addedGuarantor.id && addedPatient.id) {
                  console.log("I am here 3");
                  var patient = {
                    ...addedPatient,
                    parentId: addedGuarantor.id,
                  };
                  this.props.addUpdatePatient(patient);
                  console.log("I am here 4");
                }
                this.props.navigate("/accounts/add-guarantor");
              }}
            >
              Add Patient to Guarantor
            </Button>
          </div>
        );
      }
    }

    var cancelBtn = (
      <Button
        className="btn back-btn"
        type="button"
        onClick={() => {
          this.props.navigate(cancelNavigate);
        }}
      >
        <img
          src={require("../../../images/back-icon.png")}
          className="add-icon"
        />
        Back
      </Button>
    );

    var hideGuaSameAsPat = { display: "block" };
    var addedGuarantorSelectLink;
    var hidePatientDetails = { display: "none" };
    if (addedGuarantor.id > 0) {
      hideGuaSameAsPat = { display: "none" };
      hidePatientDetails = { display: "block" };
      addedGuarantorSelectLink = (
        <div className="d-flex">
          <label className="patient-guarantor">Patient Guarantor: </label>
          <label>
            <a
              className="selectPatient"
              onClick={this.onClickGuarantorInfo.bind(this)}
            >
              {addedGuarantor.id
                ? addedGuarantor.lastName + ", " + addedGuarantor.firstName
                : ""}
            </a>
            <a
              className="selectPatient"
              onClick={this.showSearchGuarantorDialog.bind(this)}
            >
              {addedGuarantor.id ? (
                <img
                  src={require("../../../images/patient-edit.png")}
                  className="patient-edit-icon"
                />
              ) : (
                <img
                  src={require("../../../images/patient-edit.png")}
                  className="patient-edit-icon"
                />
              )}
            </a>
          </label>
        </div>
      );
    }

    var addedPatientDetails;

    if (guarantorSameAsPatient == true) {
      hidePatientDetails = { display: "block" };
    }

    if (addedPatient.id) {
      hidePatientDetails = { display: "block" };
      addedPatientDetails = (
        <div>
          <div>
            <div className="row">
              <div className="col-6">
                <div className="form-main-title mt-2">
                  <label>Phone and Email</label>
                </div>
                <Table bordered className="table-portal">
                  <thead>
                    <tr>
                      <th className="td-first"></th>
                      <th>Type</th>
                      <th>Number/ Email</th>
                      <th className="td-last">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {phoneEmailList.map((record, key) => {
                      return (
                        <>
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                { ...record, isNew: "N", btnClick: "N" },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td>{record.type}</td>
                            <td>{record.numberEmail}</td>
                            <td className="td-last">{record.status}</td>
                          </tr>
                          <tr className="main-table collapse">
                            <td className="td-first td-last" colSpan="4">
                              <Form>
                                <Row>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Type
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                        <option value="MOBILE">MOBILE</option>
                                        <option value="LANDLINE">
                                          LANDLINE
                                        </option>
                                        <option value="EMAIL">EMAIL</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridPassword"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Number/ Email
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.numberEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            numberEmail: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <span className="table-form-label-err mb-0">
                                      {this.state.numberEmailErr}
                                    </span>
                                  </div>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.setState({
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-2">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        SMS
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.sms}
                                        onChange={(e) =>
                                          this.setState({ sms: e.target.value })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6 mt-2">
                                    <Button
                                      className="form-save-btn btn-primary float-end mt-3"
                                      type="button"
                                      onClick={() => {
                                        this.savePhoneEmail();
                                      }}
                                    >
                                      <img
                                        src={require("../../../images/save-btn-icon.png")}
                                        className="add-icon"
                                      />
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr
                      onClick={(event) =>
                        this.onClickHandler(
                          {
                            ...this.phoneEmailDefault,
                            isNew: "Y",
                            btnClick: "N",
                          },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="td-last"></td>
                    </tr>
                    <tr className="main-table collapse">
                      <td className="td-first td-last" colSpan="4">
                        <Form>
                          <Row>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Type
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="HOME">HOME</option>
                                  <option value="WORK">WORK</option>
                                  <option value="MOBILE">MOBILE</option>
                                  <option value="LANDLINE">LANDLINE</option>
                                  <option value="EMAIL">EMAIL</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className="table-form-label mb-0">
                                  Number/ Email
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.numberEmail}
                                  onChange={(e) =>
                                    this.setState({
                                      numberEmail: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <span className="table-form-label-err mb-0">
                                {this.state.numberEmailErr}
                              </span>
                            </div>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Status
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({ status: e.target.value })
                                  }
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-2">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Primary
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.isPrimary}
                                  onChange={(e) =>
                                    this.setState({ isPrimary: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-2">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  SMS
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.sms}
                                  onChange={(e) =>
                                    this.setState({ sms: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6 mt-2">
                              <Button
                                className="form-save-btn btn-primary float-end mt-3"
                                type="button"
                                onClick={() => {
                                  this.savePhoneEmail();
                                }}
                              >
                                <img
                                  src={require("../../../images/save-btn-icon.png")}
                                  className="add-icon"
                                />
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-6">
                <div className="form-main-title mt-2">
                  <label>Address</label>
                </div>
                <Table bordered className="table-portal">
                  <thead>
                    <tr>
                      <th className="td-first"></th>
                      <th>Type</th>
                      <th>Address</th>
                      <th>City</th>
                      <th className="td-last">State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressList.map((record, key) => {
                      return (
                        <>
                          <tr
                            onClick={(event) =>
                              this.onClickHandler(
                                { ...record, isNew: "N", btnClick: "N" },
                                event
                              )
                            }
                          >
                            <td className="td-first">
                              <i className="fa fa-chevron-down"></i>
                            </td>
                            <td>{record.type}</td>
                            <td>{record.address1}</td>
                            <td>{record.city}</td>
                            <td className="td-last">{record.state}</td>
                          </tr>
                          <tr className="main-table collapse">
                            <td className="td-first td-last" colSpan="6">
                              <Form>
                                <Row>
                                  <div class="col-lg-3 col-6">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        TYPE
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.type}
                                        onChange={(e) =>
                                          this.setState({
                                            type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 1
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address1}
                                        onChange={(e) =>
                                          this.setState({
                                            address1: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.address1Err}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Address 2
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.address2}
                                        onChange={(e) =>
                                          this.setState({
                                            address2: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={40}
                                        value={this.state.city}
                                        onChange={(e) =>
                                          this.setState({
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.cityErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        State
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.state}
                                        onChange={(e) =>
                                          this.setState({
                                            state: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">
                                          {" "}
                                          -- Select State --{" "}
                                        </option>
                                        {USStates.map((USState, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={USState.abbreviation}
                                            >
                                              {USState.name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      <span className="table-form-label-err mb-0">
                                        {this.state.stateErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Country
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.country}
                                        onChange={(e) =>
                                          this.setState({
                                            country: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="US">US</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                      <Form.Label className="table-form-label mb-0">
                                        Zip
                                      </Form.Label>
                                      <Form.Control
                                        className="grid-form-control"
                                        maxLength={12}
                                        value={this.state.zip}
                                        onChange={(e) =>
                                          this.setState({ zip: e.target.value })
                                        }
                                      />
                                      <span className="table-form-label-err mb-0">
                                        {this.state.zipErr}
                                      </span>
                                    </Form.Group>
                                  </div>
                                </Row>
                                <Row>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Status
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.setState({
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">
                                          INACTIVE
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-3 col-6 mt-1">
                                    <Form.Group
                                      as={Col}
                                      controlId="patientFullName"
                                    >
                                      <Form.Label className="table-form-label mb-0">
                                        Primary
                                      </Form.Label>
                                      <Form.Select
                                        className="grid-form-control"
                                        value={this.state.isPrimary}
                                        onChange={(e) =>
                                          this.setState({
                                            isPrimary: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                  <div class="col-lg-6 col-6 mt-1">
                                    <Button
                                      className="form-save-btn btn-primary float-end mt-3"
                                      type="button"
                                      onClick={() => {
                                        this.saveAddress();
                                      }}
                                    >
                                      <img
                                        src={require("../../../images/save-btn-icon.png")}
                                        className="add-icon"
                                      />
                                      Save
                                    </Button>
                                  </div>
                                </Row>
                              </Form>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr
                      onClick={(event) =>
                        this.onClickHandler(
                          { ...this.addressDefault, isNew: "Y", btnClick: "N" },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="td-last"></td>
                    </tr>
                    <tr className="main-table collapse">
                      <td className="td-first td-last" colSpan="6">
                        <Form>
                          <Row>
                            <div class="col-lg-3 col-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  TYPE
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="HOME">HOME</option>
                                  <option value="WORK">WORK</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 1
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address1}
                                  onChange={(e) =>
                                    this.setState({ address1: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.address1Err}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 2
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address2}
                                  onChange={(e) =>
                                    this.setState({ address2: e.target.value })
                                  }
                                />
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  City
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.city}
                                  onChange={(e) =>
                                    this.setState({ city: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.cityErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  State
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.state}
                                  onChange={(e) =>
                                    this.setState({ state: e.target.value })
                                  }
                                >
                                  <option value=""> -- Select State -- </option>
                                  {USStates.map((USState, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={USState.abbreviation}
                                      >
                                        {USState.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.stateErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Country
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.country}
                                  onChange={(e) =>
                                    this.setState({ country: e.target.value })
                                  }
                                >
                                  <option value="US">US</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Zip
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={12}
                                  value={this.state.zip}
                                  onChange={(e) =>
                                    this.setState({ zip: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.zipErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Status
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({ status: e.target.value })
                                  }
                                >
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Primary
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.isPrimary}
                                  onChange={(e) =>
                                    this.setState({ isPrimary: e.target.value })
                                  }
                                >
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </Form.Select>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6 col-6 mt-1">
                              <Button
                                className="form-save-btn btn-primary float-end mt-3"
                                type="button"
                                onClick={() => {
                                  this.saveAddress();
                                }}
                              >
                                <img
                                  src={require("../../../images/save-btn-icon.png")}
                                  className="add-icon"
                                />
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="form-main-title mt-2">
            <label>INSURANCE</label>
          </div>
          <Table bordered className="table-portal">
            <thead>
              <tr>
                <th className="td-first"></th>
                <th>Type</th>
                <th>Payer</th>
                <th>Phone Number</th>
                <th className="td-last">Policy Number</th>
              </tr>
            </thead>
            <tbody>
              {insuranceList.map((record, key) => {
                return (
                  <>
                    <tr
                      onClick={(event) =>
                        this.onClickHandler(
                          { ...record, isNew: "N", btnClick: "N" },
                          event
                        )
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td>{record.type}</td>
                      <td>{this.getPayerName(record.payerId)}</td>
                      <td>{record.phoneNumber}</td>
                      <td className="td-last">{record.policyNumber}</td>
                    </tr>
                    <tr className="main-table collapse">
                      <td className="td-first td-last" colSpan="5">
                        <Form>
                          <Row>
                            <div class="col-lg-3">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Type
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.type}
                                  onChange={(e) =>
                                    this.setState({ type: e.target.value })
                                  }
                                >
                                  <option value="PRIMARY">PRIMARY</option>
                                  <option value="SECONDARY">SECONDARY</option>
                                  <option value="TERTIARY">TERTIARY</option>
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.typeErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-3">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Relation
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.relation}
                                  onChange={(e) =>
                                    this.setState({ relation: e.target.value })
                                  }
                                >
                                  <option value="">
                                    {" "}
                                    -- Select Relation --{" "}
                                  </option>
                                  {this.props.relation.map((rel, key) => {
                                    return (
                                      <option key={key} value={rel.value}>
                                        {rel.value}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.relationErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-6">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  Payer
                                </Form.Label>
                                <div className="d-flex">
                                  <Form.Select
                                    className="grid-form-control"
                                    value={this.state.payerId}
                                    onChange={(e) => this.selectPayer(e)}
                                  >
                                    <option value="">
                                      {" "}
                                      -- Select Payer --{" "}
                                    </option>
                                    {this.props.payers.map((payer, key) => {
                                      return (
                                        <option key={key} value={payer.id}>
                                          {payer.name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                  <a
                                    className="add-payer-btn"
                                    onClick={(e) => {
                                      this.showAddPayerModal(true);
                                    }}
                                  >
                                    <img
                                      src={require("../../../images/add-payer-icon.png")}
                                    />
                                  </a>
                                </div>
                                <span className="table-form-label-err mb-0">
                                  {this.state.payerIdErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 1
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address1}
                                  onChange={(e) =>
                                    this.setState({ address1: e.target.value })
                                  }
                                />
                              </Form.Group>
                              <span className="table-form-label-err mb-0">
                                {this.state.address1Err}
                              </span>
                            </div>
                            <div class="col-lg-3 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Address 2
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.address2}
                                  onChange={(e) =>
                                    this.setState({ address2: e.target.value })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  City
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.city}
                                  onChange={(e) =>
                                    this.setState({ city: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.cityErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="patientFullName">
                                <Form.Label className="table-form-label mb-0">
                                  State
                                </Form.Label>
                                <Form.Select
                                  className="grid-form-control"
                                  value={this.state.state}
                                  onChange={(e) =>
                                    this.setState({ state: e.target.value })
                                  }
                                >
                                  <option value=""> -- Select State -- </option>
                                  {USStates.map((USState, key) => {
                                    return (
                                      <option
                                        key={key}
                                        value={USState.abbreviation}
                                      >
                                        {USState.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                                <span className="table-form-label-err mb-0">
                                  {this.state.stateErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Zip
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={12}
                                  value={this.state.zip}
                                  onChange={(e) =>
                                    this.setState({ zip: e.target.value })
                                  }
                                />
                              </Form.Group>
                              <span className="table-form-label-err mb-0">
                                {this.state.zipErr}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Phone Number
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  type="tel"
                                  value={this.state.phoneNumber}
                                  onChange={(e) => {
                                    let phone = this.addDashes(e.target.value);
                                    this.setState({ phoneNumber: phone });
                                  }}
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.phoneNumberErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Group Name
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.groupName}
                                  onChange={(e) =>
                                    this.setState({ groupName: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.groupNameErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Group Id
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.groupId}
                                  onChange={(e) =>
                                    this.setState({ groupId: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.groupIdErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Policy Number
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.policyNumber}
                                  onChange={(e) =>
                                    this.setState({
                                      policyNumber: e.target.value,
                                    })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.policyNumberErr}
                                </span>
                              </Form.Group>
                            </div>
                          </Row>
                          <Row>
                            <div class="col-lg-2 col-6 mt-1">
                              <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">
                                  Plan
                                </Form.Label>
                                <Form.Control
                                  className="grid-form-control"
                                  maxLength={40}
                                  value={this.state.plan}
                                  onChange={(e) =>
                                    this.setState({ plan: e.target.value })
                                  }
                                />
                                <span className="table-form-label-err mb-0">
                                  {this.state.planErr}
                                </span>
                              </Form.Group>
                            </div>
                            <div className="col-lg-2 col-6 d-flex flex-column">
                              <label className="date-picker-label mb-0">
                                Begin
                              </label>
                              <DatePicker
                                className="date-picker"
                                showIcon
                                placeholderText="MM/DD/YYYY"
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    mask={MaskDateInput}
                                  />
                                }
                                onChange={(date) =>
                                  this.setState({
                                    insBegin: formatDateToCustomISO(date),
                                  })
                                }
                                selected={
                                  this.state.insBegin
                                    ? formatDateToCustomISO(
                                        moment(this.state.insBegin).toDate()
                                      )
                                    : null
                                }
                              />
                              <span className="table-form-label-err mb-0 mb-0">
                                {this.state.insBeginErr}
                              </span>
                            </div>
                            <div className="col-lg-2 col-6 d-flex flex-column">
                              <label className="date-picker-label mb-0">
                                End
                              </label>
                              <DatePicker
                                className="date-picker"
                                showIcon
                                placeholderText="MM/DD/YYYY"
                                customInput={
                                  <MaskedTextInput
                                    type="text"
                                    mask={MaskDateInput}
                                  />
                                }
                                onChange={(date) =>
                                  this.setState({
                                    insEnd: formatDateToCustomISO(date),
                                  })
                                }
                                selected={
                                  this.state.insEnd
                                    ? formatDateToCustomISO(
                                        moment(this.state.insEnd).toDate()
                                      )
                                    : null
                                }
                              />
                              <span className="table-form-label-err mb-0 mb-0">
                                {this.state.insEndErr}
                              </span>
                            </div>
                            <div class="col-lg-6 col-6 mt-1">
                              <Button
                                className="form-save-btn btn-primary float-end mt-3"
                                type="button"
                                onClick={() => {
                                  this.saveInsurance();
                                }}
                              >
                                <img
                                  src={require("../../../images/save-btn-icon.png")}
                                  className="add-icon"
                                />
                                Save
                              </Button>
                            </div>
                          </Row>
                        </Form>
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr
                onClick={(event) =>
                  this.onClickHandler(
                    { ...this.insuranceDefault, isNew: "Y", btnClick: "N" },
                    event
                  )
                }
              >
                <td className="td-first">
                  <i className="fa fa-chevron-down"></i>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="main-table collapse">
                <td className="td-first td-last" colSpan="5">
                  <Form>
                    <Row>
                      <div class="col-lg-3">
                        <Form.Group as={Col} controlId="patientFullName">
                          <Form.Label className="table-form-label mb-0">
                            Type
                          </Form.Label>
                          <Form.Select
                            className="grid-form-control"
                            value={this.state.type}
                            onChange={(e) =>
                              this.setState({ type: e.target.value })
                            }
                          >
                            <option value="PRIMARY">PRIMARY</option>
                            <option value="SECONDARY">SECONDARY</option>
                            <option value="TERTIARY">TERTIARY</option>
                          </Form.Select>
                        </Form.Group>
                        <span className="table-form-label-err mb-0">
                          {this.state.typeErr}
                        </span>
                      </div>
                      <div class="col-lg-3">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Relation
                          </Form.Label>
                          <Form.Select
                            className="grid-form-control"
                            value={this.state.relation}
                            onChange={(e) =>
                              this.setState({ relation: e.target.value })
                            }
                          >
                            <option value=""> -- Select Relation -- </option>
                            {this.props.relation.map((rel, key) => {
                              return (
                                <option key={key} value={rel.value}>
                                  {rel.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <span className="table-form-label-err mb-0">
                            {this.state.relationErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-6">
                        <Form.Group as={Col} controlId="patientFullName">
                          <Form.Label className="table-form-label mb-0">
                            Payer
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Select
                              className="grid-form-control"
                              value={this.state.payerId}
                              onChange={(e) => this.selectPayer(e)}
                            >
                              <option value=""> -- Select Payer -- </option>
                              {this.props.payers.map((payer, key) => {
                                return (
                                  <option key={key} value={payer.id}>
                                    {payer.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <a
                              className="add-payer-btn"
                              onClick={(e) => {
                                this.showAddPayerModal(true);
                              }}
                            >
                              <img
                                src={require("../../../images/add-payer-icon.png")}
                              />
                            </a>
                          </div>
                          <span className="table-form-label-err mb-0">
                            {this.state.payerIdErr}
                          </span>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div class="col-lg-3 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Address 1
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.address1}
                            onChange={(e) =>
                              this.setState({ address1: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.address1Err}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-3 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Address 2
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.address2}
                            onChange={(e) =>
                              this.setState({ address2: e.target.value })
                            }
                          />
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="patientFullName">
                          <Form.Label className="table-form-label mb-0">
                            City
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.city}
                            onChange={(e) =>
                              this.setState({ city: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.cityErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="patientFullName">
                          <Form.Label className="table-form-label mb-0">
                            State
                          </Form.Label>
                          <Form.Select
                            className="grid-form-control"
                            value={this.state.state}
                            onChange={(e) =>
                              this.setState({ state: e.target.value })
                            }
                          >
                            <option value=""> -- Select State -- </option>
                            {USStates.map((USState, key) => {
                              return (
                                <option key={key} value={USState.abbreviation}>
                                  {USState.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <span className="table-form-label-err mb-0">
                            {this.state.stateErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Zip
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={12}
                            value={this.state.zip}
                            onChange={(e) =>
                              this.setState({ zip: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.zipErr}
                          </span>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            type="tel"
                            value={this.state.phoneNumber}
                            onChange={(e) => {
                              let phone = this.addDashes(e.target.value);
                              this.setState({ phoneNumber: phone });
                            }}
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.phoneNumberErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Group Name
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.groupName}
                            onChange={(e) =>
                              this.setState({ groupName: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.groupNameErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Group Id
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.groupId}
                            onChange={(e) =>
                              this.setState({ groupId: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.groupIdErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Policy Number
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.policyNumber}
                            onChange={(e) =>
                              this.setState({ policyNumber: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.policyNumberErr}
                          </span>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div class="col-lg-2 col-6 mt-1">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Plan
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            maxLength={40}
                            value={this.state.plan}
                            onChange={(e) =>
                              this.setState({ plan: e.target.value })
                            }
                          />
                          <span className="table-form-label-err mb-0">
                            {this.state.planErr}
                          </span>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2 col-6 d-flex flex-column">
                        <label className="date-picker-label mb-0">Begin</label>
                        <DatePicker
                          className="date-picker"
                          showIcon
                          placeholderText="MM/DD/YYYY"
                          customInput={
                            <MaskedTextInput type="text" mask={MaskDateInput} />
                          }
                          onChange={(date) =>
                            this.setState({
                              insBegin: formatDateToCustomISO(date),
                            })
                          }
                          selected={
                            this.state.insBegin
                              ? formatDateToCustomISO(
                                  moment(this.state.insBegin).toDate()
                                )
                              : null
                          }
                        />
                        <span className="table-form-label-err mb-0 mb-0">
                          {this.state.insBeginErr}
                        </span>
                      </div>
                      <div className="col-lg-2 col-6 d-flex flex-column">
                        <label className="date-picker-label mb-0">End</label>
                        <DatePicker
                          className="date-picker"
                          showIcon
                          placeholderText="MM/DD/YYYY"
                          customInput={
                            <MaskedTextInput type="text" mask={MaskDateInput} />
                          }
                          onChange={(date) =>
                            this.setState({
                              insEnd: formatDateToCustomISO(date),
                            })
                          }
                          selected={
                            this.state.insEnd
                              ? formatDateToCustomISO(
                                  moment(this.state.insEnd).toDate()
                                )
                              : null
                          }
                        />
                        <span className="table-form-label-err mb-0 mb-0">
                          {this.state.insEndErr}
                        </span>
                      </div>
                      <div class="col-lg-6 col-6 mt-1">
                        <Button
                          className="form-save-btn btn-primary float-end mt-3"
                          type="button"
                          onClick={() => {
                            this.saveInsurance();
                          }}
                        >
                          <img
                            src={require("../../../images/save-btn-icon.png")}
                            className="add-icon"
                          />
                          Save
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex">
            <div className="form-main-title">
              <label>Encounter(s)</label>
            </div>
            <Button
              className="add-patients-btn btn-primary mb-1 float-end m-0 mt-1"
              variant="primary"
              type="button"
              onClick={() => {
                this.onClickEncounter({});
              }}
            >
              <img
                src={require("../../../images/add-icon.png")}
                className="add-icon"
              />
              Add New Encounter
            </Button>
          </div>
          <Table bordered className="table-portal">
            <thead>
              <tr>
                <th className="td-first-none-collapse">Account Number</th>
                <th>Order Number</th>
                <th>Visit Date</th>
                <th className="td-last">Visit Time</th>
              </tr>
            </thead>
            <tbody>
              {encounterList.map((record, key) => {
                return (
                  <>
                    <tr
                      key={key}
                      className={key % 2 == 1 ? "even-row" : ""}
                      onClick={(event) => this.onClickEncounter(record)}
                    >
                      <td className="td-first-none-collapse">
                        {record.encounter.accountNumber}
                      </td>
                      <td>
                        {record.authorization
                          ? record.authorization.orderNumber
                          : ""}
                      </td>
                      <td>
                        {record.encounter.visitDate
                          ? moment(
                              formatDateToCustomISO(
                                moment(record.encounter.visitDate).toDate()
                              )
                            ).format("MM/DD/YYYY")
                          : ""}
                      </td>
                      <td className="td-last">{record.encounter.visitTime}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <div className="form-main-title">
            <label>Documentation(s)</label>
          </div>
          <Table bordered className="table-portal">
            <thead>
              <tr>
                <th className="td-first"></th>
                <th>Document Type</th>
                <th>Document Name</th>
                <th>Date Uploaded</th>
                <th>User</th>
                <th>File Name</th>
                <th className="td-last"></th>
              </tr>
            </thead>
            <tbody>
              {documentationList.map((record, key) => {
                return (
                  <>
                    <tr
                      onClick={(event) =>
                        this.onClickHandler({ ...record, isNew: "N" }, event)
                      }
                    >
                      <td className="td-first">
                        <i className="fa fa-chevron-down"></i>
                      </td>
                      <td>{record.documentType}</td>
                      <td>{record.documentName}</td>
                      <td>
                        {moment(
                          formatDateToCustomISO(
                            moment(record.dateUploaded).toDate()
                          )
                        ).format("MM/DD/YYYY")}
                      </td>
                      <td>{record.username}</td>
                      <td>{record.filename}</td>
                      <td className="td-last">
                        <a
                          className="btn-view-document-attach"
                          onClick={async () => {
                            var fileName = record.filename;
                            var filetype = fileName.substring(
                              fileName.length - 3
                            );
                            var type = "image/tif";
                            if (filetype.toUpperCase() == "PNG") {
                              localStorage.setItem(
                                "tickler_sent_attachment_filetype",
                                "image/png"
                              );
                              type = "image/png";
                            } else if (filetype.toUpperCase() == "PDF") {
                              localStorage.setItem(
                                "tickler_sent_attachment_filetype",
                                "application/pdf"
                              );
                              type = "application/pdf";
                            } else if (filetype.toUpperCase() == "TIF") {
                              localStorage.setItem(
                                "tickler_sent_attachment_filetype",
                                "image/tif"
                              );
                              type = "image/tif";
                            } else if (filetype.toUpperCase() == "JPG") {
                              localStorage.setItem(
                                "tickler_sent_attachment_filetype",
                                "image/jpg"
                              );
                              type = "image/jpg";
                            } else {
                              localStorage.setItem(
                                "tickler_sent_attachment_filetype",
                                "application/octet-stream"
                              );
                              type = "application/octet-stream";

                              // download etc files...
                              if (
                                window.navigator &&
                                window.navigator.msSaveOrOpenBlob
                              ) {
                                //window.navigator.msOpenBlob(file, "Statement.pdf");
                                this.props.getDocumentationDownloadFile(
                                  fileName,
                                  ""
                                );
                              } else {
                                await this.props.getDocumentationDownloadFile(
                                  fileName,
                                  record.clientId
                                );

                                const file = new Blob(
                                  [this.props.documentaitonDownloadFile],
                                  { type: type }
                                );

                                const fileURL = URL.createObjectURL(file);
                                const a = document.createElement("a");
                                a.href = fileURL;
                                a.download = fileName;
                                const clickHandler = () => {
                                  setTimeout(() => {
                                    URL.revokeObjectURL(fileURL);
                                    a.removeEventListener(
                                      "click",
                                      clickHandler
                                    );
                                  }, 150);
                                };
                                a.addEventListener(
                                  "click",
                                  clickHandler,
                                  false
                                );
                                a.click();
                                return;
                              }
                            }
                            if (
                              window.navigator &&
                              window.navigator.msSaveOrOpenBlob
                            ) {
                              //window.navigator.msOpenBlob(file, "Statement.pdf");
                              this.props.getDocumentationDownloadFile(
                                fileName,
                                ""
                              );
                            } else {
                              //localStorage.setItem("tickler_sent_attachment_filename", data.attachmentFilename);
                              //localStorage.setItem("tickler_sent_parent_id", data.parentId);
                              //window.open('../attachment');
                              await this.props.getDocumentationDownloadFile(
                                fileName,
                                record.clientId
                              );

                              if (
                                this.props.documentationDownloadFile instanceof
                                Blob
                              ) {
                                const file = new Blob(
                                  [this.props.documentationDownloadFile],
                                  { type: type }
                                );

                                const fileURL = URL.createObjectURL(file);

                                this.props.documentationDownloadFileClear();
                                window.open(fileURL, "_blank");
                              }
                            }
                          }}
                        >
                          VIEW
                        </a>
                      </td>
                    </tr>
                    <tr className="main-table collapse">
                      <td className="td-first td-last" colSpan="7">
                        <Row>
                          <div class="col-lg-4 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Type
                              </Form.Label>
                              <Form.Select
                                className="grid-form-control-select"
                                value={this.state.documentType}
                                onChange={(e) =>
                                  this.setState({
                                    documentType: e.target.value,
                                  })
                                }
                              >
                                <option value="">Select Type</option>
                                <option value="MEDICAL RECORD">
                                  MEDICAL RECORD
                                </option>
                                <option value="INSURANCE CARD">
                                  INSURANCE CARD
                                </option>
                                <option value="DRIVERS LICENSE">
                                  DRIVERS LICENSE
                                </option>
                                <option value="PAYER DOCUMENTATION">
                                  PAYER DOCUMENTATION
                                </option>
                                <option value="PATIENT CORRESPONDENCE">
                                  PATIENT CORRESPONDENCE
                                </option>
                                <option value="APPOINTMENT INFORMATION">
                                  APPOINTMENT INFORMATION
                                </option>
                                <option value="ELIGIBILITY VERIFICATION">
                                  ELIGIBILITY VERIFICATION
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-4 col-6">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Document Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                value={this.state.documentName}
                                onChange={(e) =>
                                  this.setState({
                                    documentName: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-lg-4 col-6">
                            <Form.Group
                              as={Col}
                              controlId=""
                              className="table-form-label mb-0 mt-2"
                            >
                              <form id="attachform" name="attachform">
                                <Form.Control
                                  type="file"
                                  id="myfile"
                                  accept="image/*,.pdf"
                                  text="Edit File"
                                  name="myfile"
                                  className="myfile fileEdit"
                                  onChange={(e) =>
                                    this.setState({
                                      selectedFile: e.target.files[0],
                                    })
                                  }
                                />
                              </form>
                            </Form.Group>
                          </div>
                        </Row>
                        <div className="col-md-12">
                          <div className="d-flex float-end">
                            <Button
                              className="form-save-btn btn-primary mt-1"
                              type="button"
                              onClick={() => {
                                this.saveDocumentation();
                              }}
                            >
                              <img
                                src={require("../../../images/save-btn-icon.png")}
                                className="add-icon"
                              />
                              Save
                            </Button>
                            <Button
                              className="view-doc-btn btn-primary mt-1"
                              type="button"
                              onClick={() => {
                                this.viewAttachment(record);
                              }}
                            >
                              View Attachment
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr
                onClick={(event) =>
                  this.onClickHandler(
                    { ...this.documentationDefault, isNew: "Y" },
                    event
                  )
                }
              >
                <td className="td-first">
                  <i className="fa fa-chevron-down"></i>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="td-last"></td>
              </tr>
              <tr className="main-table collapse">
                <td className="td-first td-last" colSpan="7">
                  <Form>
                    <Row>
                      <div class="col-lg-4 col-6">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Document Type
                          </Form.Label>
                          <Form.Select
                            className="grid-form-control-select"
                            value={this.state.documentType}
                            onChange={(e) =>
                              this.setState({ documentType: e.target.value })
                            }
                          >
                            <option value="">Select Type</option>
                            <option value="MEDICAL RECORD">
                              MEDICAL RECORD
                            </option>
                            <option value="INSURANCE CARD">
                              INSURANCE CARD
                            </option>
                            <option value="DRIVERS LICENSE">
                              DRIVERS LICENSE
                            </option>
                            <option value="PAYER DOCUMENTATION">
                              PAYER DOCUMENTATION
                            </option>
                            <option value="PATIENT CORRESPONDENCE">
                              PATIENT CORRESPONDENCE
                            </option>
                            <option value="APPOINTMENT INFORMATION">
                              APPOINTMENT INFORMATION
                            </option>
                            <option value="ELIGIBILITY VERIFICATION">
                              ELIGIBILITY VERIFICATION
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div class="col-lg-4 col-6">
                        <Form.Group as={Col} controlId="">
                          <Form.Label className="table-form-label mb-0">
                            Document Name
                          </Form.Label>
                          <Form.Control
                            className="grid-form-control"
                            value={this.state.documentName}
                            onChange={(e) =>
                              this.setState({ documentName: e.target.value })
                            }
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div class="col-lg-4 col-6">
                        <Form.Group
                          as={Col}
                          controlId=""
                          className="table-form-label mb-0 mt-2"
                        >
                          <form id="attachform" name="attachform">
                            <Form.Control
                              type="file"
                              id="myfile"
                              name="myfile"
                              accept="image/*,.pdf"
                              className="myfile"
                              onChange={(e) =>
                                this.setState({
                                  selectedFile: e.target.files[0],
                                })
                              }
                            />
                          </form>
                        </Form.Group>
                      </div>
                    </Row>
                    <div className="col-md-12">
                      <div className="d-flex float-end">
                        <Button
                          className="form-save-btn btn-primary mt-1"
                          type="button"
                          onClick={() => {
                            this.saveDocumentation();
                          }}
                        >
                          <img
                            src={require("../../../images/save-btn-icon.png")}
                            className="add-icon"
                          />
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                </td>
                <td className="td-last" colSpan="3" />
              </tr>
            </tbody>
          </Table>
        </div>
      );
    }

    return (
      <div class="m-2">
        <Modal show={addPayerModal} size="lg" centered>
          <Modal.Body>
            <AddPayerModal
              cancelled={(data) => {
                this.showAddPayerModal(false);
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal show={searchGuarantorsModal} size="lg" centered>
          <Modal.Header>
            <label>Search Guarantor</label>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    MRN:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="mrn"
                    type="text"
                    maxLength={40}
                    placeholder="Enter MRN"
                    onChange={(e) =>
                      this.setState({ searchMrn: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Last Name:{" "}
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="lastname"
                    maxLength={40}
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ searchLastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    First Name:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="firstname"
                    type="text"
                    placeholder="Enter First Name"
                    maxLength={40}
                    onChange={(e) =>
                      this.setState({ searchFirstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Date of Birth:
                  </Form.Label>
                  <DatePicker
                    className="date-picker"
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    onChange={(date) =>
                      this.setState({
                        searchDob: formatDateToCustomISO(date),
                      })
                    }
                    selected={
                      this.state.searchDob
                        ? formatDateToCustomISO(
                            moment(this.state.searchDob).toDate()
                          )
                        : null
                    }
                    customInput={
                      <MaskedTextInput type="text" mask={MaskDateInput} />
                    }
                  />
                </Form.Group>
              </Col>
              <Col className="pt-2">
                <Button
                  className="btn grid-btn"
                  variant="primary"
                  onClick={this.searchGuarantors.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="mt-3">
              <label>Search Result(s)</label>
            </div>
            <Table bordered className="table-portal">
              <thead>
                <tr>
                  <th className="td-first">MRN</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th className="td-last">Date of Birth</th>
                </tr>
              </thead>
              <tbody>
                {guarantorPatientsSearchResults.map((guarantor, key) => {
                  return (
                    <>
                      <tr
                        key={key}
                        onClick={() => {
                          this.props.addUpdateGuarantorSelected(guarantor);

                          if (addedPatient.id) {
                            var patient = {
                              ...addedPatient,
                              parentId: guarantor.id,
                            };
                            this.props.addUpdatePatient(patient);
                            console.log("I am here 1");
                          }
                          console.log("I am here 2");

                          this.closeSearchGuarantorDialog();
                          console.log("I am here 3");
                        }}
                      >
                        <td className="td-first">{guarantor.mrn}</td>
                        <td>{guarantor.lastName}</td>
                        <td>{guarantor.firstName}</td>
                        <td className="td-last">{guarantor.dob}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn grid-btn"
              variant="secondary"
              onClick={this.closeSearchGuarantorDialog.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={searchGuaPatDupModal} size="lg" centered>
          <Modal.Header>
            <label>Patient Already Exist!</label>
          </Modal.Header>
          <Modal.Body>
            <Table bordered className="table-portal">
              <thead>
                <tr>
                  <th className="td-first">MRN</th>
                  <th className="td-first">SSN</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th className="td-last">Date of Birth</th>
                </tr>
              </thead>
              <tbody>
                {guarantorPatientSearchDuplicateResults.map((patient, key) => {
                  return (
                    <>
                      <tr
                        key={key}
                        onClick={() => {
                          this.props.addUpdatePatientSelected(patient);

                          var parentId = 0;
                          if (!patient.parentId) {
                            parentId = patient.parentId;
                          }

                          this.props.getPatientPhoneEmail(patient.id);
                          this.props.getPatientAddress(patient.id);
                          this.props.getPatientInsurance(patient.id);
                          this.setState({ ...patient });

                          this.props.getPatientGuarantor(parentId);

                          this.props.guarantorPatientSearchDuplicateClear();
                          this.closeSearchGuaPatDupDialog();
                          console.log("I am here 3");
                        }}
                      >
                        <td className="td-first">{patient.mrn}</td>
                        <td>{patient.ssn}</td>
                        <td>{patient.lastName}</td>
                        <td>{patient.firstName}</td>
                        <td className="td-last">{patient.dob}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn grid-btn"
              variant="secondary"
              onClick={this.closeSearchGuaPatDupDialog.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-md-8 ">
            <div className="row">
              <label className="patient-title">PATIENTS</label>
              <div className="d-flex">
                <label className="patient-info-header">
                  Reference Number :{" "}
                </label>
                <label className="patient-info-header">{addedPatient.id}</label>
                {addedGuarantorSelectLink}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex float-end m-0 mt-1">
              {cancelBtn}
              {addButton}
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-6 d-flex mt-2">
          <div style={hideGuaSameAsPat}>
            <Form.Label className="form-title-label mt-1">
              &nbsp;Please check&nbsp;
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat}>
            <Form.Check onChange={this.handleSameAsGuarantor} />
          </div>
          <div style={hideGuaSameAsPat}>
            <Form.Label className="form-title-label mt-1">
              &nbsp;Guarantor same as Patient
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat}>
            <Form.Label className="form-title-label">
              &nbsp;-Or-&nbsp;
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat} className="form-title-label mt-1">
            <Form.Label className="form-title-label">
              <a
                className="selectPatient"
                onClick={this.showSearchGuarantorDialog.bind(this)}
              >
                Select Existing Guarantor
              </a>
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat}>
            <Form.Label className="form-title-label">
              &nbsp;-Or-&nbsp;
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat} className="form-title-label mt-1">
            <Form.Label className="form-title-label">
              <a
                className="selectPatient"
                onClick={() => {
                  this.props.navigate("/accounts/add-guarantor");
                }}
              >
                Add New Guarantor
              </a>
            </Form.Label>
          </div>
          <div style={hideGuaSameAsPat}>
            <Form.Label className="form-title-label mt-1">
              &nbsp;first to create new Patient.
            </Form.Label>
          </div>
        </div>
        <div class="line-sep"></div>
        <div style={hidePatientDetails}>
          <Row className="mb-1 mt-2">
            <div class="col-lg-4 col-6">
              <Form.Group as={Col} controlId="clientName">
                <Form.Label className="table-form-label mb-0">
                  Client Name
                </Form.Label>
                <Form.Select
                  className="grid-form-control-select"
                  placeholder="Select Client"
                  value={this.state.clientId}
                  onChange={(e) => {
                    //console.log(e)
                    //this.setState({clientName: e.target.value, selectedClient: e.target.selectedIndex})
                    this.selectClient(e);
                  }}
                >
                  <option selected value="">
                    {" "}
                    -- Select Client --{" "}
                  </option>
                  {clients.map((client, key) => {
                    return (
                      <option value={client.id} key={key}>
                        {client.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <span className="table-form-label-err mb-0">
                  {this.state.clientIdErr}
                </span>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-6">
              <Form.Group as={Col} controlId="facility">
                <Form.Label className="table-form-label mb-0">
                  Facility
                </Form.Label>
                <Form.Select
                  className="grid-form-control-select"
                  placeholder="Select Facility"
                  onChange={(e) =>
                    this.setState({ facilityId: e.target.value })
                  }
                  value={this.state.facilityId}
                >
                  <option selected value="">
                    {" "}
                    -- Select Facility --
                  </option>
                  {facilities.map((facility, key) => {
                    return (
                      <option value={facility.id} key={key}>
                        {facility.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <span className="table-form-label-err mb-0">
                  {this.state.facilityIdErr}
                </span>
              </Form.Group>
            </div>
            <div className="col-lg-4 col-6">
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">SSN</Form.Label>
                <MaskedFormControl
                  className="grid-form-control"
                  type="text"
                  maxLength={40}
                  value={this.state.ssn}
                  onChange={(e) => this.setState({ ssn: e.target.value })}
                  mask="111-11-1111"
                />
              </Form.Group>
              <span className="table-form-label-err mb-0">
                {this.state.ssnErr}
              </span>
            </div>
            <div class="col-lg-4 col-6">
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  First Name
                </Form.Label>
                <Form.Control
                  className="grid-form-control"
                  value={this.state.firstName}
                  maxLength={40}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
              </Form.Group>
              <span className="table-form-label-err mb-0">
                {this.state.firstNameErr}
              </span>
            </div>
            <div class="col-lg-4 col-6">
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  Middle Name
                </Form.Label>
                <Form.Control
                  className="grid-form-control"
                  value={this.state.middleName}
                  maxLength={40}
                  onChange={(e) =>
                    this.setState({ middleName: e.target.value })
                  }
                />
              </Form.Group>
              <span className="table-form-label-err mb-0">
                {this.state.middleNameErr}
              </span>
            </div>
            <div className="col-lg-4 col-6">
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  Last Name
                </Form.Label>
                <Form.Control
                  className="grid-form-control"
                  value={this.state.lastName}
                  maxLength={40}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
              </Form.Group>
              <span className="table-form-label-err mb-0">
                {this.state.lastNameErr}
              </span>
            </div>
            <div className="col-lg-2 col-6 d-flex flex-column">
              <label className="date-picker-label mb-0">Date of Birth</label>
              <DatePicker
                className="date-picker"
                showIcon
                placeholderText="MM/DD/YYYY"
                customInput={
                  <MaskedTextInput type="text" mask={MaskDateInput} />
                }
                onChange={(date) =>
                  this.setState({ dob: formatDateToCustomISO(date) })
                }
                selected={
                  this.state.dob
                    ? formatDateToCustomISO(moment(this.state.dob).toDate())
                    : null
                }
              />
              <span className="table-form-label-err mb-0 mb-0">
                {this.state.dobErr}
              </span>
            </div>
            <div class="col-lg-2 col-6">
              <Form.Label className="table-form-label mb-0">Gender:</Form.Label>
              <Form.Select
                className="grid-form-control"
                value={this.state.gender}
                onChange={(e) => this.setState({ gender: e.target.value })}
              >
                <option value="">--Select Gender --</option>
                <option value="M">MALE</option>
                <option value="F">FEMALE</option>
              </Form.Select>
            </div>
            <div class="col-lg-4 col-6">
              <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">MRN</Form.Label>
                <Form.Control
                  className="grid-form-control"
                  value={this.state.mrn}
                  maxLength={40}
                  onChange={(e) => this.setState({ mrn: e.target.value })}
                />
              </Form.Group>
            </div>
            <div class="col-lg-4 col-6">
              <div className="d-flex float-end mt-4">
                <div>
                  <Button
                    className="form-save-btn ml-2"
                    variant="primary"
                    disabled={this.state.disabledSavePatient}
                    type="button"
                    onClick={() => {
                      this.savePatient();
                    }}
                  >
                    <img
                      src={require("../../../images/save-btn-icon.png")}
                      className="add-icon"
                    />
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Row>
        </div>
        {addedPatientDetails}

        {addPatientToGuarantor}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateGuarantor: bindActionCreators(addUpdateGuarantor, dispatch),
    addUpdatePatient: bindActionCreators(addUpdatePatient, dispatch),
    updatePatientsSearchResult: bindActionCreators(
      updatePatientsSearchResult,
      dispatch
    ),
    addUpdatePhoneEmail: bindActionCreators(addUpdatePhoneEmail, dispatch),
    addUpdateAddress: bindActionCreators(addUpdateAddress, dispatch),
    addUpdateInsurance: bindActionCreators(addUpdateInsurance, dispatch),
    addUpdateEncounter: bindActionCreators(addUpdateEncounter, dispatch),
    addUpdateEncounterSelected: bindActionCreators(
      addUpdateEncounterSelected,
      dispatch
    ),
    getPatientPhoneEmail: bindActionCreators(getPatientPhoneEmail, dispatch),
    getPatientAddress: bindActionCreators(getPatientAddress, dispatch),
    getPatientInsurance: bindActionCreators(getPatientInsurance, dispatch),
    getPatientEncounter: bindActionCreators(getPatientEncounter, dispatch),
    addUpdatePatientSelected: bindActionCreators(
      addUpdatePatientSelected,
      dispatch
    ),
    addUpdateGuarantorSelected: bindActionCreators(
      addUpdateGuarantorSelected,
      dispatch
    ),
    guarantorAddressClear: bindActionCreators(guarantorAddressClear, dispatch),
    guarantorPhoneEmailClear: bindActionCreators(
      guarantorPhoneEmailClear,
      dispatch
    ),
    guarantorPatientsClear: bindActionCreators(
      guarantorPatientsClear,
      dispatch
    ),
    patientAddressClear: bindActionCreators(patientAddressClear, dispatch),
    patientPhoneEmailClear: bindActionCreators(
      patientPhoneEmailClear,
      dispatch
    ),
    patientInsuranceClear: bindActionCreators(patientInsuranceClear, dispatch),
    patientEncounterClear: bindActionCreators(patientEncounterClear, dispatch),
    getClients: bindActionCreators(getClientsByOrgId, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    guarantorPatientsSearchClear: bindActionCreators(
      guarantorPatientsSearchClear,
      dispatch
    ),
    guarantorPatientSearchDuplicate: bindActionCreators(
      guarantorPatientSearchDuplicate,
      dispatch
    ),
    guarantorPatientSearchDuplicateClear: bindActionCreators(
      guarantorPatientSearchDuplicateClear,
      dispatch
    ),
    getPatientGuarantor: bindActionCreators(
      guarantorPatientSearchDuplicateClear,
      dispatch
    ),
    addUpdateDocumentation: bindActionCreators(
      addUpdateDocumentation,
      dispatch
    ),
    getPatientDocumentation: bindActionCreators(
      getPatientDocumentation,
      dispatch
    ),
    getDocumentationDownloadFile: bindActionCreators(
      getDocumentationDownloadFile,
      dispatch
    ),
    documentationDownloadFileClear: bindActionCreators(
      documentationDownloadFileClear,
      dispatch
    ),
    getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
    addUpdateAuthorizationSelected: bindActionCreators(
      addUpdateAuthorizationSelected,
      dispatch
    ),
    addUpdateAuthorizationRequestingProviderSelected: bindActionCreators(
      addUpdateAuthorizationRequestingProviderSelected,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    addedGuarantor: state.addUpdateGuarantor.data,
    addedPatient: state.addUpdatePatient.data,
    addedPhoneEmail: state.addUpdatePhoneEmail.data,
    addedAddress: state.addUpdateAddress.data,
    phoneEmailList: state.getPatientPhoneEmail.data,
    addressList: state.getPatientAddress.data,
    insuranceList: state.getPatientInsurance.data,
    encounterList: state.getPatientEncounter.data,
    menu: state.menu.data,
    clients: state.getClientsByOrgId.data,
    facilities: state.getFacilities.data,
    user: state.currentUser.data,
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
    patientsSearchResults: state.patientsSearch.guarantorPatientsSearchResults,
    guarantorPatientSearchDuplicateResults:
      state.guarantorPatientSearchDuplicate.data,
    documentationList: state.getPatientDocumentation.data,
    documentationDownloadFile: state.documentationDownloadFile.data,
    payers: state.getPayersAll.data,
    authentication: state.authentication,
    relation: state.getSystemsCode.relation,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(AddPatients));
