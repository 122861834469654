import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationApiActionConstants from "./AddUpdateAuthorizationApiActionConstants"
import {getAddUpdateAuthorizationUriPath} from '../EndpointResolver'

export function addUpdateAuthorization(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUEST,
                AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_SUCCESS,
                AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_FAILED,
            ]
        },
    }
}

export function addUpdateAuthorizationSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_SELECTED,
             val: val
         })
     }
}