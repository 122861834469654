import { CALL_API } from "../../../middleware/api";
import { getAddUpdateNoteUriPath } from "../EndpointResolver";
import * as AddUpdateNoteApiActionConstants from "./AddUpdateNoteApiActionConstants";

export function addUpdateNote(requestParams = {}) {
  return {
    [CALL_API]: {
      endpoint: getAddUpdateNoteUriPath(),
      config: {
        data: requestParams,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestParams,
      },
      types: [
        AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_REQUEST,
        AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_SUCCESS,
        AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_FAILED,
      ],
    },
  };
}
