import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorization278ApiActionConstants from "./AddUpdateAuthorization278ApiActionConstants"
import {getAuth278UriPath, getAuthorization278UriPath} from '../EndpointResolver'

export function addUpdateAuthorization278(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAuth278UriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_REQUEST,
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_SUCCESS,
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_FAILED,
            ]
        },
    }
}


export function getAuthorization278(authId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorization278UriPath(authId),
            config: { method: 'get' },
            types: [
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_REQUEST,
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_SUCCESS,
                AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_FAILED,
            ],
        },
    }
}


export function addUpdateAuthorization278Selected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateAuthorization278ApiActionConstants.ADD_UPDATE_AUTHORIZATION_278_SELECTED,
             val: val
         })
     }
}