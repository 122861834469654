import { CALL_API } from '../../../middleware/api'
import * as GetPayerDtoApiActionConstants from "./GetPayerDtoApiActionConstants"
import { getPayerDtoUriPath } from '../EndpointResolver'

export default function getPayerDto(id) {
    return {
        [CALL_API]: {
            endpoint: getPayerDtoUriPath(id),
            config: { method: 'get' },
            types: [
                GetPayerDtoApiActionConstants.GET_PAYER_DTO_REQUEST,
                GetPayerDtoApiActionConstants.GET_PAYER_DTO_SUCCESS,
                GetPayerDtoApiActionConstants.GET_PAYER_DTO_FAILED,
            ],
        },
    }
}