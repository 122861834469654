import { CALL_API } from "../../../middleware/api";
import * as GetNotesApiActionConstants from "./GetNotesApiActionConstants";
import { getNotesByClientIdUriPath } from "../EndpointResolver";

export function getNotes(clientId) {
  return {
    [CALL_API]: {
      endpoint: getNotesByClientIdUriPath(clientId),
      config: { method: "get" },
      types: [
        GetNotesApiActionConstants.GET_NOTE_REQUEST,
        GetNotesApiActionConstants.GET_NOTE_SUCCESS,
        GetNotesApiActionConstants.GET_NOTE_FAILED,
      ],
    },
  };
}
