import React, { Component } from "react";
import "../../App.css";
import "./AddAuthorization.css";
import RightPanel from "../common/leftPanel/RightPanel";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { guarantorPatientsSearch } from "../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";
import moment from "moment";
import { addUpdateAuthorization } from "../../redux/actions/api/authorization/AddUpdateAuthorizationApiActions";
import { WithRouter } from "../common/WithRouter";
import getFacilities from "../../redux/actions/api/facility/GetFacilitiesApiActions";
import getClientsByOrgId from "../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import { getProvider } from "../../redux/actions/api/provider/GetProviderApiActions";
import getPayersFindAll from "../../redux/actions/api/payer/GetPayersApiActions";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDatePicker from "react-datepicker";
import { formatDateToCustomISO } from "../common/UtilsDate";
import MaskedTextInput from "react-text-mask";

class AddAuthorization extends Component {
  state = {
    searchPatientsModal: false,
    searchMrn: null,
    searchLastName: null,
    searchFirstName: null,
    searchDob: null,
    selectedPatient: null,
    patientName: null,
    clientName: null,
    facility: null,
    payerName: null,
    payerId: null,
    referringPhysician: null,
    attendingPhysician: null,
    patientType: "IN_PATIENT",
    requestedDate: null,
    scheduleDate: null,
    preAuthStatus: "NEW",
    invoiceStatus: "",
    icdCodes: [],
    cptCodes: [],
    //procedureCodes: [],
    requestedUnit: null,
    approvedUnit: null,
    completedUnit: null,
    selectedClient: null,
    payerOptions: null,
    patientMrn: null,
    accountNumber: null,
    payerAuthorizationId: null,
    selectedFacility: null,
    selectedRefPhysician: null,
    selectedAttPhysician: null,
  };

  saveAuth = async () => {
    const {
      selectedPatient,
      selectedClient,
      patientName,
      clientName,
      facility,
      payerName,
      payerId,
      referringPhysician,
      attendingPhysician,
      patientType,
      requestedDate,
      scheduleDate,
      preAuthStatus,
      invoiceStatus,
      icdCodes,
      cptCodes,
      requestedUnit,
      approvedUnit,
      completedUnit,
      selectedFacility,
      selectedRefPhysician,
      selectedAttPhysician,
    } = this.state;
    let auth = {};
    auth.patientName = patientName;
    auth.patientId = selectedPatient.id;
    auth.patientMrn = selectedPatient.mrn;
    auth.patientDob = selectedPatient.dob;
    auth.patientFirstName = selectedPatient.firstName;
    auth.patientLastName = selectedPatient.lastName;
    //auth.accountNumber = selectedPatient.accountNumber; // todo
    auth.clientName = clientName;
    auth.clientId = selectedClient.id;
    auth.facility = facility;
    auth.facilityId = selectedFacility.id;
    auth.payerName = payerName;
    auth.payerId = payerId;
    auth.referringPhysician = referringPhysician;
    auth.referringPhysicianProviderId = selectedRefPhysician.id;
    auth.attendingPhysician = attendingPhysician;
    auth.attendingPhysicianProviderId = selectedAttPhysician.id;
    auth.patientType = patientType;
    auth.requestedDate = requestedDate;
    auth.scheduleDate = scheduleDate;
    auth.preAuthStatus = preAuthStatus;
    auth.invoiceStatus = invoiceStatus;
    auth.icdCodes = icdCodes;
    auth.cptCodes = cptCodes;
    //auth.procedureCodes = procedureCodes;
    auth.requestedUnit = requestedUnit;
    auth.approvedUnit = approvedUnit;
    auth.completedUnit = completedUnit;
    auth.orgId = this.props.organization.id;
    console.log(auth);
    await this.props.addUpdateAuthorization(auth);
    this.props.navigate("/dashboard");
  };

  patientNameFocus = () => {
    this.setState({ searchPatientsModal: true });
  };

  searchPatientsModalClose = () => {
    this.setState({ searchPatientsModal: false });
  };

  searchPatients = () => {
    const { searchMrn, searchLastName, searchFirstName, searchDob } =
      this.state;
    let dob = undefined;
    if (!searchMrn && !searchLastName && !searchFirstName && !searchDob) return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      if (searchDob) {
        dob = moment(searchDob).format("MM/DD/YYYY");
      } else {
        dob = undefined;
      }
      this.props.guarantorPatientsSearch(
        searchMrn,
        lastName,
        firstName,
        dob,
        "P"
      );
    } catch (err) {
      console.log(err);
    }
  };

  selectPatient = (patient) => {
    console.log(patient);
    this.setState({
      selectedPatient: patient,
      patientName: patient.lastName + ", " + patient.firstName,
      patientDob: patient.dob,
      patientMrn: patient.mrn,
      accountNumber: patient.accountNumber,
    });
  };

  selectClient = (e) => {
    let selectedClient = this.props.clients[e.target.value];
    console.log(e);
    this.setState({
      selectedClient: selectedClient,
      clientName: selectedClient.name,
    });
    this.props.getFacilities(selectedClient.id);
    this.props.getProvider(selectedClient.id);
  };

  selectFacility = (e) => {
    let selectedFacility = this.props.facilities[e.target.value];
    this.setState({
      selectedFacility: selectedFacility,
      facility: selectedFacility.name,
    });
  };

  selectRefPhysician = (e) => {
    let selected = this.props.provider[e.target.value];
    this.setState({
      selectedRefPhysician: selected,
      referringPhysician: selected.name,
    });
  };

  selectAttPhysician = (e) => {
    let selected = this.props.provider[e.target.value];
    this.setState({
      selectedAttPhysician: selected,
      attendingPhysician: selected.name,
    });
  };

  payerTypeaheadFilterBy(option, state) {
    if (state.selected.length) {
      return true;
    }
    return option.name.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  }

  payerMenu(results, menuProps) {
    const items = results.map((result, index) => {
      if (result.divider === true) {
        return <li key={index} role="separator" className="divider" />;
      }
      if (result.header === true) {
        return (
          <li key={index} className="dropdown-header">
            {result.name}
          </li>
        );
      }
      return (
        <MenuItem key={index} option={result}>
          <p className="table-form-label mb-0">{result.name}</p>
        </MenuItem>
      );
    });
    return <Menu {...menuProps}>{items}</Menu>;
  }

  async componentDidMount() {
    await this.props.getClients(this.props.organization.id);
    console.log(this.props.clients);
    await this.props.getPayersFindAll();
    this.setState({ payerOptions: this.props.payers });
  }

  render() {
    const {
      auth,
      searchPatientsModal,
      patientName,
      clientName,
      facility,
      payer,
      referringPhysician,
      attendingPhysician,
      patientType,
      requestedDate,
      scheduleDate,
      preAuthStatus,
      invoiceStatus,
      icdCodes,
      cptCodes,
      procedureCodes,
      requestedUnit,
      approvedUnit,
      completedUnit,
      payerOptions,
      patientMrn,
      accountNumber,
      payerAuthorizationId,
    } = this.state;

    console.log(payerOptions);
    let clients = [];
    if (this.props.clients) {
      clients = this.props.clients;
    }

    let facilities = [];
    if (this.props.facilities) {
      facilities = this.props.facilities;
    }

    let providers = [];
    if (this.props.providers) {
      providers = this.props.providers;
    }

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="d-flex">
            <h5>
              <img alt="#" src={require("../../images/AuthoFiLogo.jpg")} />
            </h5>
          </div>
          <div className="d-flex justify-content-end">
            <RightPanel />
          </div>
        </div>
        <Modal show={searchPatientsModal} size="lg" centered>
          <Modal.Header>
            <h1>Search Patients</h1>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    MRN:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="mrn"
                    type="text"
                    placeholder="Enter MRN"
                    onChange={(e) =>
                      this.setState({ searchMrn: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Last Name:{" "}
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="lastname"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ searchLastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    First Name:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="firstname"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ searchFirstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Date of Birth:
                  </Form.Label>
                  <ReactDatePicker
                    className="date-picker"
                    calendarIconClassname="date-picker-icon"
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    onChange={(date) =>
                      this.setState({
                        searchDob: formatDateToCustomISO(date),
                      })
                    }
                    selected={
                      this.state.searchDob
                        ? formatDateToCustomISO(
                            moment(this.state.searchDob).toDate()
                          )
                        : null
                    }
                    customInput={
                      <MaskedTextInput type="text" mask={MaskDateInput} />
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button
                  className="mt-2 w-75"
                  onClick={this.searchPatients.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="mt-3">
              <h3>Search Results</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>MRN</td>
                    <td>Date of Birth</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.guarantorPatientsSearchResults.map(
                    (patient, key) => {
                      return (
                        <tr
                          key={key}
                          onClick={() => {
                            this.selectPatient(patient);
                            this.searchPatientsModalClose();
                          }}
                        >
                          <td>{patient.lastName + ", " + patient.firstName}</td>
                          <td>{patient.mrn}</td>
                          <td>{patient.dob}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              onClick={this.searchPatientsModalClose.bind(this)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content p-5">
            <Form className="main-form">
              <h1>Add Authorization</h1>
              <Row>
                <Col>
                  <Form.Group as={Col} controlId="patientFullName">
                    <Form.Label className="table-form-label mb-0">
                      Patient Full Name
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Click to Select Patient"
                      value={patientName}
                      onClick={this.patientNameFocus.bind(this)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label className="table-form-label mb-0">
                    Patient Type
                  </Form.Label>
                  <Form.Select
                    className="grid-form-control-select"
                    placeholder="Select Patient Type"
                    onChange={(e) =>
                      this.setState({ patientType: e.target.value })
                    }
                  >
                    <option disabled selected value>
                      {" "}
                      -- Select Patient Type --{" "}
                    </option>
                    <option value="IN-PATIENT">IN-PATIENT</option>
                    <option value="OUT-PATIENT">OUT-PATIENT</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="patientMrn">
                    <Form.Label className="table-form-label mb-0">
                      Patient MRN
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Patient MRN"
                      value={patientMrn}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="accountNumber">
                    <Form.Label className="table-form-label mb-0">
                      Account Number
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Account Number"
                      value={accountNumber}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Col} controlId="clientName">
                    <Form.Label className="table-form-label mb-0">
                      Client Name
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control-select"
                      placeholder="Select Client"
                      onChange={(e) => {
                        this.selectClient(e);
                      }}
                    >
                      <option disabled selected value>
                        {" "}
                        -- Select Client --{" "}
                      </option>
                      {clients.map((client, key) => {
                        return (
                          <option value={key} key={key}>
                            {client.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="facility">
                    <Form.Label className="table-form-label mb-0">
                      Facility
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control-select"
                      placeholder="Select Facility"
                      onChange={(e) => {
                        this.selectFacility(e);
                      }}
                    >
                      <option disabled selected value>
                        {" "}
                        -- Select Facility --{" "}
                      </option>
                      {facilities.map((facility, key) => {
                        return (
                          <option value={key} key={key}>
                            {facility.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="refPhysician">
                    <Form.Label className="table-form-label mb-0">
                      Referring Physician
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control-select"
                      placeholder="Select Provider"
                      onChange={(e) => {
                        this.selectRefPhysician(e);
                      }}
                    >
                      <option disabled selected value>
                        {" "}
                        -- Select Provider --{" "}
                      </option>
                      {providers.map((provider, key) => {
                        return (
                          <option value={key} key={key}>
                            {provider.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="attPhysician">
                    <Form.Label className="table-form-label mb-0">
                      Attending Physician
                    </Form.Label>
                    <Form.Select
                      className="grid-form-control-select"
                      placeholder="Select Provider"
                      onChange={(e) => {
                        this.selectAttPhysician(e);
                      }}
                    >
                      <option disabled selected value>
                        {" "}
                        -- Select Provider --{" "}
                      </option>
                      {providers.map((provider, key) => {
                        return (
                          <option value={key} key={key}>
                            {provider.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Col} controlId="icdCodes">
                    <Form.Label className="table-form-label mb-0">
                      Diagnosis Codes
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Diagnosis Codes"
                      value={icdCodes}
                      onChange={(e) =>
                        this.setState({ icdCodes: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="cptCodes">
                    <Form.Label className="table-form-label mb-0">
                      Procedure Codes
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Procedure Codes"
                      value={cptCodes}
                      onChange={(e) =>
                        this.setState({ cptCodes: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="actualDate">
                    <Form.Label className="table-form-label mb-0">
                      Requested Date
                    </Form.Label>
                    <ReactDatePicker
                      className="date-picker"
                      calendarIconClassname="date-picker-icon"
                      placeholderText="MM/DD/YYYY"
                      showIcon
                      value={requestedDate}
                      onChange={(date) =>
                        this.setState({
                          requestedDate: formatDateToCustomISO(date),
                        })
                      }
                      selected={
                        this.state.requestedDate
                          ? formatDateToCustomISO(
                              moment(this.state.requestedDate).toDate()
                            )
                          : null
                      }
                      customInput={
                        <MaskedTextInput type="text" mask={MaskDateInput} />
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="scheduleDate">
                    <Form.Label className="table-form-label mb-0">
                      Schedule Date
                    </Form.Label>
                    <ReactDatePicker
                      className="date-picker"
                      calendarIconClassname="date-picker-icon"
                      placeholderText="MM/DD/YYYY"
                      showIcon
                      value={scheduleDate}
                      onChange={(date) =>
                        this.setState({
                          scheduleDate: formatDateToCustomISO(date),
                        })
                      }
                      selected={
                        this.state.scheduleDate
                          ? formatDateToCustomISO(
                              moment(this.state.scheduleDate).toDate()
                            )
                          : null
                      }
                      customInput={
                        <MaskedTextInput type="text" mask={MaskDateInput} />
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Col} controlId="payer">
                    <Form.Label className="table-form-label mb-0">
                      Payer
                    </Form.Label>
                    <Typeahead
                      labelKey="name"
                      filterBy={this.payerTypeaheadFilterBy}
                      id="payer-typeahead"
                      options={payerOptions}
                      placeholder="Select Payer"
                      renderMenu={this.payerMenu}
                      className="grid-form-control"
                      onChange={(e) =>
                        this.setState({
                          payerName: e[0].name,
                          payerId: e[0].id,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="payerAuthId">
                    <Form.Label className="table-form-label mb-0">
                      Payer Authorization ID
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Payer Authorization ID"
                      value={payerAuthorizationId}
                      onChange={(e) =>
                        this.setState({ payerAuthorizationId: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="authStatus">
                    <Form.Label className="table-form-label mb-0">
                      Status
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Status"
                      value={preAuthStatus}
                      onChange={(e) =>
                        this.setState({ preAuthStatus: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="invoiceStatus">
                    <Form.Label className="table-form-label mb-0">
                      Invoice
                    </Form.Label>
                    <Form.Control
                      className="grid-form-control"
                      placeholder="Invoice"
                      value={invoiceStatus}
                      onChange={(e) =>
                        this.setState({ invoiceStatus: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Col} controlId="requestedUnits">
                    <Form.Label className="table-form-label mb-0">
                      Requested Unit
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="grid-form-control"
                      placeholder="Requested Unit"
                      value={requestedUnit}
                      onChange={(e) =>
                        this.setState({ requestedUnit: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="approvedUnits">
                    <Form.Label className="table-form-label mb-0">
                      Approved Unit
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="grid-form-control"
                      placeholder="Approved Unit"
                      value={approvedUnit}
                      onChange={(e) =>
                        this.setState({ approvedUnit: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Col} controlId="completedUnits">
                    <Form.Label className="table-form-label mb-0">
                      Completed Unit
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="grid-form-control"
                      placeholder="Completed Unit"
                      value={completedUnit}
                      onChange={(e) =>
                        this.setState({ completedUnit: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <div className="d-flex justify-content-center mt-5">
                <Button
                  className="btn btn-primary grid-btn"
                  variant="primary"
                  type="button"
                  onClick={this.saveAuth.bind(this)}
                >
                  Save
                </Button>
                <Button
                  className="btn btn-primary grid-btn"
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    this.props.navigate("/dashboard");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    addUpdateAuthorization: bindActionCreators(
      addUpdateAuthorization,
      dispatch
    ),
    getClients: bindActionCreators(getClientsByOrgId, dispatch),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    getProvider: bindActionCreators(getProvider, dispatch),
    getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
    user: state.currentUser.data,
    organization: state.getOrganization.data,
    clients: state.getClientsByOrgId.data,
    facilities: state.getFacilities.data,
    providers: state.getProvider.data,
    payers: state.getPayersAll.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(AddAuthorization));
