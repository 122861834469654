import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationNoteApiActionConstants from "./AddUpdateAuthorizationNoteApiActionConstants"
import {getAddUpdateAuthorizationNoteUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationNote(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationNoteUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_REQUEST,
                AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_SUCCESS,
                AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_FAILED,
            ]
        },
    }
}