import * as GetPatientDocumentationApiActionConstants from '../../redux/actions/api/documentation/GetPatientDocumentationApiActionConstants'

const initialState = {
    data: []
};

export default function getPatientDocumentationReducer(state = initialState, action) {
    switch (action.type) {
        case GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_REQUEST:
            return {
                ...state,
            };
        case GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_CLEAR:
            return {
                ...state,
                data: 'CLEARED'
            };
        default:
            return state
    }
}
