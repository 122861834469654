import * as GetAuthorizationNoteApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationNoteApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationNoteReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_FAILED:
            return {
                ...state,
                data: []
            };
        case GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
