import { CALL_API } from '../../../middleware/api'
import * as GetGuarantorPatientsApiActionConstants from "./GetGuarantorPatientsApiActionConstants"
import {getGuarantorPatientsUriPath} from '../EndpointResolver'

export function getGuarantorPatients(parentId) {
    return {
        [CALL_API]: {
            endpoint: getGuarantorPatientsUriPath(parentId),
            config: { method: 'get' },
            types: [
                GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_REQUEST,
                GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_SUCCESS,
                GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_FAILED,
            ],
        },
    }
}

export function guarantorPatientsClear() {
     return function (dispatch) {
         dispatch({
             type: GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_CLEAR
         })
     }
}


