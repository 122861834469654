import moment from "moment";

export function formatDateToCustomISO(date) {
  if (!date) return;

  const formattedDate = date.toISOString().split("T")[0];
  const timezone = moment(date).format("Z");
  const customISODate = `${formattedDate}T00:00:00.000${timezone}`;

  return new Date(customISODate);
}
