import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationDiagnosisApiActionConstants from "./AddUpdateAuthorizationDiagnosisApiActionConstants"
import {getAddUpdateAuthorizationDiagnosisUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationDiagnosis(requestParams = {}, ...restParams) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationDiagnosisUriPath(restParams[0], restParams[1]),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationDiagnosisApiActionConstants.ADD_UPDATE_AUTHORIZATION_DIAGNOSIS_REQUEST,
                AddUpdateAuthorizationDiagnosisApiActionConstants.ADD_UPDATE_AUTHORIZATION_DIAGNOSIS_SUCCESS,
                AddUpdateAuthorizationDiagnosisApiActionConstants.ADD_UPDATE_AUTHORIZATION_DIAGNOSIS_FAILED,
            ],
            ...restParams
        },
    }
}