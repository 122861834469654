import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationByIdApiActionConstants from "./GetAuthorizationByIdApiActionConstants"
import { getAuthorizationByIdUriPath } from '../EndpointResolver'

export function getAuthorizationById(authId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationByIdUriPath(authId),
            config: { method: 'get' },
            types: [
                GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_REQUEST,
                GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_SUCCESS,
                GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_FAILED,
            ],
        },
    }
}

export function authorizationByIdSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_SELECTED,
             val: val
         })
     }
}