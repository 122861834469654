import * as GetAuthorizationDetailApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationDetailApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationDetailReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_FAILED:
            return {
                ...state,
                data: []
            };
        case GetAuthorizationDetailApiActionConstants.GET_AUTHORIZATION_DETAIL_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
