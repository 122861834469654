import { CALL_API } from '../../../middleware/api'
import * as GetClientApiActionConstants from "./GetClientApiActionConstants"
import {getClientUriPath} from '../EndpointResolver'

export function getClient(clientId) {
    return {
        [CALL_API]: {
            endpoint: getClientUriPath(clientId),
            config: { method: 'get' },
            types: [
                GetClientApiActionConstants.GET_CLIENT_REQUEST,
                GetClientApiActionConstants.GET_CLIENT_SUCCESS,
                GetClientApiActionConstants.GET_CLIENT_FAILED,
            ],
        },
    }
}
