import * as AddUpdateEmployeeApiActionConstants from '../../redux/actions/api/employee/AddUpdateEmployeeApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateEmployeeReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_REQUEST:
            return {
                ...state,
            };
        case AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
