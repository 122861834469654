import * as AddUpdateAuthorizationDetailApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationDetailApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationDetailReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorizationDetailApiActionConstants.ADD_UPDATE_AUTHORIZATION_DETAIL_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
