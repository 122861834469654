import * as DocumentationDownloadFileApiActionConstants from '../actions/api/documentation/DocumentationDownloadFileApiActionConstants';
const initialState = {
    data:{}
};

export default function documentationDownloadFile(state = initialState, action) {
    switch (action.type) {
        case DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
            };
        case DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_SUCCESS:
            const filetype = localStorage.getItem("tickler_sent_attachment_filetype");
            const file = new Blob(
                [action.data],
                {type: filetype});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {

                window.navigator.msSaveOrOpenBlob(file, localStorage.getItem("tickler_sent_attachment_filename"));

            }
            else {
                //window.location = fileURL;
            }

            return {
                ...state,
                data:action.data
            };
        case DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_FAILED:


            return {
                ...state,
                data: {}
            };
        case DocumentationDownloadFileApiActionConstants.DOCUMENTATION_DOWNLOAD_FILE_CLEAR:
            return {
                ...state,
                data: {}
            };
        default:
            return state
    }
}
