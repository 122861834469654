import { CALL_API } from '../../../middleware/api'
import * as AddUpdatePatientApiActionConstants from "./AddUpdatePatientApiActionConstants"
import {getAddUpdatePatientUriPath} from '../EndpointResolver'

export function addUpdatePatient(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdatePatientUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_REQUEST,
                AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_SUCCESS,
                AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_FAILED,
            ]
        },
    }
}

export function addUpdatePatientSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_SELECTED,
             val: val
         })
     }
}