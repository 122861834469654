import { CALL_API } from '../../../middleware/api'
import * as GetSystemsCodeByActionConstants from "./GetSystemsCodeApiActionConstants"
import {getSystemsCodeUriPath} from '../EndpointResolver'

export function getSystemsCode() {
    return {
        [CALL_API]: {
            endpoint: getSystemsCodeUriPath(),
            config: { method: 'get' },
            types: [
                GetSystemsCodeByActionConstants.GET_SYSTEMS_CODE_REQUEST,
                GetSystemsCodeByActionConstants.GET_SYSTEMS_CODE_SUCCESS,
                GetSystemsCodeByActionConstants.GET_SYSTEMS_CODE_FAILED,
            ],
        },
    }
}