import { CALL_API } from '../../../middleware/api'
import * as GetServiceTypesApiActionConstants from "./GetServiceTypesApiActionConstants"
import {getServiceTypesUriPath} from '../EndpointResolver'

export function getServiceTypes() {
    return {
        [CALL_API]: {
            endpoint: getServiceTypesUriPath(),
            config: { method: 'get' },
            types: [
                GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_REQUEST,
                GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_SUCCESS,
                GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_FAILED,
            ],
        },
    }
}

export function serviceTypesClear() {
     return function (dispatch) {
         dispatch({
             type: GetServiceTypesApiActionConstants.GET_SERVICE_TYPES_CLEAR
         })
     }
}
