import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationDocumentationApiActionConstants from "./GetAuthorizationDocumentationApiActionConstants"
import {getAuthorizationDocumentationUriPath} from '../EndpointResolver'

export function getAuthorizationDocumentation(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationDocumentationUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_REQUEST,
                GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_SUCCESS,
                GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_FAILED,
            ],
        },
    }
}


export function authorizationDocumentationClear() {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_CLEAR
         })
     }
}