import * as AddUpdateAuthorizationApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdateAuthorizationApiActionConstants.ADD_UPDATE_AUTHORIZATION_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
