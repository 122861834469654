import * as AddPayerApiActionConstants from '../../redux/actions/api/payer/AddPayerApiActionConstants'

const initialState = {
    data: '',
    selectedPayer: null
};

export default function addUpdatePayerReducer(state = initialState, action) {
    switch (action.type) {
        case AddPayerApiActionConstants.ADD_UPDATE_PAYER_REQUEST:
            return {
                ...state,
            };
        case AddPayerApiActionConstants.ADD_UPDATE_PAYER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
            break;
        }
        case AddPayerApiActionConstants.ADD_UPDATE_PAYER_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddPayerApiActionConstants.ADD_UPDATE_PAYER_SELECTED:
            return {
                ...state,
                selectedPayer: action.val
            };
        case AddPayerApiActionConstants.ADD_UPDATE_PAYER_SELECTED_CLEAR:
            return {
                ...state,
                selectedPayer: null
            };
        default:
            return state
    }
}
