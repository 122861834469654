import { CALL_API } from '../../../middleware/api'
import * as GetPatientDocumentationApiActionConstants from "./GetPatientDocumentationApiActionConstants"
import {getPatientDocumentationUriPath} from '../EndpointResolver'

export function getPatientDocumentation(patientId) {
    return {
        [CALL_API]: {
            endpoint: getPatientDocumentationUriPath(patientId),
            config: { method: 'get' },
            types: [
                GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_REQUEST,
                GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_SUCCESS,
                GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_FAILED,
            ],
        },
    }
}


export function patientDocumentationClear() {
     return function (dispatch) {
         dispatch({
             type: GetPatientDocumentationApiActionConstants.GET_PATIENT_DOCUMENTATION_CLEAR
         })
     }
}