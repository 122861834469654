import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationProviderApiActionConstants from "./AddUpdateAuthorizationProviderApiActionConstants"
import {getAddUpdateAuthorizationRequestingProviderUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationProvider(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateAuthorizationRequestingProviderUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_REQUEST,
                AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_SUCCESS,
                AddUpdateAuthorizationProviderApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROVIDER_FAILED,
            ]
        },
    }
}