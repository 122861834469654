import * as AddUpdateProviderApiActionConstants from '../../redux/actions/api/provider/AddUpdateProviderApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateProviderReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_REQUEST:
            return {
                ...state,
            };
        case AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
