import * as GetAuthorizationDiagnosisApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationDiagnosisApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationDiagnosisReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_FAILED:
            return {
                ...state,
                data: []
            };
        case GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
