import { CALL_API } from "../../../middleware/api";
import * as GetCorrespondenceApiActionConstants from "./GetCorrespondenceApiActionConstants";
import { getCorrespondenceByClientIdUriPath } from "../EndpointResolver";

export function getCorrespondence(clientId) {
  return {
    [CALL_API]: {
      endpoint: getCorrespondenceByClientIdUriPath(clientId),
      config: { method: "get" },
      types: [
        GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_REQUEST,
        GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_SUCCESS,
        GetCorrespondenceApiActionConstants.GET_CORRESPONDENCE_FAILED,
      ],
    },
  };
}
