import * as GetPatientEncounterApiActionConstants from '../../redux/actions/api/encounter/GetPatientEncounterApiActionConstants'

const initialState = {
    data: []
};

export default function getPatientEncounterReducer(state = initialState, action) {
    switch (action.type) {
        case GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_REQUEST:
            return {
                ...state,
            };
        case GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPatientEncounterApiActionConstants.GET_PATIENT_ENCOUNTER_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
