import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import "../../App.css";
import "./Notes.css";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import MaskedTextInput from "react-text-mask";
import { formatDateToCustomISO } from "../common/UtilsDate";
import { bindActionCreators } from "redux";
import { addUpdateNote } from "../../redux/actions/api/client/AddUpdateNoteApiActions";
import { getNotes } from "../../redux/actions/api/client/GetNotesApiActions";

const mockCLientNotes = [
  {
    notes: "test notes 1",
    userName: "Victor Morilla",
    date: moment(formatDateToCustomISO(moment(new Date()).toDate())).format(
      "MM/DD/YYYY"
    ),
  },
  {
    notes: "test notes 2",
    userName: "Jonathan Caday",
    date: moment(formatDateToCustomISO(moment(new Date()).toDate())).format(
      "MM/DD/YYYY"
    ),
  },
];

const defaultNotesData = {
  notes: "",
  userName: "",
  date: "",
};

const Notes = (props) => {
  const [notesListData, setNotesListData] = useState(props.notesList);
  const [selectedNote, setSelectedNote] = useState({});
  const [notesFieldError, setNotesFieldError] = useState("");
  const [isSaveNoteDisabled, setIsSaveNoteDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await props.getNotes(props.addedClient.id);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addedClient]);

  useEffect(() => {
    setNotesListData(props.notesList);
  }, [props.notesList]);

  const MaskDateInput = [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const clearFieldErrors = () => {
    setNotesFieldError("");
  };

  const handleOnClickRow = (record, e) => {
    clearFieldErrors();
    setSelectedNote(record);

    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
  };

  const handleOnClickSaveNote = async () => {
    setIsSaveNoteDisabled(true);

    let hasFieldError;

    if (!selectedNote.notes) {
      setNotesFieldError("Notes is required.");
      hasFieldError = true;
    }

    if (hasFieldError) {
      return;
    }

    //todo: save notes
    await props.addUpdateNote({
      clientId: props.addedClient.id,
      notes: selectedNote.notes,
      userName: props.user.userId,
    });
    await props.getNotes(props.addedClient.id);

    if (selectedNote.isNew == "Y") {
      setSelectedNote(defaultNotesData);
    }

    clearFieldErrors();
    setIsSaveNoteDisabled(false);
  };

  const renderNotesForm = () => {
    return (
      <Form>
        <Row className="mb-1">
          <div class="col-lg-3 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Note Date
              </Form.Label>
              <ReactDatePicker
                className="date-picker"
                calendarIconClassname="date-picker-icon"
                placeholderText="MM/DD/YYYY"
                showIcon
                value={moment(formatDateToCustomISO(moment(new Date()))).format(
                  "MM/DD/YYYY"
                )}
                onChange={(date) => {
                  setSelectedNote({
                    ...selectedNote,
                    date: formatDateToCustomISO(date),
                  });
                }}
                selected={
                  selectedNote.date
                    ? formatDateToCustomISO(moment(selectedNote.date).toDate())
                    : null
                }
                customInput={
                  <MaskedTextInput type="text" mask={MaskDateInput} />
                }
                disabled
              />
            </Form.Group>
            {/* <span className="table-form-label-err mb-0">
              {noteFieldErrors.notesFieldError}
            </span> */}
          </div>
          <div class="col-lg-3 col-6">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                User Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="User Name"
                value={props.user.userId}
                disabled
              />
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div class="col-lg-12">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">Notes</Form.Label>
              <Form.Control
                className="grid-form-control textarea"
                placeholder="Notes"
                as="textarea"
                value={selectedNote.notes}
                onChange={(e) => {
                  setSelectedNote({
                    ...selectedNote,
                    notes: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <span className="table-form-label-err mb-0">{notesFieldError}</span>
          </div>
        </Row>
        <Row>
          <div>
            <Button
              className="form-save-btn btn-primary mt-1 float-end"
              type="button"
              disabled={isSaveNoteDisabled}
              onClick={handleOnClickSaveNote}
            >
              <img
                src={require("../../images/save-btn-icon.png")}
                className="add-icon"
                alt=""
              />
              Save
            </Button>
          </div>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <div class="form-title-label">
        <label class="border-bottom">Notes</label>
      </div>
      <Table bordered hover className="table-portal">
        <thead>
          <tr>
            <th className="td-first"></th>
            <th>Note Date</th>
            <th>Notes</th>
            <th className="td-last">User Name</th>
          </tr>
        </thead>
        <tbody>
          {notesListData.map((record, key) => {
            return (
              <>
                <tr
                  onClick={(event) =>
                    handleOnClickRow(
                      {
                        ...record,
                        isNew: "N",
                      },
                      event
                    )
                  }
                >
                  <td className="td-first">
                    <i className="fa fa-chevron-down"></i>
                  </td>
                  <td>
                    {moment(
                      formatDateToCustomISO(moment(record.notesDate))
                    ).format("MM/DD/YYYY")}
                  </td>
                  <td>{record.notes}</td>
                  <td>{record.userName}</td>
                </tr>
                <tr className="main-table collapse">
                  <td className="td-first td-last" colSpan="7">
                    {renderNotesForm()}
                  </td>
                </tr>
              </>
            );
          })}
          <tr
            onClick={(event) =>
              handleOnClickRow(
                {
                  ...defaultNotesData,
                  isNew: "Y",
                },
                event
              )
            }
          >
            <td className="td-first">
              <i className="fa fa-chevron-down"></i>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="main-table collapse">
            <td className="td-first td-last" colSpan="7">
              {renderNotesForm()}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotes: bindActionCreators(getNotes, dispatch),
    addUpdateNote: bindActionCreators(addUpdateNote, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.currentUser.data,
    notesList: state.getNotes.data,
    addedClient: state.addUpdateClient.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
