import { CALL_API } from '../../../middleware/api'
import * as AddUpdateProviderApiActionConstants from "./AddUpdateProviderApiActionConstants"
import {getAddUpdateProviderUriPath} from '../EndpointResolver'

export function addUpdateProvider(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateProviderUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_REQUEST,
                AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_SUCCESS,
                AddUpdateProviderApiActionConstants.ADD_UPDATE_PROVIDER_FAILED,
            ]
        },
    }
}