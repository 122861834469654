import * as AddUpdatePatientApiActionConstants from '../../redux/actions/api/patient/AddUpdatePatientApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdatePatientReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_REQUEST:
            return {
                ...state,
            };
        case AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_FAILED:
            return {
                ...state,
                data: ''
            };
        case AddUpdatePatientApiActionConstants.ADD_UPDATE_PATIENT_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
