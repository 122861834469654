import { CALL_API } from '../../../middleware/api'
import * as GetGuarantorAddressApiActionConstants from "./GetGuarantorAddressApiActionConstants"
import {getGuarantorAddressUriPath} from '../EndpointResolver'

export function getGuarantorAddress(guarantorId) {
    return {
        [CALL_API]: {
            endpoint: getGuarantorAddressUriPath(guarantorId),
            config: { method: 'get' },
            types: [
                GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_REQUEST,
                GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_SUCCESS,
                GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_FAILED,
            ],
        },
    }
}

export function guarantorAddressClear() {
     return function (dispatch) {
         dispatch({
             type: GetGuarantorAddressApiActionConstants.GET_GUARANTOR_ADDRESS_CLEAR,
         })
     }
}
