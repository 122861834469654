import * as AddUpdateNoteApiActionConstants from "./../actions/api/client/AddUpdateNoteApiActionConstants";

const initialState = {
  data: "",
};

export default function addUpdateNoteReducer(state = initialState, action) {
  switch (action.type) {
    case AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_REQUEST:
      return {
        ...state,
      };
    case AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case AddUpdateNoteApiActionConstants.ADD_UPDATE_NOTE_FAILED:
      return {
        ...state,
        data: "",
      };
    default:
      return state;
  }
}
