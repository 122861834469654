import { CALL_API } from '../../../middleware/api'
import * as GetMedicalReqApiActionConstants from "./GetMedicalReqApiActionConstants"
import { getMedicalReqUriPath } from '../EndpointResolver'

export function getMedicalReq(requestParams = {}) {

  return {
      [CALL_API]: {
          endpoint: getMedicalReqUriPath(),
          config: {
              data: requestParams,
              method: 'post',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: requestParams },
          types: [
              GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_REQUEST,
              GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_SUCCESS,
              GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_FAILED,
          ]
      },
  }
}

export function medicalReqClear() {
     return function (dispatch) {
         dispatch({
             type: GetMedicalReqApiActionConstants.GET_MEDICAL_REQ_CLEAR
         })
     }
}