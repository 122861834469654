import { BASE_URL } from "../../middleware/api";

const API_PREFIX = `${BASE_URL}/api`;
const URI_SEPARATOR = "/";

export const LOGIN = `${BASE_URL}/api/login`;
export const USER = `${BASE_URL}/api/users`;
export const ORGANIZATION = `${BASE_URL}/api/organization`;
export const CLIENT = `${BASE_URL}/api/client`;
export const CLIENT_NOTES = `${BASE_URL}/api/client/clientbusinessrules`;
export const EMPLOYEE = `${BASE_URL}/api/employee`;
export const GUARANTORPATIENTS = `${BASE_URL}/api/guarantorpatients`;
export const AUTHORIZATION = `${BASE_URL}/api/authorization`;
export const PROVIDER = `${BASE_URL}/api/provider`;
export const PAYER = `${BASE_URL}/api/payer`;
export const CLIENTEMPLOYEES = `${BASE_URL}/api/employee`;
export const DOCUMENTATION = `${BASE_URL}/api/documentation`;
export const ENCOUNTER = `${BASE_URL}/api/encounter`;
export const PHONE_EMAIL = `${BASE_URL}/api/phoneemail`;
export const SYSTEMS_CODE = `${BASE_URL}/api/systemscode`;
export const SERVICE_TYPE = `${BASE_URL}/api/servicetype`;
export const CORRESPONDENCE = `${BASE_URL}/api/correspondence`;
export const FAX = `${BASE_URL}/api/fax`;

export function getCignaFaxUriPath(clientId, authId) {
  return FAX + "/cigna?clientId=" + clientId + "&authorizationId=" + authId;
}

export function getLoginUriPath() {
  return LOGIN;
}

export function getEmployeeUriPath(userId) {
  return USER + "?userId=" + userId;
}

export function getGuarantorPatientPatientsSearchDuplicateUriPath(
  id,
  orgId,
  clientId,
  facilityId,
  type,
  mrn,
  ssn,
  lastName,
  firstName,
  dob
) {
  return (
    GUARANTORPATIENTS +
    "/searchduplicate?id=" +
    id +
    "&orgId=" +
    orgId +
    "&clientId=" +
    clientId +
    "&facilityId=" +
    facilityId +
    "&type=" +
    type +
    "&mrn=" +
    mrn +
    "&ssn=" +
    ssn +
    "&lastName=" +
    lastName +
    "&firstName=" +
    firstName +
    "&dob=" +
    dob
  );
}

export function getGuarantorPatientsSearchUriPath(
  mrn,
  accountNumber,
  lastName,
  firstName,
  dob,
  type
) {
  let uriPathCompare = GUARANTORPATIENTS + "/search";
  let uriPath = GUARANTORPATIENTS + "/search";
  if (mrn && mrn !== "") {
    uriPath = uriPath + "?mrn=" + mrn;
  }
  if (accountNumber && accountNumber !== "")
    uriPath =
      uriPath +
      addAndOrQM(uriPath, uriPathCompare) +
      "accountNumber=" +
      accountNumber;
  if (lastName && lastName !== "")
    uriPath =
      uriPath + addAndOrQM(uriPath, uriPathCompare) + "lastName=" + lastName;
  if (firstName && firstName !== "")
    uriPath =
      uriPath + addAndOrQM(uriPath, uriPathCompare) + "firstName=" + firstName;
  if (dob && dob !== "")
    uriPath = uriPath + addAndOrQM(uriPath, uriPathCompare) + "dob=" + dob;
  if (type && type !== "")
    uriPath = uriPath + addAndOrQM(uriPath, uriPathCompare) + "type=" + type;
  return uriPath;
}

function addAndOrQM(uri, uriCompare) {
  if (uri == uriCompare) return "?";
  return "&";
}

export function getDocumentationUploadFileUriPath() {
  return [API_PREFIX, "documentation"].join(URI_SEPARATOR) + "/file/uploadFile";
}

export function getDocumentationDownloadFileUriPath(filename, clientName) {
  return (
    DOCUMENTATION +
    "/file/downloadFile/" +
    filename +
    "?clientName=" +
    clientName
  );
}

export function getMedicalNecessityUriPath(authId, procedureCodes) {
  return (
    ENCOUNTER +
    "/medicalnecessity?authId=" +
    authId +
    "&procedureCodes=" +
    procedureCodes
  );
}

export function getMedicalReqUriPath() {
  return AUTHORIZATION + "/documentationrequirement";
}

export function getAddUpdatePatientUriPath() {
  return GUARANTORPATIENTS + "/createupdategp";
}

export function getAddUpdatePhoneEmailUriPath() {
  return GUARANTORPATIENTS + "/createupdatephoneemail";
}

export function getAddUpdateAddressUriPath() {
  return GUARANTORPATIENTS + "/createupdateaddress";
}

export function getAddUpdateInsuranceUriPath() {
  return GUARANTORPATIENTS + "/createupdateinsurance";
}

export function getPatientPhoneEmailUriPath(parentId) {
  return GUARANTORPATIENTS + "/phoneemail?parentId=" + parentId;
}

export function getGuarantorPhoneEmailUriPath(guarantorId) {
  return GUARANTORPATIENTS + "/guaphoneemail?guarantorId=" + guarantorId;
}

export function getPatientAddressUriPath(parentId) {
  return GUARANTORPATIENTS + "/address?parentId=" + parentId;
}

export function getGuarantorAddressUriPath(guarantorId) {
  return GUARANTORPATIENTS + "/guaaddress?guarantorId=" + guarantorId;
}

export function getPatientInsuranceUriPath(patientId) {
  return GUARANTORPATIENTS + "/insurance?patientId=" + patientId;
}

export function getAddUpdateAuthorizationUriPath() {
  return AUTHORIZATION + "/createupdate";
}

export function getAuth278UriPath() {
  return AUTHORIZATION + "/278check";
}

export function getAuthInsEligibilityUriPath() {
  return AUTHORIZATION + "/eligibilitycheck";
}

export function getAuthorizationEligibilityUriPath(authId) {
  return AUTHORIZATION + "/geteligibilitycheck?authId=" + authId;
}

export function getAuthorization278UriPath(authId) {
  return AUTHORIZATION + "/get278check?authId=" + authId;
}

export function getAddUpdateAuthorizationNoteUriPath() {
  return AUTHORIZATION + "/authnote/create";
}

export function getAddUpdateAuthorizationRequestingProviderUriPath() {
  return AUTHORIZATION + "/authprovider/create";
}

export function getAddUpdateAuthorizationDiagnosisUriPath(clientId) {
  return AUTHORIZATION + "/authdiagnosis/create?clientId=" + clientId;
}

export function getAddUpdateAuthorizationProcedureUriPath(clientId) {
  return AUTHORIZATION + "/authprocedure/create?clientId=" + clientId;
}

export function getEncounterDTOUriPath() {
  return AUTHORIZATION + "/dashboard/all";
}

export function getCreateAuthDtoUriPath() {
  return AUTHORIZATION + "/authdto/create";
}

export function scrapeAllIcdUriPath() {
  return AUTHORIZATION + "/scrapeallicd";
}

export function getAuthorizationUriPath(orgId) {
  return AUTHORIZATION + "/findbyorgid?orgId=" + orgId;
}

export function getAuthorizationDuplicatesUriPath(
  clientId,
  type,
  id,
  orderNumber
) {
  return (
    AUTHORIZATION +
    "/authCheckDuplicates?clientId=" +
    clientId +
    "&type=" +
    type +
    "&id=" +
    id +
    "&orderNumber=" +
    orderNumber
  );
}

export function getCptDescriptionUriPath(clientId, cptCode) {
  return (
    AUTHORIZATION +
    "/cpt/description?clientId=" +
    clientId +
    "&cptCode=" +
    cptCode
  );
}

export function getAuthorizationByIdUriPath(authId) {
  return AUTHORIZATION + "/auth/findbyid?authId=" + authId;
}

export function getAuthorizationDetailUriPath(parentId) {
  return (
    AUTHORIZATION +
    "/authdto/findbyparentid?parentId=" +
    parentId +
    "&type=ORDER_DETAIL"
  );
}

export function validateIcdCptUriPath(clientId, authId) {
  return (
    AUTHORIZATION + "/icdcptvalidate?clientId=" + clientId + "&authId=" + authId
  );
}

export function getAuthorizationNoteUriPath(authorizationId) {
  return (
    AUTHORIZATION + "/authnote/findbyauthid?authorizationId=" + authorizationId
  );
}

export function getAuthorizationProviderUriPath(authorizationId) {
  return (
    AUTHORIZATION +
    "/authprovider/findbyauthid?authorizationId=" +
    authorizationId
  );
}

export function getAuthorizationDiagnosisUriPath(authorizationId) {
  return (
    AUTHORIZATION +
    "/authdiagnosis/findbyauthid?authorizationId=" +
    authorizationId
  );
}

export function getAuthorizationProcedureUriPath(authorizationId) {
  return (
    AUTHORIZATION +
    "/authprocedure/findbyauthid?authorizationId=" +
    authorizationId
  );
}

export function getAuthorizationHistoryUriPath(authorizationId) {
  return (
    AUTHORIZATION +
    "/authhistory/findbyauthid?authorizationId=" +
    authorizationId
  );
}

export function getUserUriPath(userId) {
  return EMPLOYEE + "/findbyuserid?userId=" + userId;
}

export function getEmployeeByOrgIdUriPath(orgId) {
  return EMPLOYEE + "/findbyorgid?orgId=" + orgId;
}

export function getEmployeeByClientIdUriPath(clientId) {
  return EMPLOYEE + "/findbyclientid?clientId=" + clientId;
}

export function getEmployeeByFacilityIdUriPath(facilityId) {
  return EMPLOYEE + "/findbyfacilityid?facilityId=" + facilityId;
}

export function getOrganizationUriPath(orgId) {
  return ORGANIZATION + "/findbyid?orgId=" + orgId;
}

export function getAddUpdateOrganizationUriPath() {
  return ORGANIZATION + "/createupdate";
}

export function getAllOrganizationUriPath() {
  return ORGANIZATION + "/findall";
}

export function getClientsByOrgIdUriPath(orgId) {
  return CLIENT + "/findbyorgid?orgId=" + orgId;
}

export function getFacilitiesUriPath(clientId) {
  return CLIENT + "/facility/findbyclientid?clientId=" + clientId;
}

export function getAddUpdateFacilityUriPath() {
  return CLIENT + "/facility/createupdate";
}

export function getPatientGuarantorUriPath(id) {
  return GUARANTORPATIENTS + "/findbyid?id=" + id;
}

export function getGuarantorPatientsUriPath(parentId) {
  return GUARANTORPATIENTS + "/findbyparentid?parentId=" + parentId;
}

export function getAddUpdateClientUriPath() {
  return CLIENT + "/createupdate";
}

export function getClientsUriPath() {
  return CLIENT + "/findall";
}

export function getClientUriPath(clientId) {
  return CLIENT + "/facility/findbyclientid?clientId=" + clientId;
}

export function getAddUpdateProviderUriPath() {
  return PROVIDER + "/createupdate";
}

export function getAddUpdatePayerUriPath() {
  return PAYER + "/createupdate";
}

export function getPayersUriPath() {
  return PAYER + "/findall";
}

export function getPayerDtoUriPath(id) {
  return AUTHORIZATION + "/payer/payerDto?id=" + id;
}

export function getProviderUriPath(clientId) {
  return PROVIDER + "/findbyclientId?clientId=" + clientId;
}

export function getAddUpdateEmployeeUriPath() {
  return CLIENTEMPLOYEES + "/createupdate";
}

export function getClientEmployeesUriPath(parentId) {
  return CLIENTEMPLOYEES + "/findbyparentid?parentId=" + parentId;
}

export function getAddUpdateDocumentationUriPath() {
  return DOCUMENTATION + "/createupdate";
}

export function getPatientDocumentationUriPath(patientId) {
  return DOCUMENTATION + "/findbypatientid?patientId=" + patientId;
}

export function getAuthorizationDocumentationUriPath(authorizationId) {
  return (
    DOCUMENTATION + "/findauthdocbyauthid?authorizationId=" + authorizationId
  );
}

export function getAddUpdateAuthorizationDocumentationByStatusUriPath(
  clientId,
  patientId,
  authorizationId,
  documentationId,
  status
) {
  return (
    DOCUMENTATION +
    "/updateCreateAuthDocByStatus?clientId=" +
    clientId +
    "&patientId=" +
    patientId +
    "&authorizationId=" +
    authorizationId +
    "&documentationId=" +
    documentationId +
    "&status=" +
    status
  );
}

export function getPhoneEmailCommonUriPath(parentId, parentType, guaId) {
  if (guaId && guaId != "") {
    return (
      PHONE_EMAIL +
      "/findallcustom?parentId=" +
      parentId +
      "&parentType=" +
      parentType +
      "&guarantorId=" +
      guaId
    );
  }
  return (
    PHONE_EMAIL +
    "/findallcustom?parentId=" +
    parentId +
    "&parentType=" +
    parentType
  );
}

export function getAddUpdateTransactionTypeUriPath() {
  return PAYER + "/transactiontype/createupdate";
}

export function getTransactionTypeUriPath(payerId, trxType) {
  return (
    PAYER +
    "/transactiontype/findbypayerid?payerId=" +
    payerId +
    "&transactionType=" +
    trxType
  );
}

export function getTransactionTypeListUriPath(payerId) {
  return PAYER + "/transactiontype/findlistbypayerid?payerId=" + payerId;
}

export function getAddUpdateEncounterUriPath() {
  return ENCOUNTER + "/createupdate";
}

export function getPatientEncounterUriPath(patientId) {
  return ENCOUNTER + "/findencdtobypatientid?patientId=" + patientId;
}

export function getSystemsCodeUriPath() {
  return SYSTEMS_CODE + "/all";
}

export function getServiceTypesUriPath() {
  console.log("getServiceTypesUriPath");
  return SERVICE_TYPE + "/all";
}

export function validateIcd10cmCodeUriPath(code) {
  return AUTHORIZATION + "/icd10cm/validate?diagnosisCode=" + code;
}

export function getCorrespondenceByClientIdUriPath(clientId) {
  return CORRESPONDENCE + "/findbyclientid?clientId=" + clientId;
}

export function getAddUpdateCorrespondenceUriPath() {
  return CORRESPONDENCE + "/createupdate";
}

export function getCorrespondenceUploadFileUriPath() {
  return (
    [API_PREFIX, "correspondence"].join(URI_SEPARATOR) + "/file/uploadFile"
  );
}

export function getCorrespondenceDownloadFileUriPath(filename, clientName) {
  return (
    CORRESPONDENCE +
    "/file/downloadFile/" +
    filename +
    "?clientName=" +
    clientName
  );
}

export function getCorrespondenceSearchUriPath(
  title,
  clientId,
  patientId,
  authorizationId,
  payerId
) {
  let uriPathCompare = CORRESPONDENCE + "/filter";
  let uriPath = CORRESPONDENCE + "/filter";
  if (title && title !== "") {
    uriPath = uriPath + "?title=" + title;
  }
  if (clientId && clientId !== "")
    uriPath =
      uriPath + addAndOrQM(uriPath, uriPathCompare) + "clientId=" + clientId;
  if (patientId && patientId !== "")
    uriPath =
      uriPath + addAndOrQM(uriPath, uriPathCompare) + "patientId=" + patientId;
  if (authorizationId && authorizationId !== "")
    uriPath =
      uriPath +
      addAndOrQM(uriPath, uriPathCompare) +
      "authorizationId=" +
      authorizationId;
  if (payerId && payerId !== "")
    uriPath =
      uriPath + addAndOrQM(uriPath, uriPathCompare) + "payerId=" + payerId;
  return uriPath;
}

export function getNotesByClientIdUriPath(clientId) {
  return CLIENT_NOTES + "/findbyclientid?clientId=" + clientId;
}

export function getAddUpdateNoteUriPath() {
  return CLIENT_NOTES + "/createupdate";
}
