import { CALL_API } from '../../../middleware/api'
import * as AddUpdateEmployeeApiActionConstants from "./AddUpdateEmployeeApiActionConstants"
import {getAddUpdateEmployeeUriPath} from '../EndpointResolver'

export function addUpdateEmployee(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateEmployeeUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_REQUEST,
                AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_SUCCESS,
                AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_FAILED,
            ]
        },
    }
}

export function addUpdateEmployeeSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateEmployeeApiActionConstants.ADD_UPDATE_EMPLOYEE_SELECTED,
             val: val
         })
     }
}