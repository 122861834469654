import * as CreateAuthDTOApiActionConstants from "./CreateAuthDTOApiActionConstants";
import {CALL_API} from "../../../middleware/api";
import {getCreateAuthDtoUriPath} from "../EndpointResolver";

export function createAuthDto(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getCreateAuthDtoUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_REQUEST,
                CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_SUCCESS,
                CreateAuthDTOApiActionConstants.CREATE_AUTH_DTO_FAILED,
            ]
        },
    }
}

export function setAuthDto(val) {
    return function (dispatch) {
        dispatch({
            type: CreateAuthDTOApiActionConstants.AUTH_DTO_SET_VALUE,
            val: val
        })
    }
}

export function authDtoClear() {
    return function (dispatch) {
        dispatch({
            type: CreateAuthDTOApiActionConstants.AUTH_DTO_VALUE_CLEAR
        })
    }
}