import { CALL_API } from '../../../middleware/api'
import * as GetEmployeeByClientIdApiActionConstants from "./GetEmployeeByClientIdApiActionConstants"
import { getEmployeeByClientIdUriPath } from '../EndpointResolver'

export default function getEmployeeByClientId(clientId) {
    return {
        [CALL_API]: {
            endpoint: getEmployeeByClientIdUriPath(clientId),
            config: { method: 'get' },
            types: [
                GetEmployeeByClientIdApiActionConstants.GET_EMPLOYEE_BY_CLIENT_ID_REQUEST,
                GetEmployeeByClientIdApiActionConstants.GET_EMPLOYEE_BY_CLIENT_ID_SUCCESS,
                GetEmployeeByClientIdApiActionConstants.GET_EMPLOYEE_BY_CLIENT_ID_FAILED,
            ],
        },
    }
}