import { CALL_API } from '../../../middleware/api'
import * as AuthenticationActionConstants from "./AuthenticationApiActionConstants"
import { getLoginUriPath } from '../EndpointResolver'

export default function authenticate(requestParams = {}) {
    return {
        [CALL_API]: {
            endpoint: getLoginUriPath(),
            config: { data: requestParams, method: 'post' },
            types: [
                AuthenticationActionConstants.AUTHENTICATE_REQUEST,
                AuthenticationActionConstants.AUTHENTICATE_SUCCESS,
                AuthenticationActionConstants.AUTHENTICATE_FAILED,
            ],
        },
    }
}

export function logout() {
    return {
        type: AuthenticationActionConstants.LOG_OUT
    }
}
