import { CALL_API } from '../../../middleware/api'
import * as GetClientsApiActionConstants from "./GetClientsApiActionConstants"
import { getClientsUriPath } from '../EndpointResolver'

export function getClients() {
    return {
        [CALL_API]: {
            endpoint: getClientsUriPath(),
            config: { method: 'get' },
            types: [
                GetClientsApiActionConstants.GET_CLIENTS_REQUEST,
                GetClientsApiActionConstants.GET_CLIENTS_SUCCESS,
                GetClientsApiActionConstants.GET_CLIENTS_FAILED,
            ],
        },
    }
}
