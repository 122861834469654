import React, { useState, Component } from "react";
import "./PatientInfo.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";
import getAllOrganization from "../../../redux/actions/api/organization/GetAllOrganizationApiActions";
import { getClients } from "../../../redux/actions/api/client/GetClientsApiActions";
import { getPatientGuarantor } from "../../../redux/actions/api/guarantor/AddUpdateGuarantorApiActions";
import { WithRouter } from "../../common/WithRouter";
import { formatDateToCustomISO } from "../../common/UtilsDate";

class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.props.getAllOrganization();
    await this.props.getClients();
  }

  patientView = (parentId) => {
    console.log("HI");
    console.log("parentId" + parentId);
    this.props.getPatientGuarantor(parentId);
    this.props.navigate("/accounts/add-patients");
  };

  render() {
    var { organizationList, addedPatient, clientList } = this.props;

    const patientDOB = moment(
      formatDateToCustomISO(new Date(addedPatient.dob))
    ).format("MM/DD/YYYY");

    if (!clientList) {
      clientList = [];
    }

    var client = clientList.filter((a) => a.id == addedPatient.clientId)[0];
    console.log("addedPatient.clientId", addedPatient.clientId);
    var organization = {};
    if (client) {
      if (!organizationList) {
        organizationList = [];
      }

      organization = organizationList.filter((a) => a.id == client.orgId)[0];
      if (!organization) {
        organization = {};
      }
    } else {
      client = {};
      organization = {};
    }
    console.log(organization);

    return (
      <div className="encounter-patient-info-cont">
        <div className="d-flex justify-content-between">
          <div className="row flex-column">
            <label className="patient-info-label">Client:</label>
            <label className="patient-sub-label">{client.name}</label>
          </div>
          <div className="row flex-column">
            <label className="patient-info-label">Patient ID:</label>
            <label className="patient-sub-label">{addedPatient.id}</label>
          </div>
          <div className="row flex-column">
            <label className="patient-info-label">Name:</label>
            <Button
              type="button"
              className="patient-view"
              onClick={() => {
                this.patientView(addedPatient.parentId);
              }}
            >
              {addedPatient.firstName} {addedPatient.lastName}
            </Button>
          </div>
          <div className="row flex-column">
            <label className="patient-info-label">MRN:</label>
            <label className="patient-sub-label">{addedPatient.mrn}</label>
          </div>
          <div className="row flex-column">
            <label className="patient-info-label">Date of Birth:</label>
            <label className="patient-sub-label">{patientDOB}</label>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
    getClients: bindActionCreators(getClients, dispatch),
    getPatientGuarantor: bindActionCreators(getPatientGuarantor, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    clientList: state.getClients.data,
    organizationList: state.getAllOrganization.data,
    addedPatient: state.addUpdatePatient.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(PatientInfo));
