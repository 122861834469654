import * as AddUpdateAuthorizationNoteApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationNoteApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationNoteReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationNoteApiActionConstants.ADD_UPDATE_AUTHORIZATION_NOTE_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
