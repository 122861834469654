import { CALL_API } from '../../../middleware/api'
import * as GetFacilitiesApiActionConstants from "./GetFacilitiesApiActionConstants"
import { getFacilitiesUriPath } from '../EndpointResolver'

export default function getFacilities(userId) {
    return {
        [CALL_API]: {
            endpoint: getFacilitiesUriPath(userId),
            config: { method: 'get' },
            types: [
                GetFacilitiesApiActionConstants.GET_FACILITY_REQUEST,
                GetFacilitiesApiActionConstants.GET_FACILITY_SUCCESS,
                GetFacilitiesApiActionConstants.GET_FACILITY_FAILED,
            ],
        },
    }
}

export function facilitiesClear() {
     return function (dispatch) {
         dispatch({
             type: GetFacilitiesApiActionConstants.GET_FACILITY_CLEAR
         })
     }
}