import * as GetPatientInsuranceApiActionConstants from '../../redux/actions/api/insurance/GetPatientInsuranceApiActionConstants'

const initialState = {
    data: []
};

export default function getInsuranceReducer(state = initialState, action) {
    switch (action.type) {
        case GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_REQUEST:
            return {
                ...state,
            };
        case GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPatientInsuranceApiActionConstants.GET_PATIENT_INSURANCE_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
