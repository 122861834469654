import { createStore, applyMiddleware, combineReducers, compose } from "redux";
//import { reducer as formReducer } from 'redux-form'
import ReduxThunk from "redux-thunk";
import api from "./middleware/api";
import authentication from "./reducers/AuthenticationReducer";
import menu from "./reducers/MenuReducer";
import guarantorPatientsSearch from "./reducers/GuarantorPatientsSearchReducer";
import guarantorSearch from "./reducers/GuarantorSearchReducer";
import patientsSearch from "./reducers/PatientsSearchReducer";
import addUpdateGuarantor from "./reducers/AddUpdateGuarantorReducer";
import addUpdatePatient from "./reducers/AddUpdatePatientReducer";
import addUpdatePhoneEmail from "./reducers/AddUpdatePhoneEmailReducer";
import addUpdateAddress from "./reducers/AddUpdateAddressReducer";
import addUpdateInsurance from "./reducers/AddUpdateInsuranceReducer";
import getGuarantorPatients from "./reducers/GetGuarantorPatientsReducer";
import getPatientPhoneEmail from "./reducers/GetPatientPhoneEmailReducer";
import getGuarantorPhoneEmail from "./reducers/GetGuarantorPhoneEmailReducer";
import getPatientAddress from "./reducers/GetPatientAddressReducer";
import getPatientDocumentation from "./reducers/GetPatientDocumentationReducer";
import getAuthorizationDocumentation from "./reducers/GetAuthorizationDocumentationReducer";
import getGuarantorAddress from "./reducers/GetGuarantorAddressReducer";
import getPatientInsurance from "./reducers/GetPatientInsuranceReducer";
import addUpdateAuthorization from "./reducers/AddUpdateAuthorizationReducer";
import addUpdateAuthorizationDetail from "./reducers/AddUpdateAuthorizationDetailReducer";
import addUpdateAuthorizationProvider from "./reducers/AddUpdateAuthorizationProviderReducer";
import addUpdateAuthorizationNote from "./reducers/AddUpdateAuthorizationNoteReducer";
import addUpdateAuthorizationDiagnosis from "./reducers/AddUpdateAuthorizationDiagnosisReducer";
import addUpdateAuthorizationProcedure from "./reducers/AddUpdateAuthorizationProcedureReducer";
import addUpdateAuthorizationRequestingProvider from "./reducers/AddUpdateAuthorizationRequestingProviderReducer";
import addUpdateAuthorizationEligibility from "./reducers/AddUpdateAuthorizationEligibilityReducer";
import addUpdateAuthorization278 from "./reducers/AddUpdateAuthorization278Reducer";
import addUpdateAuthorizationDocumentationByStatus from "./reducers/AddUpdateAuthorizationDocumentationByStatusReducer";
import currentUser from "./reducers/GetUserReducer";
import getOrganization from "./reducers/GetOrganizationReducer";
import getClients from "./reducers/GetClientsReducer";
import getFacilities from "./reducers/GetFacilitiesReducer";
import documentationDownloadFile from "./reducers/DocumentationDownloadFileReducer";
import medicalNecessity from "./reducers/MedicalNecessityReducer";
import addUpdateClient from "./reducers/AddUpdateClientReducer";
import addUpdateOrganization from "./reducers/AddUpdateOrganizationReducer";
import addUpdateProvider from "./reducers/AddUpdateProviderReducer";
import getProvider from "./reducers/GetProviderReducer";
import addUpdateEmployee from "./reducers/AddUpdateEmployeeReducer";
import getClientEmployees from "./reducers/GetClientEmployeesReducer";
import getClientsByOrgId from "./reducers/GetClientsByOrgIdReducer";
import getClient from "./reducers/GetClientReducer";
import getAuthorization from "./reducers/GetAuthorizationReducer";
import getAuthorizationDuplicates from "./reducers/GetAuthorizationDuplicatesReducer";
import getCptDescription from "./reducers/GetCptDescriptionReducer";
import getAuthorizationById from "./reducers/GetAuthorizationByIdReducer";
import getAuthorizationDetail from "./reducers/GetAuthorizationDetailReducer";
import getAuthorizationProvider from "./reducers/GetAuthorizationProviderReducer";
import getAuthorizationNote from "./reducers/GetAuthorizationNoteReducer";
import getAuthorizationDiagnosis from "./reducers/GetAuthorizationDiagnosisReducer";
import getAuthorizationProcedure from "./reducers/GetAuthorizationProcedureReducer";
import getAuthorizationHistory from "./reducers/GetAuthorizationHistoryReducer";
import addUpdatePayer from "./reducers/AddUpdatePayerReducer";
import getPayersAll from "./reducers/GetPayersReducer";
import getAllOrganization from "./reducers/GetAllOrganizationReducer";
import addUpdateFacility from "./reducers/AddUpdateFacilityReducer";
import getPhoneEmailCommon from "./reducers/GetPhoneEmailCommonReducer";
import addUpdateTransactionType from "./reducers/AddUpdateTransactionTypeReducer";
import getTransactionType from "./reducers/GetTransactionTypeReducer";
import getEmployeeByOrgId from "./reducers/GetEmployeeByOrgIdReducer";
import getEmployeeByClientId from "./reducers/GetEmployeeByClientIdReducer";
import addUpdateEncounter from "./reducers/AddUpdateEncounterReducer";
import getPatientEncounter from "./reducers/GetPatientEncounterReducer";
import guarantorPatientSearchDuplicate from "./reducers/GuarantorPatientSearchDuplicateReducer";
import getEmployeeByFacilityId from "./reducers/GetEmployeeByFacilityIdReducer";
import createAuthDto from "./reducers/CreateAuthDtoReducer";
import getSystemsCode from "./reducers/GetSystemsCodeReducer";
import getEncounterDTO from "./reducers/GetEncounterDTOReducer";
import getTransactionTypeList from "./reducers/GetTransactionTypeListReducer";
import getServiceTypes from "./reducers/GetServiceTypesReducer";
import validateIcd10cmCodeReducer from "./reducers/ValidateIcd10cmCodeReducer";
import getPayerDtoReducer from "./reducers/GetPayerDtoReducer";
import getMedicalReq from "./reducers/GetMedicalReqReducer";
import getCorrespondence from "./reducers/GetCorrespondenceReducer";
import addUpdateCorrespondence from "./reducers/AddUpdateCorrespondenceReducer";
import getFaxReducer from "./reducers/GetFaxReducer";
import correspondenceDownloadFile from "./reducers/CorrespondenceDownloadFileReducer";
import correspondenceSearch from "./reducers/CorrespondenceSearchReducer";
import getNotes from "./reducers/GetNotesReducer";
import addUpdateNote from "./reducers/AddUpdateNoteReducer";

const rootReducer = combineReducers({
  authentication,
  menu,
  currentUser,
  getOrganization,
  getClientsByOrgId,
  getFacilities,
  guarantorPatientsSearch,
  guarantorPatientSearchDuplicate,
  guarantorSearch,
  patientsSearch,
  getGuarantorPatients,
  documentationDownloadFile,
  medicalNecessity,
  addUpdateGuarantor,
  addUpdatePatient,
  addUpdatePhoneEmail,
  addUpdateInsurance,
  addUpdateAddress,
  addUpdateEncounter,
  getPatientPhoneEmail,
  getGuarantorPhoneEmail,
  getPatientAddress,
  getPatientEncounter,
  getGuarantorAddress,
  getPatientInsurance,
  addUpdateAuthorization,
  addUpdateAuthorizationDetail,
  addUpdateAuthorizationProvider,
  addUpdateAuthorizationNote,
  addUpdateAuthorizationDiagnosis,
  addUpdateAuthorizationProcedure,
  addUpdateAuthorizationRequestingProvider,
  addUpdateAuthorizationEligibility,
  addUpdateAuthorization278,
  addUpdateClient,
  addUpdateProvider,
  addUpdateOrganization,
  getProvider,
  addUpdateEmployee,
  getClientEmployees,
  getClients,
  getClient,
  getCptDescription,
  getAuthorization,
  getAuthorizationDuplicates,
  getAuthorizationById,
  getAuthorizationDetail,
  getAuthorizationProvider,
  getAuthorizationNote,
  getAuthorizationDiagnosis,
  getAuthorizationProcedure,
  getAuthorizationHistory,
  getPatientDocumentation,
  getAuthorizationDocumentation,
  addUpdateAuthorizationDocumentationByStatus,
  addUpdatePayer,
  getPayersAll,
  getAllOrganization,
  addUpdateFacility,
  getPhoneEmailCommon,
  addUpdateTransactionType,
  getTransactionType,
  getEmployeeByOrgId,
  getEmployeeByClientId,
  getEmployeeByFacilityId,
  createAuthDto,
  getSystemsCode,
  getEncounterDTO,
  getTransactionTypeList,
  getServiceTypes,
  validateIcd10cmCodeReducer,
  getPayerDtoReducer,
  getMedicalReq,
  getFaxReducer,
  getCorrespondence,
  addUpdateCorrespondence,
  correspondenceDownloadFile,
  correspondenceSearch,
  getNotes,
  addUpdateNote,
});

const doCreateStore = compose(applyMiddleware(ReduxThunk, api))(createStore);

export default function configureStore() {
  return doCreateStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  //return doCreateStore(rootReducer)
}
