import { CALL_API } from '../../../middleware/api'
import * as AddUpdateEncounterApiActionConstants from "./AddUpdateEncounterApiActionConstants"
import {getAddUpdateEncounterUriPath} from '../EndpointResolver'

export function addUpdateEncounter(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateEncounterUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_REQUEST,
                AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_SUCCESS,
                AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_FAILED,
            ]
        },
    }
}

export function addUpdateEncounterSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateEncounterApiActionConstants.ADD_UPDATE_ENCOUNTER_SELECTED,
             val: val
         })
     }
}