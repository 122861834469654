import * as GetPatientPhoneEmailApiActionConstants from '../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActionConstants'

const initialState = {
    data: []
};

export default function getPatientPhoneEmailReducer(state = initialState, action) {
    switch (action.type) {
        case GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_REQUEST:
            return {
                ...state,
            };
        case GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPatientPhoneEmailApiActionConstants.GET_PATIENT_PHONE_EMAIL_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
