import { CALL_API } from '../../../middleware/api'
import * as GetEncounterDTOApiActionConstants from "./GetEncounterDTOApiActionConstants"
import {getEncounterDTOUriPath} from '../EndpointResolver'

export function getEncounterDTO(requestParams = {}) {
    return {
        [CALL_API]: {
            endpoint: getEncounterDTOUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                GetEncounterDTOApiActionConstants.GET_ENCOUNTER_DTO_REQUEST,
                GetEncounterDTOApiActionConstants.GET_ENCOUNTER_DTO_SUCCESS,
                GetEncounterDTOApiActionConstants.GET_ENCOUNTER_DTO_FAILED,
            ],
        },
    }
}


export function encounterDTOClear() {
     return function (dispatch) {
         dispatch({
             type: GetEncounterDTOApiActionConstants.GET_ENCOUNTER_DTO_CLEAR
         })
     }
}