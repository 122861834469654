import { CALL_API } from '../../../middleware/api'
import * as GetProviderApiActionConstants from "./GetProviderApiActionConstants"
import {getProviderUriPath} from '../EndpointResolver'

export function getProvider(clientId) {
    return {
        [CALL_API]: {
            endpoint: getProviderUriPath(clientId),
            config: { method: 'get' },
            types: [
                GetProviderApiActionConstants.GET_PROVIDER_REQUEST,
                GetProviderApiActionConstants.GET_PROVIDER_SUCCESS,
                GetProviderApiActionConstants.GET_PROVIDER_FAILED,
            ],
        },
    }
}

export function providerClear() {
     return function (dispatch) {
         dispatch({
             type: GetProviderApiActionConstants.GET_PROVIDER_CLEAR
         })
     }
}
