import React, { useState, Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";




class ForgotPassword extends Component {

    render() {

        return(
            <div className="d-flex align-items-center justify-content-center viewport-bg">
                <div className="d-flex">
                    <div className="bg-white">
                        <h5 className="m-3 d-flex align-items-center justify-content-center">
                            <img alt="#" src={require("../../../images/AuthoFiLogo.jpg")} />
                        </h5>
                        <form className="m-5">
                            <h3 className="text-center">Forgot Password</h3>
                            <p>Please enter your username and email address to reset your password.</p>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" placeholder="Enter your username" />
                            </div>
                            <div className="form-group">
                                <label>Email address</label>
                                <input type="email" className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="m-3 d-flex align-items-center justify-content-center">
                                <button type="submit" className="btn btn-primary">Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)