import React from 'react';
import Login from './components/login/Login';
import Accounts from './components/accounts/Accounts';
import Dashboard from './components/dashboard/Dashboard';
import Documentation from './components/documentation/Documentation';
import Patients from './components/patients/Patients';
import Guarantor from './components/guarantor/Guarantor';
import Reports from './components/reports/Reports';
import Clients from './components/clients/Clients';
import RefreshClients from './components/clients/RefreshClients';
import Payer from './components/payer/Payer';
import Employee from './components/employee/Employee';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import AddAuthorization from "./components/dashboard/AddAuthorization";
import AddPayer from "./components/payer/AddPayer";
import Organization from './components/organization/Organization';
import Authorizations from './components/authorizations/Authorizations';
import Encounter from './components/encounter/Encounter';
import RefreshEncounter from './components/encounter/RefreshEncounter';
import OrderDetails from './components/encounter/orderDetails/OrderDetails';
import ForgotPassword from "./components/login/forgot-password/ForgotPassword";
import ChangePassword from "./components/common/change-password/ChangePassword";


export const PublicRoutes = () => (
    <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="*" element={<Navigate to="/login"/>}/>
    </Routes>
);

export const AuthenticatedRoutes = () => (
    <Routes>
        <Route path="/accounts*" element={<Accounts/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/dashboard/createauth" element={<AddAuthorization/>}/>
        <Route path="/patients" element={<Patients/>}/>
        <Route path="/guarantor" element={<Guarantor/>}/>
        <Route path="/organization" element={<Organization/>}/>
        <Route path="/clients" element={<Clients/>}/>
        <Route path="/refreshclients*" element={<RefreshClients/>}/>
        <Route path="/payer" element={<Payer/>}/>
        <Route path="/payer/createupdatepayer" element={<AddPayer/>}/>
        <Route path="/employee" element={<Employee/>}/>
        <Route path="/reports" element={<Reports/>}/>
        <Route path="/documentation" element={<Documentation/>}/>
        <Route path="/authorizations*" element={<Authorizations/>}/>
        <Route path="/encounter*" element={<Encounter/>}/>
        <Route path="/refreshencounter*" element={<RefreshEncounter/>}/>
        <Route path="/order-details" element={<OrderDetails/>}/>
        <Route path="/change-password" element={<ChangePassword/>}/>
        <Route path="*" element={<Navigate to="/dashboard"/>}/>


    </Routes>
);