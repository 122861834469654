import { CALL_API } from '../../../middleware/api'
import * as GetEmployeeByFacilityIdApiActionConstants from "./GetEmployeeByFacilityIdApiActionConstants"
import { getEmployeeByFacilityIdUriPath } from '../EndpointResolver'

export default function getEmployeeByFacilityId(facilityId) {
    return {
        [CALL_API]: {
            endpoint: getEmployeeByFacilityIdUriPath(facilityId),
            config: { method: 'get' },
            types: [
                GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_REQUEST,
                GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_SUCCESS,
                GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_FAILED,
            ],
        },
    }
}