import * as GetPhoneEmailCommonApiActionConstants from '../../redux/actions/api/phoneEmail/GetPhoneEmailCommonApiActionConstants'

const initialState = {
    data: []
};

export default function getPhoneEmailCommonReducer(state = initialState, action) {
    switch (action.type) {
        case GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_REQUEST:
            return {
                ...state,
            };
        case GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_FAILED:
            return {
                ...state,
                data: []
            };
        case GetPhoneEmailCommonApiActionConstants.GET_PHONE_EMAIL_COMMON_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
