import * as GuarantorPatientsSearchApiActionConstants from '../actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActionConstants'

const ID_TOKEN = "id_token"
const USER_NAME = "userId"

const initialState = {
    guarantorPatientsSearchResults: []
}

export default function guarantorPatientsSearch(state = initialState, action) {
    switch (action.type) {
        case GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_REQUEST:
            return {
                ...state
            }
        case GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_SUCCESS: {
            return {
                ...state,
                guarantorPatientsSearchResults: action.data
            }
        }
        case GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_FAILED:
            return {
                ...state
            }
        case GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_CLEAR:
            return {
                ...state,
                guarantorPatientsSearchResults: []
            };
        default:
            return state
    }
}
