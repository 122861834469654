import React, {Component} from "react";
import "../../App.css";
import {Form, Row, Table, Col, Button, Dropdown} from "react-bootstrap";
import {addUpdatePayer, selectedPayerClear, selectPayer} from "../../redux/actions/api/payer/AddPayerApiActions";
import {bindActionCreators} from "redux"
import {
    getTransactionType,
    selectTransactionType, transactionTypeClear
} from "../../redux/actions/api/transaction-type/GetTransactionTypeApiActions";
import {WithRouter} from "../common/WithRouter";
import {addUpdateTransactionType} from "../../redux/actions/api/transaction-type/AddUpdateTransactionTypeApiActions";
import {getSystemsCode} from "../../redux/actions/api/systemsCode/GetSystemsCodeApiActions";
import {connect} from "react-redux/es/index";
import {getTransactionTypeList} from "../../redux/actions/api/transaction-type/GetTransactionTypeListApiActions";

class TransactionType extends Component {

    planTypesList = [
        {id: 0, label: "COMMERCIAL", checked: false},
        {id: 1, label: "MEDICAID", checked: false},
        {id: 2, label: "MEDICARE", checked: false}
    ];

    state = {
        payer_id: null,
        id: 0,
        payerId: "",
        supportingDocumentationSubmissionType: "",
        authStatusSubmissionType: "",
        transactionType: "",
        validated: false,
        saving: false,
        transactionPhoneNumber: "",
        transactionFaxNumber: "",
        transactionEmail: "",
        priority1Type: "",
        priority1Url: "",
        priority1Username: "",
        priority1Password: "",
        priority1Folder: "",
        priority1Rpa: "N",
        priority2Type: "",
        priority2Url: "",
        priority2Username: "",
        priority2Password: "",
        priority2Folder: "",
        priority2Rpa: "N",
        priority3Type: "",
        priority3Url: "",
        priority3Username: "",
        priority3Password: "",
        priority3Folder: "",
        priority3Rpa: "N",
        priority4Type: "",
        priority4Url: "",
        priority4Username: "",
        priority4Password: "",
        priority4Folder: "",
        priority4Rpa: "N",
        checkIsAuthRequiredUrl: "",
        planTypes: "",
        serviceTypes: "",
        planTypesList: this.planTypesList,
        serviceCodes: [],
        priority5Type: "MANUAL",
        serviceTypeSelectAll: false
    };

    default = {
        id: null,
        payerId: "",
        supportingDocumentationSubmissionType: "",
        authStatusSubmissionType: "",
        transactionType: "",
        transactionPhoneNumber: "",
        transactionFaxNumber: "",
        transactionEmail: "",
        priority1Type: "",
        priority1Url: "",
        priority1Username: "",
        priority1Password: "",
        priority1Folder: "",
        priority1Rpa: "N",
        priority2Type: "",
        priority2Url: "",
        priority2Username: "",
        priority2Password: "",
        priority2Folder: "",
        priority2Rpa: "N",
        priority3Type: "",
        priority3Url: "",
        priority3Username: "",
        priority3Password: "",
        priority3Folder: "",
        priority3Rpa: "N",
        priority4Type: "",
        priority4Url: "",
        priority4Username: "",
        priority4Password: "",
        priority4Folder: "",
        priority4Rpa: "N",
        checkIsAuthRequiredUrl: "",
        planTypes: "",
        serviceTypes: "",
        priority5Type: "MANUAL",
        planTypesList: this.planTypesList,
    };

    clearTransactionFields = async () => {
        await this.setState({
            id: null,
            transactionType: "",
            authStatusSubmissionType: "",
            supportingDocumentationSubmissionType: "",
            transactionPhoneNumber: "",
            transactionFaxNumber: "",
            transactionEmail: "",
            priority1Type: "",
            priority1Url: "",
            priority1Username: "",
            priority1Password: "",
            priority1Folder: "",
            priority1Rpa: "N",
            priority2Type: "",
            priority2Url: "",
            priority2Username: "",
            priority2Password: "",
            priority2Folder: "",
            priority2Rpa: "N",
            priority3Type: "",
            priority3Url: "",
            priority3Username: "",
            priority3Password: "",
            priority3Folder: "",
            priority3Rpa: "N",
            priority4Type: "",
            priority4Url: "",
            priority4Username: "",
            priority4Password: "",
            priority4Folder: "",
            priority4Rpa: "N",
            checkIsAuthRequiredUrl: "",
            planTypes: "",
            serviceTypes: ""
        });
    };

    setTransactionTypeFields = (trx) => {
        for(let key in trx) {
            if(trx[key] == null && key != "priority1Rpa" && key != "priority2Rpa" && key != "priority3Rpa" && key != "priority4Rpa") {
                trx[key] = "";
            }
        }
        this.setState({
            id: trx.id?trx.id:null,
            transactionType: trx.transactionType,
            authStatusSubmissionType: trx.authStatusSubmissionType,
            supportingDocumentationSubmissionType: trx.supportingDocumentationSubmissionType,
            transactionPhoneNumber: trx.phoneNumber,
            transactionFaxNumber: trx.faxNumber,
            transactionEmail: trx.email,
            priority1Type: trx.priority1Type,
            priority1Url: trx.priority1Url,
            priority1Username: trx.priority1Username,
            priority1Password: trx.priority1Password,
            priority1Folder: trx.priority1Folder,
            priority1Rpa: trx.priority1Rpa == "Y",
            priority2Type: trx.priority2Type,
            priority2Url: trx.priority2Url,
            priority2Username: trx.priority2Username,
            priority2Password: trx.priority2Password,
            priority2Folder: trx.priority2Folder,
            priority2Rpa: trx.priority2Rpa == "Y",
            priority3Type: trx.priority3Type,
            priority3Url: trx.priority3Url,
            priority3Username: trx.priority3Username,
            priority3Password: trx.priority3Password,
            priority3Folder: trx.priority3Folder,
            priority3Rpa: trx.priority3Rpa == "Y",
            priority4Type: trx.priority4Type,
            priority4Url: trx.priority4Url,
            priority4Username: trx.priority4Username,
            priority4Password: trx.priority4Password,
            priority4Folder: trx.priority4Folder,
            priority4Rpa: trx.priority4Rpa == "Y",
            checkIsAuthRequiredUrl: trx.checkIsAuthRequiredUrl,
            priority5Type: "MANUAL",
            planTypes: trx.planTypes,
            serviceTypes: trx.serviceTypes
        });
    };

    priority1FolderOrRpa = (type) => {
        const { priority1Rpa, priority1Folder } = this.state;
        if(type == "PORTAL") {
            return (
                <Form.Group as={Col} controlId="" className="d-flex align-items-center">
                    <Form.Check
                        className="grid-form-control mt-1 mb-0"
                        type="checkbox"
                        label="RPA"
                        checked={priority1Rpa}
                        onChange={async (e) => {
                            await this.setState({priority1Rpa: e.target.checked});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        if(type == "EDI") {
            return (
                <Form.Group as={Col} controlId="">
                    <Form.Control
                        className="grid-form-control"
                        placeholder="Folder"
                        value={priority1Folder}
                        onChange={async (e) => {
                            await this.setState({priority1Folder: e.target.value});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        return "";
    }

    priority2FolderOrRpa = (type) => {
        const { priority2Rpa, priority2Folder } = this.state;
        if(type == "PORTAL") {
            return (
                <Form.Group as={Col} controlId="" className="d-flex align-items-center">
                    <Form.Check
                        className="grid-form-control mt-1 mb-0"
                        type="checkbox"
                        label="RPA"
                        checked={priority2Rpa}
                        onChange={async (e) => {
                            await this.setState({priority2Rpa: e.target.checked});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        if(type == "EDI") {
            return (
                <Form.Group as={Col} controlId="">
                    <Form.Control
                        className="grid-form-control"
                        placeholder="Folder"
                        value={priority2Folder}
                        onChange={async (e) => {
                            await this.setState({priority2Folder: e.target.value});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        return "";
    }

    priority3FolderOrRpa = (type) => {
        const { priority3Rpa, priority3Folder } = this.state;
        if(type == "PORTAL") {
            return (
                <Form.Group as={Col} controlId="" className="d-flex align-items-center">
                    <Form.Check
                        className="grid-form-control mt-1 mb-0"
                        type="checkbox"
                        label="RPA"
                        checked={priority3Rpa}
                        onChange={async (e) => {
                            await this.setState({priority3Rpa: e.target.checked});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        if(type == "EDI") {
            return (
                <Form.Group as={Col} controlId="">
                    <Form.Control
                        className="grid-form-control"
                        placeholder="Folder"
                        value={priority3Folder}
                        onChange={async (e) => {
                            await this.setState({priority3Folder: e.target.value});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        return "";
    }

    priority4FolderOrRpa = (type) => {
        const { priority4Rpa, priority4Folder } = this.state;
        if(type == "PORTAL") {
            return (
                <Form.Group as={Col} controlId="" className="d-flex align-items-center">
                    <Form.Check
                        className="grid-form-control mt-1 mb-0"
                        type="checkbox"
                        label="RPA"
                        checked={priority4Rpa}
                        onChange={async (e) => {
                            await this.setState({priority4Rpa: e.target.checked});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        if(type == "EDI") {
            return (
                <Form.Group as={Col} controlId="">
                    <Form.Control
                        className="grid-form-control"
                        placeholder="Folder"
                        value={priority4Folder}
                        onChange={async (e) => {
                            await this.setState({priority4Folder: e.target.value});
                            
                        }}
                    />
                </Form.Group>
            );
        }
        return "";
    };

    saveTransactionType = async (event) => {
        await this.setState({saving: true});
        const { authStatusSubmissionType, supportingDocumentationSubmissionType,  transactionType, payer_id,
            transactionPhoneNumber, transactionFaxNumber, transactionEmail, priority1Type, priority1Url, priority1Username,
            priority1Password, priority1Folder, priority1Rpa, priority2Type, priority2Url, priority2Username,
            priority2Password, priority2Folder, priority2Rpa, priority3Type, priority3Url, priority3Username,
            priority3Password, priority3Folder, priority3Rpa, priority4Type, priority4Url, priority4Username,
            priority4Password, priority4Folder, priority4Rpa, checkIsAuthRequiredUrl, planTypes, serviceTypes, id } = this.state;

        let trxType = {};

        if(this.props.selectedTransactionType) {
            trxType = this.props.selectedTransactionType;
        }

        if(!id) {
            trxType.id = null;
        } else {
            trxType.id = id;
        }

        trxType.payerId = payer_id;
        trxType.transactionType = transactionType;
        trxType.authStatusSubmissionType = authStatusSubmissionType;
        trxType.supportingDocumentationSubmissionType = supportingDocumentationSubmissionType;
        trxType.phoneNumber = transactionPhoneNumber;
        trxType.faxNumber = transactionFaxNumber;
        trxType.email = transactionEmail;
        trxType.priority1Type = priority1Type;
        trxType.priority1Url = priority1Url;
        trxType.priority1Username = priority1Username;
        trxType.priority1Password = priority1Password;
        trxType.priority1Folder = priority1Folder;
        trxType.priority1Rpa = priority1Rpa?"Y":"N";
        trxType.priority2Type = priority2Type;
        trxType.priority2Url = priority2Url;
        trxType.priority2Username = priority2Username;
        trxType.priority2Password = priority2Password
        trxType.priority2Folder = priority2Folder;
        trxType.priority2Rpa = priority2Rpa?"Y":"N";
        trxType.priority3Type = priority3Type;
        trxType.priority3Url = priority3Url;
        trxType.priority3Username = priority3Username;
        trxType.priority3Password = priority3Password;
        trxType.priority3Folder = priority3Folder;
        trxType.priority3Rpa = priority3Rpa?"Y":"N";
        trxType.priority4Type = priority4Type;
        trxType.priority4Url = priority4Url;
        trxType.priority4Username = priority4Username;
        trxType.priority4Password = priority4Password;
        trxType.priority4Folder = priority4Folder;
        trxType.priority4Rpa = priority4Rpa?"Y":"N";
        trxType.checkIsAuthRequiredUrl = checkIsAuthRequiredUrl;
        trxType.planTypes = planTypes;
        trxType.serviceTypes = serviceTypes;
        trxType.priority5Type = "MANUAL";

        try {
            await this.props.addUpdateTransactionType(trxType);
        } catch (e) {

        }
        await this.setState({saving: false});
    };

    onClickHandler(record, isNew, e) {
        if(isNew) {
            this.setState({...record})
        } else {
            this.setState({...record, id: record.id});
        }

        this.setTransactionTypeFields(record)

        let planTypesList = this.planTypesList;
        for(let type of planTypesList) type.checked = false;
        this.setState({planTypesList: planTypesList});
        let serviceCodes = this.props.serviceTypes;
        let key = 0;
        for(let code of serviceCodes) {
            code.key = key;
            code.checked = false;
            key += 1;
        }
        this.setState({serviceCodes: serviceCodes});
        if(record.planTypes) {
            let planTypes = this.planTypesList;
            for(let type of planTypes) {
                if(record.planTypes.includes(type.label)) {
                    type.checked = true;
                } else {
                    type.checked = false;
                }
            }
            this.setState({planTypesList: planTypes})
        }
        if(record.serviceTypes) {
            let serviceCodes = this.state.serviceCodes;
            for(let code of serviceCodes) {
                if(record.serviceTypes.includes(code.description)) {
                    code.checked = true;
                } else {
                    code.checked = false;
                }
            }
        }

        const elementsShow = document.querySelectorAll('tr.show');
        const hiddenElement = e.currentTarget.nextSibling;

        /*Reset all show details*/
        elementsShow.forEach((element) => {
            if(hiddenElement != element){
                element.classList.remove('show');
            }
        });

        /*Set selected row*/
        const selected = e.currentTarget;
        const elementsSelected = document.querySelectorAll('tr.selected-table-row');

        elementsSelected.forEach((element) => {
            if(selected != element){
                element.classList.remove('selected-table-row');
            }
        });

        selected.classList.add("selected-table-row");

        /*Reset all up arrow*/
        const elementsArrow = document.querySelectorAll('.fa-chevron-up');
        elementsArrow.forEach((element) => {
            element.classList.remove('fa-chevron-up');
            element.classList.add('fa-chevron-down');
        });

        /*set up and show details on selected row*/
        const arrowSelected = document.querySelectorAll('.selected-table-row .fa');

        if(hiddenElement.className.indexOf("collapse show") > -1){
            hiddenElement.classList.remove("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-up');
                element.classList.add('fa-chevron-down');
            });

        }else{
            hiddenElement.classList.add("show");

            arrowSelected.forEach((element) => {
                element.classList.remove('fa-chevron-down');
                element.classList.add('fa-chevron-up');
            });

        }
    };

    async componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data) {
            let data = this.props.data;
            if(data.payer_id) {
                await this.props.getTransactionTypeList(data.payer_id);
                this.setState({payer_id: data.payer_id})
            }
        }
    }

    async componentDidMount() {
        const data = this.props.data;
        if(data.payer_id) {
            await this.props.getTransactionTypeList(data.payer_id);
            await this.setState({payer_id: data.payer_id})
        }
        await this.props.getSystemsCode();
        let serviceCodes = this.props.serviceTypes;
        let key = 0;
        for(let code of serviceCodes) {
            code.key = key;
            code.checked = false;
            key += 1;
        }
        this.setState({serviceCodes: serviceCodes});
    };

    addDashes = (phone) => {
        let x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        phone = !x[2] ? x[1] :  + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
        return phone;
    }

    render() {
        const { supportingDocumentationSubmissionType, transactionType, authStatusSubmissionType, transactionPhoneNumber,
            transactionFaxNumber, transactionEmail, priority1Type, priority1Url, priority1Username,
            priority1Password, priority1Folder, priority1Rpa, priority2Type, priority2Url, priority2Username,
            priority2Password, priority2Folder, priority2Rpa, priority3Type, priority3Url, priority3Username,
            priority3Password, priority3Folder, priority3Rpa, priority4Type, priority4Url, priority4Username,
            priority4Password, priority4Folder, priority4Rpa, checkIsAuthRequiredUrl, priority5Type, serviceTypeSelectAll } = this.state;

        return(
            <Table bordered className="table-main" hidden={!this.state.payer_id}>
                <thead>
                <tr>
                    <th className="td-first"></th>
                    <th>Transaction Type</th>
                    <th>Plan Types</th>
                    <th className="td-last">Service Types</th>
                </tr>
                </thead>
                <tbody>
                {this.props.transactionTypeList.map((record, key) => {
                    return (
                        <>
                            <tr key={key} onClick={(event)=> this.onClickHandler({...record}, false, event)}>
                                <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                                <td>{record.transactionType}</td>
                                <td>{record.planTypes}</td>
                                <td>{record.serviceTypes}</td>
                            </tr>
                            <tr key={key + 100000} className="sub-table collapse">
                                <td className="td-first td-last"colSpan="4">
                                    <Form className="p-1">
                                        <Row>
                                            <div className="col-md-6">
                                                <Form.Group as={Col} controlId="transactionType">
                                                    <Form.Label className="table-form-label mb-0">Transaction Type</Form.Label>
                                                    <Form.Select
                                                        required
                                                        className="grid-form-control"
                                                        placeholder=""
                                                        value={transactionType}
                                                        onChange={ async (e) => {
                                                            await this.setState({transactionType: e.target.value});
                                                        }}>
                                                        <option disabled selected value=""> -- Select Transaction Type -- </option>
                                                        <option value="HCSR DETAILED AUTH/REFERRAL (278)">HCSR DETAILED AUTH/REFERRAL (278)</option>
                                                        <option value="HCSR REFERRAL (278)">HCSR REFERRAL (278)</option>
                                                        <option value="HCSR INPATIENT AUTH (278)">HCSR INPATIENT AUTH (278)</option>
                                                        <option value="HCSR OUTPATIENT AUTH (278)">HCSR OUTPATIENT AUTH (278)</option>
                                                        <option value="ELIGIBILITY AND BENEFITS (270)">ELIGIBILITY AND BENEFITS (270)</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3">
                                                <Form.Group as={Col} controlId="transactionType">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="payer-dropdown">
                                                            Select Plan Types
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {this.state.planTypesList.map((planType, key) => {
                                                                return (
                                                                    <Form.Group className="pl-1 dropdown-item" controlId="check1">
                                                                        <Form.Check type="checkbox" id={key} name={planType.label} label={planType.label} checked={planType.checked} onChange={async (e) => {
                                                                            let planTypes = this.state.planTypesList;
                                                                            planTypes.find(i => i.id === key).checked = e.target.checked;
                                                                            let planTypesString = "";
                                                                            for(let planType of planTypes) {
                                                                                if(planType.checked) {
                                                                                    planTypesString += planType.label + ";"
                                                                                }
                                                                            }
                                                                            if(planTypesString != "") planTypesString = planTypesString.substring(0, planTypesString.length - 1);
                                                                            await this.setState({planTypesList: planTypes, planTypes: planTypesString});
                                                                        }}/>
                                                                    </Form.Group>
                                                                )
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-3">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="payer-dropdown">
                                                        Select Service Types
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="service-code">
                                                        <Form.Group className="pl-1 dropdown-item" controlId="selectAll">
                                                            <Form.Check type="checkbox" id="selectAll" name="selectAll" label="Select All" checked={serviceTypeSelectAll} onChange={async (e) => {
                                                                let codes = this.state.serviceCodes;
                                                                if(e.target.checked) {
                                                                    let serviceTypesString = "";
                                                                    for(let type of codes) {
                                                                        type.checked = true;
                                                                        serviceTypesString += type.code + " - " + type.description + ";"
                                                                    }
                                                                    if(serviceTypesString != "") serviceTypesString = serviceTypesString.substring(0, serviceTypesString.length - 1);
                                                                    await this.setState({serviceCodes: codes, serviceTypes: serviceTypesString, serviceTypeSelectAll: e.target.checked});
                                                                } else {
                                                                    for(let type of codes) {
                                                                        type.checked = false;
                                                                    }
                                                                    await this.setState({serviceCodes: codes, serviceTypes: "", serviceTypeSelectAll: e.target.checked});
                                                                }

                                                            }}/>
                                                        </Form.Group>
                                                        {this.state.serviceCodes.map((code, key) => {
                                                            return (
                                                                <Form.Group className="pl-1 dropdown-item" controlId="check2">
                                                                    <Form.Check type="checkbox" id={key} name={code.label} label={code.code + " - " + code.description} checked={code.checked} onChange={async (e) => {
                                                                        let codes = this.state.serviceCodes;
                                                                        codes.find(i => i.key === key).checked = e.target.checked;
                                                                        let serviceTypesString = "";
                                                                        for(let type of codes) {
                                                                            if(type.checked) serviceTypesString += type.code + " - " + type.description + ";"
                                                                        }
                                                                        if(serviceTypesString != "") serviceTypesString = serviceTypesString.substring(0, serviceTypesString.length - 1);
                                                                        let serviceTypeSelectAll = true;
                                                                        for(let type of codes) {
                                                                            if(!type.checked) serviceTypeSelectAll = false
                                                                        }
                                                                        await this.setState({serviceCodes: codes, serviceTypes: serviceTypesString, serviceTypeSelectAll: serviceTypeSelectAll});
                                                                    }}/>
                                                                </Form.Group>
                                                            )
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <Col>
                                                <div className="mt-3">
                                                    <h6 hidden={!this.state.saving}>Auto-Saving...</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col md={3}>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label className="table-form-label mb-0">Auth Status Submission
                                                        Type</Form.Label>
                                                    <Form.Select
                                                        className="grid-form-control"
                                                        placeholder=""
                                                        value={authStatusSubmissionType}
                                                        onChange={async (e) => {
                                                            await this.setState({authStatusSubmissionType: e.target.value});
                                                        }}>
                                                        <option disabled selected value=""> -- Select Submission Type --</option>
                                                        <option value="REST">REST</option>
                                                        <option value="SOAP">SOAP</option>
                                                        <option value="EDI">EDI</option>
                                                        <option value="PORTAL">PORTAL</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label className="table-form-label mb-0">Supporting
                                                        Doc. Submission Type</Form.Label>
                                                    <Form.Select
                                                        className="grid-form-control"
                                                        placeholder="Assumed RPA or API"
                                                        value={supportingDocumentationSubmissionType}
                                                        onChange={async (e) => {
                                                            await this.setState({supportingDocumentationSubmissionType: e.target.value});
                                                            
                                                        }}
                                                    >
                                                        <option disabled selected value=""> -- Select Submission Type -- </option>
                                                        <option value="REST">REST</option>
                                                        <option value="SOAP">SOAP</option>
                                                        <option value="EDI">EDI</option>
                                                        <option value="PORTAL">PORTAL</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label className="table-form-label mb-0">Phone Number</Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        maxLength={15}
                                                        className="grid-form-control"
                                                        placeholder=""
                                                        value={transactionPhoneNumber}
                                                        onChange={async (e) => {
                                                            let phone = this.addDashes(e.target.value)
                                                            await this.setState({transactionPhoneNumber: phone});
                                                            
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label className="table-form-label mb-0">Fax
                                                        Number</Form.Label>
                                                    <Form.Control
                                                        type="tel"
                                                        maxLength={15}
                                                        className="grid-form-control"
                                                        placeholder=""
                                                        value={transactionFaxNumber}
                                                        onChange={async (e) => {
                                                            let fax = this.addDashes(e.target.value)
                                                            await this.setState({transactionFaxNumber: fax});
                                                            
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label className="table-form-label mb-0">Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        maxLength={40}
                                                        className="grid-form-control"
                                                        placeholder=""
                                                        value={transactionEmail}
                                                        onChange={async (e) => {
                                                            await this.setState({transactionEmail: e.target.value});
                                                            
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className="sub-panel">
                                            <Row className="mt-2">
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0 mt-4">Priority 1</Form.Label>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label className="table-form-label mb-0">Type</Form.Label>
                                                        <Form.Select
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority1Type}
                                                            onChange={async (e) => {
                                                                await this.setState({priority1Type: e.target.value});

                                                            }}
                                                        >
                                                            <option disabled selected value="">Select</option>
                                                            <option value="REST">REST</option>
                                                            <option value="SOAP">SOAP</option>
                                                            <option value="EDI">EDI</option>
                                                            <option value="PORTAL">PORTAL</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label className="table-form-label mb-0">URL</Form.Label>
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority1Url}
                                                            onChange={async (e) => {
                                                                await this.setState({priority1Url: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label className="table-form-label mb-0">Username</Form.Label>
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority1Username}
                                                            onChange={async (e) => {
                                                                await this.setState({priority1Username: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label className="table-form-label mb-0">Password</Form.Label>
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority1Password}
                                                            onChange={async (e) => {
                                                                await this.setState({priority1Password: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0">Folder/RPA</Form.Label>
                                                    {this.priority1FolderOrRpa(priority1Type)}
                                                </div>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0">Priority 2</Form.Label>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Select
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority2Type}
                                                            onChange={async (e) => {
                                                                await this.setState({priority2Type: e.target.value});

                                                            }}
                                                        >
                                                            <option disabled selected value="">Select</option>
                                                            <option value="REST">REST</option>
                                                            <option value="SOAP">SOAP</option>
                                                            <option value="EDI">EDI</option>
                                                            <option value="PORTAL">PORTAL</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority2Url}
                                                            onChange={async (e) => {
                                                                await this.setState({priority2Url: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority2Username}
                                                            onChange={async (e) => {
                                                                await this.setState({priority2Username: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority2Password}
                                                            onChange={async (e) => {
                                                                await this.setState({priority2Password: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    {this.priority2FolderOrRpa(priority2Type)}
                                                </div>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0">Priority 3</Form.Label>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Select
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority3Type}
                                                            onChange={async (e) => {
                                                                await this.setState({priority3Type: e.target.value});

                                                            }}
                                                        >
                                                            <option disabled selected value="">Select</option>
                                                            <option value="REST">REST</option>
                                                            <option value="SOAP">SOAP</option>
                                                            <option value="EDI">EDI</option>
                                                            <option value="PORTAL">PORTAL</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority3Url}
                                                            onChange={async (e) => {
                                                                await this.setState({priority3Url: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority3Username}
                                                            onChange={async (e) => {
                                                                await this.setState({priority3Username: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority3Password}
                                                            onChange={async (e) => {
                                                                await this.setState({priority3Password: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    {this.priority3FolderOrRpa(priority3Type)}
                                                </div>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0">Priority 4</Form.Label>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Select
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority4Type}
                                                            onChange={async (e) => {
                                                                await this.setState({priority4Type: e.target.value});

                                                            }}
                                                        >
                                                            <option disabled selected value="">Select</option>
                                                            <option value="REST">REST</option>
                                                            <option value="SOAP">SOAP</option>
                                                            <option value="EDI">EDI</option>
                                                            <option value="PORTAL">PORTAL</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-5">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority4Url}
                                                            onChange={async (e) => {
                                                                await this.setState({priority4Url: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority4Username}
                                                            onChange={async (e) => {
                                                                await this.setState({priority4Username: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority4Password}
                                                            onChange={async (e) => {
                                                                await this.setState({priority4Password: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-1">
                                                    {this.priority4FolderOrRpa(priority4Type)}
                                                </div>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className="col-md-1">
                                                    <Form.Label className="table-form-label mb-0">Priority 5</Form.Label>
                                                </div>
                                                <div className="col-md-2">
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Select
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={priority5Type}
                                                            onChange={async (e) => {
                                                                await this.setState({priority5Type: e.target.value});

                                                            }}
                                                        >
                                                            <option selected value="MANUAL">MANUAL</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className="col-md-6 d-flex mb-2">
                                                    <Form.Label className="table-form-label auth-label">Check if Authorization is Required: </Form.Label>
                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            className="grid-form-control"
                                                            placeholder=""
                                                            value={checkIsAuthRequiredUrl}
                                                            onChange={async (e) => {
                                                                await this.setState({checkIsAuthRequiredUrl: e.target.value});

                                                            }}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Row>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <Button className="form-save-btn float-end" variant="primary" type="button" onClick={async (e) => {
                                                await this.saveTransactionType(e);
                                            }}>
                                                <img src={require("../../images/save-btn-icon.png")} className="add-icon"/>
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                </td>
                            </tr>
                        </>
                    )
                })}
                <tr onClick={(event)=> this.onClickHandler({...this.default}, true, event)}>
                    <td className="td-first"><i className="fa fa-chevron-down"></i></td>
                    <td></td>
                    <td></td>
                    <td className="td-last">
                    </td>
                </tr>
                <tr className="sub-table collapse">
                    <td className="td-first td-last" colSpan="4">
                        <Form className="p-1">
                            <Row>
                                <div className="col-md-6">
                                    <Form.Group as={Col} controlId="transactionType">
                                        <Form.Label className="table-form-label mb-0">Transaction Type</Form.Label>
                                        <Form.Select
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={transactionType}
                                            onChange={ async (e) => {
                                                await this.setState({transactionType: e.target.value});
                                            }}>
                                            <option disabled selected value=""> -- Select Transaction Type -- </option>
                                            <option value="HCSR DETAILED AUTH/REFERRAL (278)">HCSR DETAILED AUTH/REFERRAL (278)</option>
                                            <option value="HCSR REFERRAL (278)">HCSR REFERRAL (278)</option>
                                            <option value="HCSR INPATIENT AUTH (278)">HCSR INPATIENT AUTH (278)</option>
                                            <option value="HCSR OUTPATIENT AUTH (278)">HCSR OUTPATIENT AUTH (278)</option>
                                            <option value="ELIGIBILITY AND BENEFITS (270)">ELIGIBILITY AND BENEFITS (270)</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Form.Group as={Col} controlId="transactionType">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="payer-dropdown">
                                                Select Plan Types
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {this.state.planTypesList.map((planType, key) => {
                                                    return (
                                                        <Form.Group className="pl-1 dropdown-item" controlId="check1">
                                                            <Form.Check type="checkbox" id={key} name={planType.label} label={planType.label} checked={planType.checked} onChange={async (e) => {
                                                                let planTypes = this.state.planTypesList;
                                                                planTypes.find(i => i.id === key).checked = e.target.checked;
                                                                let planTypesString = "";
                                                                for(let planType of planTypes) {
                                                                    if(planType.checked) {
                                                                        planTypesString += planType.label + ";"
                                                                    }
                                                                }
                                                                if(planTypesString != "") planTypesString = planTypesString.substring(0, planTypesString.length - 1);
                                                                await this.setState({planTypesList: planTypes, planTypes: planTypesString});
                                                            }}/>
                                                        </Form.Group>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                </div>
                                <div className="col-md-3">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="payer-dropdown">
                                            Select Service Types
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="service-code">
                                            <Form.Group className="pl-1 dropdown-item" controlId="selectAll">
                                                <Form.Check type="checkbox" id="selectAll" name="selectAll" label="Select All" checked={serviceTypeSelectAll} onChange={async (e) => {
                                                    let codes = this.state.serviceCodes;
                                                    if(e.target.checked) {
                                                        let serviceTypesString = "";
                                                        for(let type of codes) {
                                                            type.checked = true;
                                                            serviceTypesString += type.code + " - " + type.description + ";"
                                                        }
                                                        if(serviceTypesString != "") serviceTypesString = serviceTypesString.substring(0, serviceTypesString.length - 1);
                                                        await this.setState({serviceCodes: codes, serviceTypes: serviceTypesString, serviceTypeSelectAll: e.target.checked});
                                                    } else {
                                                        for(let type of codes) {
                                                            type.checked = false;
                                                        }
                                                        await this.setState({serviceCodes: codes, serviceTypes: "", serviceTypeSelectAll: e.target.checked});
                                                    }
                                                }}/>
                                            </Form.Group>
                                            {this.state.serviceCodes.map((code, key) => {
                                                return (
                                                    <Form.Group className="pl-1 dropdown-item" controlId="check2">
                                                        <Form.Check type="checkbox" id={key} name={code.label} label={code.code + " - " + code.description} checked={code.checked} onChange={async (e) => {
                                                            let codes = this.state.serviceCodes;
                                                            codes.find(i => i.key === key).checked = e.target.checked;
                                                            let serviceTypesString = "";
                                                            for(let type of codes) {
                                                                if(type.checked) serviceTypesString += type.code + " - " + type.description + ";"
                                                            }
                                                            if(serviceTypesString != "") serviceTypesString = serviceTypesString.substring(0, serviceTypesString.length - 1);
                                                            let serviceTypeSelectAll = true;
                                                            for(let type of codes) {
                                                                if(!type.checked) serviceTypeSelectAll = false
                                                            }
                                                            await this.setState({serviceCodes: codes, serviceTypes: serviceTypesString, serviceTypeSelectAll: serviceTypeSelectAll});
                                                        }}/>
                                                    </Form.Group>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <Col>
                                    <div className="mt-3">
                                        <h6 hidden={!this.state.saving}>Auto-Saving...</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col md={3}>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Auth Status Submission
                                            Type</Form.Label>
                                        <Form.Select
                                            className="grid-form-control"
                                            placeholder=""
                                            value={authStatusSubmissionType}
                                            onChange={async (e) => {
                                                await this.setState({authStatusSubmissionType: e.target.value});
                                            }}>
                                            <option disabled selected value=""> -- Select Submission Type --</option>
                                            <option value="REST">REST</option>
                                            <option value="SOAP">SOAP</option>
                                            <option value="EDI">EDI</option>
                                            <option value="PORTAL">PORTAL</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Supporting
                                            Doc. Submission Type</Form.Label>
                                        <Form.Select
                                            className="grid-form-control"
                                            placeholder="Assumed RPA or API"
                                            value={supportingDocumentationSubmissionType}
                                            onChange={async (e) => {
                                                await this.setState({supportingDocumentationSubmissionType: e.target.value});

                                            }}
                                        >
                                            <option disabled selected value=""> -- Select Submission Type -- </option>
                                            <option value="REST">REST</option>
                                            <option value="SOAP">SOAP</option>
                                            <option value="EDI">EDI</option>
                                            <option value="PORTAL">PORTAL</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            maxLength={15}
                                            className="grid-form-control"
                                            placeholder=""
                                            value={transactionPhoneNumber}
                                            onChange={async (e) => {
                                                let phone = this.addDashes(e.target.value)
                                                await this.setState({transactionPhoneNumber: phone});

                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Fax
                                            Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            maxLength={15}
                                            className="grid-form-control"
                                            placeholder=""
                                            value={transactionFaxNumber}
                                            onChange={async (e) => {
                                                let fax = this.addDashes(e.target.value)
                                                await this.setState({transactionFaxNumber: fax});

                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            maxLength={40}
                                            className="grid-form-control"
                                            placeholder=""
                                            value={transactionEmail}
                                            onChange={async (e) => {
                                                await this.setState({transactionEmail: e.target.value});

                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="sub-panel">
                                <Row className="mt-2">
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0 mt-4">Priority 1</Form.Label>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">Type</Form.Label>
                                            <Form.Select
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority1Type}
                                                onChange={async (e) => {
                                                    await this.setState({priority1Type: e.target.value});

                                                }}
                                            >
                                                <option disabled selected value="">Select</option>
                                                <option value="REST">REST</option>
                                                <option value="SOAP">SOAP</option>
                                                <option value="EDI">EDI</option>
                                                <option value="PORTAL">PORTAL</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-5">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">URL</Form.Label>
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority1Url}
                                                onChange={async (e) => {
                                                    await this.setState({priority1Url: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">Username</Form.Label>
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority1Username}
                                                onChange={async (e) => {
                                                    await this.setState({priority1Username: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label className="table-form-label mb-0">Password</Form.Label>
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority1Password}
                                                onChange={async (e) => {
                                                    await this.setState({priority1Password: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0">Folder/RPA</Form.Label>
                                        {this.priority1FolderOrRpa(priority1Type)}
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0">Priority 2</Form.Label>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Select
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority2Type}
                                                onChange={async (e) => {
                                                    await this.setState({priority2Type: e.target.value});

                                                }}
                                            >
                                                <option disabled selected value="">Select</option>
                                                <option value="REST">REST</option>
                                                <option value="SOAP">SOAP</option>
                                                <option value="EDI">EDI</option>
                                                <option value="PORTAL">PORTAL</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-5">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority2Url}
                                                onChange={async (e) => {
                                                    await this.setState({priority2Url: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority2Username}
                                                onChange={async (e) => {
                                                    await this.setState({priority2Username: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority2Password}
                                                onChange={async (e) => {
                                                    await this.setState({priority2Password: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        {this.priority2FolderOrRpa(priority2Type)}
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0">Priority 3</Form.Label>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Select
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority3Type}
                                                onChange={async (e) => {
                                                    await this.setState({priority3Type: e.target.value});

                                                }}
                                            >
                                                <option disabled selected value="">Select</option>
                                                <option value="REST">REST</option>
                                                <option value="SOAP">SOAP</option>
                                                <option value="EDI">EDI</option>
                                                <option value="PORTAL">PORTAL</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-5">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority3Url}
                                                onChange={async (e) => {
                                                    await this.setState({priority3Url: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority3Username}
                                                onChange={async (e) => {
                                                    await this.setState({priority3Username: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority3Password}
                                                onChange={async (e) => {
                                                    await this.setState({priority3Password: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        {this.priority3FolderOrRpa(priority3Type)}
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0">Priority 4</Form.Label>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Select
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority4Type}
                                                onChange={async (e) => {
                                                    await this.setState({priority4Type: e.target.value});

                                                }}
                                            >
                                                <option disabled selected value="">Select</option>
                                                <option value="REST">REST</option>
                                                <option value="SOAP">SOAP</option>
                                                <option value="EDI">EDI</option>
                                                <option value="PORTAL">PORTAL</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-5">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority4Url}
                                                onChange={async (e) => {
                                                    await this.setState({priority4Url: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority4Username}
                                                onChange={async (e) => {
                                                    await this.setState({priority4Username: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority4Password}
                                                onChange={async (e) => {
                                                    await this.setState({priority4Password: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-1">
                                        {this.priority4FolderOrRpa(priority4Type)}
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="col-md-1">
                                        <Form.Label className="table-form-label mb-0">Priority 5</Form.Label>
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Select
                                                className="grid-form-control"
                                                placeholder=""
                                                value={priority5Type}
                                                onChange={async (e) => {
                                                    await this.setState({priority5Type: e.target.value});

                                                }}
                                            >
                                                <option selected value="MANUAL">MANUAL</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="col-md-6 d-flex mb-2">
                                        <Form.Label className="table-form-label auth-label">Check if Authorization is Required: </Form.Label>
                                        <Form.Group as={Col} controlId="">
                                            <Form.Control
                                                className="grid-form-control"
                                                placeholder=""
                                                value={checkIsAuthRequiredUrl}
                                                onChange={async (e) => {
                                                    await this.setState({checkIsAuthRequiredUrl: e.target.value});

                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </Row>
                            </div>
                            <div className="col-md-12 mt-2">
                                <Button className="form-save-btn float-end" variant="primary" type="button" onClick={async (e) => {
                                    await this.saveTransactionType(e);
                                    await this.clearTransactionFields();
                                }}>
                                    <img src={require("../../images/save-btn-icon.png")} className="add-icon"/>
                                    Add
                                </Button>
                            </div>
                        </Form>
                    </td>
                </tr>
                </tbody>
            </Table>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPayer: bindActionCreators(addUpdatePayer, dispatch),
        selectPayer: bindActionCreators(selectPayer, dispatch),
        selectedPayerClear: bindActionCreators(selectedPayerClear, dispatch),
        getTransactionType: bindActionCreators(getTransactionType, dispatch),
        selectTransactionType: bindActionCreators(selectTransactionType, dispatch),
        transactionTypeClear: bindActionCreators(transactionTypeClear, dispatch),
        addUpdateTransactionType: bindActionCreators(addUpdateTransactionType, dispatch),
        getSystemsCode: bindActionCreators(getSystemsCode, dispatch),
        getTransactionTypeList: bindActionCreators(getTransactionTypeList, dispatch)
    }
};

const mapStateToProps = state => {
    return {
        payer: state.addUpdatePayer.data,
        selectedPayer: state.addUpdatePayer.selectedPayer,
        selectedTransactionType: state.getTransactionType.selected,
        transactionType: state.getTransactionType.data,
        addedTransactionType: state.addUpdateTransactionType.data,
        serviceTypes: state.getSystemsCode.serviceTypes,
        transactionTypeList: state.getTransactionTypeList.data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(TransactionType))