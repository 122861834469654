import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationDiagnosisApiActionConstants from "./GetAuthorizationDiagnosisApiActionConstants"
import { getAuthorizationDiagnosisUriPath } from '../EndpointResolver'

export function getAuthorizationDiagnosis(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationDiagnosisUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_REQUEST,
                GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_SUCCESS,
                GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_FAILED,
            ],
        },
    }
}

export function getAuthorizationDiagnosisSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationDiagnosisApiActionConstants.GET_AUTHORIZATION_DIAGNOSIS_SELECTED,
             val: val
         })
     }
}