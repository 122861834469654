import React, { useState, Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {WithRouter} from "../WithRouter";
import "./ChangePassword.css";
import getUser from "../../../redux/actions/api/authentication/GetUserApiActions";
import {addUpdateEmployee} from "../../../redux/actions/api/employee/AddUpdateEmployeeApiActions";



class ChangePassword extends Component {
    state = {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
    }

    async componentDidMount() {
        let userId = sessionStorage.getItem("userId");
        await this.props.getUser(userId);
    }

    changePassword = () => {
        console.log(this.props.user);
        let user = this.props.user;
        const {oldPassword, newPassword, confirmPassword} = this.state;
        var re =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
        if(newPassword.match(re)){
            if(newPassword == confirmPassword){
                user.userPassword = newPassword;
                user.passwordChanged = new Date();
                this.props.addUpdateEmployee(user);
                alert("Password Successfully Changed")
            }
        }
        else {
            alert("Password did not satisfy the rules");
        }
    }

    render() {

        return(
            <div className="main-view">
                <div className="auth-content-header d-flex justify-content-between">
                    <div>
                        <h5>
                            <img alt="#" src={require("../../../images/AuthoFiLogo.jpg")}/>
                        </h5>
                    </div>
                </div>
                <div className="auth-content-main d-flex align-items-center justify-content-center">
                    <div className="auth-content-content p-2">
                        <div className="bg-white d-flex align-items-center justify-content-center">
                            <form className="m-5 change-password-div">
                                <h3 className="text-center fw-bolder">Change Password</h3>
                                <p>Please enter your old password and new password.</p>
                                <div className="form-group mb-2">
                                    <label>Old Password</label>
                                    <input maxLength={40} type="password" className="form-control" placeholder="Enter your old password" onChange={(e) => {this.setState({oldPassword: e.target.value})}} />
                                </div>
                                <div className="form-group mb-2">
                                    <label>New Password</label>
                                    <input maxLength={40} type="password" className="form-control" placeholder="Enter your new password" onChange={(e) => {this.setState({newPassword: e.target.value})}}/>
                                </div>
                                <div className="form-group mb-2">
                                    <label>Confirm New Password</label>
                                    <input maxLength={40} type="password" className="form-control" placeholder="Confirm your new password" onChange={(e) => {this.setState({confirmPassword: e.target.value})}}/>
                                </div>
                                <div className="m-3 d-flex align-items-center justify-content-center">
                                    <Button className="btn btn-primary" onClick={this.changePassword.bind(this)}>Change Password</Button>
                                </div>
                                <div className="mt-3">
                                    <p className="fw-bold">Rules for Changing Password</p>
                                    <p> 1) Password must be between 8 to 30 characters</p>
                                    <p> 2) Password must contain at least one uppercase, or capital letter</p>
                                    <p> 3) Password must contain at least one lowercase letter</p>
                                    <p> 4) Password must contain at least one number</p>
                                    <p> 5) Password must contain at least one special character ($, #, @, !, &, *, (,))</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: bindActionCreators(getUser, dispatch),
        addUpdateEmployee: bindActionCreators(addUpdateEmployee, dispatch)
    }
}

const mapStateToProps = state => {
    return {
        user: state.currentUser.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(ChangePassword))