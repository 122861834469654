import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import "../../App.css";
import "./Correspondence.css";
import ReactDatePicker from "react-datepicker";
import { formatDateToCustomISO } from "../common/UtilsDate";
import moment from "moment";
import MaskedTextInput from "react-text-mask";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import getClientsByOrgId from "../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getPayersFindAll from "../../redux/actions/api/payer/GetPayersApiActions";
import { getCorrespondence } from "../../redux/actions/api/correspondence/GetCorrespondenceApiActions";
import { addUpdateCorrespondence } from "../../redux/actions/api/correspondence/AddUpdateCorrespondenceApiActions";
import { getCorrespondenceUploadFileUriPath } from "../../redux/actions/api/EndpointResolver";
import axios from "axios";
import {
  correspondenceDownloadFileClear,
  getCorrespondenceDownloadFile,
} from "../../redux/actions/api/correspondence/CorrespondenceDownloadFileApiActions";
import { correspondenceSearchClear } from "../../redux/actions/api/correspondence/CorrespondenceSearchApiActions";

const mockCorrespondenceData = [];
const defaultCorrespondenceData = {
  date: null,
  title: "",
  notes: "",
  attachment: "",
  clientId: "",
  patientId: "",
  payerId: "",
  authorizationId: "",
  status: "",
};

const Correspondence = (props) => {
  const [correspondenceListData, setCorrespondenceListData] = useState(
    props.correspondenceList
  );
  const [selectedCorrespondence, setSelectedCorrespondence] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [correspondenceFieldErrors, setCorrespondenceFieldErrors] = useState({
    dateFieldError: "",
    titleFieldError: "",
    clientFieldError: "",
    patientFieldError: "",
    payerFieldError: "",
    authorizationIdFieldError: "",
    statusFieldError: "",
  });
  const [downloadedFileType, setDownloadedFileType] = useState();
  const [isSaveCorrespondenceDisabled, setIsSaveCorrespondenceDisabled] =
    useState(false);
  const [sortHeader, setSortHeader] = useState("");
  const [sortBy, setSortBy] = useState("DESC");

  useEffect(() => {
    const fetchData = async () => {
      //get correspondence by Client "Au Health"
      await props.getCorrespondence("2000000023");
      //get clients By Authofi Org
      await props.getClientsByOrgId("1000000002");
      //get all payers
      await props.getPayersFindAll();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCorrespondenceListData(props.correspondenceList);
  }, [props.correspondenceList]);

  useEffect(() => {
    if (props.correspondenceSearchResultList) {
      setCorrespondenceListData(props.correspondenceSearchResultList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.correspondenceSearchResultList]);

  useEffect(() => {
    if (downloadedFileType === "application/octet-stream") {
      // download etc files...
      const file = new Blob([props.correspondenceDownloadFile], {
        type: downloadedFileType,
      });

      const fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = selectedCorrespondence.attachment;
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(fileURL);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      return;
    }

    if (props.correspondenceDownloadFile instanceof Blob) {
      const file = new Blob([props.correspondenceDownloadFile], {
        type: downloadedFileType,
      });

      const fileURL = URL.createObjectURL(file);

      props.correspondenceDownloadFileClear();
      window.open(fileURL, "_blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.correspondenceDownloadFile]);

  useEffect(() => {
    let sortedCorrData = correspondenceListData;
    if (sortBy === "ASC") {
      // setSortBy("DESC");
    } else {
      // setSortBy("ASC");
    }
  }, [sortBy]);

  const clearFieldErrors = () => {
    setCorrespondenceFieldErrors({
      dateFieldError: "",
      titleFieldError: "",
      clientFieldError: "",
      patientFieldError: "",
      payerFieldError: "",
      authorizationIdFieldError: "",
    });
  };

  const toggleSortBy = () => {
    if (sortBy == "ASC") {
      setSortBy("DESC");
    } else {
      setSortBy("ASC");
    }
  };

  const handleClickDateHeader = () => {
    let sortedCorrData = correspondenceListData;

    sortedCorrData.sort(function (a, b) {
      if (sortBy == "ASC") {
        return (
          new Date(
            moment(b.date ? b.date : "01/01/0001").format("MM/DD/YYYY")
          ) -
          new Date(moment(a.date ? a.date : "01/01/0001").format("MM/DD/YYYY"))
        );
      } else {
        return (
          new Date(
            moment(a.date ? a.date : "01/01/0001").format("MM/DD/YYYY")
          ) -
          new Date(moment(b.date ? b.date : "01/01/0001").format("MM/DD/YYYY"))
        );
      }
    });

    setCorrespondenceListData(sortedCorrData);
    toggleSortBy();
  };

  const handleClickTitleHeader = () => {
    let sortedCorrData = correspondenceListData;

    sortedCorrData.sort(function (a, b) {
      const nameA = a.title != null ? a.title.toUpperCase() : ""; // ignore upper and lowercase
      const nameB = b.title != null ? b.title.toUpperCase() : ""; // ignore upper and lowercase

      if (nameA < nameB) {
        if (sortBy == "ASC") {
          return -1;
        } else {
          return 1;
        }
      }
      if (nameA > nameB) {
        if (sortBy == "ASC") {
          return 1;
        } else {
          return -1;
        }
      }

      // names must be equal
      return 0;
    });

    setCorrespondenceListData(sortedCorrData);
    toggleSortBy();
  };

  const handleOnClickRow = (record, e) => {
    clearFieldErrors();
    setSelectedCorrespondence(record);

    const elementsShow = document.querySelectorAll("tr.main-table");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
  };

  const uploadFile = async (file, fileName, clientName) => {
    //var axios = require('axios');
    var FormData = require("form-data");
    //var fs = require('fs');
    var data = new FormData();
    data.append("file", file);

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    var config = {
      method: "post",
      url:
        getCorrespondenceUploadFileUriPath() +
        "?name=" +
        fileName +
        "&clientName=" +
        clientName,
      headers: {
        Authorization: localStorage.getItem("id_token") || null,
        "Content-Type": "multipart/form-data",
        //...data.getHeaders()
      },
      data: data,
    };
    var retData = {};
    retData.success = false;
    await axios(config)
      .then(function (response) {
        retData.success = true;
        retData.response = response.data;
      })
      .catch(function (error) {
        retData.success = false;
        retData.error = error;
      });
    return retData;
  };

  const handleOnClickSaveCorrespondence = async () => {
    setIsSaveCorrespondenceDisabled(true);

    let hasFieldError;

    if (!selectedCorrespondence.date) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        dateFieldError: "Date is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.title) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        titleFieldError: "Title is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.clientId) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        clientFieldError: "Client Id is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.patientId) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        patientFieldError: "Patient Id is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.payerId) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        payerFieldError: "Payer Id is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.authorizationId) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        authorizationFieldError: "Authorization Id is required.",
      }));
      hasFieldError = true;
    }

    if (!selectedCorrespondence.status) {
      setCorrespondenceFieldErrors((prevState) => ({
        ...prevState,
        statusFieldError: "Status is required.",
      }));
      hasFieldError = true;
    }

    if (hasFieldError) {
      return;
    }

    let filename;

    if (selectedFile != null && selectedFile != "") {
      var name = selectedFile.name;
      // var fileType = name.substring(name.length - 3);
      filename =
        selectedCorrespondence.clientId +
        "_" +
        selectedCorrespondence.patientId +
        "_" +
        name;
      filename = filename.replace(" ", "_");
      var response = await uploadFile(
        selectedFile,
        filename,
        selectedCorrespondence.clientId
      );
      if (response.success) {
      } else {
        return;
      }
    }

    //clear attachment field value
    var c = document.getElementsByClassName("myfile");
    for (var b = 0; b < c.length; b++) {
      c[b].value = null;
    }

    await props.addUpdateCorrespondence({
      ...selectedCorrespondence,
      attachment: filename,
    });
    await props.getCorrespondence("2000000010");

    if (selectedCorrespondence.isNew == "Y") {
      setSelectedCorrespondence(defaultCorrespondenceData);
    }

    clearFieldErrors();
    setIsSaveCorrespondenceDisabled(false);
  };

  const MaskDateInput = [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleOnClickView = async (record) => {
    var fileName = record.attachment;
    var filetype = fileName.substring(fileName.length - 3);
    var type = "image/tif";
    if (filetype.toUpperCase() == "PNG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/png");
      type = "image/png";
    } else if (filetype.toUpperCase() == "PDF") {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/pdf"
      );
      type = "application/pdf";
    } else if (filetype.toUpperCase() == "TIF") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/tif");
      type = "image/tif";
    } else if (filetype.toUpperCase() == "JPG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/jpg");
      type = "image/jpg";
    } else {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/octet-stream"
      );
      type = "application/octet-stream";

      // download etc files...
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //window.navigator.msOpenBlob(file, "Statement.pdf");
        props.getCorrespondenceDownloadFile(fileName, "");
      } else {
        await props.getCorrespondenceDownloadFile(fileName, record.clientId);

        const file = new Blob([props.correspondenceDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = fileName;
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(fileURL);
            a.removeEventListener("click", clickHandler);
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        return;
      }
    }

    setDownloadedFileType(type);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //window.navigator.msOpenBlob(file, "Statement.pdf");
      props.getCorrespondenceDownloadFile(fileName, "");
    } else {
      //localStorage.setItem("tickler_sent_attachment_filename", data.attachmentFilename);
      //localStorage.setItem("tickler_sent_parent_id", data.parentId);
      //window.open('../attachment');
      await props.getCorrespondenceDownloadFile(fileName, record.clientId);

      if (props.correspondenceDownloadFile instanceof Blob) {
        const file = new Blob([props.correspondenceDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);

        props.correspondenceDownloadFileClear();
        window.open(fileURL, "_blank");
      }
    }
  };

  const renderCorrespondenceForm = () => {
    return (
      <Form>
        <Row>
          <div class="col-lg-4">
            <Form.Group as={Col} controlId="date">
              <Form.Label className="table-form-label mb-0">Date</Form.Label>
              <ReactDatePicker
                className="date-picker"
                calendarIconClassname="date-picker-icon"
                placeholderText="MM/DD/YYYY"
                showIcon
                value={
                  selectedCorrespondence.date
                    ? moment(
                        formatDateToCustomISO(
                          moment(selectedCorrespondence.date).toDate()
                        )
                      ).format("MM/DD/YYYY")
                    : null
                }
                onChange={(date) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    date: formatDateToCustomISO(date),
                  });
                }}
                selected={
                  selectedCorrespondence.date
                    ? formatDateToCustomISO(
                        moment(selectedCorrespondence.date).toDate()
                      )
                    : null
                }
                customInput={
                  <MaskedTextInput type="text" mask={MaskDateInput} />
                }
              />
            </Form.Group>
            <span className="table-form-label-err mb-0">
              {correspondenceFieldErrors.dateFieldError}
            </span>
          </div>
          <div class="col-lg-4">
            <Form.Group as={Col} controlId="title">
              <Form.Label className="table-form-label mb-0">Title</Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="Title"
                value={selectedCorrespondence.title}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    title: e.target.value,
                  });
                }}
              />
              <span className="table-form-label-err mb-0">
                {correspondenceFieldErrors.titleFieldError}
              </span>
            </Form.Group>
          </div>
          <div class="col-lg-4">
            <Form.Group as={Col} controlId="notes">
              <Form.Label className="table-form-label mb-0">Notes</Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="Notes"
                value={selectedCorrespondence.notes}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    notes: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="attachment">
              <Form.Label className="table-form-label mb-0">
                Attachment
              </Form.Label>
              <form id="attachform" name="attachform">
                <Form.Control
                  type="file"
                  id="myfile"
                  name="myfile"
                  accept="image/*, .pdf"
                  className="myfile"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
              </form>
            </Form.Group>
          </div>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="clientId">
              <Form.Label className="table-form-label mb-0">
                Client Name
              </Form.Label>
              <Form.Select
                className="grid-form-control-select"
                value={selectedCorrespondence.clientId}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    clientId: e.target.value,
                  });
                }}
              >
                <option value="">Select client</option>
                {props.clientList.map((client, key) => {
                  return (
                    <option key={key} value={client.id}>
                      {client.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <span className="table-form-label-err mb-0">
              {correspondenceFieldErrors.clientFieldError}
            </span>
          </div>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="patientId">
              <Form.Label className="table-form-label mb-0">
                Patient Id
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="Patient Id"
                value={selectedCorrespondence.patientId}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    patientId: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <span className="table-form-label-err mb-0">
              {correspondenceFieldErrors.patientFieldError}
            </span>
          </div>
        </Row>
        <Row>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="payerId">
              <Form.Label className="table-form-label mb-0">
                Payer Name
              </Form.Label>
              <Form.Select
                className="grid-form-control-select"
                value={selectedCorrespondence.payerId}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    payerId: e.target.value,
                  });
                }}
              >
                <option value="">Select patient</option>
                {props.payers.map((payer, key) => {
                  return (
                    <option key={key} value={payer.id}>
                      {payer.name}
                    </option>
                  );
                })}
              </Form.Select>
              <span className="table-form-label-err mb-0">
                {correspondenceFieldErrors.payerFieldError}
              </span>
            </Form.Group>
          </div>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="authorizationId">
              <Form.Label className="table-form-label mb-0">
                Authorization Id
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="Authorization_id"
                value={selectedCorrespondence.authorizationId}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    authorizationId: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <span className="table-form-label-err mb-0">
              {correspondenceFieldErrors.authorizationFieldError}
            </span>
          </div>
          <div class="col-lg-4 mt-1">
            <Form.Group as={Col} controlId="status">
              <Form.Label className="table-form-label mb-0">Status</Form.Label>
              <Form.Select
                className="grid-form-control-select"
                value={selectedCorrespondence.status}
                onChange={(e) => {
                  setSelectedCorrespondence({
                    ...selectedCorrespondence,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="New">New</option>
                <option value="Completed">Completed</option>
              </Form.Select>
            </Form.Group>
            <span className="table-form-label-err mb-0">
              {correspondenceFieldErrors.statusFieldError}
            </span>
          </div>
          <div class="col-lg-12 mt-1">
            <Button
              className="form-save-btn btn-primary float-end mt-3"
              type="button"
              onClick={handleOnClickSaveCorrespondence}
              disabled={isSaveCorrespondenceDisabled}
            >
              <img
                src={require("../../images/save-btn-icon.png")}
                className="add-icon"
                alt=""
              />
              Save
            </Button>
          </div>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <div className="form-main-title mt-2">
        <label>Fax & Correspondence</label>
      </div>
      <Table bordered className="table-portal">
        <thead>
          <tr>
            <th className="td-first"></th>
            <th
              onClick={(e) => {
                handleClickDateHeader("DATE");
              }}
            >
              Date
            </th>
            <th
              onClick={(e) => {
                handleClickTitleHeader("TITLE");
              }}
            >
              Title
            </th>
            <th>Notes</th>
            <th>Attachment</th>
            <th></th>
            <th>Client Id</th>
            <th>Patient Id</th>
            <th>Payer Id</th>
            <th>Authorization Id</th>
            <th className="td-last">Status</th>
          </tr>
        </thead>
        <tbody>
          {correspondenceListData.map((record, key) => {
            return (
              <>
                <tr
                  onClick={(event) =>
                    handleOnClickRow(
                      { ...record, isNew: "N", btnClick: "N" },
                      event
                    )
                  }
                >
                  <td className="td-first">
                    <i className="fa fa-chevron-down"></i>
                  </td>
                  <td>
                    {moment(
                      formatDateToCustomISO(moment(record.date).toDate())
                    ).format("MM/DD/YYYY")}
                  </td>
                  <td>{record.title}</td>
                  <td>{record.notes}</td>
                  <td>{record.attachment}</td>
                  <td>
                    <a
                      className="btn-view-document-attach view-file-btn"
                      onClick={(e) => handleOnClickView(record)}
                    >
                      VIEW
                    </a>
                  </td>
                  <td>{record.clientId}</td>
                  <td>{record.patientId}</td>
                  <td>{record.payerId}</td>
                  <td>{record.authorizationId}</td>
                  <td className="td-last">{record.status}</td>
                </tr>
                <tr className="main-table collapse">
                  <td className="td-first td-last" colSpan="12">
                    {renderCorrespondenceForm()}
                  </td>
                </tr>
              </>
            );
          })}
          <tr
            onClick={(event) =>
              handleOnClickRow(
                { ...defaultCorrespondenceData, isNew: "Y", btnClick: "N" },
                event
              )
            }
          >
            <td className="td-first">
              <i className="fa fa-chevron-down"></i>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="td-last"></td>
          </tr>
          <tr className="main-table collapse">
            <td className="td-first td-last" colSpan="12">
              {renderCorrespondenceForm()}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
    getCorrespondence: bindActionCreators(getCorrespondence, dispatch),
    addUpdateCorrespondence: bindActionCreators(
      addUpdateCorrespondence,
      dispatch
    ),
    getCorrespondenceDownloadFile: bindActionCreators(
      getCorrespondenceDownloadFile,
      dispatch
    ),
    correspondenceDownloadFileClear: bindActionCreators(
      correspondenceDownloadFileClear,
      dispatch
    ),
    correspondenceSearchClear: bindActionCreators(
      correspondenceSearchClear,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    clientList: state.getClientsByOrgId.data,
    payers: state.getPayersAll.data,
    correspondenceList: state.getCorrespondence.data,
    correspondenceDownloadFile: state.correspondenceDownloadFile.data,
    correspondenceSearchResultList:
      state.correspondenceSearch.correspondenceSearchResults,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Correspondence);
