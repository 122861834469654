import { CALL_API } from '../../../middleware/api'
import * as GetPayersApiActionConstants from "./GetPayersApiActionConstants"
import { getPayersUriPath } from '../EndpointResolver'

export default function getPayersFindAll() {
    return {
        [CALL_API]: {
            endpoint: getPayersUriPath(),
            config: { method: 'get' },
            types: [
                GetPayersApiActionConstants.GET_PAYERS_REQUEST,
                GetPayersApiActionConstants.GET_PAYERS_SUCCESS,
                GetPayersApiActionConstants.GET_PAYERS_FAILED,
            ],
        },
    }
}