import * as AddUpdateAuthorizationProcedureApiActionConstants from '../../redux/actions/api/authorization/AddUpdateAuthorizationProcedureApiActionConstants'

const initialState = {
    data: ''
};

export default function addUpdateAuthorizationProcedureReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_REQUEST:
            return {
                ...state,
            };
        case AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateAuthorizationProcedureApiActionConstants.ADD_UPDATE_AUTHORIZATION_PROCEDURE_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
