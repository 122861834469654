import React, { Component } from "react";
import {Modal, Button, Accordion} from "react-bootstrap";
import { AdobeFonts } from 'react-adobe-fonts'

class MedicalNecessityRequirementsModal extends Component {
    state = { result: {isOpen: false}};

    closeModal = () => {
        let result = { isOpen: false };
        this.props.result(result);
    };

    componentDidMount() {
        console.log(this.props.data)
    }

    render() {
        return(
            <Modal show={this.props.data.isOpen} size="lg">
                <Modal.Body className="auth-modal-body">
                    <label className="auth-modal-title">MEDICAL NECESSITY REQUIREMENTS</label>
                    <div className="auth-modal-sep"></div>
                    <div className="d-flex mt-4" >
                        <p className="label-bold">CPT Code: </p>
                        <p className="label-normal">: 70336</p>
                    </div>
                    <div className="d-flex" >
                        <p className="label-bold">Description: </p>
                        <p className="label-normal">: TEMPOROMANDIBULAR JOINT (TMJ) MRI</p>
                    </div>
                    <p className="label-bold">INDICATIONS FOR TEMPOROMANDIBULAR JOINT (TMJ) MRI</p>
                    <Accordion className="medical-nes-accordion">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                For evaluation of temporomandibular joint dysfunction (TMD) with suspected internal joint
                                derangement with:
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>Persistent symptoms of facial or jaw pain, restricted range of motion, pain and/or noise with TMJ function (i.e., chewing) AND
                                    Conservative therapy with a trial of anti-inflammatory AND behavioral modification* has been unsuccessful for at least
                                    four (4) weeks
                                    * Behavioral modification includes patient education, self-care, cognitive behavior therapy,
                                    physical therapy, and occlusal devices. Muscle relaxants can be used for spasm.
                                    Note: X-ray should be the initial study if there is recent trauma, dislocation, malocclusion, or
                                    dental infection</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button"
                            onClick={this.closeModal.bind(this)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default MedicalNecessityRequirementsModal;