import * as GetClientsApiActionConstants from '../../redux/actions/api/client/GetClientsApiActionConstants'

const initialState = {
    data: ''
};

export default function getClientsReducer(state = initialState, action) {
    switch (action.type) {
        case GetClientsApiActionConstants.GET_CLIENTS_REQUEST:
            return {
                ...state,
            };
        case GetClientsApiActionConstants.GET_CLIENTS_SUCCESS:
            return {
                ...state,
                data: action.data
            };
        case GetClientsApiActionConstants.GET_CLIENTS_FAILED:
            return {
                ...state,
                data: ''
            };
        default: {
            return state
        }
    }
}
