import React, { useState } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux"

import "./LeftPanelMobile.css"

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const LeftPanelMobile = (props) => {


    return (
        <>
          {[false].map((expand) => (
            <Navbar key={expand} bg="light" expand={expand}>
              <Container fluid>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                  className="navbar-mobile-cont"
                >
                  <Offcanvas.Header closeButton>
                    <div className="left-menu-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                        <div className="left-menu-logo-text">
                            <p>AUTHOFI</p>
                        </div>
                    </div>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Nav.Link className="navbar-mobile-btn" href="accounts">PATIENTS</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2" href="dashboard">Dashboard</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2">MEDICAL</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2">ENCOUNTER</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2">STATEMENT</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2">EMPLOYEE</Nav.Link>
                      <Nav.Link className="navbar-mobile-btn mt-2">SETTINGS</Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </>
      );
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

const mapStateToProps = ({}) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanelMobile)