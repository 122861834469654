import * as GetGuarantorPatientsApiActionConstants from '../../redux/actions/api/patient/GetGuarantorPatientsApiActionConstants'

const initialState = {
    data: []
};

export default function getGuarantorPatientsReducer(state = initialState, action) {
    switch (action.type) {
        case GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_REQUEST:
            return {
                ...state,
            };
        case GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_FAILED:
            return {
                ...state,
                data: []
            };
        case GetGuarantorPatientsApiActionConstants.GET_GUARANTOR_PATIENTS_CLEAR:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
