import * as AuthenticationApiActionConstants from '../actions/api/authentication/AuthenticationApiActionConstants'

const ID_TOKEN = "id_token"
const USER_NAME = "userId"

const initialState = {
    isAuthenticated: !!sessionStorage.getItem(ID_TOKEN),
    username: sessionStorage.getItem(USER_NAME),
    errorMessage: '',
}

export default function authentication(state = initialState, action) {
    switch (action.type) {
        case AuthenticationApiActionConstants.AUTHENTICATE_REQUEST:

            return {
                ...state,
            }
        case AuthenticationApiActionConstants.AUTHENTICATE_SUCCESS: {
            sessionStorage.setItem(ID_TOKEN, action.data.token)
            localStorage.setItem(ID_TOKEN, action.data.token)
            sessionStorage.setItem(USER_NAME, action.config.data.userId)

            return {
                ...state,
                isAuthenticated: true,
                username: action.config.data.userId,
                errorMessage: ''
            }
        }
        case AuthenticationApiActionConstants.AUTHENTICATE_FAILED:
            sessionStorage.removeItem(ID_TOKEN)
            localStorage.removeItem(ID_TOKEN)
            sessionStorage.removeItem(USER_NAME)

            return {
                ...state,
                isAuthenticated: false,
                username: null,
                errorMessage: action.error.response.data.message,
            }
        case AuthenticationApiActionConstants.LOG_OUT:
            //alert('LOG_OUT');
            sessionStorage.removeItem(ID_TOKEN)
            localStorage.removeItem(ID_TOKEN)
            sessionStorage.removeItem(USER_NAME)

            return {
                ...state,
                username: null,
                isAuthenticated: false,
            }
        default:
            return state
    }
}
