import { CALL_API } from '../../../middleware/api'
import * as MedicalNecessityActionConstants from "./MedicalNecessityApiActionConstants"
import { getMedicalNecessityUriPath } from '../EndpointResolver'

export function getMedicalNecessity(authId, procedureCodes) {
    return {
        [CALL_API]: {
            endpoint: getMedicalNecessityUriPath(authId, procedureCodes),
            config: { method: 'get', responseType: 'blob' },
            types: [
                MedicalNecessityActionConstants.MEDICAL_NECESSITY_REQUEST,
                MedicalNecessityActionConstants.MEDICAL_NECESSITY_SUCCESS,
                MedicalNecessityActionConstants.MEDICAL_NECESSITY_FAILED,
            ]
        },
    }
}

export function medicalNecessityClear() {
    return {
        type: MedicalNecessityActionConstants.MEDICAL_NECESSITY_CLEAR
    }
}