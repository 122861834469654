import * as GetEmployeeByFacilityIdApiActionConstants from '../../redux/actions/api/employee/GetEmployeeByFacilityIdApiActionConstants'

const initialState = {
    data: ''
};

export default function getEmployeeByFacilityIdReducer(state = initialState, action) {
    switch (action.type) {
        case GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_REQUEST:
            return {
                ...state,
            };
        case GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetEmployeeByFacilityIdApiActionConstants.GET_EMPLOYEE_BY_FACILITY_ID_FAILED:
            return {
                ...state,
                data: ''
            };
        default:
            return state
    }
}
