import React, {Component} from "react";
import "../../../App.css";
import "../../payer/AddPayer.css";
import "../../payer/Payer.css"
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import RightPanel from "../../common/leftPanel/RightPanel";
import {Button, Col, Form, Row, Dropdown} from "react-bootstrap";
import {WithRouter} from "../../common/WithRouter";
import {addUpdatePayer, selectedPayerClear, selectPayer} from "../../../redux/actions/api/payer/AddPayerApiActions";
import PhoneEmail from "../../phone-email/PhoneEmail";
import {
    getTransactionType,
    selectTransactionType, transactionTypeClear
} from "../../../redux/actions/api/transaction-type/GetTransactionTypeApiActions";
import {addUpdateTransactionType} from "../../../redux/actions/api/transaction-type/AddUpdateTransactionTypeApiActions";
import {getSystemsCode} from "../../../redux/actions/api/systemsCode/GetSystemsCodeApiActions";
import USStates from "../../common/USStates";
import getUser from "../../../redux/actions/api/authentication/GetUserApiActions";
import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
//import TransactionType from "./TransactionType";

class AddPayerModal extends Component {

    state = {
        payer_id: null,
        name: "",
        payerId: "",
        shortName: "",
        phoneNumber: "",
        faxNumber: "",
        email: "",
        validated: false,
        saving: false,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        mnemonic: "",
        financialClass: "",
        orgId: "",
        clientId: ""
    };

    savePayer = async (event) => {
        this.setState({saving: true});

        const { name, payerId, shortName, enrolmentRequired, address1, address2,
            city, state, zip, mnemonic, financialClass, orgId, clientId } = this.state;

        let payer = {};

        if(this.props.selectedPayer) {
            payer = this.props.selectedPayer;
        }

        payer.name = name?name.toUpperCase():null;
        payer.payerId = payerId?payerId.toUpperCase():null;
        payer.shortName = shortName?shortName.toUpperCase():null;
        payer.enrolmentRequired = enrolmentRequired?"Y":"N";
        payer.address1 = address1;
        payer.address2 = address2;
        payer.city = city;
        payer.state = state;
        payer.zip = zip;
        payer.mnemonic = mnemonic;
        payer.financialClass = financialClass;
        payer.orgId = orgId;
        payer.clientId = clientId;
        payer.status = "ACTIVE";

        try {
            await this.props.addPayer(payer);
            await this.props.selectPayer(this.props.payer);
            await this.setPayerFields(this.props.payer);
        } catch (e) {

        }
        this.setState({validated: false, saving: false});
    };

    setPayerFields = (payer) => {
        this.setState({
            payer_id: payer.id,
            name: payer.name,
            payerId: payer.payerId,
            shortName: payer.shortName,
            serviceType: payer.serviceType,
            initialSubmissionType: payer.initialSubmissionType,
            enrolmentRequired: payer.enrolmentRequired == "Y",
            address1: payer.address1,
            address2: payer.address2,
            city: payer.city,
            state: payer.state,
            zip: payer.zip,
            mnemonic: payer.mnemonic,
            financialClass: payer.financialClass,
            //orgId: payer.orgId,
            clientId: payer.clientId
        })
    };

    async componentDidMount() {
        if(!this.props.user) {
            let userId = sessionStorage.getItem("userId");
            await this.props.getUser(userId);
        }
        this.setState({orgId: this.props.user.orgId});
        await this.props.getClientsByOrgId(this.props.user.orgId);
        await this.props.getSystemsCode();
        if(this.props.selectedPayer) {
            await this.setPayerFields(this.props.selectedPayer);
        }
    };

    render() {
        const { name, payerId, shortName, enrolmentRequired, address1, address2, city, state, zip, mnemonic, financialClass, clientId } = this.state;

        return(
            <div className="w-100">
                <div className="d-flex align-content-between">
                    <h1 className="mb-3">Add Payer</h1>
                </div>
                <Form className="main-form">
                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Client</Form.Label>
                                <Form.Select className="grid-form-control" value={clientId} onChange={e => this.setState({clientId:e.target.value})}>
                                    <option selected disabled value=""> -- Select Client -- </option>
                                    {
                                        this.props.clientList.map((client, key) => {
                                            return <option key={key} value={client.id}>{client.name}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Payer Name</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    required
                                    className="grid-form-control"
                                    placeholder=""
                                    value={name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                />
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Col} controlId=""  className="ml-2">
                                <Form.Label className="table-form-label mb-0">Payer ID</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    className="grid-form-control"
                                    placeholder=""
                                    value={payerId}
                                    onChange={(e) => this.setState({payerId: e.target.value})}
                                />
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Col} controlId="" className="mr-2">
                                <Form.Label className="table-form-label mb-0">Payer Short Name</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    className="grid-form-control"
                                    placeholder=""
                                    value={shortName}
                                    onChange={(e) => this.setState({shortName: e.target.value})}
                                />
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Col} controlId="" className="mr-2">
                                <Form.Label className="table-form-label mb-0">Mnemonic</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    className="grid-form-control"
                                    placeholder=""
                                    value={mnemonic}
                                    onChange={(e) => this.setState({mnemonic: e.target.value})}
                                />
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Col} controlId="" className="mr-2">
                                <Form.Label className="table-form-label mb-0">Financial Class</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    className="grid-form-control"
                                    placeholder=""
                                    value={financialClass}
                                    onChange={(e) => this.setState({financialClass: e.target.value})}
                                />
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Col} controlId="" className="d-flex align-items-center justify-content-center mt-2">
                                <Form.Check
                                    className="grid-form-control mt-3 mb-0"
                                    type="checkbox"
                                    label="Enrollment Required"
                                    checked={enrolmentRequired}
                                    onChange={(e) => this.setState({enrolmentRequired: e.target.checked})}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div class="col-lg-12 col-12">
                            <Row className="mb-1">
                                <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Address 1</Form.Label>
                                        <Form.Control className="grid-form-control" maxLength={40} value={address1} onChange={e => this.setState({address1:e.target.value})}/>
                                    </Form.Group>
                                </div>
                                <div class="col-lg-6 col-6">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Address 2</Form.Label>
                                        <Form.Control className="grid-form-control" maxLength={40} value={address2} onChange={e => this.setState({address2:e.target.value})}/>
                                    </Form.Group>
                                </div>
                            </Row>
                            <Row>
                                <div class="col-lg-4 col-6 mt-1">
                                    <Form.Group as={Col} controlId="patientFullName">
                                        <Form.Label className="table-form-label mb-0">City</Form.Label>
                                        <Form.Control className="grid-form-control" maxLength={40} value={city} onChange={e => this.setState({city:e.target.value})}/>
                                    </Form.Group>
                                </div>
                                <div class="col-lg-4 col-6 mt-1">
                                    <Form.Group as={Col} controlId="patientFullName">
                                        <Form.Label className="table-form-label mb-0">State</Form.Label>
                                        <Form.Select className="grid-form-control" value={state} onChange={e => this.setState({state:e.target.value})}>
                                            <option selected disabled value=""> -- Select State -- </option>
                                            {
                                                USStates.map((USState, key) => {
                                                    return <option key={key} value={USState.abbreviation}>{USState.name}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div class="col-lg-4 col-6 mt-1">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Zip</Form.Label>
                                        <Form.Control className="grid-form-control" maxLength={10} value={zip} onChange={e => this.setState({zip:e.target.value})}/>
                                    </Form.Group>
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <div className="d-flex mt-3 justify-content-center mb-3">
                        <Button className="btn btn-success grid-btn grid-btn" variant="primary" type="button" onClick={async (e) => {
                            await this.savePayer(e);
                            this.props.cancelled({isOpen: false})
                        }}>
                            Save
                        </Button>
                        <Button className="btn btn-primary grid-btn grid-btn" variant="secondary" type="button"
                                onClick={() => {
                                    this.props.selectedPayerClear();
                                    this.props.transactionTypeClear();
                                    this.props.cancelled({isOpen: false})
                                }}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPayer: bindActionCreators(addUpdatePayer, dispatch),
        selectPayer: bindActionCreators(selectPayer, dispatch),
        selectedPayerClear: bindActionCreators(selectedPayerClear, dispatch),
        getTransactionType: bindActionCreators(getTransactionType, dispatch),
        selectTransactionType: bindActionCreators(selectTransactionType, dispatch),
        transactionTypeClear: bindActionCreators(transactionTypeClear, dispatch),
        addUpdateTransactionType: bindActionCreators(addUpdateTransactionType, dispatch),
        getSystemsCode: bindActionCreators(getSystemsCode, dispatch),
        getUser: bindActionCreators(getUser, dispatch),
        getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    }
};

const mapStateToProps = state => {
    return {
        payer: state.addUpdatePayer.data,
        selectedPayer: state.addUpdatePayer.selectedPayer,
        selectedTransactionType: state.getTransactionType.selected,
        transactionType: state.getTransactionType.data,
        addedTransactionType: state.addUpdateTransactionType.data,
        serviceTypes: state.getSystemsCode.serviceTypes,
        user: state.currentUser.data,
        clientList: state.getClientsByOrgId.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(AddPayerModal));
