import { CALL_API } from '../../../middleware/api'
import * as GetAuthorizationNoteApiActionConstants from "./GetAuthorizationNoteApiActionConstants"
import { getAuthorizationNoteUriPath } from '../EndpointResolver'

export function getAuthorizationNote(authorizationId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationNoteUriPath(authorizationId),
            config: { method: 'get' },
            types: [
                GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_REQUEST,
                GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_SUCCESS,
                GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_FAILED,
            ],
        },
    }
}

export function getAuthorizationNoteSelected(val) {
     return function (dispatch) {
         dispatch({
             type: GetAuthorizationNoteApiActionConstants.GET_AUTHORIZATION_NOTE_SELECTED,
             val: val
         })
     }
}