import * as ValidateIcd10cmCodeApiActionConstants from '../actions/api/icd10cm/ValidateIcd10cmCodeApiActionConstants'


const initialState = {
    data: {}
}

export default function validateIcd10cmCodeReducer(state = initialState, action) {
    switch (action.type) {
        case ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_REQUEST:
            return {
                ...state
            }
        case ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_SUCCESS: {
            return {
                ...state,
                data: action.data
            }
        }
        case ValidateIcd10cmCodeApiActionConstants.VALIDATE_ICD10CM_CODE_FAILED:
            return {
                ...state
            }
        default:
            return state
    }
}
