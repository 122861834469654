import * as GetAuthorizationDocumentationApiActionConstants from '../../redux/actions/api/authorizationDocumentation/GetAuthorizationDocumentationApiActionConstants'

const initialState = {
    data: []
};

export default function getAuthorizationDocumentationReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_FAILED:
            return {
                ...state,
                data: []
            };
        case GetAuthorizationDocumentationApiActionConstants.GET_AUTHORIZATION_DOCUMENTATION_CLEAR:
            return {
                ...state,
                data: 'CLEARED'
            };
        default:
            return state
    }
}
