import { CALL_API } from '../../../middleware/api'
import * as AddUpdateOrganizationApiActionConstants from "./AddUpdateOrganizationApiActionConstants"
import {getAddUpdateOrganizationUriPath} from '../EndpointResolver'

export function addUpdateOrganization(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateOrganizationUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateOrganizationApiActionConstants.ADD_UPDATE_ORGANIZATION_REQUEST,
                AddUpdateOrganizationApiActionConstants.ADD_UPDATE_ORGANIZATION_SUCCESS,
                AddUpdateOrganizationApiActionConstants.ADD_UPDATE_ORGANIZATION_FAILED,
            ]
        },
    }
}

export function addUpdateOrganizationSelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateOrganizationApiActionConstants.ADD_UPDATE_ORGANIZATION_SELECTED,
             val: val
         })
     }
}