import * as GuarantorPatientSearchDuplicateApiActionConstants from '../actions/api/guarantor-patients-search/GuarantorPatientSearchDuplicateApiActionConstants'

const initialState = {
    data: ''
}

export default function guarantorPatientSearchDuplicate(state = initialState, action) {
    console.log(action.type);
    switch (action.type) {
        case GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_REQUEST:
            return {
                ...state
            }
        case GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_SUCCESS: {
            console.log(action.data);
            return {
                ...state,
                data: action.data
            }
        }
        case GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_FAILED:
            return {
                ...state,
                data: ''
            }
        case GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_CLEAR:
            return {
                ...state,
                data: 'CLEARED'
            };
        default:
            return state
    }
}
