import { CALL_API } from '../../../middleware/api'
import * as GuarantorPatientSearchDuplicateApiActionConstants from "./GuarantorPatientSearchDuplicateApiActionConstants"
import {getGuarantorPatientPatientsSearchDuplicateUriPath} from '../EndpointResolver'

export function guarantorPatientSearchDuplicate(id, orgId, clientId, facilityId, type, mrn, ssn, lastName, firstName, dob) {
    return {
        [CALL_API]: {
            endpoint: getGuarantorPatientPatientsSearchDuplicateUriPath(id, orgId, clientId, facilityId, type, mrn, ssn, lastName, firstName, dob),
            config: { method: 'get' },
            types: [
                GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_REQUEST,
                GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_SUCCESS,
                GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_FAILED,
            ],
        },
    }
}

export function guarantorPatientSearchDuplicateClear() {
     return function (dispatch) {
         dispatch({
             type: GuarantorPatientSearchDuplicateApiActionConstants.GUARANTOR_PATIENT_SEARCH_DUPLICATE_CLEAR
         })
     }
}