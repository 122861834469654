import * as GetAuthorizationByIdApiActionConstants from '../../redux/actions/api/authorization/GetAuthorizationByIdApiActionConstants'

const initialState = {
    data: {}
};

export default function getAuthorizationByIdReducer(state = initialState, action) {
    switch (action.type) {
        case GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_REQUEST:
            return {
                ...state,
            };
        case GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_FAILED:
            return {
                ...state,
                data: {}
            };
        case GetAuthorizationByIdApiActionConstants.GET_AUTHORIZATION_BY_ID_SELECTED:
            return {
                ...state,
                data: action.val
            };
        default:
            return state
    }
}
