import { CALL_API } from '../../../middleware/api'
import * as AddUpdateAuthorizationEligibilityApiActionConstants from "./AddUpdateAuthorizationEligibilityApiActionConstants"
import {getAuthInsEligibilityUriPath, getAuthorizationEligibilityUriPath} from '../EndpointResolver'

export function addUpdateAuthorizationEligibility(requestParams = []) {

    return {
        [CALL_API]: {
            endpoint: getAuthInsEligibilityUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_REQUEST,
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_SUCCESS,
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_FAILED,
            ]
        },
    }
}

export function getAuthorizationEligibility(authId) {
    return {
        [CALL_API]: {
            endpoint: getAuthorizationEligibilityUriPath(authId),
            config: { method: 'get' },
            types: [
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_REQUEST,
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_SUCCESS,
                AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_FAILED,
            ],
        },
    }
}

export function addUpdateAuthorizationEligibilitySelected(val) {
     return function (dispatch) {
         dispatch({
             type: AddUpdateAuthorizationEligibilityApiActionConstants.ADD_UPDATE_AUTHORIZATION_ELIGIBILITY_SELECTED,
             val: val
         })
     }
}