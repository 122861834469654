import * as GuarantorSearchApiActionConstants from "../actions/api/guarantor-patients-search/GuarantorSearchApiActionConstants";

const ID_TOKEN = "id_token";
const USER_NAME = "userId";

const initialState = {
  guarantorPatientsSearchResults: [],
};

export default function guarantorSearch(state = initialState, action) {
  switch (action.type) {
    case GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_REQUEST:
      return {
        ...state,
      };
    case GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_SUCCESS: {
      return {
        ...state,
        guarantorPatientsSearchResults: action.data,
      };
    }
    case GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_FAILED:
      return {
        ...state,
      };
    case GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_UPDATE:
      return {
        ...state,
        guarantorPatientsSearchResults:
          state.guarantorPatientsSearchResults.map((obj) =>
            obj.id === action.val.id ? action.val : obj
          ),
      };
    default:
      return state;
  }
}
