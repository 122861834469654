import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import {connect} from 'react-redux'

import {
    PublicRoutes,
    AuthenticatedRoutes
} from './routes'


import './App.css';
import MainView from "./components/common/main/MainView";

const App = (props) => {

    const renderPublicPage = () => (<PublicRoutes />);
    const renderAuthenticatedPage = () => (<MainView />);

    if(props.isAuthenticated){
        return renderAuthenticatedPage();
    }

    return renderPublicPage();
}

function mapStateToProps({ authentication }) {
    return {
        isAuthenticated: authentication.isAuthenticated
    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
