import * as AddUpdateTransactionTypeApiActionConstants from '../../redux/actions/api/transaction-type/AddUpdateTransactionTypeApiActionConstants'

const initialState = {
    data: null
};

export default function addUpdateTransactionTypeReducer(state = initialState, action) {
    switch (action.type) {
        case AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_REQUEST:
            return {
                ...state,
            };
        case AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
        }
        case AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_FAILED:
            return {
                ...state,
                data: null
            };
        default:
            return state
    }
}