import { CALL_API } from '../../../middleware/api'
import * as AddUpdateTransactionTypeApiActionConstants from "./AddUpdateTransactionTypeApiActionConstants"
import {getAddUpdateTransactionTypeUriPath} from '../EndpointResolver'

export function addUpdateTransactionType(requestParams = {}) {

    return {
        [CALL_API]: {
            endpoint: getAddUpdateTransactionTypeUriPath(),
            config: {
                data: requestParams,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: requestParams },
            types: [
                AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_REQUEST,
                AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_SUCCESS,
                AddUpdateTransactionTypeApiActionConstants.ADD_UPDATE_TRANSACTION_TYPE_FAILED,
            ]
        },
    }
}