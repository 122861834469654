import { CALL_API } from '../../../middleware/api'
import * as GetGuarantorPhoneEmailApiActionConstants from "./GetGuarantorPhoneEmailApiActionConstants"
import {getGuarantorPhoneEmailUriPath} from '../EndpointResolver'

export function getGuarantorPhoneEmail(guarantorId) {
    return {
        [CALL_API]: {
            endpoint: getGuarantorPhoneEmailUriPath(guarantorId),
            config: { method: 'get' },
            types: [
                GetGuarantorPhoneEmailApiActionConstants.GET_GUARANTOR_PHONE_EMAIL_REQUEST,
                GetGuarantorPhoneEmailApiActionConstants.GET_GUARANTOR_PHONE_EMAIL_SUCCESS,
                GetGuarantorPhoneEmailApiActionConstants.GET_GUARANTOR_PHONE_EMAIL_FAILED,
            ],
        },
    }
}

export function guarantorPhoneEmailClear() {
     return function (dispatch) {
         dispatch({
             type: GetGuarantorPhoneEmailApiActionConstants.GET_GUARANTOR_PHONE_EMAIL_CLEAR
         })
     }
}