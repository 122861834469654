import React, { useState, Component } from "react";
import "../../App.css";
import "./Authorizations.css"
import {connect} from "react-redux";
import {bindActionCreators} from "redux"
import RightPanel from '../common/leftPanel/RightPanel';
import SelectPayer from './selectPayer/SelectPayer';
import RequestProvider from './requestProvider/RequestProvider';
import PatientInformation from './patientInformation/PatientInformation';
import ProcedureCode from './procedureCode/ProcedureCode';
import ServiceInformation from './serviceInformation/ServiceInformation';
import ServiceProvider from './serviceProvider/ServiceProvider';

import {
  Routes,
  Route
} from "react-router-dom";

class Authorizations extends Component {


   render() {

        return (
            <div className="w-100">
                <div className="auth-content-header d-flex justify-content-between">
                    <div className="d-flex">
                        <h5>
                            <img alt="#" src={require("../../images/AuthoFiLogo.jpg")}/>
                        </h5>
                    </div>
                    <div className="d-flex justify-content-end">
                        <RightPanel/>
                    </div>
                </div>
                <div className="auth-content-main d-flex align-items-center justify-content-center">
                    <div className="auth-content-content p-2">
                        <Routes>
                            <Route path="/" element={<SelectPayer/>}/>
                            <Route path="/authorizations" element={<SelectPayer/>}/>
                            <Route path="/patient-information" element={<PatientInformation/>}/>
                            <Route path="/request-provider" element={<RequestProvider/>}/>
                            <Route path="/procedure-code" element={<ProcedureCode/>}/>
                            <Route path="/service-information" element={<ServiceInformation/>}/>
                            <Route path="/service-provider" element={<ServiceProvider/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorizations)