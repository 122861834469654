import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { WithRouter } from "../../common/WithRouter";
import { guarantorPatientsSearch } from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";
import moment from "moment";
import { setAuthDto } from "../../../redux/actions/api/authorization/CreateAuthDTOApiActions";
import ReactDatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { formatDateToCustomISO } from "../../common/UtilsDate";

class PatientInformation extends Component {
  state = {
    searchPatientsModal: false,
    patientName: null,
    selectedPatient: null,
  };

  patientInfoNext = () => {
    let path = "/authorizations/request-provider";
    this.props.navigate(path);
  };

  patientInfoPrev = () => {
    let path = "/authorizations";
    this.props.navigate(path);
  };

  patientNameFocus = () => {
    this.setState({ searchPatientsModal: true });
  };

  searchPatientsModalClose = () => {
    this.setState({ searchPatientsModal: false });
  };

  searchPatients = () => {
    const { searchMrn, searchLastName, searchFirstName, searchDob } =
      this.state;
    let dob = undefined;
    if (!searchMrn && !searchLastName && !searchFirstName && !searchDob) return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      if (searchDob) {
        dob = moment(searchDob).format("MM/DD/YYYY");
      } else {
        dob = undefined;
      }
      this.props.guarantorPatientsSearch(
        searchMrn,
        lastName,
        firstName,
        dob,
        "P"
      );
    } catch (err) {
      console.log(err);
    }
  };

  selectPatient = (patient) => {
    console.log(patient);
    this.setState({
      selectedPatient: patient,
      patientName: patient.lastName + ", " + patient.firstName,
    });

    let dto = { ...this.props.authDto };
    dto.authorization.patientMrn = patient.mrn;
    dto.authorization.patientDob = patient.dob;
    dto.authorization.patientFirstName = patient.firstName;
    dto.authorization.patientLastName = patient.lastName;
    dto.authorization.patientName = patient.lastName + ", " + patient.firstName;
    dto.authorization.patientGender = patient.gender;

    this.props.setAuthDto(dto);
    console.log(this.props.authDto);
  };

  render() {
    const { searchPatientsModal, patientName } = this.state;
    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    return (
      <div className="w-100">
        <Modal show={searchPatientsModal} size="lg" centered>
          <Modal.Header>
            <h1>Search Patients</h1>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    MRN:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="mrn"
                    type="text"
                    placeholder="Enter MRN"
                    onChange={(e) =>
                      this.setState({ searchMrn: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Last Name:{" "}
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="lastname"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ searchLastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    First Name:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="firstname"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ searchFirstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Date of Birth:
                  </Form.Label>
                  <ReactDatePicker
                    className="date-picker"
                    calendarIconClassname="date-picker-icon"
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    onChange={(date) =>
                      this.setState({
                        searchDob: formatDateToCustomISO(date),
                      })
                    }
                    selected={
                      this.state.searchDob
                        ? formatDateToCustomISO(
                            moment(this.state.searchDob).toDate()
                          )
                        : null
                    }
                    customInput={
                      <MaskedTextInput type="text" mask={MaskDateInput} />
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button
                  className="mt-2 w-75"
                  onClick={this.searchPatients.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="mt-3">
              <h3>Search Results</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>MRN</td>
                    <td>Date of Birth</td>
                  </tr>
                </thead>
                <tbody>
                  {this.props.guarantorPatientsSearchResults.map(
                    (patient, key) => {
                      return (
                        <tr
                          key={key}
                          onClick={() => {
                            this.selectPatient(patient);
                            this.searchPatientsModalClose();
                          }}
                        >
                          <td>{patient.lastName + ", " + patient.firstName}</td>
                          <td>{patient.mrn}</td>
                          <td>{patient.dob}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="button"
              onClick={this.searchPatientsModalClose.bind(this)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <div class="row mt-2">
          <div class="col-lg-3 mt-2 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="green"
              class="bi bi-1-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z" />
            </svg>
            <div>
              <label class="auth-step-active">Start an Authorization</label>
            </div>
          </div>
          <div class="col-lg-3 mt-2 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-2-circle"
              viewBox="0 0 16 16"
            >
              <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306Z" />
            </svg>
            <div>
              <label class="auth-step">Add Service Information</label>
            </div>
          </div>
          <div class="col-lg-3 mt-2 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-3-circle"
              viewBox="0 0 16 16"
            >
              <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z" />
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z" />
            </svg>
            <div>
              <label class="auth-step">Rendering Provider/Facility</label>
            </div>
          </div>
          <div class="col-lg-3 mt-2 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-4-circle"
              viewBox="0 0 16 16"
            >
              <path d="M7.519 5.057c.22-.352.439-.703.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Z" />
            </svg>
            <div>
              <label class="auth-step">Review and Submit/Facility</label>
            </div>
          </div>
        </div>
        <div class="patient-info">
          <div class="row">
            <div class="col-lg-3 d-flex">
              <div class="info-active"></div>
              <div>
                <div class="info-tittle">
                  <label>Request Type</label>
                </div>
                <div class="info-desc">
                  <label>{this.props.authDto.authorization.requestType}</label>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="info-tittle">
                <label>Client</label>
              </div>
              <div class="info-desc">
                <label>{this.props.authDto.authorization.clientName}</label>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="info-tittle">
                <label>Payer</label>
              </div>
              <div class="info-desc">
                <label>{this.props.authDto.authorization.payerName}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-1 emp-label-select mt-4">
          <label>Patient Information</label>
        </div>
        <div class="row mt-2">
          <div class="col-lg-6">
            <Form.Group as={Col} controlId="patientFullName">
              <Form.Label className="table-form-label mb-0">
                Select a Patient
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                placeholder="Click to Select Patient"
                value={this.props.authDto.authorization.patientName}
                onClick={this.patientNameFocus.bind(this)}
                readOnly={true}
              />
            </Form.Group>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-3">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Member ID
              </Form.Label>
              <Form.Control className="grid-form-control" />
            </Form.Group>
          </div>
          <div class="col-lg-3">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Relationship to Subscriber
              </Form.Label>
              <Form.Select className="grid-form-control">
                <option value="1">Relationship</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-3">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Patient First Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.props.authDto.authorization.patientFirstName}
                readOnly={true}
              />
            </Form.Group>
          </div>
          <div class="col-lg-3">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Patient Last Name
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.props.authDto.authorization.patientLastName}
                readOnly={true}
              />
            </Form.Group>
          </div>
          <div class="col-lg-3">
            <Form.Group as={Col} controlId="">
              <Form.Label className="table-form-label mb-0">
                Patient Date of Birth
              </Form.Label>
              <Form.Control
                className="grid-form-control"
                value={this.props.authDto.authorization.patientDob}
                readOnly={true}
              />
            </Form.Group>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-2 req-provider-btn-cont">
            <Button
              className="prev-btn btn-secondary mt-4 m-0"
              variant="primary"
              type="button"
              onClick={this.patientInfoPrev}
            >
              Previous
            </Button>
          </div>
          <div class="col-lg-2 req-provider-btn-cont">
            <Button
              className="req-provider-btn btn-primary mt-4 m-0"
              variant="primary"
              type="button"
              onClick={this.patientInfoNext}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    setAuthDto: bindActionCreators(setAuthDto, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
    authDto: state.createAuthDto.authDto,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter(PatientInformation));
